import React, { useState } from 'react';
import { useFirestoreCollectionData, useFirestoreDocData } from 'reactfire';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';

import { ADSCard, ADSSpeechBubble, ADSText } from '@aphrodite/common-ui';
import {
  LAUNCH_DATE_GLOBAL,
  LAUNCH_DATE_SINGAPORE,
  QUESTIONNAIRE_CLOSE_DATE_GLOBAL,
  QUESTIONNAIRE_CLOSE_DATE_SINGAPORE,
} from '@aphrodite/common/constants/Dates';
import { Region } from '@aphrodite/common/constants/Region';
import { useAuthHelper, useGetDateStatistics } from '@aphrodite/common/hooks';
import { useIsSingaporeWebsite } from '@aphrodite/common/hooks';
import { ICollegeConfiguration, IUserProfile } from '@aphrodite/common/types/firestore-types';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';
import { DocumentReference, collection, doc, query, where } from '@firebase/firestore';

import COLLEGE_CONSTANTS, { CollegesEnum, REGION_UTC_OFFSET } from '../../constants/College';
import DashboardLoading from './DashboardLoading';

const StyledADSCard = styled(ADSCard)`
  padding: 4%;
`;
const StyledGraphContainer = styled.div`
  height: 300px;
  width: 100%;
`;
const StyledTooltipItem = styled(ADSText)<{ textColor: string }>`
  color: ${(props) => props.textColor};
`;
const StyledTooltipLabel = styled.div`
  margin-bottom: 8px;
  min-width: 20px;
`;
const StyledTooltip = styled.div``;
interface Props {
  className?: string;
}
const StyledSelectionContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const StyledLeftButton = styled.button<{ isSelected: boolean }>`
  background: ${(props) =>
    props.isSelected ? props.theme.colors.aphroPinkHighlight : 'transparent'};
  border-color: ${(props) =>
    props.isSelected ? props.theme.colors.black80 : props.theme.colors.standardGrey40};
  border-radius: 8px 0 0 8px;
  border-width: ${(props) => (props.isSelected ? '1px' : '1px 0 1px 1px')};
  box-shadow: none;
  outline: none;
  padding: 8px 12px;
  &:hover {
    background-color: ${(props) =>
      props.disabled
        ? props.theme.colors.aphroPinkHighlight
        : props.theme.colors.aphroPinkHighlightTransparent};
    cursor: pointer;
  }
`;
const StyledRightButton = styled.button<{ isSelected: boolean }>`
  background: ${(props) =>
    props.isSelected ? props.theme.colors.aphroPinkHighlight : 'transparent'};
  border-color: ${(props) =>
    props.isSelected ? props.theme.colors.black80 : props.theme.colors.standardGrey40};
  border-radius: 0 8px 8px 0;
  border-width: ${(props) => (props.isSelected ? '1px' : '1px 1px 1px 0')};
  box-shadow: none;
  padding: 8px 12px;
  &:hover {
    background-color: ${(props) =>
      props.disabled
        ? props.theme.colors.aphroPinkHighlight
        : props.theme.colors.aphroPinkHighlightTransparent};
    cursor: pointer;
  }
`;
const StyledHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const StyledStandardToggleText = styled(ADSText)`
  @media only screen and (max-width: 680px) {
    display: none;
  }
`;
const StyledCompressedToggleText = styled(ADSText)`
  @media only screen and (min-width: 680px) {
    display: none;
  }
`;
export default function DashboardCardGraph({ className }: Props): React.ReactElement {
  const [viewSignupNumbers, setViewSignupNumbers] = useState(true);
  const toggleViewSignUpNumbers = () => {
    setViewSignupNumbers(!viewSignupNumbers);
  };
  const renderDescription = () => {
    if (viewSignupNumbers) {
      return (
        <ADSText size="xs" color="standardGrey70" wrapperStyle={{ marginTop: '8px' }}>
          Number of signups by college
        </ADSText>
      );
    }
    return (
      <ADSText size="xs" color="standardGrey70" wrapperStyle={{ marginTop: '8px' }}>
        Percentage of undergraduate population signed up (%)
      </ADSText>
    );
  };
  return (
    <StyledADSCard backgroundColor="translucent" className={className}>
      <StyledHeaderContainer>
        <ADSText weight="semibold" size="l">
          Participants so far
        </ADSText>
        <StyledSelectionContainer>
          <StyledLeftButton
            isSelected={viewSignupNumbers}
            disabled={viewSignupNumbers}
            onClick={toggleViewSignUpNumbers}
          >
            <StyledStandardToggleText size="xs"># Signups</StyledStandardToggleText>
            <StyledCompressedToggleText size="xs">#</StyledCompressedToggleText>
          </StyledLeftButton>
          <StyledRightButton
            isSelected={!viewSignupNumbers}
            disabled={!viewSignupNumbers}
            onClick={toggleViewSignUpNumbers}
          >
            <StyledStandardToggleText size="xs">% Signups</StyledStandardToggleText>
            <StyledCompressedToggleText size="xs">%</StyledCompressedToggleText>
          </StyledRightButton>
        </StyledSelectionContainer>
      </StyledHeaderContainer>
      {renderDescription()}
      <br />
      <StyledGraphContainer>
        <ExampleGraph viewSignupNumbers={viewSignupNumbers} />
      </StyledGraphContainer>
    </StyledADSCard>
  );
}
interface GraphProps {
  viewSignupNumbers: boolean;
}
function ExampleGraph({ viewSignupNumbers }: GraphProps): React.ReactElement {
  const { user } = useAuthHelper();
  const isSingaporeWebsite = useIsSingaporeWebsite();
  const getCollegeStyle = (college: string) => {
    if (college === 'mitxharvard') {
      return COLLEGE_CONSTANTS.COLLEGE_COLORS[CollegesEnum.HARVARD].default.background; // We just use the Harvard school color for both
    }
    return COLLEGE_CONSTANTS.COLLEGE_COLORS[college as CollegesEnum]?.default.background;
  };
  // Get user's college
  const userProfileRef = doc(
    firebaseFirestore,
    `UserProfiles/${user!.uid}`,
  ) as DocumentReference<IUserProfile>;
  const { data: userProfileData } = useFirestoreDocData<IUserProfile>(userProfileRef);
  const { college } = userProfileData;
  const collegeConfigIndex = 'College' + college.charAt(0).toUpperCase() + college.slice(1);
  // Check college region
  const collegeConfigRef = doc(
    firebaseFirestore,
    `Configuration/${collegeConfigIndex}`,
  ) as DocumentReference<ICollegeConfiguration>;
  const { status: collegeConfigStatus, data: collegeFlagData } =
    useFirestoreDocData<ICollegeConfiguration>(collegeConfigRef);
  // Retrieve list of active colleges in the user's region
  const region: Region | undefined = collegeFlagData?.region;
  const collegeRef = collection(firebaseFirestore, 'Configuration');
  const collegeQuery = query(
    collegeRef,
    where('isEnabled', '==', true),
    where('region', '==', (region ?? '').toUpperCase()),
  );
  const { status: activeCollegesStatus, data: activeCollegesData } = useFirestoreCollectionData(
    collegeQuery,
    { idField: 'id' },
  );
  const { statisticsStatus, statisticsData } = useGetDateStatistics(region);
  if (
    collegeConfigStatus === 'loading' ||
    activeCollegesStatus === 'loading' ||
    statisticsStatus === 'loading' ||
    !activeCollegesData ||
    !statisticsData ||
    !collegeFlagData ||
    !region
  ) {
    return <DashboardLoading />;
  }
  // Get the active colleges (in the user's college region) that are currently enabled on firestore
  const activeColleges = activeCollegesData
    .map((data) => {
      const collegeItem = {
        indexName: data.indexName,
        undergradPopulation: data.undergradPopulation,
      };
      return collegeItem;
    })
    .filter((item) => {
      return item.indexName !== 'harvard' && item.indexName !== 'mit';
    });
  if (region === Region.NORTH_AMERICA) {
    activeColleges.push({
      indexName: 'mitxharvard',
      undergradPopulation: 9860,
    });
  }
  // Initialise starting value to find cumulative sum of stats
  const firstDay: any = {
    date: statisticsData[0]?.date.toDate(),
  };
  activeColleges.forEach((item) => {
    firstDay[item.indexName] = 0;
  });
  const convertFirestoreDateToRegionalTime = (date: Date) => {
    let newDate = new Date(date.getTime());
    const localTimezoneOffset = newDate.getTimezoneOffset();
    newDate.setMinutes(newDate.getMinutes() + localTimezoneOffset + REGION_UTC_OFFSET[region] * 60);
    return newDate;
  };
  const signupsData = statisticsData.map(
    ((sum: any) => (value: any) => {
      let item: any = {
        date: convertFirestoreDateToRegionalTime(value.date.toDate()),
      };
      activeColleges.forEach((college) => {
        const collegeName = college.indexName;
        if (!(collegeName in value)) return;
        const newSum = (sum[collegeName] += value[collegeName]);
        const percentageUndergrad = (newSum / college.undergradPopulation) * 100;
        item[collegeName] = viewSignupNumbers ? newSum : percentageUndergrad.toFixed(1);
      });
      return item;
    })(firstDay),
  );
  // Add in day before launch with all numbers initialised to 0 so the graph starts from origin
  const latestLaunchDate = isSingaporeWebsite ? LAUNCH_DATE_SINGAPORE : LAUNCH_DATE_GLOBAL;
  const dayBeforeLaunch = latestLaunchDate.toDate();
  dayBeforeLaunch.setDate(dayBeforeLaunch.getDate() - 1);
  const originData: any = {
    date: dayBeforeLaunch,
  };
  activeColleges.forEach((item) => {
    originData[item.indexName] = 0;
  });
  signupsData.unshift(originData);
  // Add in an empty entry to demarcate the questionnaire close date on the graph
  let today = new Date();
  today = convertFirestoreDateToRegionalTime(today);
  today.setHours(0, 0, 0, 0);
  const questionnaireCloseDate = isSingaporeWebsite
    ? QUESTIONNAIRE_CLOSE_DATE_SINGAPORE
    : QUESTIONNAIRE_CLOSE_DATE_GLOBAL;
  let endDate = questionnaireCloseDate.toDate();
  endDate.setHours(0, 0, 0, 0);
  if (endDate > today) {
    let endDateEntry: any = {
      date: endDate,
    };
    activeColleges.forEach((college) => {
      endDateEntry[college.indexName] = null;
    });
    signupsData.push(endDateEntry);
  }
  return (
    <ResponsiveContainer>
      <LineChart data={signupsData}>
        <XAxis
          axisLine={{ stroke: 'rgba(211,211,211)' }}
          dataKey="date"
          padding={{ left: 20 }}
          tickFormatter={(date) => {
            return date.toLocaleString('default', {
              day: 'numeric',
              month: 'short',
            });
          }}
          tickLine={false}
          tickMargin={12}
        />
        <YAxis
          axisLine={{ stroke: 'rgba(211,211,211)' }}
          padding={{ bottom: 20, top: 32 }}
          tickCount={4}
          tickMargin={12}
          tickLine={false}
        />
        {activeColleges.map((college) => {
          return (
            <Line
              activeDot={{ strokeWidth: 4, r: 5 }}
              dataKey={college.indexName}
              dot={false}
              key={college.indexName}
              stroke={getCollegeStyle(college.indexName)}
              strokeWidth={2}
              type="monotone"
            />
          );
        })}
        <Tooltip
          offset={20}
          content={({ active, payload, label }) => {
            if (active) {
              payload?.sort(
                (first, second) =>
                  parseFloat(second?.value as string) - parseFloat(first?.value as string),
              );
              return (
                <StyledTooltip>
                  <ADSSpeechBubble>
                    <StyledTooltipLabel>
                      <ADSText size="xs" weight="semibold">
                        {label.toLocaleString('default', {
                          day: 'numeric',
                          month: 'short',
                        })}
                      </ADSText>
                    </StyledTooltipLabel>
                    {payload?.map((data) => {
                      const { name, value } = data;
                      return (
                        <StyledTooltipItem
                          size="xs"
                          key={name}
                          textColor={getCollegeStyle(name as string)}
                        >
                          {name}: {value}
                        </StyledTooltipItem>
                      );
                    })}
                  </ADSSpeechBubble>
                </StyledTooltip>
              );
            }
            return <></>;
          }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
