import React from 'react';
import { NavLink } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';
import styled from 'styled-components';

import { ADSText } from '@aphrodite/common-ui';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';
import { doc } from '@firebase/firestore';

import { PROFILE_IMG_URL } from '../../constants/Questionnaire';

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 680px) {
    width: 300px;
    height: 400px;
    margin: 20px;
  }
  width: 150px;
  height: 200px;
  margin: 20px;
`;
const StyledImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.5);
  overflow: hidden;
  position: relative;
  width: 90px;
  height: 90px;
  border-radius: 20px;
  margin: 10px;
  @media only screen and (min-width: 680px) {
    width: 190px;
    height: 190px;
    border-radius: 20px;
    margin: 20px;
  }
`;
const StyledImg = styled.img`
  box-shadow: rgb(0 0 0 / 60%) 0px 7px 30px -10px;
  margin-top: 30px;
  min-width: 100%;
  min-height: 100%;
  opacity: 0.3;
  filter: blur(8px);
  -webkit-filter: blur(4px);
  margin-bottom: 30px;
`;
const StyledNavLink = styled(NavLink)`
  margin-top: 20px;
  margin-bottom: 20px;
  text-decoration: none;
`;
const StyledADSText = styled(ADSText)`
  @media only screen and (min-width: 680px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

interface MatchDisplayProps {
  matchID: string;
  matchEmail: string;
}

export default function MatchInfoCard({
  matchID,
  matchEmail,
}: MatchDisplayProps): React.ReactElement {
  const matchIDRef = doc(firebaseFirestore, `UserProfiles/${matchID}`);
  const { status, data } = useFirestoreDocData<any>(matchIDRef);
  if (status === 'loading') {
    return <></>;
  }
  if (matchID === '' || data === undefined) {
    // Asser that matchID can't be null in this screen.
    return <></>;
  }
  return (
    <StyledCard>
      <StyledNavLink
        to={{
          pathname: '/dashboard/match_profile',
          state: { matchID: matchID, matchEmail: matchEmail },
        }}
      >
        <StyledImageContainer>
          {data[PROFILE_IMG_URL] && <StyledImg src={data[PROFILE_IMG_URL]} alt="match image" />}
          <StyledADSText
            size="s"
            textAlign="center"
            color="standardGrey80"
            wrapperStyle={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            Click to see your match!
          </StyledADSText>
        </StyledImageContainer>
      </StyledNavLink>
      <ADSText color="standardGrey40" size="m" inline={true} weight="semibold">
        {data.firstName} {data.lastName}
      </ADSText>
    </StyledCard>
  );
}
