// Constants
export enum PoolResponseID {
  ROMANTIC = 'matchpoolRomantic',
  PLATONIC = 'matchpoolPlatonic',
}
export enum QuestionType {
  DROPDOWN = 'dropdown',
  GROUP_COIN = 'group_coin',
  LONG_TEXT = 'long_text',
  MULTIPLE_CHOICE = 'multiple_choice',
  OPINION_SCALE = 'opinion_scale',
  STATEMENT = 'statement',
  SHORT_TEXT = 'short_text',
  NUMBER = 'number',
}
export type IDatabase = 'private' | 'profile';
export interface IQuestionGroupNumberAnswer {
  [key: string]: string;
}
export type IQuestionAnswer =
  | number
  | string
  | IQuestionChoice
  | IQuestionChoice[]
  | IQuestionGroupNumberAnswer;
export interface IQuestionChoice {
  id: string;
  label: string;
}
export interface IQuestion {
  id: string;
  title: string;
  onValueChange?: (inputAnswer: IQuestionAnswer) => void; // Used as onChange function to listen for input events
  properties: {
    alphabetical_order?: boolean;
    allow_multiple_selection?: boolean;
    allow_other_choice?: boolean;
    button_text?: string;
    choices?: IQuestionChoice[];
    database?: IDatabase;
    description?: string;
    fields?: IQuestion[];
    labels?: {
      left?: string;
      center?: string;
      right?: string;
    };
    pool_specific?: string;
    randomize?: boolean;
    start_at_one?: boolean;
    steps?: number;
    vertical_alignment?: boolean;
  };
  validations?: {
    required?: boolean;
    max_length?: number;
    max_value?: number;
    min_value?: number;
    max_selection?: number;
  };
  type: QuestionType;
}
export interface IQuestionnaire {
  id: string;
  title: string;
  fields: IQuestion[];
}

export type QuestionnaireStatusType = 'notStarted' | 'incomplete' | 'complete' | 'matchesOut';

export const ERROR_COIN = 'Please use up exactly 20 coins!';
export const ERROR_REQUIRED = 'Please fill this in.';

// Firestore Refs
export const PROFILE_FACEBOOK_REF = 'YOUfacebook';
export const PROFILE_FUN_FACT_REF = 'YOUfunfact';
export const PROFILE_INTEREST_REF = 'YOUinterest';
export const PROFILE_INSTAGRAM_REF = 'YOUinstagram';
export const PROFILE_MAJOR_REF = 'YOUmajor';
export const PROFILE_MESSAGE_REF = 'message';
export const PROFILE_STUDY_YEAR_REF = 'YOUstudyear';
export const PROFILE_MAIN_CONTACT_REF = 'YOUcontact'; // Telegram for Singapore, Facebook for North America
export const PROFILE_FIRST_NAME_REF = 'firstName';
export const PROFILE_LAST_NAME_REF = 'lastName';
export const PROFILE_IMG_URL = 'imgURL';
export const PROFILE_BLOCK_LIST_REF = 'blockList';

// Other Firestore Questionnaire document fields.
export const MATCH_POOL_FIELD = 'matchpool';
export const COIN_QUESTION_FIELD_ID = 'FUT04';
export const COIN_QUESTION_FIELD_OLD_ID = 'gJ2kNaQBysJo';
