import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';

import { ADSButton, ADSConfetti, ADSText } from '@aphrodite/common-ui';

const PopupContainer = styled.div`
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
`;
const TextContainer = styled.div`
  position: relative;
  width: 25%;
  min-width: 280px;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  max-height: 70vh;
  margin-top: 30vh;
  background: #fff;
  border-radius: 16px;
  padding: 20px;
  border: 8px solid #ffe3eb;
  overflow: auto;
`;
const StyledLogo = styled.img`
  max-height: 144px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 8px;
  padding-top: 8px;
`;
const StyledButtonContainer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledText = styled(ADSText)`
  margin: 8px 8px 8px 8px;
`;
interface ConfirmationPopupProps {
  logo?: string;
  title?: string;
  content?: string | ReactElement;
  subContent?: string | ReactElement;
  buttonText?: string;
  buttonAction?: () => void;
  showConfetti?: boolean;
  wrapperStyle?: React.CSSProperties;
}
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
const { height, width } = getWindowDimensions();
const ConfirmationPopup: FunctionComponent<ConfirmationPopupProps> = ({
  logo,
  title,
  content,
  subContent,
  buttonText,
  buttonAction,
  showConfetti,
  wrapperStyle = {},
}) => {
  return (
    <PopupContainer>
      {showConfetti && <ADSConfetti width={width} height={height} />}
      <TextContainer style={wrapperStyle}>
        {logo && <StyledLogo src={logo} alt="logo" />}
        <StyledText weight="semibold" size="l" textAlign={'center'}>
          {title}
        </StyledText>
        <StyledText weight="semibold" size="xs" textAlign={'center'}>
          {content}
        </StyledText>
        <StyledText weight="semibold" size="xxs" textAlign={'center'}>
          {subContent}
        </StyledText>
        <StyledButtonContainer>
          <ADSButton onClick={buttonAction}>{buttonText}</ADSButton>
        </StyledButtonContainer>
      </TextContainer>
    </PopupContainer>
  );
};

export default ConfirmationPopup;
