import { Route } from 'react-router-dom';
import styled from 'styled-components';

import { NAV_ITEMS } from '../../constants/Navigation';
import Questionnaire from '../questionnaire/Questionnaire';
import QuestionnaireStatusScreen from '../questionnaire/QuestionnaireStatusScreen';

export const StyledContainer = styled.main`
  @media only screen and (min-width: 1024px) {
    margin-left: ${({ theme }) => theme.spacing.navbarDesktopWidth};
  }
`;

export default function DashboardTabQuestionnaire(): React.ReactElement {
  return (
    <StyledContainer>
      <Route path={NAV_ITEMS.QUESTIONNAIRE.to} component={QuestionnaireStatusScreen} exact={true} />
      <Route
        path={NAV_ITEMS.QUESTIONNAIRE.to + '/questions'}
        component={Questionnaire}
        exact={true}
      />
    </StyledContainer>
  );
}
