import { DocumentReference, doc } from 'firebase/firestore';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';
import styled from 'styled-components';

import ShareIcon from '@aphrodite/assets/icons/button_share.png';
import TickIcon from '@aphrodite/assets/icons/tick_square.svg';
import { ADSButton, ADSHeading, ADSPage, ADSPageLoading, ADSText } from '@aphrodite/common-ui';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';

import Navbar from '../components/Navbar';
import { IFirestoreWaitlistCollege } from '../constants/Waitlist';

const StyledADSText = styled.div`
  margin-right: 10px;
`;
const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  gap: 5%;
  height: 80vh;
  justify-content: center;
  padding: 8%;
  width: 100%;
`;
const StyledRowFlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  height: auto;
  margin-top: auto;
  width: 100%;
`;
const StyledCopiedModal = styled(StyledRowFlexDiv)`
  align-items: center;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  left: 30%;
  gap: 8px;
  padding: 14px 16px;
  position: absolute;
  top: 80%;
  width: auto;
  @media only screen and (min-width: 1440px) {
    left: 63%;
  }
`;
const StyledDropdown = styled.div`
  margin: auto;
  margin-top: 3%;
  margin-bottom: 3%;
  text-align: center;
`;
const StyledHeading = styled(ADSHeading)`
  font-size: 32px;
`;
const StyledNumber = styled(ADSHeading)`
  font-size: 44px;
  background-color: #ffffff;
  background-image: linear-gradient(#ff8859, #ff84a7, #7ba9ff);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  margin-bottom: 3%;
`;
const StyledSentence = styled(ADSText)`
  margin-bottom: 3%;
`;
const StyledSubmitButton = styled.div`
  align-self: center;
  display: flex;
`;

export default function PageWaitlistSubmitSuccess() {
  const history = useHistory();
  const [showPopup, setShowPopup] = useState(false);
  const pathParams: { collegeId: string } = useParams();
  const waitlistedCollegeRef = doc(
    firebaseFirestore,
    `Waitlist/${pathParams.collegeId}`,
  ) as DocumentReference<IFirestoreWaitlistCollege>;
  const { status: waitlistedCollegeStatus, data: waitlistedCollegeData } =
    useFirestoreDocData<IFirestoreWaitlistCollege>(waitlistedCollegeRef);
  if (waitlistedCollegeStatus === 'success' && !waitlistedCollegeData) {
    history.push(`/waitlist`); // Redirect back to waitlist if an invalid path parameter is given
  }
  if (waitlistedCollegeStatus === 'loading' || !waitlistedCollegeData) return <ADSPageLoading />;
  const displaySignupNumber = () => {
    const numSignups = waitlistedCollegeData.emails.length;
    if (numSignups > 0) {
      return (
        <>
          <StyledNumber inline={true} level="2" textAlign="center" weight="semibold">
            {numSignups} of 500
          </StyledNumber>
          <StyledSentence>
            people from your school have joined the Aphrodite waitlist!
          </StyledSentence>
        </>
      );
    } else {
      return <></>;
    }
  };
  const handleCopyLink = () => {
    navigator.clipboard.writeText('aphrodite.global/waitlist');
    // alert("Link copied to clipboard!");
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 5000);
  };

  const renderShareButton = () => {
    return (
      <>
        <StyledADSText>Share the love</StyledADSText>
        <img alt="share link" height="18" src={ShareIcon} width="18" />
      </>
    );
  };
  return (
    <ADSPage>
      <Navbar />
      <StyledContainer>
        <StyledHeading inline={true} level="2" textAlign="center" weight="semibold">
          {waitlistedCollegeData.name} is on the list!
        </StyledHeading>
        <StyledDropdown>
          {displaySignupNumber()}
          <StyledSentence>Share the love with your friends to see aphrodite sooner!</StyledSentence>
        </StyledDropdown>
        <StyledSubmitButton>
          <ADSButton onClick={handleCopyLink} styleType="defaultGradient" type="button">
            {renderShareButton()}
            {showPopup && (
              <StyledCopiedModal>
                <ADSText size="xxs">Link copied</ADSText>
                <img alt="tick" justify-self="center" src={TickIcon} />
              </StyledCopiedModal>
            )}
          </ADSButton>
        </StyledSubmitButton>
      </StyledContainer>
    </ADSPage>
  );
}
