import { FunctionComponent } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  Text as RechartsText,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';

import { ADSText } from '@aphrodite/common-ui';
import { GraphProps } from '@aphrodite/common/types/DataReportTypes';

import { changeScale3 } from '../constants/Graph';

// Styled Components
const StyledContainer = styled.div`
  margin: 0;
`;
const StyledTitle = styled(ADSText)`
  font-size: 18px;
`;
const StyledText = styled(RechartsText)`
  font-size: 6px;
  @media only screen and (min-width: 680px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 768px) {
    font-size: 14px;
  }
`;

/**
 * Chart for changes over time.
 */

interface TickProps {
  x?: number;
  y?: number;
  payload?: any;
}
const CustomizedAxisTick: FunctionComponent<TickProps> = ({ x, y, payload }) => {
  return (
    <StyledText x={x} y={y} width={75} textAnchor="middle" verticalAnchor="start">
      {payload.value}
    </StyledText>
  );
};

/**
 * Generic Stacked Bar Chart Component.
 */
const StackedBarChart: FunctionComponent<GraphProps> = ({
  className,
  dataKeys,
  title,
  xLabel,
  yLabel,
  items,
}) => {
  if (!dataKeys || !items) {
    return null;
  }

  return (
    <StyledContainer>
      <StyledTitle size="m" weight="semibold">
        {title}
      </StyledTitle>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={items}
          height={450}
          margin={{
            right: 40,
            left: 40,
            bottom: 20,
          }}
          width={750}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="category" dataKey="name" interval={0} tick={<CustomizedAxisTick />}>
            {xLabel ? <Label value={xLabel} offset={0} position="bottom" /> : null}
          </XAxis>
          <YAxis type="number" tickCount={11}>
            {yLabel ? <Label value={yLabel} offset={0} position="left" /> : null}
          </YAxis>
          <Legend
            iconSize={16}
            iconType="rect"
            align="right"
            verticalAlign="top"
            wrapperStyle={{ paddingTop: '8px', paddingBottom: '8px' }}
          />
          <Tooltip />
          {dataKeys.map((item, index) => {
            return <Bar dataKey={item} key={item} stackId="_" fill={changeScale3[index]} />;
          })}
        </BarChart>
      </ResponsiveContainer>
    </StyledContainer>
  );
};

export default StackedBarChart;
