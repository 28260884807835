import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';
import styled from 'styled-components';

import { ADSAnchor, ADSButton, ADSCard, ADSHeading, ADSText } from '@aphrodite/common-ui';
import { SHOW_PARTNERSHIPS } from '@aphrodite/common/constants/Partnerships';
import { useAuthHelper } from '@aphrodite/common/hooks';
import { useIsSingaporeWebsite } from '@aphrodite/common/hooks';
import { IMatchResult } from '@aphrodite/common/types/firestore-types';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';
import { doc } from '@firebase/firestore';

import DashboardLoading from '../dashboard/DashboardLoading';
import MatchInfoCard from './MatchInfoCard';

// Styled Components
const StyledFlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 20px;
  .button {
    margin-top: 20px;
  }
`;
// `Safety Features` styled components
const StyledArticleContainerCard = styled(ADSCard)`
  align-items: center;
  border-radius: 24px;
  display: flex;
  flex-direction: column nowrap;
  margin: 68px auto;
  max-width: 720px;
  @media only screen and (min-width: 1024px) {
    padding: 0 20px;
  }
`;
const StyledArticlePage = styled.div`
  padding: 12px 20px;
  width: 100%;
  max-width: 720px;
`;
const StyledArticleParagraph = styled(ADSText)`
  margin-top: 20px;
  @media only screen and (min-width: 600px) {
    margin-top: 36px;
  }
`;
const StyledButtonRegion = styled.div`
  margin: 28px 0;
  display: flex;
  justify-content: center;
`;

interface SafetyFeaturesViewProps {
  onClick: () => void;
}

const SafetyFeaturesView = ({ onClick }: SafetyFeaturesViewProps) => {
  return (
    <StyledContainer>
      <ADSHeading level="2" wrapperStyle={{ marginTop: '4%' }}>
        My Match
      </ADSHeading>
      <StyledFlexDiv>
        <StyledArticleContainerCard backgroundColor="translucent">
          <StyledArticlePage>
            <ADSHeading level="3" wrapperStyle={{ marginTop: '4%', textAlign: 'center' }}>
              Safety Guidelines
            </ADSHeading>
            <StyledArticleParagraph hyphensNone={true}>
              Before you start, we'd like to remind you that the safety of our participants is our
              priority and our responsibility. Toxic behaviour is not tolerated on our platform.
            </StyledArticleParagraph>
            <StyledArticleParagraph hyphensNone={true}>
              Please let us know if you've encountered abuse, harassment, or any uncomfortable
              situations with your matches by reporting them to us. Your report will be kept private
              and anonymous, and we will do our best to provide you with the support you need.
            </StyledArticleParagraph>
            <StyledArticleParagraph hyphensNone={true}>
              We've also added a list of <b>safety resources</b>, on our{' '}
              <ADSAnchor
                to="https://docs.google.com/document/d/1Rr5pTuiN0ietGrMxFRxtuNuXK2xFbO-5dEIjidxsZVg/edit?usp=sharing"
                target="_blank"
                underline
              >
                {`Safety page`}
              </ADSAnchor>{' '}
              if you'd like to seek support in your local region.
            </StyledArticleParagraph>
            <StyledArticleParagraph hyphensNone={true}>
              Please reach out to your community's resources if you ever feel unsafe as soon as
              possible. We hope the Aphrodite Project will be a safe, loving and inclusive platform
              for all participants.
            </StyledArticleParagraph>
            <StyledButtonRegion>
              <ADSButton onClick={onClick}>Get Started</ADSButton>
            </StyledButtonRegion>
          </StyledArticlePage>
        </StyledArticleContainerCard>
      </StyledFlexDiv>
    </StyledContainer>
  );
};

export { SafetyFeaturesView };

enum VIEWS {
  SAFETY_FEATURES = 'safetyFeatures',
  MATCH = 'match',
}

export default function MatchResultView(): React.ReactElement {
  const [view, setView] = useState(VIEWS.SAFETY_FEATURES);
  const { user } = useAuthHelper();
  const matchResultRef = doc(firebaseFirestore, `MatchResult/${user!.uid}`);
  const { status: matchResultStatus, data: matchResult } =
    useFirestoreDocData<IMatchResult>(matchResultRef);
  if (matchResultStatus === 'loading') {
    return <DashboardLoading />;
  }
  // Only show Safety Guidelines if the participant got a match
  const hasMatch = matchResult && (matchResult.matchA || matchResult.matchB);
  if (view === VIEWS.SAFETY_FEATURES && hasMatch) {
    return <SafetyFeaturesView onClick={() => setView(VIEWS.MATCH)} />;
  } else {
    if (!matchResult) {
      return <QuestionnaireIncompleteContent />;
    }
    const matchA = matchResult.matchA;
    const matchB = matchResult.matchB;
    const emailA = matchResult.emailA;
    const emailB = matchResult.emailB;
    if (matchA && matchB && emailA && emailB) {
      if (matchA === matchB) {
        return <MatchedContent MatchInfo={[matchA]} EmailInfo={[emailA]} />;
      }
      return <MatchedContent MatchInfo={[matchA, matchB]} EmailInfo={[emailA, emailB]} />;
    } else if (!matchA && matchB && emailB) {
      return <MatchedContent MatchInfo={[matchB]} EmailInfo={[emailB]} />;
    } else if (matchA && !matchB && emailA) {
      return <MatchedContent MatchInfo={[matchA]} EmailInfo={[emailA]} />;
    } else {
      return <MatchlessContent />;
    }
  }
}

// Styled Components
const StyledContainer = styled.div`
  max-width: 100%;
  display: flex;
  padding: 20% 8% 8% 10%;
  flex-direction: column;
  justify-content: center;
  @media only screen and (min-width: 1024px) {
    margin-top: ${({ theme }) => `${parseFloat(theme.spacing.navbarDesktopLogoHeight) * 0.25}px`};
    margin-left: ${({ theme }) => theme.spacing.navbarDesktopWidth};
    padding: 0 4%;
  }
`;
const StyledCardDiv = styled(StyledFlexDiv)`
  flex-direction: row;
`;
const StyledText = styled(ADSText)`
  margin-bottom: 5px;
  @media only screen and (min-width: 1024px) {
    font-size: 30px;
    line-height: 39px;
  }
`;
const StyledCaptionText = styled(ADSText)`
  margin-bottom: 20px;
  @media only screen and (min-width: 1024px) {
    margin-left: 14%;
    margin-right: 14%;
  }
`;
const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`;

function MatchlessContent(): React.ReactElement {
  const isSingaporeWebsite = useIsSingaporeWebsite();
  return (
    <StyledContainer>
      <ADSHeading level="2" wrapperStyle={{ marginTop: '4%' }}>
        My Match
      </ADSHeading>
      <StyledFlexDiv>
        <ADSHeading level="1">😭️</ADSHeading>
        <StyledText color="blackDefault" size="m" inline={true} className="text" textAlign="center">
          Unfortunately...
          <br /> we couldn't find you a match this time around :(
        </StyledText>
      </StyledFlexDiv>
      <StyledFlexDiv>
        <StyledCaptionText
          color="standardGrey70"
          size="s"
          inline={true}
          weight="light"
          className="text"
          textAlign="center"
          hyphensNone={true}
        >
          A common reason for not getting a match is the pool is not big or diverse enough :( As our
          community grows, we hope to get even better quality matches for all our participants.{' '}
          <br />
          <br />
          {isSingaporeWebsite && SHOW_PARTNERSHIPS && (
            <b>You can still access some of our Aphrodite deals here!</b>
          )}
        </StyledCaptionText>
        {isSingaporeWebsite && SHOW_PARTNERSHIPS && (
          <StyledNavLink to="/dashboard/partnerships">
            <ADSButton>Go to Partnership page</ADSButton>
          </StyledNavLink>
        )}
      </StyledFlexDiv>
    </StyledContainer>
  );
}

function QuestionnaireIncompleteContent(): React.ReactElement {
  const isSingaporeWebsite = useIsSingaporeWebsite();
  return (
    <StyledContainer>
      <ADSHeading level="2" wrapperStyle={{ marginTop: '4%' }}>
        My Match
      </ADSHeading>
      <StyledFlexDiv>
        <StyledText color="blackDefault" size="m" inline={true} className="text" textAlign="center">
          We couldn't find you a match this time!
        </StyledText>
      </StyledFlexDiv>
      <StyledFlexDiv>
        <StyledCaptionText
          color="standardGrey70"
          size="s"
          inline={true}
          weight="light"
          className="text"
          textAlign="center"
          hyphensNone={true}
        >
          This is because your questionnaire was incomplete before the deadline.
          <br /> We cannot run our matching algorithm without all your completed answers!
          <br />
          <br /> <b>We hope to see you in our next run!</b>
        </StyledCaptionText>
        {isSingaporeWebsite && (
          <StyledNavLink to="/dashboard/partnerships">
            <ADSButton>Go to Partnership page</ADSButton>
          </StyledNavLink>
        )}
      </StyledFlexDiv>
    </StyledContainer>
  );
}

interface MatchedContentProps {
  MatchInfo: string[];
  EmailInfo: string[];
}

function MatchedContent({ MatchInfo, EmailInfo }: MatchedContentProps): React.ReactElement {
  return (
    <StyledContainer>
      <ADSHeading level="2" wrapperStyle={{ marginTop: '4%' }}>
        Meet Your Match 🎉️
      </ADSHeading>
      <StyledFlexDiv>
        <StyledCardDiv>
          {MatchInfo.map((info, i) => {
            if (info !== '') {
              return <MatchInfoCard matchID={info} matchEmail={EmailInfo[i]} key={i} />;
            }
            return <div />;
          })}
        </StyledCardDiv>
      </StyledFlexDiv>
    </StyledContainer>
  );
}
