import { FunctionComponent } from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import styled from 'styled-components';

import { ADSText } from '@aphrodite/common-ui';
import { pieColors } from '@aphrodite/common/constants/Graph';
import { GraphProps } from '@aphrodite/common/types/DataReportTypes';

// Styled Components

const StyledContainer = styled.div`
  margin: 0;
`;

const StyledTitle = styled(ADSText)`
  margin-left: 28px;
`;

/**
 * Generic Pie Chart Component.
 */
const SimplePieChart: FunctionComponent<GraphProps> = ({ className, title, items }) => {
  const showLegend = items.length < 10;
  const legendHeight = 100;
  const legendPadding = 20;

  return (
    <>
      <StyledContainer>
        <StyledTitle size="l" weight="semibold">
          {title}
        </StyledTitle>
        <ResponsiveContainer width="100%" height={300 + legendHeight}>
          <PieChart>
            <Tooltip />
            <Pie dataKey="value" data={items} innerRadius="50%" outerRadius="80%" fill="#8884d8">
              {items.map((item, index) => (
                <Cell key={item.name} fill={pieColors[index % pieColors.length]} />
              ))}
            </Pie>
            {showLegend ? <Legend wrapperStyle={{ paddingTop: legendPadding }} /> : null}
          </PieChart>
        </ResponsiveContainer>
      </StyledContainer>
      <br />
    </>
  );
};

export default SimplePieChart;
