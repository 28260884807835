import { ADSAnchor, ADSText } from '@aphrodite/common-ui';
import { FunctionComponent, useEffect, useState } from 'react';
import { getAnalytics, logEvent } from '@firebase/analytics';
import styled, { css } from 'styled-components';

import { NavLink } from 'react-router-dom';
import apLogo from '@aphrodite/assets/logos/ap.png';
import apWhiteLogo from '@aphrodite/assets/logos/ap_white.png';
import cancel from '@aphrodite/assets/logos/cancel.svg';
import instagramLogo from '@aphrodite/assets/logos/instagram-logo.png';
import menu from '@aphrodite/assets/logos/menu.svg';
import { useIsSingaporeWebsite } from '@aphrodite/common/hooks';

// Styled Components
const StyledNavBar = styled.div<{ scroll: boolean }>`
  position: sticky;
  top: 0px;
  display: flex;
  width: 100vw;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #ffffff70;
  transition: ease-in 0.5s;
  z-index: 5;
  &:focus {
    outline: none;
  }
  @media only screen and (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5%;
    padding-left: 8%;
    padding-right: 8%;
    padding-bottom: 0%;
    border-bottom: none;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-align: center;
    background-color: transparent;
    ${(props) => {
      if (props.scroll) {
        return css`
          background-color: rgba(255, 255, 255);
          transition: ease-in 0.5s;
        `;
      }
    }}
  }
  ${(props) => {
    if (props.scroll) {
      return css`
        background-color: rgba(255, 255, 255, 0.4);
        transition: ease-in 0.5s;
      `;
    }
  }}
`;

const StyledNavBrand = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
`;

const StyledLogo = styled.img`
  height: 1.5em;
  margin-right: 12px;
  @media only screen and (max-width: 640px) {
    height: 1.3em;
  }
`;
const StyledWhiteLogo = styled.img<{ scroll: boolean }>`
  height: 2.6em;
  filter: invert(100%);
  @media only screen and (max-width: 640px) {
    height: 5vh;
    margin-right: 12px;
  }
`;
const StyledNavLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  padding-right: 24px;
  &:hover {
    opacity: 0.75;
  }
  @media only screen and (max-width: 640px) {
    padding-top: 4px;
    padding-bottom: 4px;
    margin: 8px 0;
    padding-right: 0;
    margin-left: auto;
  }
  @media only screen and (max-width: 900px) {
    padding-right: 24px;
  }
`;
const StyledImage = styled.img`
  width: 20px;
  height: 20px;
`;
const StyledLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  padding-right: 36px;
  &:hover {
    opacity: 0.75;
  }
  @media only screen and (max-width: 640px) {
    padding-top: 4px;
    padding-bottom: 4px;
    margin: 8px 0;
    padding-right: 0;
    margin-left: auto;
  }
  @media only screen and (max-width: 900px) {
    padding-right: 24px;
  }
`;
const StyledText = styled(ADSText)`
  @media only screen and (min-width: 640px) and (max-width: 1080px) {
    font-size: 12px;
  }
`;
const StyledIcon = styled.img`
  width: 1em;
  height: 1em;
`;
const StyledNavItems = styled.nav<{ collapsed: boolean }>`
  display: flex;
  align-items: center;
  margin-left: auto;
  ${StyledLink}:last-child {
    padding-right: 0;
  }
  @media only screen and (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    margin-top: 8px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.5s;
    flex-basis: 100%;
    margin-left: auto;
    ${(props) => {
      if (!props.collapsed) {
        return css`
          max-height: 320px;
          z-index: 11;
          transition: all 1s;
          margin-left: auto;
          margin-bottom: 24px;
        `;
      }
    }}
  }
`;

const StyledNavToggle = styled.nav`
  display: none;
  @media only screen and (max-width: 640px) {
    align-self: center;
    display: initial;
    cursor: pointer;
  }
`;

const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(document.body.scrollTop);
    };
    document.body.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => document.body.removeEventListener('scroll', updatePosition);
  }, []);
  return scrollPosition;
};

interface Props {
  isLanding?: boolean;
}

//Main Component
const Navbar: FunctionComponent<Props> = ({ isLanding }) => {
  const analytics = getAnalytics();
  const isSingaporeWebsite = useIsSingaporeWebsite();
  const instagramLink = isSingaporeWebsite
    ? 'https://www.instagram.com/aphroditeproject.sg'
    : 'https://www.instagram.com/aphroditeproject';
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleToggleClick = () => setIsNavCollapsed(!isNavCollapsed);
  const collapseNav = () => {
    setIsNavCollapsed(true);
  };
  const scrollPosition = useScrollPosition();
  const scrolled = scrollPosition > 0 ? true : false;
  return (
    <StyledNavBar onBlur={collapseNav} tabIndex={0} scroll={scrolled}>
      <StyledNavBrand to="/">
        {isLanding ? (
          <StyledWhiteLogo src={apWhiteLogo} alt="Aphrodite logo" scroll={scrolled} />
        ) : (
          <StyledLogo src={apLogo} alt="Aphrodite logo" />
        )}
      </StyledNavBrand>
      <StyledNavToggle onClick={handleToggleClick}>
        <StyledIcon src={isNavCollapsed ? menu : cancel} alt="menu" />
      </StyledNavToggle>
      <StyledNavItems collapsed={isNavCollapsed}>
        <StyledLink>
          <ADSAnchor
            target="_blank"
            to="mailto: cupids@aphrodite.global"
            onClick={() =>
              logEvent(analytics, 'link-click', {
                component: 'NAVBAR',
                button_name: 'contact_us',
              })
            }
          >
            <StyledText size="s">Contact Us</StyledText>
          </ADSAnchor>
        </StyledLink>
        <StyledNavLink to="/about">
          <StyledText size="s">About Us </StyledText>
        </StyledNavLink>
        <StyledLink>
          <ADSAnchor
            target="_blank"
            to={
              isSingaporeWebsite
                ? 'https://bit.ly/aphropolicy2022' // todo: Switch to /data-privacy
                : 'https://bit.ly/aphropolicy2022'
            }
            onClick={() =>
              logEvent(analytics, 'link-click', {
                component: 'NAVBAR',
                button_name: 'privacy_policy',
              })
            }
          >
            <StyledText size="s">Data Privacy</StyledText>
          </ADSAnchor>
        </StyledLink>
        <StyledLink>
          <ADSAnchor
            target="_blank"
            to={'/safety-resources'}
            onClick={() =>
              logEvent(analytics, 'link-click', {
                component: 'NAVBAR',
                button_name: 'safetyresources',
              })
            }
          >
            <StyledText size="s">Safety</StyledText>
          </ADSAnchor>
        </StyledLink>
        <StyledLink>
          <ADSAnchor
            target="_blank"
            to={isSingaporeWebsite ? 'https://bit.ly/3dZUcpX' : 'https://bit.ly/aphrofaq2022'}
            onClick={() =>
              logEvent(analytics, 'link-click', {
                component: 'NAVBAR',
                button_name: 'faqs',
              })
            }
          >
            <StyledText size="s">FAQ</StyledText>
          </ADSAnchor>
        </StyledLink>
        <StyledNavLink to="/press">
          <StyledText size="s">Press </StyledText>
        </StyledNavLink>
        <StyledLink>
          <ADSAnchor
            target="_blank"
            to={instagramLink}
            onClick={() =>
              logEvent(analytics, 'link-click', {
                component: 'NAVBAR',
                button_name: 'instagram',
              })
            }
            wrapperStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <StyledImage src={instagramLogo} />
          </ADSAnchor>
        </StyledLink>
      </StyledNavItems>
    </StyledNavBar>
  );
};

export default Navbar;
