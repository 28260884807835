import styled, { css } from 'styled-components';

import { ADSText } from '@aphrodite/common-ui';

import QuestionChoiceButton from './QuestionChoiceButton';

// Mixins
const SelectedMixin = css`
  &,
  &:hover {
    margin: 0 2px;
  }
  &:first-child {
    margin-left: -1px;
  }
  &:last-child {
    margin-right: -1px;
  }
`;
const RadioButtonMixin = css<{ isSelected: boolean }>`
  display: inline-block;
  flex-basis: 0;
  flex-grow: 1;
  height: 54px;
  margin: 0 3px;
  text-align: center;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  ${({ isSelected }) => (isSelected ? SelectedMixin : undefined)}
`;

//Styled Components
const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const StyledTable = styled.table`
  table-layout: fixed;
  width: 100%;
`;
const StyledTd = styled.td`
  width: 33%;
  &:first-child {
    padding-right: 5px;
  }
  &:last-child {
    padding-left: 5px;
  }
`;
const StyledTr = styled.tr`
  vertical-align: top;
`;
enum TransitionTimeout {
  BLINK_WAIT_DURATION = 700,
}
export interface Props {
  className?: string;
  handleSetResponse: (inputAnswer: number) => void;
  onClickNext: () => void;
  properties: {
    start_at_one?: boolean;
    steps?: number;
    labels?: {
      left?: string;
      center?: string;
      right?: string;
    };
  };
  value?: number;
}
export default function QuestionOpinionScale({
  className,
  handleSetResponse,
  onClickNext,
  properties: { start_at_one = false, steps = 0, labels = { left: '', center: '', right: '' } },
  value,
}: Props): React.ReactElement {
  const handleClick = () => {
    setTimeout(() => {
      onClickNext();
    }, TransitionTimeout.BLINK_WAIT_DURATION);
  };
  const renderLabel = (
    <tbody>
      <StyledTr>
        <StyledTd>
          <ADSText size="xs" weight="light" wrapperStyle={{ textAlign: 'left' }}>
            {labels.left}
          </ADSText>
        </StyledTd>
        <StyledTd>
          <ADSText size="xs" weight="light" wrapperStyle={{ textAlign: 'center' }}>
            {labels.center}
          </ADSText>
        </StyledTd>
        <StyledTd>
          <ADSText size="xs" weight="light" wrapperStyle={{ textAlign: 'right' }}>
            {labels.right}
          </ADSText>
        </StyledTd>
      </StyledTr>
    </tbody>
  );
  const selections = Array.from({ length: steps }, (_, i) => (start_at_one ? i + 1 : i));
  return (
    <>
      <StyledDiv role="radiogroup">
        {selections.map((selectionValue) => {
          return (
            <QuestionChoiceButton
              key={selectionValue}
              isSelected={value === selectionValue}
              onClick={() => {
                handleSetResponse(selectionValue);
                handleClick();
              }}
              additionalStyles={RadioButtonMixin}
            >
              <ADSText size="m">{selectionValue}</ADSText>
            </QuestionChoiceButton>
          );
        })}
      </StyledDiv>
      <StyledTable>{renderLabel}</StyledTable>
    </>
  );
}
