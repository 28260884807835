import React from 'react';

import { ADSPage } from '@aphrodite/common-ui';

import Navbar from '../components/Navbar';
import LandingPrivacyList from '../components/landing/privacyPage/LandingPrivacyList';

export default function PageDataPrivacy(): React.ReactElement {
  return (
    <ADSPage>
      <Navbar />
      <LandingPrivacyList />
    </ADSPage>
  );
}
