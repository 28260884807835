import { useFirestoreDocData } from 'reactfire';

import { ADSPage, ADSPageLoading } from '@aphrodite/common-ui';
import { useIsMatchesReleased } from '@aphrodite/common/hooks';
import { useGetDateStatisticsAll } from '@aphrodite/common/hooks/useStatistics';
import { IUserFlags } from '@aphrodite/common/types/firestore-types';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';
import { DocumentReference, doc } from '@firebase/firestore';

import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import LandingSpeechBubble from '../components/landing/LandingSpeechBubble';
import LandingStarting from '../components/landing/Landing_01Starting';
import LandingByStudent from '../components/landing/Landing_02ByStudent';
import LandingUniCards from '../components/landing/Landing_03UniCards';
import LandingStats from '../components/landing/Landing_04Stats';
import LandingQuestionnaire from '../components/landing/Landing_05Questionnaire';
import LandingStories from '../components/landing/Landing_06Stories';
import LandingStepsSection from '../components/landing/Landing_07StepsSection';
import LandingPressCoverage from '../components/landing/Landing_08PressCoverage';
import LandingJoinUs from '../components/landing/Landing_09JoinUs';
import LandingEnding from '../components/landing/Landing_10Ending';
import { CollegesEnum } from '../constants/College';

// Main Component
export default function PageLanding(): React.ReactElement {
  const userFlagRef = doc(
    firebaseFirestore,
    `UserFlags/matchresult`,
  ) as DocumentReference<IUserFlags>;
  const { status: userFlagsStatus } = useFirestoreDocData<IUserFlags>(userFlagRef);
  const { status: matchesOutFlagStatus, flag: isMatchesReleased } = useIsMatchesReleased();
  const collegeValues = Object.values(CollegesEnum);
  const { statisticsStatus, statisticsData } = useGetDateStatisticsAll();
  if (
    statisticsStatus === 'loading' ||
    matchesOutFlagStatus === 'loading' ||
    userFlagsStatus === 'loading'
  ) {
    return <ADSPageLoading />;
  }
  let signupNumber = 0;
  statisticsData?.forEach((stats) => {
    collegeValues.forEach((college) => {
      if (stats[college]) {
        signupNumber += stats[college];
      }
    });
  });
  return (
    <ADSPage fadeIn={true} className="Landing">
      <Navbar isLanding={true} />
      <LandingStarting
        isMatchesReleased={isMatchesReleased as boolean}
        signUpCount={signupNumber}
      />
      <LandingByStudent />
      <LandingSpeechBubble
        content="“You matched us perfectly and we are both so grateful to have met one another.” "
        isLeft={true}
        name="Year 2 (She/her) UMcmaster"
      />
      <LandingSpeechBubble
        content="“We get along extremely well. We see eye to eye on all our fundamental values.” "
        isLeft={false}
        name="Grad student (He/him) MIT"
      />
      <LandingStats signUpCount={signupNumber} />
      <LandingSpeechBubble
        content="“I am convinced that this is my literal soulmate.” "
        isLeft={true}
        name="Year 1 (She/her) Uwaterloo"
      />
      <LandingStories />
      <LandingUniCards />
      <LandingSpeechBubble
        content="“I am glad I met someone new and went out of my comfort zone.” "
        isLeft={false}
        name="Year 3 (She/her) UWaterloo"
      />
      <LandingQuestionnaire />
      <LandingStepsSection />
      <LandingPressCoverage />
      <LandingSpeechBubble
        content="[We] have a ton of things in common. Not hobbies and stuff, but life choices almost."
        isLeft={true}
        name="Year 4 (She/her) Uwaterloo"
      />
      <LandingJoinUs />
      <LandingSpeechBubble
        content="This project is really, really awesome and I’m glad to be part of it. "
        isLeft={false}
        name="Year 1 (She/her) UToronto"
      />
      <LandingEnding />
      <Footer />
    </ADSPage>
  );
}
