import teamAbbyImg from '../assets/images/team_abby.jpeg';
import teamAidenImg from '../assets/images/team_aiden.jpeg';
import teamCharlesImg from '../assets/images/team_charles.jpg';
import teamCherylImg from '../assets/images/team_cheryl.jpg';
import teamChristinaImg from '../assets/images/team_christina.png';
import teamEricImg from '../assets/images/team_eric.jpg';
import teamFelixImg from '../assets/images/team_felix.jpg';
import teamHugoImg from '../assets/images/team_hugo.png';
import teamJanellImg from '../assets/images/team_janell.jpeg';
import teamJayaImg from '../assets/images/team_jaya.jpg';
import teamJessicaImg from '../assets/images/team_jessica.jpeg';
import teamJovynImg from '../assets/images/team_jovyn.jpg';
import teamKaiyuanImg from '../assets/images/team_kaiyuan.jpg';
import teamLionelImg from '../assets/images/team_lionel.jpg';
import teamMayaImg from '../assets/images/team_maya.png';
import teamMeganImg from '../assets/images/team_megan.jpg';
import teamPeiyuImg from '../assets/images/team_peiyu.jpg';
import teamPeizhenImg from '../assets/images/team_peizhen.jpg';
import teamRaneeImg from '../assets/images/team_ranee.jpeg';
import teamRaphaelImg from '../assets/images/team_raphael.jpg';
import teamSidImg from '../assets/images/team_sid.jpg';
import teamTjunjetImg from '../assets/images/team_tjunjet.png';
import teamTobyImg from '../assets/images/team_toby.jpg';

//enter new member info here!
//Follow the format as shown for InfoCard
export const coreMemberInfo = [
  {
    imgSrc: teamAidenImg,
    name: 'Aiden Low',
    role: 'Founder • Engineering • Product',
    university: [
      'National University of Singapore',
      'University of Waterloo',
      'Stanford University',
    ],
    linkedin: 'https://www.linkedin.com/in/aidenywl',
  },
  {
    imgSrc: teamCharlesImg,
    name: 'Charles Lin',
    role: 'Data Science • Research',
    university: ['University of California, Berkeley', 'University of Waterloo'],
    linkedin: 'https://www.linkedin.com/in/xcharleslin/',
  },
  {
    imgSrc: teamCherylImg,
    name: 'Cheryl Li',
    role: 'Global Launch Lead • Engineering',
    university: ['University of Waterloo'],
    linkedin: 'https://www.linkedin.com/in/cheryl-li-20962b194/',
  },
  {
    imgSrc: teamEricImg,
    name: 'Eric Tu',
    role: 'Applied Research • Data Science',
    university: ['York University'],
    linkedin: 'https://www.linkedin.com/in/eric2/',
  },
  {
    imgSrc: teamFelixImg,
    name: 'Felix Indrawan',
    role: 'Engineering',
    university: ['University of Toronto'],
    linkedin: 'https://www.linkedin.com/in/felixindrawan/',
  },
  {
    imgSrc: teamJanellImg,
    name: 'Janell Lin',
    role: 'UX Designer',
    university: ['University of Toronto'],
    linkedin: 'https://www.linkedin.com/in/janelllin/',
  },
  {
    imgSrc: teamJayaImg,
    name: 'Jaya Hu',
    role: 'UX Team Lead',
    university: ['University of Toronto'],
    linkedin: 'https://www.linkedin.com/in/jayahu/',
  },
  {
    imgSrc: teamJessicaImg,
    name: 'Jessica Cui',
    role: 'Marketing • Communications • Engineering',
    university: ['University of Waterloo'],
    linkedin: 'https://www.linkedin.com/in/jessica-jia-cui/',
  },
  {
    imgSrc: teamJovynImg,
    name: 'Jovyn Tan',
    role: 'Engineering',
    university: ['National University of Singapore'],
    linkedin: 'https://www.linkedin.com/in/jovyn-tan/',
  },
  {
    imgSrc: teamKaiyuanImg,
    name: 'Kai Yuan Chi',
    role: 'Engineering • Product',
    university: ['University of Waterloo'],
    linkedin: 'https://www.linkedin.com/in/kaiyuan-chi/',
  },
  {
    imgSrc: teamLionelImg,
    name: 'Lionel Oh',
    role: 'Strategy • Partnerships',
    university: ['Harvard University'],
    linkedin: 'https://www.linkedin.com/in/lionelohwh/',
  },
  {
    imgSrc: teamMayaImg,
    name: 'Maya Bozzo-Rey',
    role: 'Marketing & Communication Team Lead',
    university: ['McMaster University'],
    linkedin: 'https://www.linkedin.com/in/maya-bozzo-rey-804324227/',
  },
  {
    imgSrc: teamMeganImg,
    name: 'Megan Ong',
    role: 'Marketing • Communications',
    university: ['University of British Columbia'],
    linkedin: 'https://www.linkedin.com/in/jie-ni-ong/',
  },
  {
    imgSrc: teamPeiyuImg,
    name: 'Peiyu Wang',
    role: 'UX Designer',
    university: ['University of Toronto'],
    linkedin: 'https://www.linkedin.com/in/peiyu-wang-a48270200',
  },
  {
    imgSrc: teamPeizhenImg,
    name: 'Yap Pei Zhen',
    role: 'Engineering • Partnerships',
    university: ['National University of Singapore'],
    linkedin: 'https://www.linkedin.com/in/pei-zhen-338218191/',
  },
  {
    imgSrc: teamRaphaelImg,
    name: 'Raphael Chew',
    role: 'Engineering • Data Science',
    university: ['Massachusetts Institute of Technology'],
    linkedin: 'https://www.linkedin.com/in/raphaelchew/',
  },
  {
    imgSrc: teamSidImg,
    name: 'Sid Gupta',
    role: 'Engineering • Data Science',
    university: ['University of Toronto'],
    linkedin: 'https://www.linkedin.com/in/sidgupta-99/',
  },
  {
    imgSrc: teamTjunjetImg,
    name: 'Tjun Jet Ong',
    role: 'Engineering • Lead Project Manager',
    university: ['Carnegie Mellon University'],
    linkedin: 'https://www.linkedin.com/in/tjun-jet-ong/',
  },
  {
    imgSrc: teamTobyImg,
    name: 'Toby Cheung',
    role: 'Engineering • UX Designer',
    university: ['The University of Hong Kong'],
    linkedin: 'https://www.linkedin.com/in/toby-cheung-chunyik/',
  },
  // Launch Managers
  {
    imgSrc: teamAbbyImg,
    name: 'Abby Lui',
    role: 'Lead Project Manager',
    university: ['The University of Hong Kong'],
    linkedin: 'https://www.linkedin.com/in/abby-lui-088305248/',
  },
  {
    imgSrc: teamChristinaImg,
    name: 'Christina Chen',
    role: 'Lead Project Manager',
    university: ['Western University of Ontario'],
    linkedin: 'https://www.linkedin.com/in/chriistinachen/',
  },
  {
    imgSrc: teamHugoImg,
    name: 'Hugo Ong',
    role: 'Lead Project Manager',
    university: ['University of California, Los Angeles'],
    linkedin: 'https://www.linkedin.com/in/mrhugobear/',
  },
  {
    imgSrc: teamRaneeImg,
    name: 'Ranee Patricia',
    role: 'Lead Project Manager',
    university: ['The University of Hong Kong'],
    linkedin: 'https://www.linkedin.com/in/ranee-patricia-62874733p/',
  },
];
