//import classNames from 'classnames';
import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import ShareIcon from '@aphrodite/assets/icons/Share.svg';
import TickIcon from '@aphrodite/assets/icons/tick_square.svg';
import { ADSAnchor, ADSButton, ADSHeading, ADSPage, ADSText } from '@aphrodite/common-ui';

import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import { PressArticleInfo } from '../constants/PressConstants';

// Styled Components
const StyledContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 100%;
  padding-left: 8%;
  padding-right: 8%;
  margin-top: 8%;
  @media only screen and (min-width: 680px) {
    margin-top: 6%;
    padding: 0 10%;
  }
`;
const StyledSection = styled.div`
  margin-top: 12px;
`;
const StyledADSText = styled(ADSText)`
  margin-top: 24px;
`;
const StyledCardSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 4vh;
  @media only screen and (min-width: 680px) {
    flex-direction: row;
    margin-top: 20px;
    flex-wrap: wrap;
    &:after {
      content: '';
      flex: 0 0 300px;
    }
  }
  @media only screen and (min-width: 1440px) {
    &:after {
      content: '';
      flex: 0 0 584px;
    }
  }
`;
const StyledInfoCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
  padding-top: 0px;
  gap: 12px;
  position: relative;
  width: 300px;
  height: auto;
  align-items: center;
  align-self: center;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  @media only screen and (min-width: 680px) and (max-width: 1440px) {
    height: 460px;
  }
  @media only screen and (min-width: 1440px) {
    flex-direction: row;
    width: 572px;
    height: 432px;
    padding-top: 24px;
  }
`;
const StyledImage = styled.img`
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  object-fit: cover;
  width: 300px;
  height: 120px;
  @media only screen and (min-width: 1440px) {
    width: 260px;
    height: 368px;
    border-radius: 16px;
  }
`;
const StyledLogo = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 12px;
  align-self: center;
`;
const StyledFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledContentDiv = styled(StyledFlexColumn)`
  height: 100%;
  align-items: stretch;
  @media only screen and (min-width: 1440px) {
    height: 90%;
    margin-top: 0%;
    margin-left: 5%;
  }
`;
const StyledArticleInfoDiv = styled(StyledFlexColumn)`
  margin-left: auto;
  margin-bottom: 16px;
`;
const StyledArticleHeadlineDiv = styled(StyledFlexColumn)`
  align-self: center;
  margin-top: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  .title {
    margin-bottom: 16px;
  }
  @media only screen and (min-width: 680px) {
    margin-top: 24px;
    .title {
      margin-top: 4px;
      margin-bottom: 8px;
      min-height: 88px;
    }
  }
`;
const StyledRowFlexDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  margin-top: auto;
`;
const StyledReadMoreDiv = styled(ADSAnchor)`
  margin-left: auto;
`;
const StyledCopiedModal = styled(StyledRowFlexDiv)`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  position: absolute;
  top: 80%;
  left: 30%;
  width: auto;
  align-items: center;
  padding: 14px 16px;
  gap: 8px;
  @media only screen and (min-width: 1440px) {
    left: 63%;
  }
`;

// Main Component
interface ArticleProps {
  title?: string;
  icon?: string;
  imgSrc?: string;
  description?: string;
  source?: string;
  url: string;
}

const InfoCard: FunctionComponent<ArticleProps> = ({
  imgSrc,
  icon,
  title,
  description,
  source,
  url,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const copyLink = () => {
    navigator.clipboard.writeText(url);
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 2000);
  };
  return (
    <StyledInfoCard>
      <div>
        <StyledImage src={imgSrc} />
      </div>
      <StyledContentDiv>
        <StyledArticleInfoDiv>
          <StyledRowFlexDiv>
            {icon ? <StyledLogo src={icon} /> : null}
            <ADSText size="s" weight="semibold" textAlign="right">
              {source}
            </ADSText>
          </StyledRowFlexDiv>
        </StyledArticleInfoDiv>
        <StyledArticleHeadlineDiv>
          <ADSText size="m" weight="semibold" className="title">
            {title}
          </ADSText>
          <ADSText color="standardGrey80" size="xxs" text-overflow="ellipsis">
            {description}
          </ADSText>
          <br></br>
        </StyledArticleHeadlineDiv>
        <StyledRowFlexDiv>
          <img src={ShareIcon} alt="share" justify-self="center" onClick={() => copyLink()} />
          <StyledReadMoreDiv target="_blank" to={url}>
            <ADSButton styleType="pinkGradient">
              <ADSText size="s">Read more</ADSText>
            </ADSButton>
          </StyledReadMoreDiv>
        </StyledRowFlexDiv>
        {showPopup && (
          <StyledCopiedModal>
            <ADSText size="xxs">link copied</ADSText>
            <img justify-self="center" src={TickIcon} alt="tick" />
          </StyledCopiedModal>
        )}
      </StyledContentDiv>
    </StyledInfoCard>
  );
};

const PagePress: FunctionComponent<{}> = () => {
  return (
    <ADSPage fadeIn={true}>
      <Navbar />
      <StyledContainer>
        <ADSHeading hyphensNone={true} level="1">
          press coverage
        </ADSHeading>
        <StyledADSText hyphensNone={true} size="s">
          Read more about the stories on Aphrodite Project around the world!
        </StyledADSText>
        <StyledSection>
          <StyledCardSection>
            {PressArticleInfo.map((info, i) => {
              return (
                <InfoCard
                  title={info.title}
                  imgSrc={info.imgSrc}
                  icon={info.icon}
                  description={info.description}
                  source={info.source}
                  url={info.url}
                  key={i}
                />
              );
            })}
          </StyledCardSection>
        </StyledSection>
      </StyledContainer>
      <Footer />
    </ADSPage>
  );
};

export default PagePress;
