import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as EditIcon } from '@aphrodite/assets/icons/edit.svg';
import { ReactComponent as SaveIcon } from '@aphrodite/assets/icons/save.svg';
import { ADSCard, ADSDivider, ADSHeading, ADSLoading, ADSText } from '@aphrodite/common-ui';

import { IStatus } from '../questionnaire/Questionnaire';

// Styled Components
const StyledButton = styled.button<{ disabled: boolean }>`
  background-color: transparent;
  border: none;
  cursor: pointer;
  justify-content: right;
  display: ${(props) => (props.disabled ? 'none' : '')};
`;
const StyledCard = styled(ADSCard)`
  padding: 24px;
  position: relative;
  height: auto;
`;
const StyledEditIcon = styled(EditIcon)`
  fill: ${(props) => props.theme.colors.standardGrey40};
  height: 20px;
  width: 20px;
`;
const StyledLoadingIcon = styled(ADSLoading)`
  right: 16px;
  margin-right: 8px;
  position: absolute;
`;
const StyledSaveIcon = styled(SaveIcon)`
  fill: ${(props) => props.theme.colors.standardGrey40};
  height: 20px;
  width: 20px;
`;
const StyledTooltip = styled.div`
  visibility: hidden;
  max-width: 200px;
  position: absolute;
  text-align: center;
  background-color: ${(props) => props.theme.colors.aphroPinkHighlight};
  padding: 8px 20px;
  border-radius: 8px;
  right: -56px;
  top: -52px;
  z-index: 1;
  @media only screen and (max-width: 680px) {
    display: none;
  }
`;
const StyledWrapper = styled.div`
  &:hover ${StyledTooltip} {
    visibility: visible;
  }
`;
const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
`;
interface Props {
  cardStyle?: 'primary' | 'secondary' | 'translucent';
  children: React.ReactNode;
  onToggleEditMode?: () => void;
  onSave?: () => void;
  redirectLink?: string;
  status?: IStatus;
  title?: string;
  icon?: string;
  titleColor?: 'blackDefault' | 'standardGrey70';
  tooltipText?: string;
  underline?: boolean;
  isDisabled?: boolean;
  isEditMode?: boolean;
}
export default function ProfileInfoCard({
  cardStyle = 'primary',
  children,
  onToggleEditMode,
  onSave,
  redirectLink,
  status = 'saved',
  title,
  titleColor = 'blackDefault',
  icon,
  tooltipText,
  underline = true,
  isDisabled = false,
  isEditMode = false,
}: Props): React.ReactElement {
  const history = useHistory();

  function handleClickEdit() {
    if (redirectLink) {
      history.push(redirectLink);
      return;
    }
    if (!isEditMode && onToggleEditMode) {
      onToggleEditMode();
    }
  }
  function renderIcon() {
    if (!isEditMode) {
      if (status === 'loading') {
        return <StyledLoadingIcon size="s" />;
      } else if (status === 'saved') {
        return (
          <StyledWrapper>
            {tooltipText && (
              <StyledTooltip>
                <ADSText size="xs" color="standardGrey70">
                  {tooltipText}
                </ADSText>
              </StyledTooltip>
            )}
            <StyledButton type="button" disabled={isDisabled} onClick={handleClickEdit}>
              <StyledEditIcon />
            </StyledButton>
          </StyledWrapper>
        );
      }
    }
    return (
      <StyledButton type="button" disabled={false} onClick={onSave}>
        <StyledSaveIcon />
      </StyledButton>
    );
  }
  function renderTitle() {
    if (title) {
      let emoji;
      if (icon === 'university') emoji = '🎓 ';
      if (icon === 'interest') emoji = '😄 ';
      return (
        <StyledTitleContainer>
          {icon ? <ADSHeading level="3">{emoji}</ADSHeading> : null}
          <ADSText weight="semibold" color={titleColor} size="s">
            {title}
          </ADSText>
        </StyledTitleContainer>
      );
    }
  }
  function renderUnderline() {
    if (underline) {
      return <ADSDivider />;
    }
  }
  return (
    <StyledCard backgroundColor={cardStyle}>
      <StyledTitleContainer>
        {renderTitle()}
        {renderIcon()}
      </StyledTitleContainer>
      {renderUnderline()}
      {children}
    </StyledCard>
  );
}
