export const QUESTIONNAIRE_URLS = {
  QUESTIONNAIRE_REF_NUS: 'questionnaire/nus_questionnaire.json',
  QUESTIONNAIRE_REF_NTU: 'questionnaire/ntu_questionnaire.json',
  QUESTIONNAIRE_REF_SMU: 'questionnaire/smu_questionnaire.json',
  // CANADA
  QUESTIONNAIRE_REF_WESTERN: 'questionnaire/western_questionnaire.json',
  QUESTIONNAIRE_REF_UW: 'questionnaire/uw_questionnaire.json',
  QUESTIONNAIRE_REF_UOFT: 'questionnaire/uoft_questionnaire.json',
  QUESTIONNAIRE_REF_UBC: 'questionnaire/ubc_questionnaire.json',
  QUESTIONNAIRE_REF_MAC: 'questionnaire/mcmaster_questionnaire.json',
  // USA
  QUESTIONNAIRE_REF_UCLA: 'questionnaire/ucla_questionnaire.json',
  QUESTIONNAIRE_REF_MIT: 'questionnaire/mit_questionnaire.json',
  QUESTIONNAIRE_REF_HARVARD: 'questionnaire/harvard_questionnaire.json',
  QUESTIONNAIRE_REF_CMU: 'questionnaire/cmu_questionnaire.json',
  // Hong Kong
  QUESTIONNAIRE_REF_HKU: 'questionnaire/hku_questionnaire.json',
};
