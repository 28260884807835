import { ReactNode } from 'react';
import styled from 'styled-components';

// Styled Components
const StyledTextContainer = styled.div`
  border: white;
  border-radius: 5px;
  margin: 50px auto;
  padding: 10px;
  text-align: center;
  background-color: white;
  position: relative;
  box-shadow: 0px 6.18113px 6.18113px rgba(50, 50, 71, 0.08),
    0px 6.18113px 12.3623px rgba(50, 50, 71, 0.06);
  &:before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid white;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    left: -19px;
    top: 40%;
  }
`;
interface Props {
  children?: ReactNode;
}
export default function ADSSpeechBubble({ children }: Props): React.ReactElement {
  return (
    <>
      <StyledTextContainer>{children}</StyledTextContainer>
    </>
  );
}
