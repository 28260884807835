import styled from 'styled-components';

import ProfileQuestionnaire from './ProfileQuestionnaire';

export const StyledContainer = styled.main`
  padding: 0 8%;
  @media only screen and (min-width: 1024px) {
    margin-left: ${({ theme }) => theme.spacing.navbarDesktopWidth};
  }
`;

export default function DashboardTabQuestionnaire(): React.ReactElement {
  return (
    <StyledContainer>
      <ProfileQuestionnaire />
    </StyledContainer>
  );
}
