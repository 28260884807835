export const changeScale3 = ['#B81A40', '#F6BA57', '#34608C'];
export const genericScale12 = [
  '#1d6996',
  '#cd6163',
  '#73af48',
  '#94346e',
  '#38a6a5',
  '#e17c05',
  '#5f4690',
  '#0f8554',
  '#6f4070',
  '#cc503e',
  '#994e95',
  '#666666',
];
export const linearScale7 = [
  '#B81A40',
  '#D44C45',
  '#ED7746',
  '#F6BA57',
  '#E7708A',
  '#9768A5',
  '#34608C',
];
export const lineColors: { [key: string]: any } = {
  Man: '#646892',
  Woman: '#D63943',
  Nonbinary: '#edad08',
  ALGO: '#4E79A7',
  'SEMI-RANDOM': '#B81A40',
};
export const pieColors = [
  '#5b498c',
  '#346892',
  '#59a4a4',
  '#3c8258',
  '#80ac56',
  '#e3af3d',
  '#d4802f',
  '#bd5845',
  '#893c6c',
  '#69436d',
  '#8f5391',
  '#666666',
];
const graphConstants = {
  changeScale3,
  genericScale12,
  linearScale7,
  lineColors,
  pieColors,
};
export default graphConstants;
