import universityArray from '../constants/waitlist-university-list/shortWorldUniversitiesNew.json';

export const getWorldUniversitiesList = () => {
  return universityArray;
};

export const getUniversityDetails = (collegeId: string) => {
  return universityArray.find((item) => {
    return item.id === collegeId;
  });
};
