import styled from 'styled-components';

import { ADSAccordion, ADSAccordionItem, ADSHeading, ADSText } from '@aphrodite/common-ui';

const StyledCardDiv = styled.div`
  left: 0px;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  padding-left: 8%;
  padding-right: 8%;
  width: 100vw;
  height: fit-content;
  background: #ffe5db;
  margin-bottom: 80px;
  @media only screen and (min-width: 680px) {
    padding-bottom: 100px;
  }
  @media only screen and (min-width: 1440px) {
    padding-left: 16%;
    padding-right: 16%;
  }
`;

export default function LandingPrivacyList(): React.ReactElement {
  return (
    <StyledCardDiv>
      <ADSHeading
        weight="bold"
        textAlign="left"
        level="1"
        wrapperStyle={{ marginBottom: 24, color: '#000000' }}
      >
        Data Privacy
      </ADSHeading>
      <ADSText size="s" wrapperStyle={{ marginBottom: 30 }}>
        This document exists because we believe in complete accountability and transparency. We are
        not a company or government entity nor do we have lawyers. We write this because it’s simply
        the right thing to do. 
        <br />
        <br />
        We may be a student-run project but we take your trust and privacy very seriously and this
        is our written commitment to you and to ourselves. By using our website located at
        https://aphrodite.global/ (“Website”) for Aphrodite Project, you agree to the practices set
        forth in this Privacy Policy.
      </ADSText>
      <ADSAccordion style={{ marginBottom: 30 }}>
        {PRIVACY_LIST.map((item, idx) => {
          return (
            <ADSAccordionItem
              index={idx}
              title={
                <>
                  <ADSHeading weight="bold" level="3" inline wrapperStyle={{ marginRight: 4 }}>
                    0{idx + 1} -
                  </ADSHeading>
                  <ADSHeading level="3" inline wrapperStyle={{ fontWeight: 'inherit' }}>
                    {item.title}
                  </ADSHeading>
                </>
              }
              content={item.content}
              key={idx}
            />
          );
        })}
      </ADSAccordion>
    </StyledCardDiv>
  );
}

const SCHOOLS = [
  'Carnegie Mellon University',
  'Harvard University',
  'Massachusetts Institute of Technology',
  'McMaster University',
  'The University of Hong Kong',
  'University of Waterloo',
  'University of Toronto',
  'University of British Columbia',
  'University of California',
  'Western University',
  'National University of Singapore',
  'Nanyang Technological University',
  'Singapore Management University',
];

const PRIVACY_LIST = [
  {
    number: 0,
    title: 'What is the Aphrodite Project?',
    content: (
      <div>
        {
          'Aphrodite – an ancient Greek goddess associated with love, beauty, pleasure, passion, and procreation.'
        }
        <br />
        <br />
        {
          'Aphrodite Project isn’t your usual dating app: we’re a one-off,  student-run digital event that’s designed for meaningful compatibility to help university students in Singapore like you to meet their ideal date from your school! Built on a combination of computer science and psychology, Aphrodite Project algorithmically provides you a most optimal stable match from your school, based on your responses to our questionnaire that’s carefully designed based on extensive primary and secondary research.'
        }
        <br />
        <br />
        {'Aphrodite Project is free to participate for students and alumni from:'}
        <br />
        <br />
        {SCHOOLS.map((school, idx) => (
          <li key={idx}>{school}</li>
        ))}
        <br />
        {
          'Aphrodite Project 2023 is our 5th run in North America and 3rd run in Hong Kong. Since our inception in Singapore at NUS and Yale-NUS in 2019 and later at the University of Waterloo and the University of Toronto in 2020, we’ve matched more than 45,900 participants with hundreds of known romantic relationships and started many more new friendships!'
        }
        <br />
        <br />
        {
          'Check out our FAQ page to answer your detailed questions about Aphrodite Project:  bit.ly/aphrofaq2022'
        }
      </div>
    ),
  },
  {
    number: 1,
    title: 'Opening Remarks',
    content:
      'This document exists because we believe in complete accountability and transparency. We are not a company or government entity nor do we have lawyers. We write this because it’s simply the right thing to do. \n\nWe may be a student-run project but we take your trust and privacy very seriously and this is our written commitment to you and to ourselves. By using our website located at https://aphrodite.global/ (“Website”) for Aphrodite Project, you agree to the practices set forth in this Privacy Policy.',
  },
  {
    number: 2,
    title: 'Objectives',
    content:
      'To be completely transparent about our principles when handling any data from the Aphrodite Project\n\nTo inform you on the  information we gather and how that data will be used.\n\nTo write down on paper, the specific measures we have taken and will undertake to protect and secure your privacy. ',
  },
  {
    number: 3,
    title: 'Our key principles',
    content: '',
  },
  {
    number: 4,
    title: 'Information Collection and Usage',
    content: '',
  },
  {
    number: 5,
    title: 'How will my data be protected?',
    content: '',
  },
  {
    number: 6,
    title: 'Other important information',
    content: '',
  },
  {
    number: 7,
    title: 'Closing remarks',
    content: '',
  },
];
