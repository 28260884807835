import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '@aphrodite/assets/icons/close.svg';
import { ReactComponent as CollapseIcon } from '@aphrodite/assets/icons/collapse-dropdown.svg';
import { ReactComponent as ExpandIcon } from '@aphrodite/assets/icons/expand-dropdown.svg';
import { ReactComponent as TickIcon } from '@aphrodite/assets/logos//tick.svg';

import { IQuestionChoice } from '../../constants/Questionnaire';
import QuestionChoiceButton from './QuestionChoiceButton';

// Styled Components
const StyledChoiceContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  overflow-y: auto;
  text-overflow: hidden;
  width: 100%;
`;
const StyledCloseIcon = styled(CloseIcon)`
  fill: ${(props) => props.theme.colors.darkPurple};
  height: 2em;
  text-align: center;
  vertical-align: middle;
  width: 2em;
  &:hover {
    fill: ${(props) => props.theme.colors.transparentPurple};
  }
`;
const StyledCollapseIcon = styled(CollapseIcon)`
  fill: ${(props) => props.theme.colors.darkPurple};
  height: 2em;
  text-align: center;
  vertical-align: middle;
  width: 2em;
  &:hover {
    fill: ${(props) => props.theme.colors.transparentPurple};
  }
`;
const StyledDropdownContainer = styled.div<{ showDropdown?: boolean }>`
  display: inline-flex;
  flex-direction: column;
  margin-top: 8px;
  overflow-y: auto;
  text-overflow: hidden;
  max-height: 400px;
  @media only screen and (max-width: 680px) {
    max-height: 200px;
  }
  @media only screen and (max-height: 680px) {
    height: ${(props) => (props.showDropdown ? '100px' : 'auto')};
  }
`;
const StyledExpandIcon = styled(ExpandIcon)`
  fill: ${(props) => props.theme.colors.darkPurple};
  height: 2em;
  text-align: center;
  vertical-align: middle;
  width: 2em;
  &:hover {
    fill: ${(props) => props.theme.colors.transparentPurple};
  }
`;
const StyledIconButton = styled.button`
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
`;
const StyledIconCell = styled.td`
  width: 12px;
`;
const StyledInput = styled.input`
  border: none;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.transparentPurple}`};
  background: none;
  color: ${(props) => props.theme.colors.darkPurple};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  height: 40px;
  hyphens: auto;
  line-height: 40px;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: normal;
  width: 100%;
  &:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    border-bottom: ${({ theme }) => `2px solid ${theme.colors.darkPurple}`};
  }
  @media only screen and (min-width: 680px) {
    height: 44px;
    font-size: 22px;
    line-height: 34px;
  }
`;
const StyledTable = styled.table`
  width: 100%;
`;
const StyledTickIcon = styled(TickIcon)<{ isShown: boolean }>`
  fill: ${(props) => props.theme.colors.darkPurple};
  margin-left: 16px;
  height: 1em;
  text-align: center;
  vertical-align: middle;
  visibility: ${({ isShown }) => (isShown ? 'visible' : 'hidden')};
  width: 1em;
`;

export interface Props {
  handleSetResponse: (inputAnswer: IQuestionChoice) => void;
  onClickNext?: () => void;
  onSelect: (res: string) => void;
  properties: {
    alphabetical_order?: boolean;
    randomize?: boolean;
    choices?: IQuestionChoice[];
  };
  toggleDropdownFullscreen?: (isFullscreen: boolean) => void;
  value: IQuestionChoice;
}

enum TransitionTimeout {
  BLINK_WAIT_DURATION = 700,
}

export default function QuestionDropdown({
  handleSetResponse = () => {},
  onClickNext = () => {},
  properties: { alphabetical_order = false, randomize = false, choices = [] },
  value,
  toggleDropdownFullscreen = () => {},
}: Props): React.ReactElement {
  const searchContainerRef = useRef(null);
  const searchResultRef = useRef(null);
  const [cursor, setCursor] = useState(-1);
  const [userSearch, setUserSearch] = useState('');
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const scrollElemIntoView = (position: any) => {
    (searchResultRef as any).current.parentNode.scrollTo({
      top: position,
      behaviour: 'smooth',
    });
  };
  const suggestions = useMemo(() => {
    if (!userSearch) return choices;
    setCursor(-1);
    scrollElemIntoView(0);
    return choices.filter(({ label }) => label.toLowerCase().includes(userSearch.toLowerCase()));
  }, [choices, userSearch]);
  const showDropdown = () => {
    setVisibleDropdown(true);
    toggleDropdownFullscreen(true);
  };
  const hideDropdown = () => {
    setVisibleDropdown(false);
    toggleDropdownFullscreen(false);
  };
  const handleUserSelect = (selectedChoice: IQuestionChoice) => {
    setUserSearch(selectedChoice.label);
    handleSetResponse(selectedChoice);
    setTimeout(() => {
      hideDropdown();
      onClickNext();
    }, TransitionTimeout.BLINK_WAIT_DURATION);
    // onSelect(suggestions[cursor]);
  };
  const isSelected = (choice: IQuestionChoice) => {
    return choice.label === userSearch;
  };
  const keyboardNavigation = (e: any) => {
    if (e.key === 'ArrowDown') {
      visibleDropdown ? setCursor((c) => (c < suggestions.length - 1 ? c + 1 : c)) : showDropdown();
    }
    if (e.key === 'ArrowUp') {
      setCursor((c) => (c > 0 ? c - 1 : 0));
    }
    if (e.key === 'Escape') {
      hideDropdown();
    }
    if (e.key === 'Enter' && cursor >= 0) {
      setUserSearch(suggestions[cursor].label);
      handleUserSelect(suggestions[cursor]);
      setTimeout(() => {
        hideDropdown();
        onClickNext();
      }, TransitionTimeout.BLINK_WAIT_DURATION);
      // onSelect(suggestions[cursor]);
    }
  };
  // Fill in the text box when there is existing database data.
  useEffect(() => {
    if (value) {
      setUserSearch(value.label);
    }
  }, [value]);
  // When users click outside of box
  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (searchContainerRef.current && !(searchResultRef as any).current.contains(e.target)) {
        hideDropdown();
      }
    };
    window.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);
  // When cursor changes/scrolls to particular item in list
  useEffect(() => {
    if (cursor < 0 || cursor > suggestions.length || !searchResultRef) {
      return () => {};
    }
    const listItems: any = Array.from((searchResultRef as any).current.children);
    listItems[cursor] && scrollElemIntoView(listItems[cursor].offsetTop);
  }, [cursor, suggestions.length]);
  return (
    <StyledChoiceContainer
      className="parent-dropdown"
      onWheel={(e) => e.stopPropagation()}
      onTouchMove={(e) => e.stopPropagation()}
    >
      <StyledTable>
        <tbody>
          <tr>
            <td>
              <StyledInput
                ref={searchContainerRef}
                autoComplete="off"
                className="typeahead-bar"
                onClick={showDropdown}
                onChange={(e) => setUserSearch(e.target.value)}
                onKeyDown={(e) => keyboardNavigation(e)}
                placeholder="Type or select an option"
                type="text"
                value={userSearch}
              />
            </td>
            <StyledIconCell>
              <StyledIconButton>
                {userSearch !== '' ? (
                  <StyledCloseIcon onClick={() => setUserSearch('')} />
                ) : visibleDropdown ? (
                  <StyledCollapseIcon />
                ) : (
                  <StyledExpandIcon onClick={() => showDropdown()} />
                )}
              </StyledIconButton>
            </StyledIconCell>
          </tr>
        </tbody>
      </StyledTable>
      <StyledDropdownContainer ref={searchResultRef} showDropdown={visibleDropdown}>
        {visibleDropdown &&
          suggestions.map((choice: IQuestionChoice, i: number) => (
            <QuestionChoiceButton
              key={choice.id}
              onClick={() => handleUserSelect(choice)}
              isHighlighted={cursor === i}
              isSelected={isSelected(choice)}
            >
              <StyledTable>
                <tbody>
                  <tr>
                    <td>{choice.label}</td>
                    <StyledIconCell>
                      <StyledTickIcon isShown={isSelected(choice)} />
                    </StyledIconCell>
                  </tr>
                </tbody>
              </StyledTable>
            </QuestionChoiceButton>
          ))}
      </StyledDropdownContainer>
    </StyledChoiceContainer>
  );
}
