export type ThemeType = typeof aphroditeTheme;

const aphroditeTheme = {
  colors: {
    // Partnership
    lightCream: `#F0E1DE`,
    // Primaries
    aphroRedDark: 'rgba(214, 57, 67, 1)',
    aphroRedLight: '#e82c3a',
    aphroBackgroundLightPink: '#fef4f6',
    aphroBackgroundMainPink: '#ffe3eb',
    aphroBackgroundWhite: 'rgba(255, 255, 255, 0.8)',
    // Secondaries
    aphroPinkHighlight: 'rgba(246, 236, 238, 1.0)',
    aphroPinkHighlightTransparent: 'rgba(246, 236, 238, 0.5)',
    aphroRedHighlight: '#fef2f5',
    aphroditeAqua: '#57b8d1',
    black025: 'rgba(0, 0, 0, 0.25)',
    black80: 'rgba(0, 0, 0, 0.8)',
    blackDefault: 'rgba(38, 38, 38, 1)',
    darkBlue: '#2f4858',
    prussianBlue: '#12406A',
    darkGrey: '#372525',
    palePinkHighlight: 'rgba(255, 238, 249, 1.0)',
    palePinkHighlightTransparent: 'rgba(255, 238, 249, 0.3)',
    standardGrey90: 'rgba(38, 38, 38, 0.9)',
    standardGrey80: 'rgba(38, 38, 38, 0.8)',
    standardGrey70: 'rgba(38, 38, 38, 0.7)',
    standardGrey40: 'rgba(38, 38, 38, 0.4)',
    lightGrey: '#D9D9D9',
    paleGrey: 'rgba(196, 196, 196, 0.5)',
    rechartsGrey: '#cccccc',
    inputBackground: 'rgba(255, 255, 255, 0.9)',
    // Questionnaire
    darkPurple: 'rgb(82, 20, 66)',
    palePurple: 'rgb(250, 232, 236)',
    transparentPalePurple: 'rgba(82, 20, 66, 0.08)',
    transparentPurple: 'rgb(82, 20, 66, 0.3)',
    transparentDarkPurple: 'rgba(82, 20, 67, 0.85)',
  },
  spacing: {
    // Footer
    footerHeight: '120px',
    navbarDesktopLogoHeight: '112px',
    navbarDesktopWidth: '260px;',
    // Questionnaire
    questionnaireWidth: '952px',
    // Grid
    cardSpacing: '16px',
  },
  // Background gradients
  gradients: {
    darkGradient: `linear-gradient(90deg, #eba487 0%, #e68fa8 53.13%, #7a9fe3 98.97%)`,
    selectedButtonGradient: `linear-gradient(90deg, #FFBBA0 0%, #FE99B6 53%, #D0E0FF 100%)`,
    buttonGradient: `linear-gradient(180deg, rgba(235, 164, 135, 0.5) 0%, rgba(230, 143, 168, 0.5) 44.79%, rgba(122, 159, 227, 0.5) 98.44%)`,
    progressGradient: `linear-gradient(90deg, rgba(235, 164, 135, 0.5) 0%, rgba(230, 143, 168, 0.5) 44.79%, rgba(122, 159, 227, 0.5) 98.44%)`,
    translucent:
      'linear-gradient(105.77deg, rgba(255, 255, 255, 0.4) 19.96%, rgba(255, 255, 255, 0.4) 97.97%)',
  },
};

export default aphroditeTheme;
