import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { useFirestoreDocData } from 'reactfire';
import styled from 'styled-components';

import CircledArrowedHeart from '@aphrodite/assets/icons/circle-arrowed-heart.png';
import CupidHeartsPink from '@aphrodite/assets/logos/cupid-hearts-pink.png';
import DoublePinkHeart from '@aphrodite/assets/logos/double-pink-heart.png';
import { ADSButton } from '@aphrodite/common-ui';
import { ADSHeading } from '@aphrodite/common-ui';
import { ADSText } from '@aphrodite/common-ui';
import { useAuthHelper } from '@aphrodite/common/hooks';
import { useGetDomainsForCollege, useIsCollegeEnabled } from '@aphrodite/common/hooks';
import { IUserProfile } from '@aphrodite/common/types/firestore-types';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';
import { firebaseFunctions } from '@aphrodite/firebase/firebase';
import { getAnalytics, logEvent } from '@firebase/analytics';
import { DocumentReference, doc } from '@firebase/firestore';
import { httpsCallable } from '@firebase/functions';

import COLLEGE_CONSTANTS, { CollegesEnum } from '../../constants/College';
import { isEmailDomainValidForCollege } from '../../helpers/emails';
import ConfirmationPopup from '../ConfirmationPopup';
import PlayCupidInvitationCard from '../PlayCupidInvitationCard';

const StyledContainer = styled.div`
  max-width: 100%;
  display: flex;
  padding: 20% 8% 8% 10%;
  flex-direction: column;
  justify-content: center;
  @media only screen and (min-width: 1024px) {
    margin-top: ${({ theme }) => `${parseFloat(theme.spacing.navbarDesktopLogoHeight) * 0.25}px`};
    margin-left: ${({ theme }) => theme.spacing.navbarDesktopWidth};
    padding: 0 4%;
  }
`;
const StyledCardsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media only screen and (min-width: 1024px) {
    padding: 24px 0;
    flex-direction: row;
  }
`;
const StyledHorizontalHeader = styled.div`
  display: flex;
  justify-content: left;
  align-items: baseline;
  flex-direction: row;
  padding-bottom: 20px;
  margin-top: 4%;
`;
const StyledCenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 12px;
`;
const StyledHeader = styled(ADSHeading)`
  hyphens: none;
  line-height: 110%;
  display: block;
`;
const StyledErrorMessage = styled(ADSText)`
  height: 20px;
`;
const StyledLogo = styled.img`
  height: 68px;
  padding-right: 44px;
  padding-left: 44px;
`;
const StyledHeaderHearts = styled.img`
  margin-left: 20px;
  max-height: 36px;
`;
const StyledPlayCupidInvitationCard = styled(PlayCupidInvitationCard)`
  margin: 0 20px;
`;
interface AllowedEmailTextProps {
  college: string;
}
const AllowedEmailText: FunctionComponent<AllowedEmailTextProps> = ({ college }) => {
  const isCollegeEnabled = useIsCollegeEnabled(college);
  const emailFormats = useGetDomainsForCollege(college);
  return (
    <div>
      {isCollegeEnabled ? (
        <ADSText
          color="standardGrey70"
          hyphensNone={true}
          size="xs"
          textAlign="center"
          wrapperStyle={{ whiteSpace: 'pre-line' }}
        >
          {emailFormats.join(', ')}
        </ADSText>
      ) : null}
    </div>
  );
};

export default function DashboardPlayCupid(): React.ReactElement {
  const { user } = useAuthHelper();
  const analytics = getAnalytics();
  const userEmail = user?.email;
  const userProfileRef = doc(
    firebaseFirestore,
    `UserProfiles/${user!.uid}`,
  ) as DocumentReference<IUserProfile>;
  const { data } = useFirestoreDocData<IUserProfile>(userProfileRef);
  const { firstName, college } = data;
  const senderCollege = COLLEGE_CONSTANTS.COLLEGE_NAMES[college];
  const emailFormatMessageTitle = 'Only college emails of the following formats are allowed!';
  const getCollegeByEmail = (email: string) => {
    if (isEmailDomainValidForCollege(email, college)) {
      return college as CollegesEnum;
    }
    return null;
  };
  const isValidName = (name: string) => {
    return name && name.replace(/\s/g, '').length !== 0;
  };
  const [emailA, setEmailA] = useState('');
  const [nameA, setNameA] = useState('');
  const [emailB, setEmailB] = useState('');
  const [nameB, setNameB] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const onEmailAChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    setEmailA(e.target.value.toLowerCase());
  };
  const onEmailBChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    setEmailB(e.target.value.toLowerCase());
  };
  const onNameAChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    setNameA(e.target.value);
  };
  const onNameBChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    setNameB(e.target.value);
  };
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  const onSubmitEmail = async () => {
    logEvent(analytics, 'link-click', {
      component: 'PLAY_CUPID',
      button_name: 'user_submitted_emails',
    });
    setErrorMessage('');
    setIsLoading(true);
    if (!emailB || !emailA || !isValidName(nameA) || !isValidName(nameB)) {
      setErrorMessage('Fields cannot be empty or only empty space');
      setIsLoading(false);
      return;
    }
    // Check if the user actually typed in an email
    if (
      !COLLEGE_CONSTANTS.EMAIL_REGEX.test(emailA) ||
      !COLLEGE_CONSTANTS.EMAIL_REGEX.test(emailB)
    ) {
      setErrorMessage('The emails you entered are not valid');
      setIsLoading(false);
      return;
    }
    // Emails cannot be the same
    if (emailA === emailB) {
      setErrorMessage('You must enter different emails');
      setIsLoading(false);
      return;
    }
    // Emails must be from a participating school
    if (getCollegeByEmail(emailA) == null || getCollegeByEmail(emailB) == null) {
      setErrorMessage(
        'The email address does not follow the format of any of the participating schools',
      );
      setIsLoading(false);
      return;
    }
    // Both recipients must be from the same school
    if (
      getCollegeByEmail(emailA) !== getCollegeByEmail(emailB) ||
      getCollegeByEmail(emailA) !== college
    ) {
      setErrorMessage('The recipients must be from the same school');
      setIsLoading(false);
      return;
    }
    try {
      await httpsCallable(
        firebaseFunctions,
        'sendInviteToEmail',
      )({
        firstName,
        senderCollege,
        userEmail,
        nameA,
        emailA,
        nameB,
        emailB,
      });
    } catch (error: any) {
      setErrorMessage(error.message);
      setIsLoading(false);
      return;
    }
    // Email sent, clear all fields
    setIsLoading(false);
    setShowPopup(true);
    setNameA('');
    setNameB('');
    setEmailA('');
    setEmailB('');
  };
  return (
    <StyledContainer>
      <StyledHorizontalHeader>
        <StyledHeader level="2">Play Cupid</StyledHeader>
        <StyledHeaderHearts src={DoublePinkHeart} alt="Cupids heart logo" />
      </StyledHorizontalHeader>
      <ADSText weight="semibold" size={'s'}>
        Know two friends who might be a great match?
      </ADSText>
      <ADSText weight="semibold" size={'s'}>
        Enter their details here and we’ll drop them an invite to participate in Aphrodite and take
        a shot with the algorithm!
      </ADSText>
      <br />
      <ADSText size={'s'}>
        Your friends will receive an anonymous email invitation. The friends you match will have a
        1% greater chance of being matched!
      </ADSText>
      <br />
      <StyledCardsContainer>
        <StyledPlayCupidInvitationCard
          handleNameInput={onNameAChange}
          handleEmailInput={onEmailAChange}
          email={emailA}
          name={nameA}
          title={'Person A'}
        />
        <StyledLogo src={CupidHeartsPink} alt="Cupids heart logo" />
        <StyledPlayCupidInvitationCard
          handleEmailInput={onEmailBChange}
          handleNameInput={onNameBChange}
          email={emailB}
          name={nameB}
          title={'Person B'}
        />
      </StyledCardsContainer>
      <ADSText
        color="standardGrey70"
        hyphensNone={true}
        size="xs"
        textAlign="center"
        wrapperStyle={{ whiteSpace: 'pre-line' }}
      >
        {emailFormatMessageTitle}
      </ADSText>
      <AllowedEmailText college={college} />
      <StyledErrorMessage weight="light" color="aphroRedLight" size="xs" textAlign="center">
        {errorMessage}
      </StyledErrorMessage>
      <StyledCenteredContainer>
        <ADSButton loading={isLoading} onClick={onSubmitEmail}>
          Send Invites!
        </ADSButton>
      </StyledCenteredContainer>
      {showPopup && (
        <ConfirmationPopup
          logo={CircledArrowedHeart}
          title={'🎉 You did it! 🎉'}
          content={'We have sent both of your friends an email invitation.'}
          subContent={
            'Note that if your friend has already received multiple invites, they will not receive more emails.'
          }
          buttonText={'Close'}
          buttonAction={togglePopup}
          showConfetti={true}
        />
      )}
    </StyledContainer>
  );
}
