import { FunctionComponent } from 'react';

import TemplateUniversitySelectPage from './templates/TemplateUniversitySelectPage';

// Main Component
const PageSignupUniversitySelect: FunctionComponent<{}> = () => {
  return (
    <TemplateUniversitySelectPage
      title="Let's go!"
      description="First things first, where you from? The Aphrodite Project is currently only open to
    students from these universities"
      type="signup"
    />
  );
};

export default PageSignupUniversitySelect;
