import { ADSHeading } from '@aphrodite/common-ui';

// Main component
interface Props {
  className?: string;
  children: string;
  imgSrc?: string;
}

export default function Banner({ className, children, imgSrc }: Props): React.ReactElement {
  return (
    <div className={className}>
      <ADSHeading hyphensNone={true} overflow="breakWord" level="1" weight="bold">
        {children}
      </ADSHeading>
      {imgSrc ? <img alt="banner" src={imgSrc} /> : null}
    </div>
  );
}
