import classNames from 'classnames';
import { FunctionComponent } from 'react';
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';

import { ADSText } from '@aphrodite/common-ui';
import { GraphProps } from '@aphrodite/common/types/DataReportTypes';

import { lineColors } from '../constants/Graph';

// Styled Components
const StyledContainer = styled.div`
  margin: 0;
`;

const StyledTitle = styled(ADSText)`
  margin-left: 28px;
`;

/**
 * Generic Line Chart Component.
 */
const LineChartComponent: FunctionComponent<GraphProps> = ({
  className,
  title,
  dataKeys,
  xLabel,
  yLabel,
  items,
}) => {
  if (!dataKeys || dataKeys.length < 1) {
    return null;
  }

  const graphClassName = classNames(/*styles.container,*/ className);

  const percentFormatter = (value: any) => value + '%';

  return (
    <StyledContainer className={graphClassName}>
      <StyledTitle size="m" weight="semibold">
        {title}
      </StyledTitle>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          width={500}
          height={300}
          data={items}
          margin={{
            right: 40,
            left: 40,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name">
            <Label value={xLabel} offset={0} position="bottom" />
          </XAxis>
          <YAxis>
            <Label value={yLabel} offset={0} angle={-90} position="left" dy={-70} />
          </YAxis>
          <Tooltip formatter={percentFormatter} />
          <Legend
            iconSize={16}
            align="right"
            verticalAlign="top"
            wrapperStyle={{ paddingTop: '20px' }}
          />
          {dataKeys.map((line, _) => {
            return <Line key={line} dataKey={line} stroke={lineColors[line]} />;
          })}
        </LineChart>
      </ResponsiveContainer>
    </StyledContainer>
  );
};

export default LineChartComponent;
