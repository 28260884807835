import { httpsCallable } from 'firebase/functions';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ADSHeading, ADSPage, ADSPageLoading, ADSText } from '@aphrodite/common-ui';
import { useAuthHelper } from '@aphrodite/common/hooks';
import { useIsQuestionnaireDisabled } from '@aphrodite/common/hooks';
import { firebaseFunctions } from '@aphrodite/firebase/firebase';

import Navbar from '../components/Navbar';

// Styled Components
const StyledContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  max-width: 100%;
  padding-left: 8%;
  padding-right: 8%;
  margin: auto;
  margin-top: 8%;
  @media only screen and (min-width: 680px) {
    width: 1000px;
    margin-top: 12%;
    padding: 0 4%;
  }
`;

export default function PageWithdraw(): React.ReactElement {
  const { clearUser, user } = useAuthHelper();
  const [isWithdrawing, setIsWithdrawing] = useState(true);
  const isQuestionnaireDisabled = useIsQuestionnaireDisabled();
  const history = useHistory();
  useEffect(() => {
    // if sign-ups closed, prevent withdraw.
    if (isQuestionnaireDisabled) {
      history.push('/dashboard/profile');
      return;
    }
    if (!user) {
      setIsWithdrawing(false);
      return;
    }
    async function deleteUser() {
      const deleteFunction = httpsCallable(firebaseFunctions, 'executeUserAccountDelete');
      await deleteFunction()
        .then(() => {
          clearUser();
          setIsWithdrawing(false);
        })
        .catch((error) => {
          alert(`An error occured: ${error.message}, please email us with a screenshot!`);
        });
    }
    deleteUser();
  }, [history, isQuestionnaireDisabled, clearUser, setIsWithdrawing, user]);
  if (isWithdrawing) {
    return <ADSPageLoading />;
  }
  return (
    <ADSPage>
      <Navbar />
      <StyledContainer>
        <ADSHeading hyphensNone={true}>
          You've successfully withdrawn from the Aphrodite Project.
        </ADSHeading>
        <br />
        <br />
        <ADSText>You may now close this page.</ADSText>
      </StyledContainer>
    </ADSPage>
  );
}
