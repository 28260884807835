import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { keyframes } from 'styled-components';

import { ADSAnchor, ADSButton, ADSHeading } from '@aphrodite/common-ui';

import { PressArticleInfo } from '../../constants/PressConstants';

const StyledRotatingBannerCard = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  padding-left: 8%;
  width: 100vw;
  height: 80vh;
  @media only screen and (min-width: 680px) {
    padding-top: 5px;
    padding-bottom: 50px;
    height: 30vh;
    min-height: 600px;
    justify-content: center;
  }
  @media only screen and (min-width: 1440px) {
    padding-left: 16%;
    padding-right: 16%;
  }
`;

const StyledFlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  height: 80vh;
  @media only screen and (min-width: 680px) {
    height: 30vh;
    min-height: 300px;
  }
`;
const StyledHeading = styled(ADSHeading)``;
const StyledBannerDiv = styled.div`
  width: 90vw;
  min-width: 50%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media only screen and (min-width: 680px) {
    width: 100%;
  }
`;
const ScrollAnimationMobile = keyframes`
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: calc(-104px * (${PressArticleInfo.length} - 1));
  }
`;
const ScrollAnimation = keyframes`
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: calc(-164px *  (${PressArticleInfo.length} - 1));
  }
`;
const StyledImageBanner = styled.div`
  height: 100px;
  display: flex;
  flex-direction: row;
  overflow: visible;
  gap: 12px;
  animation: ${ScrollAnimationMobile} 30s linear infinite;
  @media only screen and (min-width: 680px) {
    height: 140px;
    gap: 24px;
    animation: ${ScrollAnimation} 30s linear infinite;
    &:hover {
      animation-play-state: paused;
    }
  }
`;
const StyledAnchor = styled(ADSAnchor)`
  width: fit-content;
`;
const StyledImg = styled.div`
  display: inline-flex;
  width: 92px;
  height: auto;
  align-items: center;
  justify-content: center;
  img {
    width: 92px;
    height: auto;
    justify-self: center;
  }
  @media only screen and (min-width: 680px) {
    width: 140px;
    img {
      width: 140px;
    }
  }
`;

interface StudentImageProps {
  imgSrc: string;
  url: string;
}
const PressImages: FunctionComponent<StudentImageProps> = ({ imgSrc, url }) => {
  return (
    <StyledImg>
      <img src={imgSrc} alt="press Logo" />
    </StyledImg>
  );
};

export default function LandingPressCoverage(): React.ReactElement {
  return (
    <StyledRotatingBannerCard>
      <StyledFlexDiv>
        <StyledHeading weight="bold" textAlign="left" level="2">
          We are featured in
        </StyledHeading>
        <StyledBannerDiv>
          <StyledImageBanner>
            {PressArticleInfo.map((info, i) => {
              return info.icon && <PressImages imgSrc={info.logo} url={info.url} key={i} />;
            })}
            {PressArticleInfo.map((info, i) => {
              return info.icon && <PressImages imgSrc={info.logo} url={info.url} key={i} />;
            })}
          </StyledImageBanner>
        </StyledBannerDiv>
        <StyledAnchor to="/press">
          <ADSButton>Read more</ADSButton>
        </StyledAnchor>
      </StyledFlexDiv>
    </StyledRotatingBannerCard>
  );
}
