import styled, { css, keyframes } from 'styled-components';

// Mixins
const RotateKeyframe = () => {
  return keyframes`
    100% {
    transform: rotate(360deg);
  }
  `;
};
const DashKeyframe = () => {
  return keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }`;
};
const handleSize = (size: 's' | 'm' | 'l' = 'm', isMarginOffset: boolean) => {
  switch (size) {
    case 's':
      return css`
        width: 16px;
        height: 16px;
        ${isMarginOffset &&
        css`
          margin: -8px 0 0 -8px;
        `}
      `;
    case 'm':
      return css`
        width: 32px;
        height: 32px;
        ${isMarginOffset &&
        css`
          margin: -16px 0 0 -16px;
        `}
      `;
    case 'l':
      return css`
        width: 64px;
        height: 64px;
        ${isMarginOffset &&
        css`
          margin: -32px 0 0 -32px;
        `}
      `;
  }
};

// Styled Components
const StyledContainer = styled.div`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
`;
const StyledSpinnerSVG = styled.svg<{ size?: 's' | 'm' | 'l'; isMarginOffset: boolean }>`
  z-index: 100;
  animation: ${RotateKeyframe} 1.5s linear infinite;
  ${({ isMarginOffset, size }) => handleSize(size, isMarginOffset)};
`;
const StyledPathCircle = styled.circle`
  stroke-linecap: round;
  animation: ${DashKeyframe} 1.5s ease-in-out infinite;
`;
interface Props {
  className?: string;
  color?: 'aphroRedDark' | 'blackDefault' | 'white';
  isMarginOffset?: boolean;
  size?: 's' | 'm' | 'l';
}

export default function ADSLoading({
  className = '',
  color = 'aphroRedDark',
  isMarginOffset = true,
  size,
}: Props): React.ReactElement {
  let stroke = 'white';
  switch (color) {
    case 'aphroRedDark':
      // We can't use styled-components theme here as the theme isn't loaded up before
      // the application is loaded.
      stroke = 'rgba(214, 57, 67, 1)';
      break;
    case 'white':
      stroke = 'white';
      break;
    case 'blackDefault':
    default:
      stroke = 'rgba(38, 38, 38, 1)';
  }
  return (
    <StyledContainer className={className}>
      <StyledSpinnerSVG isMarginOffset={isMarginOffset} size={size} viewBox="0 0 50 50">
        <StyledPathCircle cx="25" cy="25" r="20" fill="none" stroke={stroke} strokeWidth="5" />
      </StyledSpinnerSVG>
    </StyledContainer>
  );
}
