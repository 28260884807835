import { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import buymeacoffeeLogo from '@aphrodite/assets/logos/buymeacoffee.svg';
import instagramLogo from '@aphrodite/assets/logos/instagram-logo.png';
import { ADSAnchor, ADSText } from '@aphrodite/common-ui';
import { useIsSingaporeWebsite } from '@aphrodite/common/hooks';
import { getAnalytics, logEvent } from '@firebase/analytics';

/**
 * Footer
 */

// Styled Components
const StyledContainer = styled.div`
  display: flex;
  height: 120px;
  width: 100%;
  padding: 0px 20px;
  background-color: #000000;
  flex-direction: column;
  @media only screen and (min-width: 680px) {
    flex-direction: row;
    justify-content: space-between;
  }
  ul {
    margin-top: 24px;
  }
  li {
    list-style-type: none;
    display: inline;
  }
  a {
    padding: 8px;
    position: relative;
    padding-top: 16px;
    text-decoration: none;
    color: white;
    font-size: 12px;
    &:hover > span {
      color: grey;
    }
  }
`;

const StyledLinksText = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  li {
    margin-right: auto;
  }
  @media only screen and (min-width: 680px) {
    margin-left: 10%;
    li {
      padding-right: 36px;
    }
  }
`;

const StyledLinksSocialMedia = styled.ul`
  @media only screen and (min-width: 680px) {
    margin-right: 10%;
  }
`;

const StyledImage = styled.img`
  filter: invert(1);
  width: 20px;
  height: 20px;
`;

// Main Component
const Footer: FunctionComponent<{}> = () => {
  const analytics = getAnalytics();
  const isSingaporeWebsite = useIsSingaporeWebsite();
  const instagramLink = isSingaporeWebsite
    ? 'https://www.instagram.com/aphroditeproject.sg'
    : 'https://www.instagram.com/aphroditeproject';
  return (
    <StyledContainer>
      <StyledLinksText>
        <li>
          <NavLink
            to="/about"
            onClick={() =>
              logEvent(analytics, 'link-click', {
                component: 'FOOTER',
                button_name: 'about',
              })
            }
          >
            <ADSText color="white" inline={true} size="xs" weight="semibold">
              About Us
            </ADSText>
          </NavLink>
        </li>
        <li>
          <ADSAnchor
            target="_blank"
            to="https://bit.ly/aphropolicy2022"
            onClick={() =>
              logEvent(analytics, 'link-click', {
                component: 'FOOTER',
                button_name: 'privacy_policy',
              })
            }
          >
            <ADSText color="white" inline={true} size="xs" weight="semibold">
              Privacy Policy
            </ADSText>
          </ADSAnchor>
        </li>
        <li>
          <ADSAnchor
            target="_blank"
            to="mailto: cupids@aphrodite.global"
            onClick={() =>
              logEvent(analytics, 'link-click', {
                component: 'FOOTER',
                button_name: 'contact_us',
              })
            }
          >
            <ADSText color="white" inline={true} size="xs" weight="semibold">
              Contact Us
            </ADSText>
          </ADSAnchor>
        </li>
      </StyledLinksText>
      <StyledLinksSocialMedia>
        <li>
          <ADSAnchor
            target="_blank"
            to={instagramLink}
            onClick={() =>
              logEvent(analytics, 'link-click', {
                component: 'FOOTER',
                button_name: 'instagram',
              })
            }
          >
            <StyledImage src={instagramLogo} alt="instagram logo" />
          </ADSAnchor>
        </li>
        <li>
          <ADSAnchor
            target="_blank"
            to="https://www.buymeacoffee.com/aphroditecupids"
            onClick={() =>
              logEvent(analytics, 'link-click', {
                component: 'FOOTER',
                button_name: 'donation',
              })
            }
          >
            <StyledImage src={buymeacoffeeLogo} alt="buy me a coffee logo" />
          </ADSAnchor>
        </li>
      </StyledLinksSocialMedia>
    </StyledContainer>
  );
};

export default Footer;
