import { FunctionComponent, ReactNode } from 'react';
import styled, { css } from 'styled-components';

export interface Props {
  blockquote?: boolean;
  children: ReactNode;
  className?: string;
  color?:
    | 'black80'
    | 'blackDefault'
    | 'darkGrey'
    | 'standardGrey40'
    | 'standardGrey70'
    | 'standardGrey80'
    | 'aphroRedDark'
    | 'aphroRedLight'
    | 'prussianBlue'
    | 'white';
  decoration?: 'none';
  fontStyle?: 'normal' | 'italic' | 'underline';
  hyphensNone?: boolean;
  inline?: boolean;
  overflow?: 'normal' | 'breakWord';
  singleline?: boolean;
  size?: 'l' | 'm' | 's' | 'xs' | 'xxs';
  textAlign?: 'center' | 'left' | 'right';
  textDecoration?: 'underline';
  weight?: 'light' | 'regular' | 'semibold';
  wrapperStyle?: React.CSSProperties;
}

const handleTextDecoration = (textDecoration: string | undefined) => {
  switch (textDecoration) {
    case 'underline':
      return 'text-decoration: underline;';
    default:
      return 'text-decoration: none;';
  }
};
const handleDecoration = (decoration: string | undefined) => {
  switch (decoration) {
    case 'none':
      return 'text-decoration: none;';
  }
};
const handleFontStyle = (fontStyle: string | undefined) => {
  switch (fontStyle) {
    case 'italic':
      return 'font-style: italic;';
    case 'underline':
      return 'text-decoration: underline;';
    case 'normal':
    default:
      return 'font-style: normal;';
  }
};
const handleOverflow = (overflow: string | undefined) => {
  switch (overflow) {
    case 'breakWord':
      return 'word-break: break-word; overflow-wrap: break-word;';
    case 'normal':
    default:
      return 'word-break: normal; overflow-wrap: normal;';
  }
};
const handleTextSize = (size: string | undefined) => {
  switch (size) {
    case 'l':
      return '@media only screen and (min-width: 680px) { font-size: 22px; line-height: 34px; } font-size: 18px; line-height: 28px;';
    case 'm':
      return '@media only screen and (min-width: 680px) { font-size: 20px; line-height: 30px; } font-size: 16px; line-height: 24px;';
    case 's':
      return '@media only screen and (min-width: 680px) { font-size: 16px; line-height: 24px; } font-size: 14px; line-height: 20px;';
    case 'xs':
      return '@media only screen and (min-width: 680px) { font-size: 14px; line-height: 16px; } font-size: 12px; line-height: 14px;';
    case 'xxs':
      return '@media only screen and (min-width: 680px) { font-size: 12px; line-height: 14px; } font-size: 10px; line-height: 12px;';
    default:
  }
};
const handleTextAlign = (textAlign: string | undefined) => {
  switch (textAlign) {
    case 'center':
      return 'text-align: center;';
    case 'left':
      return 'text-align: left;';
    case 'right':
      return 'text-align: right;';
    default:
  }
};
const handleTextWeight = (weight: string | undefined) => {
  switch (weight) {
    case 'light':
      return 'font-weight: 300;';
    case 'regular':
      return 'font-weight: 400;';
    case 'semibold':
      return 'font-weight: 500;';
    default:
      return 'font-weight: 400;';
  }
};
// Mixins
const CustomizationMixin = css<Props>`
  color: ${(props) =>
    props.color ? props.theme.colors[props.color] : props.theme.colors['standardGrey90']};
  ${({ textDecoration }) => handleTextDecoration(textDecoration)};
  ${({ decoration }) => handleDecoration(decoration)};
  ${({ fontStyle }) => handleFontStyle(fontStyle)};
  ${(props) => (props.hyphensNone ? 'hyphens: none' : 'hyphens: auto')};
  ${({ overflow }) => handleOverflow(overflow)};
  ${(props) => (props.singleline ? 'white-space: nowrap' : 'white-space: normal;')};
  ${({ size }) => handleTextSize(size)};
  ${({ textAlign }) => handleTextAlign(textAlign)};
  ${({ weight }) => handleTextWeight(weight)};
`;

// Styled Components
const StyledBlockquote = styled.blockquote`
  overflow: visible;
  text-overflow: ellipsis;
  ${CustomizationMixin};
`;
const StyledStandard = styled.p`
  overflow: visible;
  text-overflow: ellipsis;
  ${CustomizationMixin};
`;
const StyledSpan = styled.span`
  overflow: visible;
  text-overflow: ellipsis;
  ${CustomizationMixin};
`;

const ADSText: FunctionComponent<Props> = ({
  blockquote,
  children,
  className,
  color = 'blackDefault',
  decoration,
  fontStyle,
  hyphensNone,
  inline,
  overflow = 'normal',
  singleline,
  size = 'm',
  textAlign,
  textDecoration,
  weight = 'regular',
  wrapperStyle = {},
}) => {
  if (blockquote) {
    return (
      <StyledBlockquote
        color={color}
        decoration={decoration}
        className={className}
        fontStyle={fontStyle}
        hyphensNone={hyphensNone}
        overflow={overflow}
        singleline={singleline}
        size={size}
        style={wrapperStyle}
        textAlign={textAlign}
        textDecoration={textDecoration}
        weight={weight}
        wrapperStyle={wrapperStyle}
      >
        {children}
      </StyledBlockquote>
    );
  } else if (!inline) {
    return (
      <StyledStandard
        color={color}
        decoration={decoration}
        className={className}
        fontStyle={fontStyle}
        hyphensNone={hyphensNone}
        overflow={overflow}
        singleline={singleline}
        size={size}
        style={wrapperStyle}
        textAlign={textAlign}
        textDecoration={textDecoration}
        weight={weight}
        wrapperStyle={wrapperStyle}
      >
        {children}
      </StyledStandard>
    );
  } else {
    return (
      <StyledSpan
        color={color}
        decoration={decoration}
        className={className}
        fontStyle={fontStyle}
        hyphensNone={hyphensNone}
        overflow={overflow}
        singleline={singleline}
        size={size}
        style={wrapperStyle}
        textAlign={textAlign}
        textDecoration={textDecoration}
        weight={weight}
        wrapperStyle={wrapperStyle}
      >
        {children}
      </StyledSpan>
    );
  }
};

export default ADSText;
