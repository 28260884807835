import { FunctionComponent, ReactNode } from 'react';
import styled, { css } from 'styled-components';

export interface Props {
  children: ReactNode;
  className?: string;
  color?: 'standardGrey90' | 'black';
  hyphensNone?: boolean;
  inline?: boolean;
  level?: '1' | '2' | '3';
  overflow?: 'normal' | 'breakWord';
  singleline?: boolean;
  tag?: 'h1' | 'h2' | 'h3';
  textAlign?: 'center' | 'left' | 'right';
  weight?: 'light' | 'regular' | 'semibold' | 'bold';
  wrapperStyle?: React.CSSProperties;
}

const handleLevel = (level: string | undefined) => {
  switch (level) {
    case '1':
      return css`
        font-size: 36px;
        line-height: 48px;
        @media only screen and (min-width: 680px) {
          font-size: 48px;
          line-height: 60px;
        }
      `;
    case '2':
      return css`
        font-size: 24px;
        line-height: 36px;
        @media only screen and (min-width: 680px) {
          font-size: 36px;
          line-height: 48px;
        }
      `;
    case '3':
      return css`
        font-size: 20px;
        line-height: 28px;
        @media only screen and (min-width: 680px) {
          font-size: 24px;
          line-height: 36px;
        }
      `;
  }
};
const handleOverflow = (overflow: string | undefined) => {
  switch (overflow) {
    case 'breakWord':
      return 'word-break: break-word; overflow-wrap: break-word;';
    case 'normal':
      return 'word-break: normal; overflow-wrap: normal;';
  }
};
const handleTextAlign = (textAlign: string | undefined) => {
  switch (textAlign) {
    case 'center':
      return 'text-align: center;';
    case 'right':
      return 'text-align: right;';
    case 'left':
      return 'text-align: left;';
  }
};
const handleWeight = (weight: string | undefined) => {
  switch (weight) {
    case 'light':
      return 'font-weight: 300;';
    case 'regular':
      return 'font-weight: 400;';
    case 'semibold':
      return 'font-weight: 600;';
    case 'bold':
      return 'font-weight: 700;';
  }
};
// Styled-component
const StyledH1 = styled.h1<Props>`
  overflow: visible;
  hyphens: auto;
  text-overflow: ellipsis;
  color: ${(props) =>
    props.color ? props.theme.colors[props.color] : props.theme.colors['standardGrey90']};
  ${(props) => (props.hyphensNone ? 'hyphens: none;' : 'hyphens: auto;')};
  ${(props) => (props.inline ? 'display: inline-block;' : '')};
  ${(props) => (props.singleline ? 'white-space: nowrap;' : '')};
  ${({ level }) => handleLevel(level)};
  ${({ overflow }) => handleOverflow(overflow)};
  ${({ textAlign }) => handleTextAlign(textAlign)};
  ${({ weight }) => handleWeight(weight)};
`;
const StyledH2 = styled.h2<Props>`
  overflow: visible;
  hyphens: auto;
  text-overflow: ellipsis;
  color: ${(props) =>
    props.color ? props.theme.colors[props.color] : props.theme.colors['standardGrey90']};
  ${(props) => (props.hyphensNone ? 'hyphens: none;' : 'hyphens: auto;')};
  ${(props) => (props.inline ? 'display: inline-block;' : '')};
  ${({ level }) => handleLevel(level)};
  ${({ overflow }) => handleOverflow(overflow)};
  ${({ textAlign }) => handleTextAlign(textAlign)};
  ${({ weight }) => handleWeight(weight)};
`;
const StyledH3 = styled.h3<Props>`
  overflow: visible;
  hyphens: auto;
  text-overflow: ellipsis;
  color: ${(props) =>
    props.color ? props.theme.colors[props.color] : props.theme.colors['standardGrey90']};
  ${(props) => (props.hyphensNone ? 'hyphens: none;' : 'hyphens: auto;')};
  ${(props) => (props.inline ? 'display: inline-block;' : '')};
  ${({ level }) => handleLevel(level)};
  ${({ overflow }) => handleOverflow(overflow)};
  ${({ textAlign }) => handleTextAlign(textAlign)};
  ${({ weight }) => handleWeight(weight)};
`;

const ADSHeading: FunctionComponent<Props> = ({
  children,
  className = '',
  color = 'standardGrey90',
  hyphensNone = false,
  inline = false,
  level = '1',
  overflow = 'normal',
  singleline = false,
  tag,
  textAlign = 'left',
  weight = 'regular',
  wrapperStyle = {},
}) => {
  switch (tag) {
    case 'h2':
      return (
        <StyledH2
          className={className}
          color={color}
          hyphensNone={hyphensNone}
          inline={inline}
          level={level}
          overflow={overflow}
          singleline={singleline}
          textAlign={textAlign}
          weight={weight}
          style={wrapperStyle}
        >
          {children}
        </StyledH2>
      );
    case 'h3':
      return (
        <StyledH3
          className={className}
          color={color}
          hyphensNone={hyphensNone}
          inline={inline}
          level={level}
          overflow={overflow}
          singleline={singleline}
          textAlign={textAlign}
          weight={weight}
          style={wrapperStyle}
        >
          {children}
        </StyledH3>
      );
    case 'h1':
    default:
      return (
        <StyledH1
          className={className}
          color={color}
          hyphensNone={hyphensNone}
          inline={inline}
          level={level}
          overflow={overflow}
          singleline={singleline}
          textAlign={textAlign}
          weight={weight}
          style={wrapperStyle}
        >
          {children}
        </StyledH1>
      );
  }
};

export default ADSHeading;
