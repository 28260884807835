/**
 * Reference: https://stackoverflow.com/questions/22593286/detect-measure-scroll-speed
 *
 * @param delay Delay in milliseconds. Higher means lower fidelity.
 * @returns Function that cal be called to check the current speed.
 */
export function createScrollSpeedChecker(delay: number = 150): (curPos: number) => number {
  // Cache these values.
  let lastPos: number | null,
    newPos: number = 0,
    timer: NodeJS.Timeout,
    delta: number;
  const clear = () => {
    lastPos = null;
    delta = 0;
  };
  clear();
  return function (currPos: number) {
    newPos = newPos + currPos;
    if (lastPos !== null) {
      // && newPos < maxScroll
      delta = newPos - lastPos;
    }
    lastPos = newPos;
    clearTimeout(timer);
    timer = setTimeout(clear, delay);
    return delta;
  };
}
