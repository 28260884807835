import { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as TickIcon } from '@aphrodite/assets/logos/tick.svg';

import { IQuestionChoice } from '../../constants/Questionnaire';
import QuestionChoiceButton from './QuestionChoiceButton';

// Styled Components
const StyledChoiceContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  max-height: 204px;
  max-width: 100%;
  overflow-y: auto;
  padding-right: 4px;
  text-overflow: hidden;
  @media only screen and (min-height: 568px) {
    max-height: 224px;
  }
  @media only screen and (min-height: 640px) {
    max-height: 284px;
  }
  @media only screen and (min-height: 768px) {
    max-height: 424px;
  }
  // Make scrollbar always visible
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
const StyledIconCell = styled.td`
  width: 12px;
`;
const StyledOtherContainer = styled.div`
  background-color: ${(props) => props.theme.colors.transparentPalePurple};
  border: 1px solid ${(props) => props.theme.colors.darkPurple};
  border-radius: 4px;
  color: ${(props) => props.theme.colors.darkPurple};
  margin-bottom: 8px;
  max-width: 100%;
  padding: none;
  position: relative;
  text-overflow: hidden;
`;
const StyledOtherInput = styled.input`
  background-color: transparent;
  border: none;
  color: ${(props) => props.theme.colors.darkPurple};
  font-size: 20px;
  padding: 8px;
  text-align: left;
  width: 85%;
  &:focus {
    color: ${(props) => props.theme.colors.darkPurple};
    outline: none;
  }
  &::placeholder {
    color: ${(props) => props.theme.colors.darkPurple};
    opacity: 70%;
  }
  @media only screen and (max-width: 680px) {
    font-size: 16px;
  }
`;
const StyledOtherTickIcon = styled(TickIcon)`
  fill: ${(props) => props.theme.colors.palePurple};
  height: 1em;
  right: 8px;
  top: 8px;
  position: absolute;
  width: 1em;
`;
const StyledTable = styled.table`
  width: 100%;
`;
const StyledTickButton = styled.button`
  background-color: ${(props) => props.theme.colors.darkPurple};
  border: none;
  border-radius: 4px;
  display: inline-block;
  font-size: 16px;
  height: 32px;
  position: absolute;
  right: 4px;
  text-align: center;
  top: 50%;
  transform: translate(0, -50%);
  width: 36px;
  &:hover {
    background-color: ${(props) => props.theme.colors.transparentDarkPurple};
  }
`;
const StyledTickIcon = styled(TickIcon)<{ isvisible: number }>`
  margin-left: 16px;
  height: 1em;
  text-align: center;
  vertical-align: middle;
  visibility: ${(props) => (props.isvisible === 1 ? 'visible' : 'hidden')};
  width: 1em;
`;
export interface Props {
  handleSetResponse: (inputAnswer: IQuestionChoice[]) => void;
  onClickNext: () => void;
  properties: {
    allow_multiple_selection?: boolean;
    allow_other_choice?: boolean;
    randomize?: boolean;
    choices?: IQuestionChoice[];
  };
  validations: {
    max_selection?: number;
  };
  selected: IQuestionChoice[];
}

enum TransitionTimeout {
  BLINK_WAIT_DURATION = 700,
}

export default function QuestionMultipleChoice({
  handleSetResponse,
  onClickNext,
  properties: {
    allow_multiple_selection = false,
    allow_other_choice = false,
    randomize = false,
    choices = [],
  },
  validations: { max_selection = 0 },
  selected = [],
}: Props): React.ReactElement {
  const [optionCount, setOptionCount] = useState(selected.length);
  const getOther = () => {
    const item = selected.find((element) => element.id === 'other');
    return item ? item : { id: 'other', label: 'Other' };
  };
  const [otherOption, setOtherOption] = useState(getOther());
  const [isOtherOptionActive, setIsOtherOptionActive] = useState(false);
  const handleClick = (choice: IQuestionChoice) => {
    if (!isSelected(choice) && (!max_selection || optionCount < max_selection)) {
      handleSelect(choice);
    } else if (isSelected(choice)) {
      handleUnselect(choice);
    }
  };
  const handleClickOther = () => {
    setIsOtherOptionActive(true);
    if (isSelected(otherOption)) {
      handleUnselect(otherOption);
    }
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtherOption({ id: 'other', label: event.target.value });
  };
  const handleSelect = (choice: IQuestionChoice) => {
    if (allow_multiple_selection && (!max_selection || optionCount < max_selection)) {
      const updatedChoices = [...selected, choice];
      updateSelection(updatedChoices);
      setOptionCount(updatedChoices.length);
    } else if (!allow_multiple_selection) {
      updateSelection([choice]);
      setTimeout(() => {
        onClickNext();
      }, TransitionTimeout.BLINK_WAIT_DURATION);
    }
  };
  const handleUnselect = (choice: IQuestionChoice) => {
    const array = [...selected];
    let index = -1;
    for (let i = 0; i < selected.length; i++) {
      if (selected[i].id === choice.id) {
        index = i;
      }
    }
    array.splice(index, 1);
    updateSelection(array);
    setOptionCount(array.length);
  };
  const handleWriteOther = () => {
    setIsOtherOptionActive(false);
    if (otherOption.label.trim() !== '' && otherOption.label.trim() !== 'Other') {
      handleSelect(otherOption);
    } else {
      otherOption.label = 'Other';
    }
  };
  const isSelected = (choice: IQuestionChoice) => {
    for (let i = 0; i < selected.length; i++) {
      if (selected[i].id === choice.id) {
        return true;
      }
    }
    return false;
  };
  const updateSelection = (choices: IQuestionChoice[]) => {
    handleSetResponse(choices);
  };
  const renderChoices = choices.map((choice: IQuestionChoice) => (
    <QuestionChoiceButton
      key={choice.id}
      isSelected={isSelected(choice)}
      onClick={() => handleClick(choice)}
    >
      <StyledTable>
        <tbody>
          <tr>
            <td>{choice.label}</td>
            <StyledIconCell>
              <StyledTickIcon isvisible={isSelected(choice) ? 1 : 0} />
            </StyledIconCell>
          </tr>
        </tbody>
      </StyledTable>
    </QuestionChoiceButton>
  ));
  return (
    <StyledChoiceContainer
      onWheel={(e) => e.stopPropagation()}
      onTouchMove={(e) => e.stopPropagation()}
    >
      {renderChoices}
      {allow_other_choice && !isOtherOptionActive && (
        <QuestionChoiceButton
          key={otherOption.id}
          isSelected={isSelected(otherOption)}
          onClick={() => handleClickOther()}
        >
          <StyledTable>
            <tbody>
              <tr>
                <td>{otherOption.label}</td>
                <StyledIconCell>
                  <StyledTickIcon isvisible={isSelected(otherOption) ? 1 : 0} />
                </StyledIconCell>
              </tr>
            </tbody>
          </StyledTable>
        </QuestionChoiceButton>
      )}
      {isOtherOptionActive && (
        <StyledOtherContainer>
          <StyledOtherInput
            autoFocus
            type="text"
            value={otherOption.label}
            placeholder="Type Your Answer"
            onChange={handleInputChange}
            onFocus={(event) => event.target.select()}
          />
          <StyledTickButton onClick={() => handleWriteOther()}>
            <StyledOtherTickIcon />
          </StyledTickButton>
        </StyledOtherContainer>
      )}
    </StyledChoiceContainer>
  );
}
