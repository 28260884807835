import { collection, getDocs } from 'firebase/firestore';

import { ICollegeConfiguration } from '@aphrodite/common/types/firestore-types';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';

import { CollegesEnum } from '../constants/College';
import { COLLEGE_DOMAINS_ALLOWLIST_REGEX } from '../constants/College';

export function isEmailDomainValidForCollege(email: string, college: CollegesEnum) {
  return COLLEGE_DOMAINS_ALLOWLIST_REGEX[college].some((regex) => regex.test(email));
}

export async function getCollegeFromEmail(email: string) {
  const querySnapshot = await getDocs(collection(firebaseFirestore, 'Configuration'));
  for (const collegeDoc of querySnapshot.docs) {
    const collegeData = collegeDoc.data() as ICollegeConfiguration;
    const isDomainMatch = collegeData.regexes.some((pattern: string) => {
      const regex = new RegExp(pattern);
      return regex.test(email);
    });
    if (isDomainMatch) return collegeData;
  }
  return undefined;
}
