import CountUp from 'react-countup';
import { NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import PinkArrow from '@aphrodite/assets/logos/pink-arrow-right.svg';
import { ADSAnchor, ADSButton, ADSHeading, ADSText } from '@aphrodite/common-ui';
import {
  QUESTIONNAIRE_DISABLED_DATE_GLOBAL,
  QUESTIONNAIRE_DISABLED_DATE_SINGAPORE,
} from '@aphrodite/common/constants/Dates';
import {
  useIsLaunched,
  useIsQuestionnaireClosed,
  useIsSingaporeLaunched,
  useIsSingaporeWebsite,
  useIsValentinesLaunched,
} from '@aphrodite/common/hooks';

import DesktopHeadLeft from '../../assets/images/landing_desktop_head_left.png';
import DesktopHeadRight from '../../assets/images/landing_desktop_head_right.png';
import MobileHeadLeft from '../../assets/images/landing_mobile_head_left.png';
import MobileHeadRight from '../../assets/images/landing_mobile_head_right.png';
import DashboardCardCountdown from '../dashboard/DashboardCardCountdown';

const StyledStartingCard = styled.div`
  left: 0px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  padding-left: 8%;
  padding-right: 8%;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  min-height: 600px;
  width: 100vw;
  padding-bottom: 50px;
  background-image: url(${MobileHeadLeft}), url(${MobileHeadRight});
  background-position: right 25%, left 75%;
  background-repeat: no-repeat, no-repeat;
  gap: 12px;
  text-align: left;
  @media only screen and (min-width: 680px) {
    background-image: url(${DesktopHeadRight}), url(${DesktopHeadLeft});
    background-position: right bottom, left 5%;
    background-size: auto 50%, auto 50%;
    height: 90vh;
    width: 100vw;
    padding-left: 16%;
    padding-right: 16%;
    align-items: center;
    padding-top: 60px;
    gap: 24px;
    text-align: center;
  }
  @media only screen and (min-width: 1000px) {
    background-size: auto, auto;
    background-position: right bottom, left 5%;
  }
`;
const StyledHeading = styled(ADSHeading)`
  font-size: 56px;
  line-height: 120%;
  .gradient {
    background: linear-gradient(60deg, #eb7518 0%, #ce468b 53.13%, #2ac8fd 98.96%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media only screen and (min-width: 680px) {
    font-size: 80px;
    align-self: center;
    text-align: center;
  }
`;
const StyledNavLink = styled(NavLink)`
  max-width: fit-content;
  text-decoration: none;
  margin-top: 24px;
`;
const StyledRedirectText = styled(ADSText)`
  margin-top: 36px;
  @media only screen and (min-width: 680px) {
    margin-top: 12px;
  }
`;
const StyledTimerDiv = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
`;
const gradient = keyframes`
	0% {
		background-position: 0% 0%;
	}
	50% {
		background-position: 100% 50%;
	}
  100% {
		background-position: 0% 0%;
	}
`;
const StyledGradientCard = styled(StyledTimerDiv)`
  display: flex;
  margin-top: 30%;
  margin-bottom: 10%;
  width: 348px;
  padding: 8px 8px 8px 0px;
  height: 126px;
  border-radius: 28px;
  border: 8px solid #fff;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    45deg,
    rgba(235, 117, 24, 0.63) 10.7%,
    #eb7518 28.01%,
    #ce468b 47.3%,
    #3a72e9 71.04%,
    rgba(58, 114, 233, 0.61) 88.35%
  );
  background-size: 200% 150%;
  animation: ${gradient} 4s linear infinite;
  @media only screen and (min-width: 680px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .header {
    margin-right: -12px;
  }
`;
const StyledDigitDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  height: 120%;
  align-content: center;
  border-left: 1px solid #fff;
  padding-left: 2px;
  padding-right: 2px;
`;
const StyledCounterDigit = styled(CountUp)`
  color: #fff;
  text-align: center;
  font-family: monospace;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
const StyledGradientBanner = styled(StyledGradientCard)`
  width: 100vw;
  height: fit-content;
  justify-content: center;
  border: 0px;
  border-radius: 0px;
`;
const StyledMainButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (min-width: 1080px) {
    margin-top: 32px;
  }
`;
const StyledIcon = styled.img`
  transform: rotate(90deg);
  margin-bottom: 60px;
  margin-top: auto;
  height: 24px;
  @media only screen and (min-width: 680px) {
    margin-bottom: 0px;
  }
`;

interface Props {
  isMatchesReleased: boolean;
  signUpCount: number;
}

const MAX_DIGITS = 6;
export default function LandingStarting({
  isMatchesReleased,
  signUpCount,
}: Props): React.ReactElement {
  const isLaunched = useIsLaunched();
  const isSingaporeWebsite = useIsSingaporeWebsite();
  const isSingaporeLaunched = useIsSingaporeLaunched();
  const isValentinesLaunched = useIsValentinesLaunched();
  const isQuestionnaireClosed = useIsQuestionnaireClosed();
  const signUpCountDigit = signUpCount.toString().split('').reverse().map(Number);
  const questionnaireCloseDate = isSingaporeWebsite
    ? QUESTIONNAIRE_DISABLED_DATE_SINGAPORE.format('MMM Do YYYY, HH:mma')
    : QUESTIONNAIRE_DISABLED_DATE_GLOBAL.format('MMM Do YYYY, HH:mma');
  while (signUpCountDigit.length < MAX_DIGITS) {
    signUpCountDigit.push(0);
  }
  const renderMainText = () => {
    if (!isLaunched) {
      // TODO: sign up number should not be hardcoded for both Singapore and NA site
      return (
        <StyledTimerDiv>
          <ADSText textAlign="center" size="s">
            {isSingaporeWebsite ? 4375 : 10580} Sign-ups last round
          </ADSText>
          <DashboardCardCountdown className="Landing" />
        </StyledTimerDiv>
      );
      //where to find the old matching stat?
    }
    if (isMatchesReleased) {
      return (
        <StyledGradientCard>
          <ADSText
            textAlign="center"
            size="l"
            weight="semibold"
            wrapperStyle={{ color: 'white', width: '100%' }}
          >
            Matches are out!
          </ADSText>
        </StyledGradientCard>
      );
    }
    if (isQuestionnaireClosed && isSingaporeWebsite) {
      return (
        <StyledTimerDiv>
          <ADSText textAlign="center" size="s">
            {signUpCount} Sign-ups!
          </ADSText>
          <DashboardCardCountdown className="Landing" />
        </StyledTimerDiv>
      );
    }
    if (signUpCount > 100) {
      return (
        <StyledGradientCard>
          <div className="header">
            <ADSText
              textAlign="center"
              size="s"
              weight="semibold"
              wrapperStyle={{ color: 'white', transform: 'rotate(-90deg)', margin: '0px' }}
            >
              Sign-ups <br /> this run
            </ADSText>
          </div>
          {signUpCountDigit.reverse().map((item, idx) => {
            return (
              <StyledDigitDiv key={idx}>
                <StyledCounterDigit end={item} delay={(MAX_DIGITS - idx) * 0.1} duration={0.8} />
              </StyledDigitDiv>
            );
          })}
        </StyledGradientCard>
      );
    }
    return (
      <StyledGradientBanner>
        <ADSText textAlign="center" size="m" weight="semibold" wrapperStyle={{ color: 'white' }}>
          Open Now!
        </ADSText>
      </StyledGradientBanner>
    );
  };
  const renderMainButton = () => {
    return (
      <StyledMainButtons>
        <StyledNavLink to="/start">
          <ADSButton>{isQuestionnaireClosed ? 'Log in' : 'Count me in'}</ADSButton>
        </StyledNavLink>
        <StyledNavLink to="/login">
          {!isQuestionnaireClosed && (
            <ADSButton styleType="plain">
              <u>Log in</u>
            </ADSButton>
          )}
        </StyledNavLink>
        <ADSText size="s" weight="light" wrapperStyle={{ marginTop: '16px' }}>
          {`Sign-ups close${isQuestionnaireClosed ? 'd' : ''} on
            ${questionnaireCloseDate}!`}
        </ADSText>
      </StyledMainButtons>
    );
  };
  const renderRedirectToCurrentRun = () => {
    if (isSingaporeWebsite && isValentinesLaunched) {
      return (
        <StyledRedirectText size="xs" color="standardGrey70">
          <ADSAnchor to="https://aphrodite.global">
            Looking for our Valentine's Day run? Visit <u>aphrodite.global</u>
          </ADSAnchor>
        </StyledRedirectText>
      );
    } else if (!isSingaporeWebsite && isSingaporeLaunched) {
      return (
        <StyledRedirectText size="xs" color="standardGrey70">
          <ADSAnchor to="https://sg.aphrodite.global">
            Looking for our Singapore run? Visit <u>sg.aphrodite.global</u>
          </ADSAnchor>
        </StyledRedirectText>
      );
    }
    return <></>;
  };
  return (
    <StyledStartingCard>
      {renderMainText()}
      <StyledHeading weight="bold" level="1">
        Meet Your <b className="gradient">Ideal</b> Date.
      </StyledHeading>
      <ADSText size="m" weight="light">
        Built on psychology + an economics nobel prize algorithm <br />
        60 questions • 20 minutes • One Final Match
      </ADSText>
      {isLaunched && renderMainButton()}
      {renderRedirectToCurrentRun()}
      <StyledIcon src={PinkArrow} />
    </StyledStartingCard>
  );
}
