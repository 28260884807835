import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
html {
  overflow: hidden;
  height: 100%;
}
body {
  height: 100%;
  overflow: auto;
}
* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, sans-serif;
  &:focus, &:hover {
    outline: none;
  }
  &, &::before, &::after {
    box-sizing: border-box;
  }
}

blockquote {
  background: ${({ theme }) => theme.colors.aphroRedHighlight};
  border-left: ${({ theme }) => `4px solid ${theme.colors.aphroRedDark}`};
  margin: 1.5em 0;
  padding: 0.5em 10px;
}

blockquote:before {
  color: $aphroRedDark;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
`;

export default GlobalStyle;
