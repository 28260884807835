import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { keyframes } from 'styled-components';

import { ADSAnchor, ADSButton, ADSHeading } from '@aphrodite/common-ui';

import { coreMemberInfo } from '../../constants/aboutUsConstants';

const StyledRotatingBannerCard = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  padding-left: 8%;
  width: 100vw;
  height: 50vh;
  @media only screen and (min-width: 640px) {
    padding-top: 5px;
    padding-bottom: 50px;
    height: 30vh;
    min-height: 600px;
    justify-content: center;
  }
  @media only screen and (min-width: 1440px) {
    padding-left: 16%;
    padding-right: 16%;
  }
`;
const StyledFlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 70vh;
  min-height: 450px;
  .Gradient {
    background: linear-gradient(90deg, #e1764c 0%, #df7291 53.13%, #6c94e0 98.97%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  .button {
    margin-left: 4px;
  }
`;
const StyledBannerDiv = styled.div`
  width: 90vw;
  margin-top: 64px;
  margin-bottom: 64px;
  min-width: 50%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 24px;
  margin-right: 8%;
  @media only screen and (min-width: 640px) {
    width: 100%;
  }
`;
const ScrollAnimation = keyframes`
  0% {
    margin-left: calc(-72px * ${coreMemberInfo.length});
  }
  100% {
    margin-left: 0px;
  }
`;
const StyledImageBanner = styled.div`
  height: 64px;
  display: flex;
  flex-direction: row;
  overflow: visible;
  gap: 8px;
  animation: ${ScrollAnimation} 50s linear infinite;
`;

interface StudentImageProps {
  imgSrc: string;
}

const StyledImgDiv = styled.div<StudentImageProps>`
  display: flex;
  min-width: 64px;
  height: 64px;
  background-image: url(${(props) => props.imgSrc});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
`;
const StudentImages: FunctionComponent<StudentImageProps> = ({ imgSrc }) => {
  return <StyledImgDiv imgSrc={imgSrc} />;
};

export default function LandingJoinUs(): React.ReactElement {
  return (
    <StyledRotatingBannerCard>
      <StyledFlexDiv>
        <ADSHeading className="Gradient" weight="bold" textAlign="left" level="2">
          The Aphrodite Team
        </ADSHeading>
        <StyledBannerDiv>
          <StyledImageBanner>
            {coreMemberInfo.map((info, i) => {
              return <StudentImages imgSrc={info.imgSrc} key={i} />;
            })}
            {coreMemberInfo.map((info, i) => {
              return <StudentImages imgSrc={info.imgSrc} key={i} />;
            })}
          </StyledImageBanner>
        </StyledBannerDiv>
        <ADSAnchor to="https://form.typeform.com/to/xD8vfMpV">
          <ADSButton className="button" styleType="pinkGradient">
            Join Us
          </ADSButton>
        </ADSAnchor>
      </StyledFlexDiv>
    </StyledRotatingBannerCard>
  );
}
