import { collection, doc, getDoc, query, setDoc, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFirestoreCollectionData } from 'reactfire';
import styled from 'styled-components';

import {
  ADSAnchor,
  ADSButton,
  ADSHeading,
  ADSPage,
  ADSPageLoading,
  ADSText,
} from '@aphrodite/common-ui';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';

import Navbar from '../components/Navbar';
import Question from '../components/questionnaire/Question';
import { EMAIL_REGEX } from '../constants/College';
import { IQuestionAnswer, IQuestionChoice, QuestionType } from '../constants/Questionnaire';
import { IWaitlistCollege, commonEmailDomains } from '../constants/Waitlist';
import { getUniversityDetails, getWorldUniversitiesList } from '../helpers/waitlist';

// Styled Components
const StyledTitleContainer = styled.div`
  margin-bottom: 10%;
  @media only screen and (min-width: 680px) {
    margin-bottom: 12px;
  }
`;
const StyledADSText = styled(ADSText)`
  margin-top: 12px;
`;
const StyledADSSubText = styled(ADSText)`
  margin-bottom: 12px;
  margin-top: -24px;
`;
const StyledContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 100%;
  padding-left: 8%;
  padding-right: 8%;
  margin-top: 8%;
  @media only screen and (min-width: 680px) {
    margin-top: 6%;
    padding: 0 10%;
  }
`;
const StyledErrorText = styled(ADSText)<{ isShown: boolean }>`
  align-items: center;
  display: flex;
  justify-content: center;
  ${(props) => {
    if (props.isShown) {
      return `color: red;`;
    }
  }}
`;
const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 2%;
`;
const StyledQuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media only screen and (min-width: 680px) {
    width: 55%;
  }
`;
const StyledSubmitContainer = styled.form`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export default function PageWaitlist() {
  const history = useHistory();
  const [inputErrorMessage, setInputErrorMessage] = useState<string>('');
  const [isErrorDisplayed, setIsErrorDisplayed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedCollege, setSelectedCollege] = useState<IWaitlistCollege>();
  const [userEmail, setUserEmail] = useState<string>('');
  const collegeRef = collection(firebaseFirestore, 'Configuration');
  const collegeQuery = query(collegeRef);
  const { status: activeCollegesStatus, data: activeCollegesData } =
    useFirestoreCollectionData(collegeQuery);
  if (activeCollegesStatus === 'loading' || !activeCollegesData) return <ADSPageLoading />;
  const universityList = getWorldUniversitiesList().map((item) => {
    return {
      id: item.id,
      label: `${item.name} [${item.country}]`,
    } as IQuestionChoice;
  });
  const handleSelectCollege = (value: IQuestionAnswer) => {
    const college = getUniversityDetails((value as IQuestionChoice).id);
    setSelectedCollege(college);
  };
  const handleUserEmail = (value: IQuestionAnswer) => {
    setUserEmail((value as string).trim().toLowerCase());
  };
  const isEmailValid = () => {
    if (!userEmail) {
      setInputErrorMessage('Please enter a valid school email');
      return false;
    }
    // Check email format validity
    if (!EMAIL_REGEX.test(userEmail)) {
      setInputErrorMessage('Please enter a valid email address');
      return false;
    }
    // Check for common non-school emails
    const isCommonFormat = commonEmailDomains.some((domain) => {
      return userEmail.endsWith('@' + domain);
    });
    if (isCommonFormat) {
      setInputErrorMessage('Please use your school email address');
      return false;
    }
    return true;
  };
  const isCollegeValid = () => {
    if (!selectedCollege) {
      setInputErrorMessage('Please select a university');
      return false;
    }
    // Check if college already exists
    const searchIndex = activeCollegesData.findIndex(
      (activeCollege) => activeCollege.id === selectedCollege.id,
    );
    if (searchIndex !== -1) {
      setInputErrorMessage('This university already exists on Aphrodite Project!');
      return false;
    }
    return true;
  };
  const handleSubmitWaitlist = () => {
    if (!isCollegeValid() || !isEmailValid()) {
      setIsErrorDisplayed(true);
      return;
    }
    setIsErrorDisplayed(false);
    setIsLoading(true);
    const region =
      selectedCollege!.country.toUpperCase() !== ('SINGAPORE' || 'HONG KONG')
        ? 'NORTH_AMERICA'
        : selectedCollege!.country.toUpperCase().replace(' ', '_');
    const waitlistedCollegeRef = doc(firebaseFirestore, 'Waitlist', selectedCollege!.id);
    getDoc(waitlistedCollegeRef).then((docSnapshot) => {
      // If the college exists on the waitlist
      if (!docSnapshot.exists()) {
        setIsLoading(true);
        setDoc(waitlistedCollegeRef, {
          id: selectedCollege!.id,
          name: selectedCollege!.name,
          country: selectedCollege!.country,
          region: region,
          emails: [userEmail],
          shortForm: '',
        })
          .then(() => {
            setIsLoading(false);
            history.push(`/waitlist/success/${selectedCollege!.id}`);
          })
          .catch((err) => {
            console.log(err);
            alert(
              'Something went wrong while submitting, please try again. If this keeps happening, please get in touch with us!',
            );
          });
        // If the school is already on the waitlist
      } else {
        const emailList = docSnapshot.data().emails;
        // If user is already on the school waitlist email chain
        if (emailList.includes(userEmail)) {
          setIsLoading(false);
          history.push(`/waitlist/success/${selectedCollege!.id}`);
        } else {
          emailList.push(userEmail);
          updateDoc(waitlistedCollegeRef, {
            emails: emailList,
          })
            .then(() => {
              setIsLoading(false);
              history.push(`/waitlist/success/${selectedCollege!.id}`);
            })
            .catch((err) => {
              console.log(err);
              alert(
                'Something went wrong while submitting, please try again. If this keeps happening, please get in touch with us!',
              );
            });
        }
      }
    });
  };
  const renderSubmitButton = () => {
    return (
      <StyledSubmitContainer>
        <ADSButton onClick={handleSubmitWaitlist} styleType="defaultGradient" type="button">
          Submit
        </ADSButton>
        <StyledErrorText
          color="standardGrey70"
          isShown={isErrorDisplayed}
          hyphensNone={true}
          size="xs"
        >
          {inputErrorMessage}
        </StyledErrorText>
      </StyledSubmitContainer>
    );
  };
  if (isLoading) {
    return <ADSPageLoading />;
  }
  return (
    <>
      <ADSPage>
        <Navbar />
        <StyledContainer>
          <StyledTitleContainer>
            <ADSHeading hyphensNone={true} inline={true} level="2" weight="semibold">
              Bring Aphrodite to your school!
            </ADSHeading>
            <StyledADSText hyphensNone={true} size="s">
              Aphrodite will automatically launch once enough people join this waitlist
            </StyledADSText>
          </StyledTitleContainer>
          <StyledFormContainer>
            <StyledQuestionContainer>
              <Question
                hideButton={true}
                id="WaitlistSchoolName"
                onValueChange={handleSelectCollege}
                properties={{ choices: universityList }}
                requireUser={false}
                title="School Name"
                type={QuestionType.DROPDOWN}
              />
              <StyledADSSubText color="standardGrey70" hyphensNone={true} size="xs">
                <ADSAnchor target="_blank" to="mailto: cupids@aphrodite.global">
                  Don't see your school? <u>Contact us</u> to bring Aphrodite Project to your campus
                </ADSAnchor>
              </StyledADSSubText>
            </StyledQuestionContainer>
            <StyledQuestionContainer>
              <Question
                hideButton={true}
                id="WaitlistEmail"
                title="Your School Email"
                onValueChange={handleUserEmail}
                properties={{}}
                requireUser={false}
                type={QuestionType.SHORT_TEXT}
              />
              <StyledADSSubText color="standardGrey70" hyphensNone={true} size="xs">
                <ADSAnchor to="https://bit.ly/aphropolicy2022">
                  Why are we asking? Check our <u>data privacy</u> to learn more.
                </ADSAnchor>
              </StyledADSSubText>
            </StyledQuestionContainer>
            {renderSubmitButton()}
          </StyledFormContainer>
        </StyledContainer>
      </ADSPage>
    </>
  );
}
