import styled from 'styled-components';

import { ADSText } from '@aphrodite/common-ui';

import { IQuestion, IQuestionGroupNumberAnswer } from '../../constants/Questionnaire';

const StyledContainer = styled.div`
  max-height: 100%;
  overflow-y: auto;
`;

export interface Props {
  handleSetResponse: (inputAnswer: IQuestionGroupNumberAnswer) => void;
  id: string;
  className?: string;
  properties: {
    fields?: IQuestion[];
  };
  value: IQuestionGroupNumberAnswer;
}

export default function QuestionGroupCoin({
  handleSetResponse,
  id,
  properties: { fields },
  value = {},
}: Props): React.ReactElement {
  if (!fields) {
    alert(
      `An error occured when rendering Question ID: ${id}. Please email the Aphrodite team with this error so we can assist!`,
    );
    return <></>;
  }
  const totalCoins = Object.values(value)
    .map((x) => parseInt(x) || 0)
    .reduce((prev, curr) => prev + curr, 0);

  return (
    <StyledContainer onWheel={(e) => e.stopPropagation()} onTouchMove={(e) => e.stopPropagation()}>
      Coins used: {totalCoins}
      {fields.map((field) => {
        const fieldValue = value[field.id];
        return (
          <NumberField
            key={field.id}
            field={field}
            onChange={(targetValue: string) =>
              handleSetResponse({ ...value, [field.id]: targetValue })
            }
            value={fieldValue}
          />
        );
      })}
    </StyledContainer>
  );
}

// Styled Components
const StyledInput = styled.input`
  border: none;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.transparentPurple}`};
  background: none;
  color: ${(props) => props.theme.colors.darkPurple};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  height: 24px;
  hyphens: auto;
  line-height: 28px;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: normal;
  width: 60px;
  min-width: 60px;
  &:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    border-bottom: ${({ theme }) => `2px solid ${theme.colors.darkPurple}`};
  }
  @media only screen and (min-width: 680px) {
    font-size: 22px;
    height: 30px;
    line-height: 34px;
  }
  @media only screen and (min-width: 1024px) {
    width: 120px;
    min-width: 120px;
  }
`;

const StyledNumberFieldContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 48px;
`;

interface NumberFieldProps {
  field: IQuestion;
  onChange: (targetValue: string) => void;
  value?: string;
}
function NumberField({ field: { title }, onChange, value }: NumberFieldProps): React.ReactElement {
  return (
    <StyledNumberFieldContainer>
      <ADSText size="m">{title}</ADSText>
      <StyledInput
        maxLength={2}
        onKeyDown={(e) =>
          (e.key === 'e' || e.key === '.' || e.key === '-' || e.key === '+') && e.preventDefault()
        }
        onChange={(e) => {
          const regex = /^[0-9\b]+$/;
          if (!e.target.validity.valid) {
            return;
          }
          if (e.target.value === '' || regex.test(e.target.value)) {
            onChange(e.target.value);
          }
        }}
        pattern="\d*"
        type="number"
        step={1}
        value={value ?? ''}
      />
    </StyledNumberFieldContainer>
  );
}
