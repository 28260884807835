import { ADSAccordion, ADSAccordionItem, ADSHeading, ADSText } from '@aphrodite/common-ui';

import { SAFETY_TIPS } from './SafetyTips';
import { SINGAPORE_SCHOOL_RESOURCES } from './SingaporeSchoolResources';
import { VALENTINES_SCHOOL_RESOURCES } from './ValentinesSchoolResources';
import styled from 'styled-components';
import { useIsSingaporeWebsite } from '@aphrodite/common/hooks';

const StyledCardDiv = styled.div`
  left: 0px;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  padding-left: 8%;
  padding-right: 8%;
  width: 100vw;
  height: fit-content;
  background: #ffe5db;
  margin-bottom: 80px;
  @media only screen and (min-width: 680px) {
    padding-bottom: 100px;
  }
  @media only screen and (min-width: 1440px) {
    padding-left: 16%;
    padding-right: 16%;
  }
`;

const StyledStepsDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 680px) {
    padding-bottom: 100px;
  }
`;


export default function SafetyPageList(): React.ReactElement {
  const isSingaporeWebsite = useIsSingaporeWebsite();
  const schoolResources = isSingaporeWebsite ? SINGAPORE_SCHOOL_RESOURCES : VALENTINES_SCHOOL_RESOURCES;
  return (
    <StyledCardDiv>
      <StyledStepsDiv>
        <ADSHeading
          weight="bold"
          textAlign="left"
          level="1"
          wrapperStyle={{ marginBottom: '24px', color: '#000000' }}
        >
          Safety Resources
        </ADSHeading>
        <ADSText wrapperStyle={{ marginBottom: 30 }}>
          We take safety when dating very seriously; especially when meeting someone online.
        </ADSText>
        <ADSText wrapperStyle={{ marginBottom: 30 }}>
          Below, we list some safety tips, and link safety resources for each school.
        </ADSText>
        <ADSText wrapperStyle={{ marginBottom: 30 }}>
          Whenever you feel uncomfortable in a dating situation, reach out to someone you trust and
          ask for help.
        </ADSText>
        <ADSAccordion title="School Resources" style={{ marginBottom: 30 }}>
          {schoolResources.map((item, idx) => {
            return (
              <ADSAccordionItem index={idx} title={item.title} content={item.content} key={idx} />
            );
          })}
        </ADSAccordion>
        <ADSAccordion title="Safety Tips">
          {SAFETY_TIPS.map((item, idx) => {
            return (
              <ADSAccordionItem
                index={idx}
                title={`0${idx + 1} - ${item.title}`}
                content={item.content}
                key={idx}
              />
            );
          })}
        </ADSAccordion>
      </StyledStepsDiv>
    </StyledCardDiv>
  );
}
