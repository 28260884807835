import styled from 'styled-components';

import { ADSHeading } from '@aphrodite/common-ui';
import { ADSText } from '@aphrodite/common-ui';

import Icon2 from '../../assets/images/landing_question_icon2.svg';
import Icon3 from '../../assets/images/landing_question_icon3.svg';
import Icon4 from '../../assets/images/landing_question_icon4.svg';
import Icon5 from '../../assets/images/landing_question_icon5.svg';
import Icon6 from '../../assets/images/landing_question_icon6.svg';
import Icon7 from '../../assets/images/landing_question_icon7.svg';

const StyledCardDiv = styled.div`
  left: 0px;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  padding-left: 8%;
  padding-right: 8%;
  height: 90vh;
  width: 100vw;
  min-height: 500px;
  @media only screen and (min-width: 680px) {
    padding-top: 40px;
    padding-bottom: 40px;
    height: 40vh;
    min-height: 800px;
  }
  @media only screen and (min-width: 1440px) {
    padding-left: 16%;
    padding-right: 16%;
  }
`;
const StyledFlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  @media only screen and (min-width: 680px) {
    flex-direction: row;
    height: 40vh;
    min-height: 500px;
  }
`;
const StyledSection = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (min-width: 680px) {
    margin-right: 100px;
  }
`;
const StyledList = styled(StyledSection)`
  @media only screen and (min-width: 680px) {
    margin-right: 0px;
  }
`;
const StyledHeading = styled(ADSHeading)`
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  letter-spacing: 0.1px;
  color: #151515;
`;
const StyledTextChunck = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
const StyledIcon = styled.img`
  width: 24px;
  height: 24px;
`;
const StyledCaption = styled(StyledTextChunck)`
  margin-top: 6px;
  margin-bottom: 6px;
  gap: 18px;
  @media only screen and (min-width: 680px) {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;

const captionDetails = [
  {
    icon: Icon2,
    caption: 'Deal breakers',
  },
  {
    icon: Icon3,
    caption: 'Lifestyled preferences',
  },
  {
    icon: Icon4,
    caption: 'Values and beliefs',
  },
  {
    icon: Icon5,
    caption: 'Personality and traits',
  },
  {
    icon: Icon6,
    caption: 'Relational and attachment styles',
  },
  {
    icon: Icon7,
    caption: 'Future Plans and Goals',
  },
];

export default function LandingQuestionnaire(): React.ReactElement {
  return (
    <StyledCardDiv>
      <StyledFlexDiv>
        <StyledSection>
          <StyledHeading>
            We designed <br />a questionnaire <br /> based on psychology research.
          </StyledHeading>
        </StyledSection>
        <StyledList>
          {captionDetails.map((item, i) => {
            return (
              <StyledCaption key={i}>
                <StyledIcon src={item.icon} />
                <ADSText color="black80" size="m">
                  {item.caption}
                </ADSText>
              </StyledCaption>
            );
          })}
        </StyledList>
      </StyledFlexDiv>
    </StyledCardDiv>
  );
}
