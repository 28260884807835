import dayjs from 'dayjs';
import { useFirestoreCollectionData, useFirestoreDocData } from 'reactfire';

import {
  QUESTIONNAIRE_CLOSE_DATE_GLOBAL,
  QUESTIONNAIRE_CLOSE_DATE_SINGAPORE,
  QUESTIONNAIRE_DISABLED_DATE_GLOBAL,
  QUESTIONNAIRE_DISABLED_DATE_SINGAPORE,
} from '@aphrodite/common/constants/Dates';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';
import { CollectionReference, DocumentReference, collection, doc } from '@firebase/firestore';

import { ICollegeConfiguration, IUserFlags } from '../types/firestore-types';

const HOSTNAME_SINGAPORE = 'sg.aphrodite.global';
const HOSTNAME_GLOBAL = 'aphrodite.global';

export function useIsSingaporeWebsite() {
  const userFlagRef = doc(
    firebaseFirestore,
    `UserFlags/matchresult`,
  ) as DocumentReference<IUserFlags>;
  const { status: userFlagsStatus, data: userFlagsData } =
    useFirestoreDocData<IUserFlags>(userFlagRef);
  const curPath = window.location.hostname;
  if (
    curPath === HOSTNAME_SINGAPORE ||
    curPath === HOSTNAME_GLOBAL ||
    userFlagsStatus === 'loading'
  ) {
    return curPath === HOSTNAME_SINGAPORE;
  }
  const { isDevServerSG } = userFlagsData;

  // For dev paths
  return isDevServerSG;
}

export function useIsMatchesReleased() {
  const isSingaporeWebsite = useIsSingaporeWebsite();
  const userFlagRef = doc(
    firebaseFirestore,
    `UserFlags/matchresult`,
  ) as DocumentReference<IUserFlags>;
  const { status: userFlagsStatus, data: userFlagsData } =
    useFirestoreDocData<IUserFlags>(userFlagRef);
  if (userFlagsStatus === 'loading') {
    return { status: 'loading', flag: undefined };
  }
  const { matchesReleasedValentines, matchesReleasedSingapore } = userFlagsData;
  const matchesReleased =
    (isSingaporeWebsite && matchesReleasedSingapore) ||
    (!isSingaporeWebsite && matchesReleasedValentines);
  return { status: userFlagsStatus, flag: matchesReleased };
}

export function useIsLaunched() {
  const isSingaporeWebsite = useIsSingaporeWebsite();
  const userFlagRef = doc(
    firebaseFirestore,
    `UserFlags/matchresult`,
  ) as DocumentReference<IUserFlags>;
  const { status: userFlagsStatus, data: userFlagsData } =
    useFirestoreDocData<IUserFlags>(userFlagRef);
  if (userFlagsStatus === 'loading') {
    return 'loading';
  }
  // If dev environment, return true.
  const hostname = window.location.hostname;
  if (hostname !== HOSTNAME_GLOBAL && hostname !== HOSTNAME_SINGAPORE) {
    return true;
  }
  const { launchedSingapore, launchedValentines } = userFlagsData;
  return (isSingaporeWebsite && launchedSingapore) || (!isSingaporeWebsite && launchedValentines);
}

export function useIsSingaporeLaunched() {
  const userFlagRef = doc(
    firebaseFirestore,
    `UserFlags/matchresult`,
  ) as DocumentReference<IUserFlags>;
  const { status: userFlagsStatus, data: userFlagsData } =
    useFirestoreDocData<IUserFlags>(userFlagRef);
  if (userFlagsStatus === 'loading') {
    return 'loading';
  }
  const { launchedSingapore } = userFlagsData;
  return launchedSingapore;
}

export function useIsValentinesLaunched() {
  const userFlagRef = doc(
    firebaseFirestore,
    `UserFlags/matchresult`,
  ) as DocumentReference<IUserFlags>;
  const { status: userFlagsStatus, data: userFlagsData } =
    useFirestoreDocData<IUserFlags>(userFlagRef);
  if (userFlagsStatus === 'loading') {
    return 'loading';
  }
  const { launchedValentines } = userFlagsData;
  return launchedValentines;
}

export function useIsCollegeEnabled(college: string) {
  const formattedCollegeName = college.charAt(0).toUpperCase() + college.toLowerCase().slice(1);
  const collegeConfigRef = doc(
    firebaseFirestore,
    `Configuration/College${formattedCollegeName}`,
  ) as DocumentReference<ICollegeConfiguration>;
  const { status: collegeConfigStatus, data: collegeConfigData } =
    useFirestoreDocData<ICollegeConfiguration>(collegeConfigRef);
  if (collegeConfigStatus === 'loading') {
    return false;
  }
  const { isEnabled } = collegeConfigData;
  return isEnabled;
}

export function useCollegeConfigs() {
  const collegeConfigRef = collection(
    firebaseFirestore,
    `Configuration`,
  ) as CollectionReference<ICollegeConfiguration>;
  const { status: collegeConfigStatus, data: collegeConfigData } =
    useFirestoreCollectionData<ICollegeConfiguration>(collegeConfigRef);
  if (collegeConfigStatus === 'loading') {
    return 'loading';
  }
  return collegeConfigData;
}

export function useIsValidEmailForCollege(college: string, email: string) {
  const formattedCollegeName = college.charAt(0).toUpperCase() + college.toLowerCase().slice(1);
  const collegeConfigRef = doc(
    firebaseFirestore,
    `Configuration/College${formattedCollegeName}`,
  ) as DocumentReference<ICollegeConfiguration>;
  const { status: collegeConfigStatus, data: collegeConfigData } =
    useFirestoreDocData<ICollegeConfiguration>(collegeConfigRef);
  if (collegeConfigStatus === 'loading') {
    return 'loading';
  }
  const { regexes } = collegeConfigData;
  return regexes.some((pattern) => {
    const regex = new RegExp(pattern);
    return regex.test(email);
  });
}

export function useGetDomainsForCollege(college: string) {
  const formattedCollegeName = college.charAt(0).toUpperCase() + college.toLowerCase().slice(1);
  const collegeConfigRef = doc(
    firebaseFirestore,
    `Configuration/College${formattedCollegeName}`,
  ) as DocumentReference<ICollegeConfiguration>;
  const { status: collegeConfigStatus, data: collegeConfigData } =
    useFirestoreDocData<ICollegeConfiguration>(collegeConfigRef);
  if (collegeConfigStatus === 'loading') {
    return [];
  }
  const { domains } = collegeConfigData;
  return domains;
}

export function useIsQuestionnaireClosed() {
  const currentTime = dayjs();
  const isSingaporeWebsite = useIsSingaporeWebsite();
  return isSingaporeWebsite
    ? dayjs(currentTime).isAfter(QUESTIONNAIRE_CLOSE_DATE_SINGAPORE)
    : dayjs(currentTime).isAfter(QUESTIONNAIRE_CLOSE_DATE_GLOBAL);
}

export function useIsQuestionnaireDisabled() {
  const currentTime = dayjs();
  const isSingaporeWebsite = useIsSingaporeWebsite();
  return isSingaporeWebsite
    ? dayjs(currentTime).isAfter(QUESTIONNAIRE_DISABLED_DATE_SINGAPORE)
    : dayjs(currentTime).isAfter(QUESTIONNAIRE_DISABLED_DATE_GLOBAL);
}
