import { FunctionComponent } from 'react';
import styled, { keyframes } from 'styled-components';

import { ADSHeading, ADSPage, ADSText } from '@aphrodite/common-ui';

import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

// Styled Components
const FadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 1000px;
  max-width: 100%;
  padding-left: 8%;
  padding-right: 8%;
  margin: auto;
  margin-top: 6%;
  animation: ${FadeIn} ease 1.5s;
  -webkit-animation: ${FadeIn} ease 1.5s;
  -moz-animation: ${FadeIn} ease 1.5s;
  -o-animation: ${FadeIn} ease 1.5s;
  -ms-animation: ${FadeIn} ease 1.5s;
  @media only screen and (min-width: 680px) {
    .container {
      padding-left: 10%;
      padding-right: 10%;
    }
  }
`;

const StyledText = styled(ADSText)`
  margin-top: 4%;
  margin-bottom: 12%;
`;

// Main Component
const PageInclusionStatement: FunctionComponent<{}> = () => {
  return (
    <>
      <Navbar />
      <ADSPage>
        <StyledContainer>
          <ADSHeading level="3">On Inclusion and Hard Decisions</ADSHeading>
          <StyledText color="darkGrey" hyphensNone={true} size="s">
            Inclusion and care for people from any walk of life matters to us. We have given extreme
            care to the choices and distinctions participants are allowed to make, as well as the
            specific language used to label and describe those sensitive dealbreaker choices.
            <br />
            <br />
            <b>Why include those distinctions and choices?</b>
            <br />
            Recent events have shone a harsher light on the deep complexities underlying our
            identities – be they religious, ethnic, or sexual. Since the inception of Aphrodite, we
            contemplated disallowing these options for the romantic pool just like the platonic pool
            (where we have never allowed filters).
            <br />
            <br />
            Yet, if we neglected to acknowledge those distinctions in a project where participants
            only receive one match, what if a friend opens up to their match only to be outright
            rejected? Simply (or partly) for some of those distinctions above? Most of us or our
            friends will know someone in those very shoes who have walked through deep pain, loss,
            or hurt. We do not wish for anyone to feel the pain of outright rejection due to any
            aspect of their own identity – not ever.
            <br />
            <br />
            For some people or even their families (which would be beyond their control), these are
            distinctions that do matter for a potential romantic partner – whether consciously or
            subconsciously. When it comes to considering a potential romantic relationship, there
            might also be many different reasons why participants pick these options. There are
            those that for some reasons, might never date a certain group. Some might only date
            within their own group. Others might wish to explore dating outside of their own group.
            Some choices might come across as curious, open, and exploratory. Some choices might be
            harshly criticized when spoken aloud and are hidden behind that subtle left swipe,
            rejection, or ghost. Us disabling these options does not magically wave away those
            underlying considerations people might have for their romantic and/or life partner.
            <br />
            <br />
            What we have settled on is to provide a{' '}
            <b>private environment for these distinctions to arise safely and privately.</b> So that
            when each participant gets their one match, everyone can come as they are and feel seen,
            heard, and accepted.
            <br />
            <br />
            Furthermore, when the aggregated data report is released for participants,{' '}
            <b>
              aggregated data from the religious, sexual, ethnic, and cultural exclusionary
              questions will not be released.
            </b>{' '}
            This is our current stance as we feel that while the discourse from that information
            might be generative, we fear that it may be used to perpetuate or justify hateful,
            prejudicial, or discriminatory behaviours and mindsets.
            <br />
            <br />
            There’s always room for learning and improvement. Should you feel that certain areas are
            lacking or can be better, we hope that you can reach out to any of us for an open
            conversation. We will take responsibility and improve where possible. Help us better
            create opportunities for anyone from any walk of life to feel loved and accepted for who
            they are.
            <br />
            <br />
            Best,
            <br />
            The Aphrodite Project Team
          </StyledText>
        </StyledContainer>
      </ADSPage>
      <Footer />
    </>
  );
};

export default PageInclusionStatement;
