import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ADSButton, ADSModal, ADSText } from '@aphrodite/common-ui';
import { useIsQuestionnaireDisabled } from '@aphrodite/common/hooks';

const StyledCenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 20px;
`;
const StyledHorizontalSegment = styled.div`
  display: flex;
  flex-direction: horizontal;
  margin-top: 12px;
  justify-content: space-between;
`;

interface Props {
  showWithdrawModal: () => void;
}

export default function ModalWithdraw({ showWithdrawModal }: Props): React.ReactElement {
  const history = useHistory();
  const isQuestionnaireDisabled = useIsQuestionnaireDisabled();
  if (isQuestionnaireDisabled) {
    return (
      <ADSModal title={'The algorithm is already running.'} cancelToggle={showWithdrawModal}>
        <br />
        <ADSText size="s" color="standardGrey70">
          The algorithm is running. We won't be able to withdraw you from the pool now!
        </ADSText>
        <StyledCenterContainer>
          <ADSButton onClick={showWithdrawModal}>Return</ADSButton>
        </StyledCenterContainer>
      </ADSModal>
    );
  }
  return (
    <ADSModal title={'Withdraw and delete account.'} cancelToggle={showWithdrawModal}>
      <br />
      <ADSText size="s" color="standardGrey70">
        By clicking <b>Withdraw</b>, you will be removed from the matching pool and your account
        will be deleted.
        <br />
        <br />
        You will have to sign up again to be in the matching pool.
      </ADSText>
      <StyledHorizontalSegment>
        <ADSButton onClick={showWithdrawModal}>Back</ADSButton>
        <ADSButton onClick={() => history.push('/withdraw')}>Withdraw</ADSButton>
      </StyledHorizontalSegment>
    </ADSModal>
  );
}
