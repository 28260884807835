import styled from 'styled-components';

import { ADSText } from '@aphrodite/common-ui';

// Styled Components
const StyledFieldItem = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
`;
const StyledContactContainer = styled.div`
  margin-left: 8px;
  width: 100%;
`;
const StyledInputField = styled.input<{ disabled: boolean }>`
  background-color: ${(props) => (!props.disabled ? props.theme.colors.paleGrey : 'transparent')};
  border: none;
  border-radius: 16px;
  box-sizing: border-box;
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.standardGrey70 : theme.colors.blackDefault};
  font-size: 14px;
  padding: 4px 8px;
  overflow-wrap: normal;
  width: 100%;
`;
interface Props {
  disabled?: boolean;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function ProfileContactField({
  disabled = false,
  Icon,
  value,
  onChange,
}: Props): React.ReactElement {
  return (
    <StyledFieldItem>
      {Icon && <Icon height={20} width={20} />}
      <StyledContactContainer>
        <ADSText
          color={disabled ? 'standardGrey40' : 'blackDefault'}
          wrapperStyle={{ marginBottom: '4px' }}
        >
          <StyledInputField type="text" value={value} disabled={disabled} onChange={onChange} />
        </ADSText>
      </StyledContactContainer>
    </StyledFieldItem>
  );
}
