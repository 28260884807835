import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { ADSCard, ADSHeading, ADSHighlighter, ADSText } from '@aphrodite/common-ui';
import {
  LAUNCH_DATE_GLOBAL,
  LAUNCH_DATE_SINGAPORE,
  MATCH_RESULTS_DATE_GLOBAL,
  MATCH_RESULTS_DATE_SINGAPORE,
  QUESTIONNAIRE_CLOSE_DATE_GLOBAL,
  QUESTIONNAIRE_CLOSE_DATE_SINGAPORE,
} from '@aphrodite/common/constants/Dates';
import { useIsLaunched, useIsSingaporeWebsite } from '@aphrodite/common/hooks';

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
  className?: string;
}

// Styled-component
const StyledADSCard = styled(ADSCard)`
  text-align: center;
  padding: 8px;
  height: fit-content;
  margin-top: 20px;
  margin-bottom: 20px;
  @media only screen and (min-width: 1024px) {
    margin-left: 10%;
  }
`;
const StyledText = styled(ADSText)`
  text-align: center;
`;
const StyledItalic = styled.i`
  font-weight: 500;
`;
const StyledTimeCard = styled(ADSCard)`
  margin: 2%;
  padding: 4%;
  background: #f7ecee;
  border: 2px solid #000000;
`;
const StyledHeading = styled(ADSHeading)`
  text-align: center;
  @media only screen and (max-width: 1200px) {
    font-size: 28px;
    line-height: 38px;
  }
`;
const StyledCaption = styled(ADSText)`
  padding-top: 2px;
  text-align: center;
  font-size: 12px;
  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    font-size: 12px;
  }
`;
const StyledHighlighter = styled(ADSHighlighter)`
  align-content: center;
`;
const StyledLandingCard = styled(StyledADSCard)`
  width: 80vw;
  padding: 16px 24px;
  border-radius: 16px;
  box-shadow: none;
  @media only screen and (min-width: 680px) {
    width: 480px;
    align-self: center;
    margin-left: 0%;
  }
`;
const StyledGradientText = styled(ADSText)`
  margin-bottom: 16px;
  background: linear-gradient(59deg, #eb7518 0%, #ce468b 53.13%, #2ac8fd 98.96%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 1;
`;
const StyledGradientTime = styled(StyledGradientText)`
  font-size: 32px;
  line-height: 100%;
  font-weight: 700;
  @media only screen and (min-width: 680px) {
    font-size: 48px;
  }
`;

export default function DashboardCardCountdown({ className }: Props): React.ReactElement {
  const [timerDays, setTimerDays] = useState('00');
  const [timerHours, setTimerHours] = useState('00');
  const [timerMins, setTimerMins] = useState('00');
  const [timerSec, setTimerSec] = useState('00');
  let interval: any = useRef<HTMLDivElement>(null);
  const isSingaporeWebsite = useIsSingaporeWebsite();
  const launchDate = isSingaporeWebsite ? LAUNCH_DATE_SINGAPORE : LAUNCH_DATE_GLOBAL;
  const isLaunched = useIsLaunched();
  const questionnaireCloseDate = isSingaporeWebsite
    ? QUESTIONNAIRE_CLOSE_DATE_SINGAPORE
    : QUESTIONNAIRE_CLOSE_DATE_GLOBAL;
  useEffect(() => {
    const countDownTimer = setInterval(() => {
      const currentTime = dayjs();
      const day = isLaunched
        ? questionnaireCloseDate.diff(currentTime, 'day')
        : launchDate.diff(currentTime, 'day');
      const hours = isLaunched
        ? questionnaireCloseDate.diff(currentTime, 'hour') % 24
        : launchDate.diff(currentTime, 'hour') % 24;
      const mins = isLaunched
        ? questionnaireCloseDate.diff(currentTime, 'minute') % 60
        : launchDate.diff(currentTime, 'minute') % 60;
      const seconds = isLaunched
        ? questionnaireCloseDate.diff(currentTime, 'second') % 60
        : launchDate.diff(currentTime, 'second') % 60;
      if (isLaunched && dayjs(currentTime).isAfter(questionnaireCloseDate)) {
        clearInterval(interval.current);
      } else {
        setTimerDays(day < 10 ? '0' + day : day.toString());
        setTimerHours(hours < 10 ? '0' + hours : hours.toString());
        setTimerMins(mins < 10 ? '0' + mins : mins.toString());
        setTimerSec(seconds < 10 ? '0' + seconds : seconds.toString());
      }
    }, 1000);
    return () => clearInterval(countDownTimer);
  });
  if (className === 'Landing') {
    return (
      <StyledLandingCard backgroundColor="primary">
        <StyledGradientText size="xs" color="standardGrey80">
          days &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; hours
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; mins
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; sec
        </StyledGradientText>
        <StyledGradientTime className="timer">
          {timerDays} : {timerHours} : {timerMins} : {timerSec}
        </StyledGradientTime>
        <StyledText size="s" wrapperStyle={{ marginTop: '12px' }}>
          {isLaunched
            ? `till sign-ups close on ${questionnaireCloseDate.format('Do  MMMM')}`
            : `till sign-ups open on ${launchDate.format('Do  MMMM')}`}
        </StyledText>
      </StyledLandingCard>
    );
  }
  if (dayjs().isAfter(questionnaireCloseDate)) {
    return (
      <StyledADSCard backgroundColor="primary">
        <StyledText size="m">
          Look out for your match result on <br />
        </StyledText>
        <StyledHighlighter>
          <StyledHeading level="2" weight="regular" inline={true}>
            <StyledItalic>
              {isSingaporeWebsite ? MATCH_RESULTS_DATE_SINGAPORE : MATCH_RESULTS_DATE_GLOBAL}
            </StyledItalic>
          </StyledHeading>
        </StyledHighlighter>
      </StyledADSCard>
    );
  }
  return (
    <StyledADSCard backgroundColor="primary">
      <StyledText size="s">
        Sign-ups close on
        <StyledItalic>&nbsp; {questionnaireCloseDate.format('Do  MMMM')}!</StyledItalic>
      </StyledText>
      <StyledTimeCard>
        <StyledHeading level="2" weight="regular" inline={true} textAlign="center">
          {timerDays} : {timerHours} : {timerMins} : {timerSec}
        </StyledHeading>
      </StyledTimeCard>
      <StyledCaption size="xs" color="standardGrey80">
        days &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; hours
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; mins
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; sec
      </StyledCaption>
    </StyledADSCard>
  );
}
