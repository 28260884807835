import styled from 'styled-components';

import DashboardReport from '../reports/DashboardReport';

export const StyledContainer = styled.main`
  margin-top: 40px;
  padding: 0 4%;
  @media only screen and (min-width: 1024px) {
    margin-left: ${({ theme }) => theme.spacing.navbarDesktopWidth};
    padding: 0 8%;
  }
`;

export default function DashboardTabReport(): React.ReactElement {
  return (
    <StyledContainer>
      <DashboardReport />
    </StyledContainer>
  );
}
