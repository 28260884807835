import classNames from 'classnames';
import React, { FunctionComponent, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  Text as RechartsText,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';

import { ADSText } from '@aphrodite/common-ui';
import { GraphProps } from '@aphrodite/common/types/DataReportTypes';
import { getAnalytics, logEvent } from '@firebase/analytics';

import { genericScale12 } from '../constants/Graph';

// Styled Components
const StyledContainer = styled.div`
  margin: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

const StyledTitle = styled(ADSText)`
  margin-left: 28px;
`;

const StyledForm = styled.form`
  margin-bottom: 20px;
`;

const StyledRadio = styled.input`
  margin-left: 8px;
  margin-bottom: 20px;
`;

const StyledRechartsText = styled(RechartsText)`
  font-size: 8px;
  @media only screen and (min-width: 680px) {
    font-size: 12px;
  }
`;

const StyledText = styled.text`
  font-size: 8px;
  @media only screen and (min-width: 680px) {
    font-size: 12px;
  }
`;

const StyledYAxisLabel = styled(Label)`
  font-size: 12px;
  @media only screen and (min-width: 680px) {
    font-size: 16px;
  }
`;

const StyledLabel = styled.label`
  margin: 0 8px;
`;

// Main component
interface TickProps {
  x?: number;
  y?: number;
  payload?: any;
}
const CustomisedAxisTick: FunctionComponent<TickProps> = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <StyledRechartsText x={30} y={40} dy={0} width={280} textAnchor="end" transform="rotate(-30)">
        {payload.value}
      </StyledRechartsText>
    </g>
  );
};

interface Props extends GraphProps {
  sort: boolean;
}

/**
 * Multiple Bar Chart Component.
 */
const MultipleSortBarChart: FunctionComponent<Props> = ({
  className,
  dataKeys,
  items,
  sort,
  title,
  yLabel,
}) => {
  const analytics = getAnalytics();
  const [selectedCategory, setselectedCategory] = useState(dataKeys ? dataKeys[0] : '');
  const [data, setData] = useState(
    sort ? items.sort((a, b) => b[selectedCategory] - a[selectedCategory]) : items,
  );

  const sortByValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedCategory = event.target.value;
    setselectedCategory(selectedCategory);
    setData([...data.sort((a, b) => b[selectedCategory] - a[selectedCategory])]);
  };

  const generateSortForm = () => {
    if (!sort || !dataKeys) {
      return null;
    }
    return (
      <StyledForm>
        <StyledText>View top rankings by:</StyledText>
        {dataKeys?.map((dataKey) => (
          <React.Fragment key={dataKey}>
            <StyledRadio
              id={dataKey.toString()}
              type="radio"
              name="sortByValue"
              value={dataKey}
              checked={selectedCategory === dataKey}
              onChange={sortByValue}
              onClick={() =>
                logEvent(analytics, 'DATA', {
                  component: 'DATA_REPORT',
                  button_name: 'sort_bar_chart',
                })
              }
            />
            <StyledLabel htmlFor={dataKey.toString()}>{`${dataKey}`}</StyledLabel>
          </React.Fragment>
        ))}
      </StyledForm>
    );
  };

  const graphClassName = classNames(/*styles.container,*/ className);

  const percentFormatter = (value: any) => value + '%';

  return (
    <StyledContainer className={graphClassName}>
      <StyledTitle size="m" weight="semibold">
        {title}
      </StyledTitle>
      <br />
      {generateSortForm()}
      <ResponsiveContainer width="100%" height={550}>
        <BarChart
          data={data}
          height={450}
          width={750}
          margin={{
            right: 12,
            left: 12,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" interval={0} height={200} tick={<CustomisedAxisTick />} />
          <YAxis>
            <StyledYAxisLabel value={yLabel} offset={0} angle={-90} position="left" dy={-70} />
          </YAxis>
          <Tooltip formatter={percentFormatter} />
          <Legend
            iconSize={16}
            iconType="rect"
            align="right"
            verticalAlign="top"
            wrapperStyle={{ paddingTop: '8px', paddingBottom: '8px' }}
          />
          {dataKeys
            ? dataKeys.map((key, index) => {
                return <Bar dataKey={key} key={key} fill={genericScale12[index]} />;
              })
            : undefined}
        </BarChart>
      </ResponsiveContainer>
    </StyledContainer>
  );
};

export default MultipleSortBarChart;
