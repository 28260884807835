import TemplateEmailPage from './templates/TemplateEmailPage';

export default function PageLoginEmail(): React.ReactElement {
  return (
    <TemplateEmailPage
      title={({ projectName }) => `Welcome back to the ${projectName} Aphrodite Project!`}
      description={() =>
        `Nice to see you again! Enter the school email associated with your Aphrodite account.`
      }
    />
  );
}
