import { collection, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useFirestoreCollectionData } from 'reactfire';
import styled from 'styled-components';

import { ADSAnchor, ADSButton, ADSHeading } from '@aphrodite/common-ui';
import { ADSText } from '@aphrodite/common-ui';
import { useIsSingaporeWebsite } from '@aphrodite/common/hooks';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';

import LogoCard from '../LogoCard';
import DashboardLoading from '../dashboard/DashboardLoading';

const StyledCardDiv = styled.div`
  left: 0px;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  padding-left: 8%;
  padding-right: 8%;
  height: 70vh;
  width: 100vw;
  @media only screen and (min-width: 680px) {
    padding-top: 40px;
    padding-bottom: 40px;
    height: 50vh;
    min-height: 600px;
  }
  @media only screen and (min-width: 1440px) {
    padding-left: 16%;
    padding-right: 16%;
  }
`;
const StyledUniversitySection = styled(StyledCardDiv)`
  align-items: center;
  justify-content: center;
  text-align: center;
  height: fit-content;
`;
const StyledWrapper = styled.div`
  width: 100%;
`;
const StyledTextChuck = styled.div`
  margin-top: 32px;
  margin-bottom: 24px;
  min-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .redirect {
    margin-top: 12px;
    margin-bottom: 24px;
  }
  .icon {
    height: 48px;
    width: 48px;
  }
`;
const StyledFlexDiv = styled.div<{ isSingapore: boolean }>`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
  margin: 36px 0;
  width: match-parent;
  @media only screen and (min-width: 680px) {
    justify-content: left;
    grid-template-columns: ${(props) => (props.isSingapore ? '1fr 1fr 1fr' : '1fr 1fr 1fr 1fr')};
  }
`;

type CollegeLogos = {
  name: string;
  logo?: string;
};
export default function LandingUniCards(): React.ReactElement {
  const isSingaporeWebsite = useIsSingaporeWebsite();
  const [singaporeLogos, setSingaporeLogos] = useState<CollegeLogos[]>([]);
  const [globalLogos, setGlobalLogos] = useState<CollegeLogos[]>([]);
  const collegeRef = collection(firebaseFirestore, 'Configuration');
  const collegeQuery = query(collegeRef);
  const { status: collegesStatus, data: collegesData } = useFirestoreCollectionData(collegeQuery);
  useEffect(() => {
    if (!collegesData) return;
    // Get a list of college names from firestore
    const singaporeColleges = collegesData
      .filter((item) => {
        return item.region === 'SINGAPORE';
      })
      .map((item) => {
        if ('landingLogo' in item) {
          return { name: item.indexName, logo: item.landingLogo };
        } else {
          return { name: item.indexName };
        }
      });
    setSingaporeLogos(singaporeColleges);
    const globalColleges = collegesData
      .filter((item) => {
        return item.region !== 'SINGAPORE';
      })
      .map((item) => {
        if ('landingLogo' in item) {
          return { name: item.indexName, logo: item.landingLogo };
        } else {
          return { name: item.indexName };
        }
      });
    setGlobalLogos(globalColleges);
  }, [collegesData]);
  if (collegesStatus === 'loading' || !collegesData) {
    return <DashboardLoading />;
  }

  const renderMainUnisTitle = () => {
    return (
      <ADSHeading level="3" weight="semibold" color="standardGrey90">
        Matching at 3 colleges in Singapore and 10+ worldwide
      </ADSHeading>
    );
  };
  const renderSGUnis = singaporeLogos.map(
    (item) =>
      item?.logo && <LogoCard key={item.name} imgSrc={item.logo} alt={`${item.name} logo`} />,
  );
  const renderGlobalUnis = globalLogos.map(
    (item) =>
      item?.logo && <LogoCard key={item.name} imgSrc={item.logo} alt={`${item.name} logo`} />,
  );
  const renderMainUnis = () => (
    <StyledFlexDiv isSingapore={isSingaporeWebsite}>
      {isSingaporeWebsite ? renderSGUnis : renderGlobalUnis}
    </StyledFlexDiv>
  );
  return (
    <StyledUniversitySection>
      <StyledWrapper>
        {renderMainUnisTitle()}
        {renderMainUnis()}
        <StyledTextChuck>
          <ADSText
            size="s"
            color="standardGrey80"
            textAlign="center"
            wrapperStyle={{ marginBottom: '8px' }}
          >
            Don't see your school?
          </ADSText>
          <ADSAnchor to="/waitlist" className="redirect">
            <ADSButton styleType="pinkGradient">Bring Aphrodite to my school</ADSButton>
          </ADSAnchor>
        </StyledTextChuck>
      </StyledWrapper>
    </StyledUniversitySection>
  );
}
