import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useFirestoreDocData } from 'reactfire';
import styled from 'styled-components';

import { ADSHeading } from '@aphrodite/common-ui';
import { ADSText } from '@aphrodite/common-ui';
import { useIsMobile } from '@aphrodite/common/hooks';
import { useAuthHelper } from '@aphrodite/common/hooks';
import { useIsMatchesReleased } from '@aphrodite/common/hooks';
import { IUserProfile } from '@aphrodite/common/types/firestore-types';
import { firebaseStorage } from '@aphrodite/firebase/firebase';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';
import { DocumentReference, doc } from '@firebase/firestore';
import { getDownloadURL, ref } from '@firebase/storage';

import COLLEGE_CONSTANTS from '../../constants/College';
import { IQuestionnaire, QuestionnaireStatusType } from '../../constants/Questionnaire';
import { getQuestionnaireStatus } from '../../helpers/questionnaire';
import { getUniversityRef } from '../../helpers/university';
import DashboardCardCountdown from './DashboardCardCountdown';
import DashboardCardDataReport from './DashboardCardDataReport';
import DashboardCardStatsGraph from './DashboardCardGraph';
import DashboardCardPlayCupid from './DashboardCardPlayCupid';
import DashboardCardStats from './DashboardCardStats';
import DashboardCardStatus from './DashboardCardStatus';
import DashboardLoading from './DashboardLoading';

const StyledContentsContainer = styled.main`
  display: flex;
  max-width: 100%;
  padding: 0 8%;
  padding-top: 20%;
  padding-bottom: 10%;
  flex-direction: column;
  justify-content: center;
  @media only screen and (min-width: 1024px) {
    display: grid;
    row-gap: 0;
    margin-top: ${({ theme }) => `${parseFloat(theme.spacing.navbarDesktopLogoHeight) * 0.25}px`};
    margin-left: ${({ theme }) => theme.spacing.navbarDesktopWidth};
    padding: 0 4%;
    grid-template-columns: 3fr 2fr;
  }
`;
const StyledTitleContainer = styled.div`
  margin-bottom: 12px;
  @media only screen and (min-width: 1024px) {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    margin-top: 4%;
  }
`;
const StyledDashboardCardStatus = styled(DashboardCardStatus)`
  @media only screen and (min-width: 1024px) {
    min-width: 150px;
    grid-column: 1;
    grid-row: 2 / 4;
  }
`;
const StyledDashboardCardCountdown = styled(DashboardCardCountdown)`
  margin-top: 4%;
  @media only screen and (min-width: 1024px) {
    margin-top: 0;
    grid-column: 2;
    grid-row: 2 / 3;
    margin-left: 10%;
  }
`;
const StyledDashboardCardStats = styled(DashboardCardStats)`
  margin-top: 4%;
  @media only screen and (min-width: 1024px) {
    margin-top: 0;
    grid-column: 2;
    grid-row: 3 / 4;
    margin-left: 10%;
  }
`;
const StyledDashboardCardStatsGraph = styled(DashboardCardStatsGraph)`
  margin-top: 4%;
  @media only screen and (min-width: 1024px) {
    grid-column: 1 / 3;
    grid-row: 4 / 5;
    margin-bottom: 4%;
  }
`;
const StyledDashboardCardPlayCupid = styled(DashboardCardPlayCupid)`
  margin-top: 4%;
`;
const StyledDashboardCardDataReport = styled(DashboardCardDataReport)`
  margin-top: 4%;
`;

export default function DashboardRouteMain(): React.ReactElement {
  const { user } = useAuthHelper();
  const { status: matchesOutFlagStatus, flag: matchesOutFlag } = useIsMatchesReleased();
  const isMobile = useIsMobile('TABLET');
  const [questionnaire, setQuestionnaire] = useState<IQuestionnaire | null>(null);
  const userProfileRef = doc(
    firebaseFirestore,
    `UserProfiles/${user!.uid}`,
  ) as DocumentReference<IUserProfile>;
  const userQuestionnaireRef = doc(
    firebaseFirestore,
    `Questionnaire/${user!.uid}`,
  ) as DocumentReference<any>;
  const { status: userProfileStatus, data: userProfileData } =
    useFirestoreDocData<IUserProfile>(userProfileRef);
  const { status: userQuestionnaireStatus, data: userQuestionnaireData } =
    useFirestoreDocData<any>(userQuestionnaireRef);
  useEffect(() => {
    let unmounted = false;
    if (userProfileStatus === 'loading') {
      return;
    }
    const { college } = userProfileData;
    const storageRef = getDownloadURL(ref(firebaseStorage, getUniversityRef(college)));
    storageRef.then((url) => {
      axios.get(url).then((response) => {
        if (!unmounted) setQuestionnaire(response.data);
      });
    });
    return () => {
      unmounted = true;
    };
  }, [userProfileData, userProfileStatus]);
  if (
    matchesOutFlagStatus === 'loading' ||
    userProfileStatus === 'loading' ||
    userQuestionnaireStatus === 'loading' ||
    !questionnaire
  ) {
    return <DashboardLoading />;
  }
  const { college, firstName } = userProfileData;
  const questionnaireCompletionStatus = getQuestionnaireStatus(
    userProfileData,
    userQuestionnaireData,
    questionnaire.fields,
  );
  const aphroditeStatus: QuestionnaireStatusType = matchesOutFlag
    ? 'matchesOut'
    : questionnaireCompletionStatus;
  return (
    <StyledContentsContainer>
      <StyledTitleContainer>
        <ADSHeading level="2">Hi{` ${firstName}`}!</ADSHeading>
        <ADSText size="xs" color="standardGrey70" wrapperStyle={{ marginTop: '8px' }}>
          Welcome to the {new Date().getFullYear()} {COLLEGE_CONSTANTS.COLLEGE_NAMES[college]}{' '}
          Aphrodite Project!
        </ADSText>
      </StyledTitleContainer>
      <StyledDashboardCardStatus status={aphroditeStatus} />
      <StyledDashboardCardCountdown />
      <StyledDashboardCardStats />
      <StyledDashboardCardStatsGraph />
      {isMobile && <StyledDashboardCardPlayCupid />}
      {isMobile && <StyledDashboardCardDataReport college={college} />}
    </StyledContentsContainer>
  );
}
