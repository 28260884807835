import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { ADSAnchor, ADSButton, ADSHeading, ADSPage, ADSText } from '@aphrodite/common-ui';
import { SectionData } from '@aphrodite/common/types/DataReportTypes';
import { getAnalytics, logEvent } from '@firebase/analytics';

import { renderSection } from '../../pages/PageReport';
import Banner from '../Banner';

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledArticlePage = styled.div`
  padding: 0 20px;
  width: 100%;
  max-width: 720px;
`;
const StyledArticleDate = styled(ADSText)`
  margin: 12px 0;
`;
const StyledArticleParagraph = styled(ADSText)`
  margin-top: 20px;
  @media only screen and (min-width: 600px) {
    margin-top: 36px;
  }
`;
const StyledHeading = styled(ADSHeading)`
  margin-top: 32px;
  margin-bottom: 8px;
`;
const StyledButtonRegion = styled.div`
  margin: 28px 0;
  display: flex;
  justify-content: center;
`;

interface Props {
  sections: SectionData[];
  date: string | undefined;
  numStudents: number | undefined;
  university: string | undefined;
  year: string | undefined;
}

const PandemicReport: FunctionComponent<Props> = ({
  sections,
  date,
  numStudents,
  university,
  year,
}) => {
  const analytics = getAnalytics();
  return (
    <ADSPage>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <StyledContentContainer>
        <StyledArticlePage>
          <Banner>{`${university} Aphrodite Pandemic Edition Data Report`}</Banner>
          <StyledArticleDate color="standardGrey80" size="m">
            {date}
          </StyledArticleDate>
          <StyledHeading hyphensNone={true} level="2" weight="light">
            {'The Pandemic Edition Data Report is finally here!'}
          </StyledHeading>
          <StyledArticleParagraph hyphensNone={true}>
            {`We've spent the past few months hard at work, crunching the research-focused pandemic edition data from ${numStudents} confirmed ${university} student participants.`}
            <br />
            <br />
            {`We create this aggregated data 
          report to give value back to you, the student community that made Aphrodite possible.`}
          </StyledArticleParagraph>
          <StyledArticleParagraph blockquote={true} hyphensNone={true}>
            {`As mentioned,
          we launched the Pandemic Edition as a `}
            <b>special research edition</b>
            {` with two intentions:`}
            <br />
            <br />
            {`1) – Create a meaningful opportunity for students to connect, and`}
            <br />
            <br />
            {`2) – Research on how we can give participants better match outcomes down the road`}
          </StyledArticleParagraph>
          <StyledArticleParagraph hyphensNone={true}>
            <b>In this report, we strived to maintain anonymity and sensitivity to participants:</b>
            <br />
            <br />
            {
              '• We omitted all dealbreaker quesitons styled as "My match SHOULD NOT be..." to avoid perpetuating any harmful stereotypes about the social desirability of any sexual orientation, ethnicity, religion, or diet.'
            }
            <br />
            <br />
            {'• We excluded categories with under 25 people to maintain participant anonymity.'}
          </StyledArticleParagraph>
          <StyledArticleParagraph blockquote={true} hyphensNone={true}>
            <b>We also seek your help to keep this report un-googleable.</b> In balancing ease of
            access and privacy, we designed this page to block search engines from indexing it
            directly to keep it off Google search results.
            <br />
            <br />
            This means that only {university} student participants and anyone they choose to send
            the link to, can view this report. This report contains information about the{' '}
            {university} student community that may feel intimate for some; We hope you can work
            with us to keep the link to this report off Google search results. If you hope to
            generate public discussion on a public forum like reddit, please only post that a
            specific snippet of the data report.
            <br />
            <br />
            This stance might change in the future depending on your feedback.
          </StyledArticleParagraph>
          <br />
          <ADSHeading level="3" weight="bold">
            – Preamble –
          </ADSHeading>
          <StyledArticleParagraph>
            This report was born from our belief that we have a responsiblity to reciprocate
            participants like you with meaningful insights from the data you've entrusted to us.
            <br />
            <br />
            We hope that this report can spur interesting discourse and ideas, and help us better
            embrace the similarities, differences, and unique diversity of our student community. We
            hope that someone may find comfort in knowing that they're not alone in some aspects of
            their life.
          </StyledArticleParagraph>
          <br />
          <br />
          <ADSHeading level="3" weight="bold">
            – The Pandemic Edition –
          </ADSHeading>
          <StyledArticleParagraph hyphensNone={true}>
            {`This Pandemic Edition was a one-time special edition where two algorithms were used for matching. This was a research-focused design as we wanted to assess whether our trademark stable matching algorithm works with measurable effect in matching participants, to explore how we can improve matching outcomes for participants down the road.`}
            <br />
            <br />
            {`Hence, 50% of participants were matched using our stable matching algorithm (2020 version), while the other 50% were matched using an experimental algorithm. This experimental algorithm matched in a semi-random manner: it accounts for a participant's match dealbreakers but otherwise matched at random on all other questions. `}
            <br />
            <br />
            {`These exact details of the experimental algorithm was not made public until now to maintain a blind condition. If you are a participant, your follow-up report email should also detail which pool you were matched in.`}
            <br />
            <br />
            {`Insights from this edition will be referenced to help us improve the Aphrodite questionnaire and refine the algorithm for future participants.`}
            <br />
            <br />
          </StyledArticleParagraph>

          {sections?.map((section, index) => {
            return <React.Fragment key={index}>{renderSection(section)}</React.Fragment>;
          })}
          <ADSText blockquote={true} hyphensNone={true}>
            Before you go, we want to thank you for participating in the Aphrodite Project! We know
            that no matches are perfect, but we hope we've helped you meet a new friend or maybe
            even someone special.
            <br />
            <br />
            We know that the data we collect can be intimate. To that end, we do not sell your data
            nor do we intend to. However, so much of what we do, the tools, secure platforms we rely
            on costs plenty of dough, effort, and time! This report, created for you and the student
            community also took us months of effort to create.
            <br />
            <br />
            Please consider supporting us in our mission to build a more loving, inclusive world and
            create opportunities for intimate and meaningful relationships by buying us a coffee!
          </ADSText>
          <StyledButtonRegion>
            <ADSAnchor target="_blank" to="https://www.buymeacoffee.com/aphroditecupids">
              <ADSButton
                onClick={() =>
                  logEvent(analytics, 'link-click', {
                    component: 'DATA_REPORT',
                    button_name: 'donation',
                  })
                }
              >
                Buy us a coffee!
              </ADSButton>
            </ADSAnchor>
          </StyledButtonRegion>
        </StyledArticlePage>
      </StyledContentContainer>
    </ADSPage>
  );
};

export default PandemicReport;
