import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';

import { connectFirestoreEmulator, getFirestore } from '@firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from '@firebase/functions';
import { connectStorageEmulator, getStorage } from '@firebase/storage';

import { emulatorConfig, isEmulatorEnabled } from './emulators/firebaseEmulatorConfiguration';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACTAPP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);
export const firebaseStorage = getStorage(firebaseApp);
export const firebaseFirestore = getFirestore(firebaseApp);
export const firebaseFunctions = getFunctions(firebaseApp);

if (isEmulatorEnabled && window.location.hostname === 'localhost') {
  connectAuthEmulator(firebaseAuth, `http://localhost:${emulatorConfig.auth}`);
  connectStorageEmulator(firebaseStorage, 'localhost', emulatorConfig.storage);
  connectFirestoreEmulator(firebaseFirestore, 'localhost', emulatorConfig.firestore);
  connectFunctionsEmulator(firebaseFunctions, 'localhost', emulatorConfig.functions);
}
