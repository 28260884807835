import styled from 'styled-components';

import {
  ADSAccordion,
  ADSAccordionItem,
  ADSAnchor,
  ADSHeading,
  ADSText,
} from '@aphrodite/common-ui';
import {
  LAUNCH_DATE_GLOBAL,
  LAUNCH_DATE_SINGAPORE,
  MATCH_RESULTS_DATE_GLOBAL,
  MATCH_RESULTS_DATE_SINGAPORE,
  QUESTIONNAIRE_CLOSE_DATE_GLOBAL,
  QUESTIONNAIRE_CLOSE_DATE_SINGAPORE,
} from '@aphrodite/common/constants/Dates';
import { useIsSingaporeWebsite } from '@aphrodite/common/hooks';

import ProcessImg1 from '../../assets/images/landing_process_001.png';
import ProcessImg2 from '../../assets/images/landing_process_002.png';
import ProcessImg4 from '../../assets/images/landing_process_004.png';

const StyledTextImageContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  @media only screen and (min-width: 680px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
`;
const StyledTextChunk = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: center;

  @media only screen and (min-width: 680px) {
    max-width: 500px;
    margin-right: 48px;
  }
`;
const StyledImg = styled.img`
  align-self: center;
  width: 50vw;
  height: auto;
  @media only screen and (min-width: 680px) {
    width: 300px;
    height: auto;
  }
`;

interface TextImageContentProps {
  title?: React.ReactNode;
  content?: React.ReactNode;
  image?: string;
}
const TextImageContent = ({ title, content, image }: TextImageContentProps) => {
  return (
    <StyledTextImageContentContainer>
      <StyledTextChunk>
        <ADSText size="s" wrapperStyle={{ fontWeight: '700' }}>
          {title}
        </ADSText>
        <ADSText size="s">{content}</ADSText>
      </StyledTextChunk>
      {image && <StyledImg src={image} />}
    </StyledTextImageContentContainer>
  );
};

const StyledCardDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 8%;
  width: 100vw;
  height: fit-content;
  background: #d1e1ff;
  margin-bottom: 80px;
  @media only screen and (min-width: 680px) {
    padding: 80px 8%;
  }
  @media only screen and (min-width: 1440px) {
    padding: 80px 16%;
  }
`;

export default function LandingStepsSection(): React.ReactElement {
  const isSingaporeWebsite = useIsSingaporeWebsite();
  const instagramLink = isSingaporeWebsite
    ? 'https://www.instagram.com/aphroditeproject.sg'
    : 'https://www.instagram.com/aphroditeproject';
  const launchDate = isSingaporeWebsite ? LAUNCH_DATE_SINGAPORE : LAUNCH_DATE_GLOBAL;
  const questionnaireCloseDate = isSingaporeWebsite
    ? QUESTIONNAIRE_CLOSE_DATE_SINGAPORE
    : QUESTIONNAIRE_CLOSE_DATE_GLOBAL;
  const matchResultsDate = isSingaporeWebsite
    ? MATCH_RESULTS_DATE_SINGAPORE
    : MATCH_RESULTS_DATE_GLOBAL;
  const PROCESS_LIST = [
    {
      title: 'Sign up!',
      subtitle: `${launchDate.format('DD MMM')}: Sign-ups open`,
      content:
        'Sign up with your school email so we can verify your school affiliation. You will only be matched with someone from your school.',
      imgUrl: ProcessImg1,
    },
    {
      title: 'Complete questionnaire',
      subtitle: `Complete application by ${questionnaireCloseDate.format('DD MMM')}`,
      content:
        'Complete our comprehensive questionnaire based on the latest psychological research to help us get to know you.',
      imgUrl: ProcessImg2,
    },
    {
      title: 'Our algorithm runs',
      subtitle: '1 week wait time',
      content: (
        <ADSText size="s">
          While our algorithm finds your ideal match you can: <br />
          ∙ Fill out your profile <br />∙ Follow us on instagram (
          <ADSAnchor to={instagramLink} underline={true}>
            link
          </ADSAnchor>
          ) <br />
          ∙ Craft your ideal opening line ;)
          <br />
          Note: when the algorithm is running we are unable to add more applications. Make sure to
          submit your application on time.
        </ADSText>
      ),
    },
    {
      title: 'Get matched!',
      subtitle: `${matchResultsDate}: Result release!`,
      content: "You'll get your match! It's up to you to take it from here.",
      imgUrl: ProcessImg4,
    },
  ];
  return (
    <StyledCardDiv>
      <ADSAccordion
        title={
          <ADSHeading
            weight="bold"
            textAlign="left"
            level="1"
            wrapperStyle={{ marginBottom: '24px', color: '#000000' }}
          >
            Process
          </ADSHeading>
        }
      >
        {PROCESS_LIST.map((item, idx) => {
          return (
            <ADSAccordionItem
              index={idx}
              title={
                <>
                  <ADSHeading weight="bold" level="3" inline wrapperStyle={{ marginRight: 4 }}>
                    0{idx + 1} -
                  </ADSHeading>
                  <ADSHeading level="3" inline wrapperStyle={{ fontWeight: 'inherit' }}>
                    {item.title}
                  </ADSHeading>
                </>
              }
              content={
                <TextImageContent
                  title={item.subtitle}
                  content={item.content}
                  image={item.imgUrl}
                />
              }
              key={idx}
            />
          );
        })}
      </ADSAccordion>
    </StyledCardDiv>
  );
}
