import { FunctionComponent } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  Text as RechartsText,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
//import styles from './styles.module.scss';
import styled from 'styled-components';

import { ADSText } from '@aphrodite/common-ui';
import { genericScale12 } from '@aphrodite/common/constants/Graph';
import { GraphProps } from '@aphrodite/common/types/DataReportTypes';

// Styled Components
const StyledContainer = styled.div`
  margin: 0;
`;

const StyledTitle = styled(ADSText)`
  font-size: 18px;
`;

const StyledText = styled(RechartsText)`
  font-size: 6px;
  @media only screen and (min-width: 680px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 768px) {
    font-size: 14px;
  }
`;

interface TickProps {
  x?: number;
  y?: number;
  payload?: any;
}
const CustomizedAxisTick: FunctionComponent<TickProps> = ({ x, y, payload }) => {
  return (
    <StyledText x={x} y={y} width={75} textAnchor="middle" verticalAnchor="start">
      {payload.value}
    </StyledText>
  );
};

/**
 * Generic Stacked Bar Chart Component.
 */
const SimpleBarChart: FunctionComponent<GraphProps> = ({
  className,
  dataKeys,
  title,
  xLabel,
  yLabel,
  items,
}) => {
  if (!dataKeys || dataKeys.length < 1) {
    return null;
  }

  //  const graphClassName = classNames(styles.container, className);

  return (
    <StyledContainer>
      <StyledTitle size="l" weight="semibold">
        {title}
      </StyledTitle>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={items}
          height={450}
          margin={{
            right: 40,
            left: 40,
            bottom: 20,
          }}
          width={750}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="category" dataKey="name" interval={0} tick={<CustomizedAxisTick />}>
            {xLabel ? <Label value={xLabel} offset={0} position="bottom" /> : null}
          </XAxis>
          <YAxis type="number" tickCount={11}>
            {yLabel ? <Label value={yLabel} offset={0} position="left" /> : null}
          </YAxis>
          <Tooltip />
          <Bar dataKey={dataKeys[0]}>
            {items.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={genericScale12[index]}
                strokeWidth={index === 2 ? 4 : 1}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </StyledContainer>
  );
};

export default SimpleBarChart;
