import { FunctionComponent } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  Text as RechartsText,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';

import { ADSText } from '@aphrodite/common-ui';
import { GraphProps } from '@aphrodite/common/types/DataReportTypes';

import { linearScale7 } from '../constants/Graph';

// Styled Components
const StyledContainer = styled.div`
  margin: 0;
`;
const StyledTitle = styled(ADSText)`
  font-size: 22px;
`;
const StyledText = styled(RechartsText)`
  font-size: 10px;
`;

//main functions

interface TickProps {
  x?: number;
  y?: number;
  payload?: any;
}
const CustomisedAxisTick: FunctionComponent<TickProps> = ({ x, y, payload }) => {
  const text = payload.value;
  return (
    <g transform={`translate(${x},${y})`}>
      <StyledText x={0} y={0} dy={5} textAnchor="end" width={150}>
        {text.length > 40 ? text.substr(0, 40) + '...' : text}
      </StyledText>
    </g>
  );
};

/**
 * Generic Stacked Bar Chart Component.
 */
const StackedBarChart: FunctionComponent<GraphProps> = ({
  className,
  title,
  xLabel,
  yLabel,
  dataKeys,
  items,
}) => {
  //const graphClassName = classNames(styles.container, className);

  const roundTick = (value: string) => {
    return parseFloat(value).toFixed(1);
  };

  const formatPercentage = (value: any) => {
    return value + '%';
  };

  return (
    <>
      <StyledContainer>
        <StyledTitle size="l" weight="semibold">
          {title}
        </StyledTitle>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={items}
            height={400}
            layout="vertical"
            margin={{
              right: 40,
              left: 40,
              bottom: 20,
            }}
            width={750}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              tickCount={11}
              type="number"
              tick={{ fontSize: '12px' }}
              domain={[0, 100]}
              tickFormatter={roundTick}
            >
              {xLabel ? <Label value={xLabel} offset={0} position="bottom" /> : null}
            </XAxis>
            <YAxis type="category" dataKey="name" tick={<CustomisedAxisTick />}>
              {yLabel ? <Label value={yLabel} offset={0} position="left" /> : null}
            </YAxis>
            <Tooltip formatter={formatPercentage} />
            <Legend
              iconSize={16}
              iconType="rect"
              align="right"
              verticalAlign="top"
              wrapperStyle={{ paddingTop: '8px', paddingBottom: '8px' }}
            />
            {dataKeys
              ? dataKeys.map((item, index) => {
                  return <Bar dataKey={item} key={item} stackId="_" fill={linearScale7[index]} />;
                })
              : undefined}
          </BarChart>
        </ResponsiveContainer>
      </StyledContainer>
      <br />
      <br />
    </>
  );
};

export default StackedBarChart;
