import { IUserProfile } from '@aphrodite/common/types/firestore-types';

import {
  COIN_QUESTION_FIELD_ID,
  COIN_QUESTION_FIELD_OLD_ID,
  IQuestion,
  MATCH_POOL_FIELD,
  PoolResponseID,
  QuestionType,
  QuestionnaireStatusType,
} from '../constants/Questionnaire';

export function getQuestionnaireStatus(
  userProfileData: IUserProfile,
  userQuestionnaireData: any,
  questions: IQuestion[],
): QuestionnaireStatusType {
  // filter out statement questions and coin question.
  const filteredQuestions = questions.filter((question) => {
    return question.type !== QuestionType.STATEMENT;
  });
  if (!userQuestionnaireData || userQuestionnaireData['hasStarted'] === false) {
    // Only has initial placeholder data.
    return 'notStarted';
  }
  const poolTypeSelection = userQuestionnaireData[MATCH_POOL_FIELD];
  if (!poolTypeSelection || poolTypeSelection.length === 0) {
    return 'incomplete';
  }
  const filteredQuestionsForPool = getQuestionsForPool(filteredQuestions, poolTypeSelection);
  for (const question of filteredQuestionsForPool) {
    // If not required, skip.
    if (!question.validations?.required) {
      continue;
    }
    const response =
      question.properties.database === 'private'
        ? userQuestionnaireData[question.id]
        : (userProfileData as any)[question.id];
    if (question.type === QuestionType.GROUP_COIN) {
      const newResponse = userQuestionnaireData[COIN_QUESTION_FIELD_ID]
        ? userQuestionnaireData[COIN_QUESTION_FIELD_ID]
        : userQuestionnaireData[COIN_QUESTION_FIELD_OLD_ID];
      if (!isQuestionComplete(question, newResponse)) {
        return 'incomplete';
      }
    } else if (!isQuestionComplete(question, response)) {
      return 'incomplete';
    }
  }
  return 'complete';
}

export function searchQuestion(id: string, questions: IQuestion[]) {
  for (const question of questions) {
    if (question.id === id) {
      return question;
    }
  }
  return null;
}

export function getFirstIncompleteQuestionIndex(
  userProfileData: IUserProfile,
  userQuestionnaireData: any,
  questions: IQuestion[],
): number | null {
  if (!userProfileData || !userQuestionnaireData || !questions) {
    return null;
  }
  const poolTypeSelection = userQuestionnaireData[MATCH_POOL_FIELD];
  if (!poolTypeSelection || poolTypeSelection.length === 0) {
    const allQuestions = getQuestionsForPool(questions, null);
    const poolQuestionID = allQuestions.findIndex((question) => question.id === MATCH_POOL_FIELD);
    // Return the romantic / platonic pool question
    return poolQuestionID;
  }
  const poolTypeResponse = userQuestionnaireData[MATCH_POOL_FIELD];
  const filteredQuestionsForPool = getQuestionsForPool(questions, poolTypeResponse);
  let index = -1;
  for (const question of filteredQuestionsForPool) {
    index += 1;
    // If statement question, skip.
    if (question.type === QuestionType.STATEMENT) {
      continue;
    }
    // If not required, skip.
    if (!question.validations?.required) {
      continue;
    }
    const response =
      question.properties.database === 'private'
        ? userQuestionnaireData[question.id]
        : (userProfileData as any)[question.id];
    if (question.type === QuestionType.GROUP_COIN) {
      const newResponse = userQuestionnaireData[COIN_QUESTION_FIELD_ID]
        ? userQuestionnaireData[COIN_QUESTION_FIELD_ID]
        : userQuestionnaireData[COIN_QUESTION_FIELD_OLD_ID];
      if (!isQuestionComplete(question, newResponse)) {
        return index;
      }
    } else if (!isQuestionComplete(question, response)) {
      return index;
    }
  }
  // If we arrive here, the questionnaire is complete. Return -1.
  return -1;
}

export function getQuestionsForPool(questions: IQuestion[], poolResponse: any) {
  if (!poolResponse || !poolResponse[0]) {
    // Pool Type hasn't been selected, just show all questions.
    return questions;
  }
  const poolResponseID = poolResponse[0].id;
  if (poolResponseID === PoolResponseID.ROMANTIC) {
    return questions.filter((question) => {
      if (!question.properties.pool_specific) {
        return true;
      }
      return question.properties.pool_specific === 'romantic';
    });
  } else if (poolResponseID === PoolResponseID.PLATONIC) {
    return questions.filter((question) => {
      if (!question.properties.pool_specific) {
        return true;
      }
      return question.properties.pool_specific === 'platonic';
    });
  }
  return questions;
}

export function isQuestionComplete(question: IQuestion, response: any) {
  if (!question.validations?.required) {
    return true;
  }
  if (response === null || response === undefined) {
    return false;
  }
  switch (question.type) {
    case QuestionType.STATEMENT:
    case QuestionType.LONG_TEXT:
    case QuestionType.SHORT_TEXT:
    case QuestionType.NUMBER:
    case QuestionType.OPINION_SCALE:
      return response !== null && response !== undefined && response !== '';
    case QuestionType.MULTIPLE_CHOICE:
      return !(!response || response.length === 0);
    case QuestionType.DROPDOWN:
      return !(!response || Object.values(response).length === 0);
    case QuestionType.GROUP_COIN:
      const coinSubQuestions = question.properties.fields!;
      if (!response || Object.values(response).length === 0) {
        return false;
      }
      let coinSum = 0;
      for (const subQuestion of coinSubQuestions) {
        const subQuestionResponse = response[subQuestion.id];
        if (subQuestionResponse === null || subQuestionResponse === undefined) {
          // We allow nulls or undefined as long as coins add up to 20.
          continue;
        }
        coinSum += parseInt(subQuestionResponse);
      }
      // Ensure that they add up to 20.
      if (coinSum !== 20) {
        return false;
      }
  }
  return true;
}
