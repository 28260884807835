import { KeyboardEvent } from 'react';
import styled from 'styled-components';

import { IQuestionAnswer } from '../../constants/Questionnaire';

// Styled Components
const StyledInput = styled.input`
  border: none;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.transparentPurple}`};
  background: none;
  color: ${(props) => props.theme.colors.darkPurple};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  height: 40px;
  hyphens: auto;
  line-height: 28px;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: normal;
  width: 100%;
  &:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    border-bottom: ${({ theme }) => `2px solid ${theme.colors.darkPurple}`};
  }
  @media only screen and (min-width: 680px) {
    font-size: 22px;
    height: 44px;
    line-height: 34px;
  }
`;

export interface Props {
  className?: string;
  handleSetResponse: (inputAnswer: IQuestionAnswer) => void;
  onClickNext: () => void;
  value: string;
}

export default function QuestionShortText({
  className,
  handleSetResponse,
  onClickNext,
  value,
}: Props): React.ReactElement {
  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onClickNext();
    }
  };
  return (
    <StyledInput
      className={className}
      maxLength={200}
      onChange={(e) => {
        handleSetResponse(e.target.value as IQuestionAnswer);
      }}
      onKeyDown={handleKeyPress}
      placeholder="Type your answer here..."
      value={value}
    />
  );
}
