import { ChangeEvent, KeyboardEvent } from 'react';
import styled, { css, keyframes } from 'styled-components';

// Mixins
const handleFontStyle = (fontStyle: 'normal' | 'italic' = 'normal') => {
  switch (fontStyle) {
    case 'normal':
      return css`
        font-style: normal;
      `;
    case 'italic':
      return css`
        font-style: italic;
      `;
  }
};
const handleOverflow = (overflow: 'normal' | 'breakWord' = 'normal') => {
  switch (overflow) {
    case 'normal':
      return css`
        word-break: normal;
        overflow-wrap: normal;
      `;
    case 'breakWord':
      return css`
        word-break: break-word;
        overflow-wrap: break-word;
      `;
  }
};
const handleSize = (size: 'xs' | 's' | 'm' | 'l' | 'xl' = 'm') => {
  switch (size) {
    case 'xs':
      return css`
        font-size: 12px;
        line-height: 18px;
        @media only screen and (min-width: 680px) {
          font-size: 12px;
          line-height: 18px;
        }
      `;
    case 's':
      return css`
        font-size: 14px;
        line-height: 20px;
        @media only screen and (min-width: 680px) {
          font-size: 16px;
          line-height: 24px;
        }
      `;
    case 'm':
      return css`
        font-size: 16px;
        line-height: 24px;
        @media only screen and (min-width: 680px) {
          font-size: 20px;
          line-height: 30px;
        }
      `;
    case 'l':
      return css`
        font-size: 18px;
        line-height: 28px;
        @media only screen and (min-width: 680px) {
          font-size: 22px;
          line-height: 34px;
        }
      `;
    case 'xl':
      return css`
        font-size: 20px;
        line-height: 32px;
        @media only screen and (min-width: 680px) {
          font-size: 26px;
          line-height: 40px;
        }
      `;
  }
};
const handleSingleLine = (singleline: boolean = false) => {
  if (singleline) {
    return css`
      white-space: nowrap;
    `;
  } else {
    return css`
      white-space: normal;
    `;
  }
};
const handleTextAlign = (textAlign: 'left' | 'center' | 'right' = 'left') => {
  switch (textAlign) {
    case 'left':
      return css`
        text-align: left;
      `;
    case 'center':
      return css`
        text-align: center;
      `;
    case 'right':
      return css`
        text-align: right;
      `;
  }
};
const ShakeKeyframe = keyframes`
  0% {
    margin-left: 0rem;
  }
  25% {
    margin-left: 0.5rem;
  }
  75% {
    margin-left: -0.5rem;
  }
  100% {
    margin-left: 0rem;
  }
`;
const InvalidMixin = css`
  border: 1px solid red !important;
  animation: ${ShakeKeyframe} 0.2s ease-in-out 0s 2 !important;
  &:focus {
    outline-color: red;
  }
`;
const handleWeight = (weight: 'light' | 'semibold' | 'regular' = 'regular') => {
  switch (weight) {
    case 'light':
      return css`
        font-weight: 300;
      `;
    case 'regular':
      return css`
        font-weight: 400;
      `;
    case 'semibold':
      return css`
        font-weight: 500;
      `;
  }
};

// Styled Components
const StyledInput = styled.input<Props>`
  border: ${({ theme }) => `1px solid ${theme.colors.lightGrey}`};
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.inputBackground};
  height: 40px;
  hyphens: ${({ hyphensNone }) => (hyphensNone ? 'none' : 'auto')};
  overflow: hidden;
  padding: 1% calc(max(2%, 16px));
  text-overflow: ellipsis;
  text-decoration: ${({ decoration }) => (decoration === 'none' ? 'none' : undefined)};
  width: 100%;
  ${({ fontStyle }) => handleFontStyle(fontStyle)};
  ${({ overflow }) => handleOverflow(overflow)};
  ${({ singleline }) => handleSingleLine(singleline)};
  ${({ fontSize }) => handleSize(fontSize)};
  ${({ textAlign }) => handleTextAlign(textAlign)};
  ${({ weight }) => handleWeight(weight)};
  ${({ isValid }) => (isValid ? undefined : InvalidMixin)}
  &:focus {
    box-shadow: ${({ theme }) => `0 0 0 1px ${theme.colors.standardGrey70}`};
  }
  @media only screen and (min-width: 680px) {
    height: 44px;
  }
`;

export interface Props {
  className?: string;
  color?: 'black80' | 'blackDefault' | 'darkGrey' | 'standardGrey80';
  decoration?: 'none';
  fontStyle?: 'normal' | 'italic';
  fontSize?: 'xl' | 'l' | 'm' | 's' | 'xs';
  hyphensNone?: boolean;
  overflow?: 'normal' | 'breakWord';
  singleline?: boolean;
  textAlign?: 'center' | 'left' | 'right';
  weight?: 'light' | 'regular' | 'semibold';
  type?: 'email' | 'text' | 'password';
  placeholder?: string;
  isValid: boolean;
  shakeOnInvalid?: boolean;
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  wrapperStyle?: React.CSSProperties;
}

export default function ADSInput({
  className,
  decoration,
  fontStyle,
  fontSize = 'm',
  hyphensNone,
  overflow,
  singleline,
  textAlign,
  weight = 'regular',
  type = 'text',
  placeholder,
  isValid,
  onKeyPress,
  onChange,
  value,
  wrapperStyle = {},
}: Props): React.ReactElement {
  return (
    <StyledInput
      onChange={onChange}
      onKeyDown={onKeyPress}
      className={className}
      decoration={decoration}
      fontStyle={fontStyle}
      fontSize={fontSize}
      hyphensNone={hyphensNone}
      isValid={isValid}
      overflow={overflow}
      singleline={singleline}
      textAlign={textAlign}
      type={type}
      placeholder={placeholder}
      value={value}
      weight={weight}
      style={wrapperStyle}
    />
  );
}
