import { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

import { ADSAnchor } from '@aphrodite/common-ui';
import { ADSCard } from '@aphrodite/common-ui';
import { ADSModal } from '@aphrodite/common-ui';
import { ADSText } from '@aphrodite/common-ui';

import { IPartner } from '../../constants/Partners';

// Styled Components
const StyledCardButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font: inherit;
  margin: 5px;
  outline: inherit;
  padding: 0;
`;
const StyledCardTable = styled.table`
  width: 100%;
  background-color: white;
`;
const StyledCardTableCell = styled.td`
  align-items: center;
  padding: 5px;
  justify-content: center;
`;
const StyledCardCodeContainer = styled.div`
  align-items: center;
  display: flex;
  background-color: ${({ theme }) => theme.colors.lightCream};
  height: 50px;
  justify-content: center;
  position: relative;
  vertical-align: center;
  z-index: 3;
  @media only screen and (min-width: 680px) {
    height: 75px;
  }
  @media only screen and (min-width: 1024px) {
    height: 90px;
  }
`;
const StyledClaimDescription = styled(ADSText)`
  margin-bottom: 4px;
`;
const StyledListItem = styled(ADSText)`
  margin-top: 4px;
`;
const StyledMatchCodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
`;
const StyledPartnerCard = styled(ADSCard)`
  margin: 12px 20px;
  overflow: hidden;
  width: 280px;
  height: 260px;
  @media only screen and (max-width: 680px) {
    width: 300px;
    height: 260px;
    margin: 12px 0;
  }
`;
const StyledPartnerDetailsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const StyledPartnerLogo = styled.img`
  margin: 40px 12px 0 12px;
  max-height: 100px;
`;
export interface Props {
  codeA?: string;
  codeB?: string;
  isMatchResultsReleased: boolean;
  partnerDetails: IPartner;
}
export default function PartnershipCard({
  codeA,
  codeB,
  isMatchResultsReleased,
  partnerDetails: {
    company,
    companyLogo,
    howToClaim,
    termsAndConditions,
    title,
    requireMatch,
    requireMatchCode,
    promoCode,
    url,
  },
}: Props): React.ReactElement {
  const [modalStatus, setModalStatus] = useState(false);
  const modalRef = useRef(null);
  const toggleModal = () => {
    setModalStatus(!modalStatus);
  };
  useEffect(() => {
    const listener = (event: any) => {
      if (modalRef.current) {
        if (!(modalRef as any).current.contains(event.target)) {
          setModalStatus(false);
        }
      }
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, []);

  /**
   * === Rules ===
   * Show "Coming Soon" if:
   * - Date is before match results release
   *
   * Match-code rendering:
   * - Users with 1 match -> Display 1 match
   * - Users with 2 matches -> Display 2 matches
   * - Users with no match -> Check if they are eligible for the deal
   *
   * Other claiming methods:
   * - Display the URL or Promo code accordingly
   * */
  const renderMatchCode = () => {
    if (!codeA && !codeB) {
      return (
        <ADSText weight="semibold" size="s" textAlign={'center'}>
          Only available to matched participants
        </ADSText>
      );
    } else if (codeA && codeB) {
      return (
        <>
          <StyledClaimDescription
            weight="semibold"
            size="xs"
            textAlign={'center'}
            color="standardGrey40"
          >
            Present this matching code on your phone to claim the deal
          </StyledClaimDescription>
          <StyledMatchCodeContainer>
            <ADSText weight="semibold" size="l" textAlign={'center'}>
              {codeA}
            </ADSText>
            <ADSText weight="semibold" size="l" textAlign={'center'}>
              {codeB}
            </ADSText>
          </StyledMatchCodeContainer>
        </>
      );
    } else {
      let code = codeA;
      if (!codeA && codeB) {
        code = codeB;
      }
      return (
        <>
          <StyledClaimDescription
            weight="semibold"
            size="xs"
            textAlign={'center'}
            color="standardGrey40"
          >
            Present this matching code on your phone to claim the deal
          </StyledClaimDescription>
          <ADSText weight="semibold" size="l" textAlign={'center'}>
            {code}
          </ADSText>
        </>
      );
    }
  };
  const renderPromoCode = () => {
    return (
      <>
        <StyledClaimDescription
          weight="semibold"
          size="xs"
          textAlign={'center'}
          color="standardGrey40"
        >
          Promotion code
        </StyledClaimDescription>
        <ADSText weight="semibold" size="l" textAlign={'center'}>
          {promoCode}
        </ADSText>
      </>
    );
  };
  const renderUrl = () => {
    if (url)
      return (
        <>
          <ADSAnchor target="_blank" to={url}>
            <ADSText weight="semibold" size="m" textAlign={'center'} textDecoration="underline">
              Click here to indicate interest!
            </ADSText>
          </ADSAnchor>
        </>
      );
  };
  const renderClaimDetails = () => {
    if (isMatchResultsReleased) {
      if (requireMatch && !codeA && !codeB) {
        return (
          <ADSText weight="semibold" size="s" textAlign={'center'}>
            Only available to matched participants
          </ADSText>
        );
      } else if (requireMatchCode) {
        return renderMatchCode();
      } else if (promoCode) {
        return renderPromoCode();
      } else if (url) {
        return renderUrl();
      }
    } else if (url) {
      return (
        <>
          <StyledClaimDescription
            weight="semibold"
            size="xs"
            textAlign={'center'}
            color="standardGrey40"
          >
            Indicate your interest using this link
          </StyledClaimDescription>
          <ADSText weight="semibold" size="s" textAlign={'center'}>
            Link coming soon!
          </ADSText>
        </>
      );
    }
    return (
      <>
        <StyledClaimDescription
          weight="semibold"
          size="xs"
          textAlign={'center'}
          color="standardGrey40"
        >
          Present this card and code on your phone to claim the deal
        </StyledClaimDescription>
        <ADSText weight="semibold" size="s" textAlign={'center'}>
          Code coming soon!
        </ADSText>
      </>
    );
  };

  const renderModal = (data: {
    company: string;
    companyLogo: string;
    howToClaim: string[];
    termsAndConditions: string[];
    title: string;
  }) => {
    const comingSoon = false;
    return (
      <ADSModal
        title={data.company}
        backdropImage={data.companyLogo}
        cancelToggle={toggleModal}
        fixedContent={renderClaimDetails()}
        innerRef={modalRef}
      >
        <StyledCardTable>
          <tbody>
            <tr>
              <StyledCardTableCell>
                <ADSText size="m" textAlign="center" weight="semibold">
                  {data.title}
                </ADSText>
              </StyledCardTableCell>
            </tr>
            <tr>
              <StyledCardTableCell>
                <ADSText size="xs" textAlign="left" weight="semibold">
                  How to claim
                </ADSText>
                {comingSoon ? (
                  <ADSText size="xs" textAlign="left" weight="light">
                    More details coming soon!
                  </ADSText>
                ) : (
                  data.howToClaim.map((x: any, i: number) => (
                    <StyledListItem size="xs" textAlign="left" weight="light" key={i}>
                      •{' '}
                      <ReactMarkdown
                        children={x}
                        components={{
                          a: (props: any) => (
                            <ADSAnchor target="_blank" to={props.href} underline={true}>
                              {props.children}
                            </ADSAnchor>
                          ),
                          p: 'span',
                        }}
                      />
                    </StyledListItem>
                  ))
                )}
              </StyledCardTableCell>
            </tr>
            <tr>
              <StyledCardTableCell>
                <ADSText size="xs" textAlign="left" weight="semibold">
                  Terms & Conditions:
                </ADSText>
                {comingSoon ? (
                  <ADSText size="xs" textAlign="left" weight="light">
                    More details coming soon!
                  </ADSText>
                ) : (
                  data.termsAndConditions.map((x: any, i: number) => (
                    <ADSText size="xxs" textAlign="left" weight="light" key={i}>
                      • {x}
                    </ADSText>
                  ))
                )}
              </StyledCardTableCell>
            </tr>
          </tbody>
        </StyledCardTable>
      </ADSModal>
    );
  };
  return (
    <>
      {modalStatus &&
        renderModal({
          company,
          companyLogo,
          howToClaim,
          termsAndConditions,
          title,
        })}
      <StyledCardButton>
        <StyledPartnerCard backgroundColor="translucent" onClick={toggleModal}>
          <StyledPartnerDetailsContainer>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                height: '100px',
                justifyContent: 'center',
              }}
            >
              {companyLogo !== '' ? (
                <StyledPartnerLogo alt={company} src={companyLogo} />
              ) : (
                <ADSText
                  color="black80"
                  hyphensNone={true}
                  size="l"
                  textAlign="center"
                  wrapperStyle={{ padding: '2% 5%' }}
                >
                  {company}
                </ADSText>
              )}
            </div>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                height: '110px',
                justifyContent: 'center',
              }}
            >
              <ADSText
                color="black80"
                hyphensNone={true}
                size="s"
                textAlign="center"
                wrapperStyle={{ padding: '2% 5%' }}
              >
                {title}
              </ADSText>
            </div>
          </StyledPartnerDetailsContainer>
          <StyledCardCodeContainer style={{ height: '55px', zIndex: 0 }}>
            <ADSText textAlign="center" size="m" hyphensNone={true} color="black80">
              See Details
            </ADSText>
          </StyledCardCodeContainer>
        </StyledPartnerCard>
      </StyledCardButton>
    </>
  );
}
