import { useCallback, useEffect, useRef, useState } from 'react';
import { useFirestoreDocData } from 'reactfire';
import styled from 'styled-components';

import { useDebounce } from '@aphrodite/common/hooks';
import { useAuthHelper } from '@aphrodite/common/hooks';
import { IUserProfile } from '@aphrodite/common/types/firestore-types';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';
import { DocumentReference, doc, updateDoc } from '@firebase/firestore';

import { PROFILE_MESSAGE_REF } from '../../constants/Questionnaire';
import { IQuestionAnswer } from '../../constants/Questionnaire';
import DashboardLoading from '../dashboard/DashboardLoading';
import { IStatus } from '../questionnaire/Questionnaire';
import ProfileInfoCard from './ProfileInfoCard';

// Styled Components
const StyledTextArea = styled.textarea<{ textAreaHeight: string }>`
  background-color: ${(props) => {
    if (!props.disabled) {
      return props.theme.colors.paleGrey;
    } else {
      return 'transparent';
    }
  }};
  border: none;
  border-radius: 16px;
  height: ${(props) => props.textAreaHeight};
  max-height: 200px;
  max-width: 100%;
  margin-top: 12px;
  margin-left: -8px;
  outline: none;
  padding: 8px 12px;
  resize: none;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
`;
export default function ProfileMessageCard(): React.ReactElement {
  const { user } = useAuthHelper();
  const userProfileRef = doc(
    firebaseFirestore,
    `UserProfiles/${user!.uid}`,
  ) as DocumentReference<IUserProfile>;
  const { status: userProfileStatus, data: userProfileData } =
    useFirestoreDocData<IUserProfile>(userProfileRef);
  const messageToMatch = userProfileData[PROFILE_MESSAGE_REF];

  const [editingEnabled, setEditingEnabled] = useState(false);
  const [response, setResponse] = useState<IQuestionAnswer>(messageToMatch ?? '');
  const [status, setStatus] = useState<IStatus>('saved');

  let textAreaRef: any = useRef<HTMLDivElement>(null);
  const [textAreaHeight, setTextAreaHeight] = useState('auto');
  useEffect(() => {
    setTextAreaHeight(`${textAreaRef.current!.scrollHeight}px`);
  }, [response]);

  const debouncedResponse = useDebounce(response, 500);
  const isValidResponse = (interest: string) => {
    return interest && interest.replace(/\s/g, '').length !== 0;
  };
  const onStatusChange = useCallback((status: IStatus) => setStatus(status), []);
  function onToggleEditMode() {
    setEditingEnabled(!editingEnabled);
  }
  function onClickSave() {
    onStatusChange('loading');
    if (
      !isValidResponse(debouncedResponse as string) ||
      debouncedResponse === null ||
      debouncedResponse === undefined
    ) {
      return;
    }
    updateDoc(userProfileRef, {
      [PROFILE_MESSAGE_REF]: debouncedResponse as string,
    })
      .then(() => {
        onStatusChange('saved');
      })
      .catch((error) => {
        onStatusChange('error');
      });
    setEditingEnabled(false);
  }
  function handleInputChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setResponse(event.target.value);
    setTextAreaHeight('auto');
  }

  if (userProfileStatus === 'loading') {
    return <DashboardLoading />;
  }
  return (
    <form>
      <ProfileInfoCard
        title="About me"
        underline={false}
        titleColor="standardGrey70"
        onSave={onClickSave}
        isEditMode={editingEnabled}
        onToggleEditMode={onToggleEditMode}
        status={status}
      >
        <StyledTextArea
          disabled={!editingEnabled}
          value={response as string}
          onChange={(e) => handleInputChange(e)}
          textAreaHeight={textAreaHeight}
          ref={textAreaRef}
        />
      </ProfileInfoCard>
    </form>
  );
}
