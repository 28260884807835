import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { ADSButton, ADSHeading } from '@aphrodite/common-ui';
import { ADSText } from '@aphrodite/common-ui';
import { LAUNCH_DATE_GLOBAL, LAUNCH_DATE_SINGAPORE } from '@aphrodite/common/constants/Dates';
import {
  useIsLaunched,
  useIsQuestionnaireClosed,
  useIsSingaporeWebsite,
} from '@aphrodite/common/hooks';

import EndingBackground from '../../assets/images/landing_ending_background.png';
import EndingDesktopBackground from '../../assets/images/landing_ending_desktop_background.png';
import PinkRing from '../../assets/images/landing_ending_logo.png';

const StyledEndingChunck = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  padding-left: 8%;
  padding-right: 8%;
  height: 50vh;
  width: 100vw;
  background-image: url(${EndingBackground});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  padding-bottom: 30vh;
  @media only screen and (min-width: 640px) {
    background-image: linear-gradient(white 65%, transparent 100%), url(${EndingDesktopBackground});
    height: fit-content;
  }
  @media only screen and (min-width: 1440px) {
    padding-left: 16%;
    padding-right: 16%;
  }
`;
const StyledEndingDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 32px;
  height: 80vh;
  @media only screen and (min-width: 640px) {
    height: 30vh;
    min-height: 300px;
    margin-top: 80px;
  }
`;
const StyledFlexDiv = styled.div`
  width: match-parent;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  margin-right: auto;
  gap: 12px;
`;
const StyledTitle = styled(ADSHeading)`
  color: #080808;
  @media only screen and (min-width: 640px) and (max-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
const StyledImg = styled.img`
  width: 20vw;
  @media only screen and (min-width: 640px) {
    width: 160px;
  }
`;
const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`;

export default function LandingStats(): React.ReactElement {
  const isLaunched = useIsLaunched();
  const isSingaporeWebsite = useIsSingaporeWebsite();
  //const isSingaporeLaunched = useIsSingaporeLaunched();
  //const isValentinesLaunched = useIsValentinesLaunched();
  const isQuestionnaireClosed = useIsQuestionnaireClosed();
  const renderTitle = () => {
    if (isLaunched) {
      return 'Sign up to get started!';
    }
    return 'Sign up to get notified!';
  };
  const renderMainText = () => {
    if (!isLaunched) {
      return `Stay tuned for our run in 
        ${
          isSingaporeWebsite
            ? LAUNCH_DATE_SINGAPORE.format('MMMM Do')
            : LAUNCH_DATE_GLOBAL.format('MMMM Do')
        }!`;
    }
  };
  const renderMainButton = () => {
    return (
      <StyledNavLink to="/start">
        <ADSButton>{isQuestionnaireClosed ? 'Log in!' : 'Count me in!'}</ADSButton>
      </StyledNavLink>
    );
  };
  return (
    <StyledEndingChunck>
      <StyledEndingDiv>
        <StyledImg src={PinkRing} />
        <StyledFlexDiv>
          <StyledTitle level="1" weight="bold">
            {renderTitle()}
          </StyledTitle>
          <ADSText size="l" color="black80">
            {renderMainText()}
          </ADSText>
        </StyledFlexDiv>
        {renderMainButton()}
      </StyledEndingDiv>
    </StyledEndingChunck>
  );
}
