import { useFirestoreCollectionData } from 'reactfire';

import { Region } from '@aphrodite/common/constants/Region';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';
import { collection, orderBy, query, where } from '@firebase/firestore';

import {
  LAUNCH_DATE_GLOBAL,
  LAUNCH_DATE_SINGAPORE,
  QUESTIONNAIRE_CLOSE_DATE_GLOBAL,
  QUESTIONNAIRE_CLOSE_DATE_SINGAPORE,
} from '../constants/Dates';
import { useIsSingaporeWebsite } from './useConfiguration';

/**
 * Retrieves the number of signups per day for each college within the requested region,
 * this only includes signups that fall between the active launch date and the current date
 * @param {string} region - The region to get signup statistics for
 * @returns An array of objects indexed by number of days since launch. E.g.
 * [{
 *    nus: 3, // NUS had 3 signups on this day
 *    smu: 4,
 *    ntu: 0,
 *    ...
 *    }
 * }]
 */
export function useGetDateStatistics(region: Region | undefined) {
  const isSingaporeWebsite = useIsSingaporeWebsite();
  const dateTrackerRef = collection(firebaseFirestore, 'Statistics/Signups/' + region);
  const latestLaunchDate = isSingaporeWebsite ? LAUNCH_DATE_SINGAPORE : LAUNCH_DATE_GLOBAL;
  const questionnaireCloseDate = isSingaporeWebsite
    ? QUESTIONNAIRE_CLOSE_DATE_SINGAPORE
    : QUESTIONNAIRE_CLOSE_DATE_GLOBAL;
  // Only get the date stats if they are within the current launch period
  const launchDate = latestLaunchDate.toDate();
  launchDate.setHours(0); // statistics are saved to 00:00 of the same day, this means we always start tracking from 00:00 onwards.
  const dateStatsQuery = query(
    dateTrackerRef,
    orderBy('date'),
    where('date', '>=', launchDate),
    where('date', '<', questionnaireCloseDate.toDate()),
  );
  const { status: statisticsStatus, data: statisticsData } =
    useFirestoreCollectionData(dateStatsQuery);
  if (!region) {
    // This means the region hasn't loaded to be passed into this function yet.
    return { statisticsStatus: 'loading', statisticsData: [] };
  }
  return { statisticsStatus, statisticsData };
}

/**
 * Retrieves the number of signups per day for all colleges in all regions,
 * this only includes signups that fall between the active launch date and the current date
 * @returns An array of objects indexed by number of days since launch. E.g.
 * [{
 *    nus: 3, // NUS had 3 signups on this day
 *    smu: 4,
 *    ntu: 0,
 *    ...
 *    }
 * }]
 */
export function useGetDateStatisticsAll() {
  const dateTrackerRefSingapore = collection(
    firebaseFirestore,
    'Statistics/Signups/' + Region.SINGAPORE,
  );
  const dateTrackerRefGlobal = collection(
    firebaseFirestore,
    'Statistics/Signups/' + Region.NORTH_AMERICA,
  );
  const questionnaireCloseDateSingapore = QUESTIONNAIRE_CLOSE_DATE_SINGAPORE.toDate();
  const questionnaireCloseDateGlobal = QUESTIONNAIRE_CLOSE_DATE_GLOBAL.toDate();
  // Only get the date stats if they are within the current launch period
  const launchDateSingapore = LAUNCH_DATE_SINGAPORE.toDate();
  const launchDateGlobal = LAUNCH_DATE_GLOBAL.toDate();
  launchDateSingapore.setHours(0); // statistics are saved to 00:00 of the same day, this means we always start tracking from 00:00 onwards.
  launchDateGlobal.setHours(0); // statistics are saved to 00:00 of the same day, this means we always start tracking from 00:00 onwards.
  const dateStatsQuerySingapore = query(
    dateTrackerRefSingapore,
    orderBy('date'),
    where('date', '>=', launchDateSingapore),
    where('date', '<', questionnaireCloseDateSingapore),
  );
  const dateStatsQueryGlobal = query(
    dateTrackerRefGlobal,
    orderBy('date'),
    where('date', '>=', launchDateGlobal),
    where('date', '<', questionnaireCloseDateGlobal),
  );
  const { status: statisticsStatusSingapore, data: statisticsDataSingapore } =
    useFirestoreCollectionData(dateStatsQuerySingapore);
  const { status: statisticsStatusGlobal, data: statisticsDataGlobal } =
    useFirestoreCollectionData(dateStatsQueryGlobal);
  let status: 'error' | 'loading' | 'success';
  if (statisticsStatusSingapore === 'error' || statisticsStatusGlobal === 'error') {
    status = 'error';
  } else if (statisticsStatusSingapore === 'loading' || statisticsStatusGlobal === 'loading') {
    status = 'loading';
  } else {
    status = 'success';
  }
  return {
    statisticsStatus: status,
    statisticsData: [
      ...(statisticsDataSingapore ? statisticsDataSingapore : []),
      ...(statisticsDataGlobal ? statisticsDataGlobal : []),
    ],
  };
}
