import { useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';
import styled from 'styled-components';

import LeftArrow from '@aphrodite/assets/icons/left-arrow.svg';
import Menu from '@aphrodite/assets/logos/menu.svg';
import { ADSButton, ADSHeading, ADSText } from '@aphrodite/common-ui';
import { Props as AphroditeThemeProps } from '@aphrodite/common-ui/design-system/ADSText';
import { SHOW_PARTNERSHIPS } from '@aphrodite/common/constants/Partnerships';
import { useAuthHelper } from '@aphrodite/common/hooks';
import { useIsSingaporeWebsite } from '@aphrodite/common/hooks';
import { IUserProfile } from '@aphrodite/common/types/firestore-types';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';
import { DocumentReference, doc } from '@firebase/firestore';

import { MATCH_POOL_FIELD, PoolResponseID } from '../../constants/Questionnaire';
import MatchResultPopup from '../MatchResultPopup';
import ProfileCollegeCard from '../profile/ProfileCollegeCard';
import ProfileContactCard from '../profile/ProfileContactCard';
import ProfileInfoCard from '../profile/ProfileInfoCard';
import ProfileDetailsContainer from './../profile/ProfileDetailsContainer';
import DashboardLoading from './DashboardLoading';

const StyledContainer = styled.div`
  max-width: 100%;
  display: flex;
  padding: 20% 8% 8% 10%;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
  @media only screen and (min-width: 1024px) {
    row-gap: 0;
    margin-top: ${({ theme }) => `${parseFloat(theme.spacing.navbarDesktopLogoHeight) * 0.25}px`};
    margin-left: ${({ theme }) => theme.spacing.navbarDesktopWidth};
    padding: 0 4%;
  }
`;
const StyledIntroContainer = styled.div`
  margin-bottom: 32px;
  @media only screen and (min-width: 1024px) {
    grid-column: 1 / 4;
    grid-row: 1 / 2;
  }
`;
const StyledNavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StyledCenteredMobileContentContainer = styled.div`
  @media only screen and (max-width: 1024px) {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }
`;
const StyledIcon = styled.img`
  cursor: pointer;
  margin-top: 10px;
  width: 20px;
  height: auto;
`;
const StyledProfileSection = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.cardSpacing};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  @media only screen and (min-width: 1024px) {
    align-items: flex-start;
  }
`;
const StyledMiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    gap: 12px;
  }
`;
const StyledCollegeInterestContainer = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.cardSpacing};
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: fit-content;
`;
const StyledFunFactHeading = styled(ADSHeading)`
  display: none;
  @media only screen and (min-width: 1024px) {
    display: inline;
  }
`;
const StyledSmallFunFactHeading = styled(ADSText)`
  text-align: center;
  @media only screen and (min-width: 1024px) {
    display: none;
  }
`;
const StyledTextChuck = styled.div`
  padding: 8px 12px;
  resize: none;
  width: 100%;
  vertical-align: top;
`;
const StyledBigTextChuck = styled(StyledTextChuck)`
  font-size: 20px;
  font-weight: 700;
`;
const StyledContactContainer = styled.div`
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing.cardSpacing};
  display: flex;
  flex-direction: column;
  gap: 12px;
  .button {
    width: 100%;
  }
  @media only screen and (min-width: 1024px) {
    padding-top: 24px;
  }
`;
const StyledFunFactContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: ${(props) => props.theme.spacing.cardSpacing};
  @media only screen and (min-width: 1024px) {
    margin-top: 16px;
  }
`;
const StyledDivider = styled.hr`
  border: none;
  border-top: 1px solid ${(props) => props.theme.colors.standardGrey70};
`;
const StyledMessageContainer = styled.div`
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing.cardSpacing};
  @media only screen and (min-width: 1024px) {
    grid-column: 2 / 4;
    grid-row: 5 / 6;
    margin-right: 4%;
  }
`;

// Local Storage key for New Features
// const NEW_FEATURE_KEY = 'reportMatchProfile';

interface MatchProfileProp {
  matchID: string;
  matchEmail: string;
}
export default function DashboardTabMatchProfile(): React.ReactElement {
  const { state } = useLocation<MatchProfileProp>();
  const { user } = useAuthHelper();
  const matchProfileRef = doc(
    firebaseFirestore,
    `UserProfiles/${state.matchID}`,
  ) as DocumentReference<IUserProfile>;
  const userQuestionnaireRef = doc(firebaseFirestore, `Questionnaire/${user!.uid}`);
  const { status: userQuestionnaireStatus, data: userQuestionnaireData } =
    useFirestoreDocData<any>(userQuestionnaireRef);
  const { status, data } = useFirestoreDocData<IUserProfile>(matchProfileRef);
  const history = useHistory();

  const [showPopup, setShowPopup] = useState(false);
  const popupToggle = () => setShowPopup(!showPopup);

  // const showNewFeatureLocalStorage = useMemo(
  //   () =>
  //     window.localStorage.getItem(NEW_FEATURE_KEY) === 'true'
  //       ? true
  //       : window.localStorage.getItem(NEW_FEATURE_KEY) === 'false'
  //       ? false
  //       : null,
  //   [],
  // );
  // const [showNewFeatureModal, setShowNewFeatureModal] = useState(
  //   showNewFeatureLocalStorage !== null ? showNewFeatureLocalStorage : true,
  // );
  // const newFeatureModalToggle = (dontShowAgain: boolean) => {
  //   if (dontShowAgain) window.localStorage.setItem(NEW_FEATURE_KEY, 'false');
  //   setShowNewFeatureModal(!showNewFeatureModal);
  // };

  const isSingaporeRun = useIsSingaporeWebsite();

  const [showOptions, setShowOptions] = useState(false);
  const optionsToggle = () => setShowOptions(!showOptions);

  if (!state.matchID) {
    return (
      <StyledContainer>
        <StyledIntroContainer>
          <ADSHeading>Oops, something went wrong!</ADSHeading>
        </StyledIntroContainer>
        <NavLink style={{ textDecoration: 'none' }} to="/dashboard">
          <ADSButton>Go to dashboard</ADSButton>
        </NavLink>
      </StyledContainer>
    );
  }
  if (status === 'loading' || userQuestionnaireStatus === 'loading') {
    return <DashboardLoading />;
  }
  const isRomantic = userQuestionnaireData[MATCH_POOL_FIELD][0].id === PoolResponseID.ROMANTIC;
  return (
    <StyledContainer>
      <StyledIntroContainer>
        <StyledNavigationContainer>
          <StyledIcon src={LeftArrow} alt="" onClick={history.goBack} />
          <StyledIcon src={Menu} alt="" onClick={optionsToggle} />
        </StyledNavigationContainer>
        <StyledCenteredMobileContentContainer>
          <ADSHeading level="2">{isRomantic ? 'My Match' : 'My Platonic Match'}</ADSHeading>
        </StyledCenteredMobileContentContainer>
        {isSingaporeRun && SHOW_PARTNERSHIPS && (
          <ADSText size="s" color="standardGrey70">
            Check out the Partner Deals tab for some perks exclusive for you and your match!
          </ADSText>
        )}
      </StyledIntroContainer>
      <StyledProfileSection>
        <ProfileDetailsContainer matchProfileData={data} isMatchProfile={true} />
      </StyledProfileSection>
      <StyledMiddleContainer>
        <StyledCollegeInterestContainer>
          <ProfileCollegeCard matchProfileData={data} isMatchProfile={true} />
          <ProfileInfoCard isEditMode={false} title="Interests" icon="interest" isDisabled={true}>
            <StyledTextChuck>{data.YOUinterest}</StyledTextChuck>
          </ProfileInfoCard>
        </StyledCollegeInterestContainer>
        <StyledContactContainer>
          <ADSButton className="button" onClick={popupToggle} styleType="defaultFullLength">
            Match Rank Information
          </ADSButton>
          <ProfileContactCard
            matchProfileData={data}
            matchEmail={state.matchEmail}
            isMatchProfile={true}
          />
        </StyledContactContainer>
      </StyledMiddleContainer>
      <StyledFunFactContainer>
        <StyledFunFactHeading level="3" weight="semibold">
          Fun fact
        </StyledFunFactHeading>
        <StyledSmallFunFactHeading size="m" weight="semibold" textAlign="center">
          Fun fact
        </StyledSmallFunFactHeading>
        <StyledDivider />
        <ProfileInfoCard
          isEditMode={false}
          title="2 Facts and 1 lie about me"
          titleColor="standardGrey70"
          isDisabled={true}
        >
          <StyledBigTextChuck>{data.YOUfunfact}</StyledBigTextChuck>
        </ProfileInfoCard>
      </StyledFunFactContainer>
      <StyledMessageContainer>
        <ProfileInfoCard
          isEditMode={false}
          title="About me!"
          titleColor="standardGrey70"
          isDisabled={true}
        >
          <StyledBigTextChuck>{data.message}</StyledBigTextChuck>
        </ProfileInfoCard>
      </StyledMessageContainer>
      {showPopup && <MatchResultPopup showPopupToggle={popupToggle} matchID={state.matchID} />}
      {/* {showNewFeatureModal && (
        <NewFeatureModal onToggle={(dontShowAgain) => newFeatureModalToggle(dontShowAgain)} />
      )} */}
      {showOptions && <MatchProfileOption onToggle={optionsToggle} />}
    </StyledContainer>
  );
}

// Styled components for NewFeatureModal
// const StyledText = styled(ADSText)`
//   margin: 8px 8px 8px 8px;
// `;
// const StyledLogo = styled.img`
//   max-height: 144px;
//   display: block;
//   margin-left: auto;
//   margin-right: auto;
//   padding-bottom: 8px;
//   padding-top: 8px;
// `;
// interface NewFeatureModalProps {
//   onToggle: (dontShowAgain: boolean) => void;
// }
// const NewFeatureModal = ({ onToggle }: NewFeatureModalProps) => {
//   const isMobile = useIsMobile('TABLET');
//   const [dontShowAgain, setDontShowAgain] = useState(false);
//   return (
//     <ConfirmationPopup
//       title={'✨ New Feature ✨'}
//       content={
//         <>
//           <StyledLogo
//             src={isMobile ? ReportProfileDemoMobile : ReportProfileDemoDefault}
//             alt="logo"
//           />
//           <StyledText weight="semibold" size="xs" textAlign={'left'}>
//             <b>Report Abuse :</b>
//             <br />
//             1. Click on “...” on the top right corner
//             <br />
//             2. Click on report
//             <br />
//             3. Provide information and details regarding your situation
//             <br />
//             4. We will work with you to support your situation and only take actions with your
//             consent.
//           </StyledText>
//           <br />
//         </>
//       }
//       subContent={
//         <>
//           <StyledText weight="semibold" size="xxs" textAlign={'center'}>
//             Once your report is sent, expect a follow up email in 1-3 business days
//           </StyledText>
//           <br />
//           <StyledText weight="semibold" size="xxs" textAlign={'center'}>
//             <input
//               type="checkbox"
//               onChange={() => setDontShowAgain(!dontShowAgain)}
//               id="matchProfileNewFeatures"
//             />{' '}
//             Don't show again
//           </StyledText>
//         </>
//       }
//       buttonText={'Close'}
//       buttonAction={() => onToggle(dontShowAgain)}
//       wrapperStyle={{ minWidth: 350, marginTop: '8vh' }}
//     />
//   );
// };

// Styled components for MatchProfileOptions
const PopupContainer = styled.div`
  background: #00000080;
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
`;
const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    align-items: flex-end;
  }
`;
const OptionsContainer = styled.div`
  width: 100%;
  margin: 0 15px 15px;
  @media only screen and (min-width: 1024px) {
    background-color: #fff;
    width: 300px;
    padding: 20px;
    border-radius: 16px;
  }
`;

interface MatchProfileOptionProps {
  onToggle: () => void;
}

interface OptionsProps {
  text?: string;
  onClick?: () => void;
  color?: AphroditeThemeProps['color'];
}

const MatchProfileOption = ({ onToggle }: MatchProfileOptionProps) => {
  const options: OptionsProps[] = [
    {
      text: 'Report',
      onClick: () =>
        (window.location.href = 'mailto:cupids@aphrodite.global?subject=[Report] Match Profile'),
      color: 'aphroRedDark',
    },
  ];

  return (
    <PopupContainer>
      <FlexContainer onClick={onToggle}>
        <OptionsContainer>
          {options.map(({ text, onClick, color }) => (
            <ADSButton styleType="defaultFullLength" onClick={onClick}>
              <ADSText color={color ?? 'blackDefault'}>{text}</ADSText>
            </ADSButton>
          ))}
          <br />
          <ADSButton styleType="minimalFullLength" onClick={onToggle}>
            Cancel
          </ADSButton>
        </OptionsContainer>
      </FlexContainer>
    </PopupContainer>
  );
};
