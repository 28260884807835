import styled from 'styled-components';

// Styled Components
const StyledHighlight = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.colors.aphroPinkHighlight};
  border-radius: 5px;
  height: 35%;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
`;
const StyledTextContainer = styled.div`
  position: relative;
  text-align: center;
  align-items: center;
  display: inline-block;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 2;
`;

interface Props {
  children: React.ReactNode;
}
export default function ADSSpeechBubble({ children }: Props): React.ReactElement {
  return (
    <>
      <StyledTextContainer>
        <StyledHighlight />
        {children}
      </StyledTextContainer>
    </>
  );
}
