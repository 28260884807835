import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';
import styled from 'styled-components';

import Heart from '@aphrodite/assets/icons/heart.svg';
import Messages from '@aphrodite/assets/icons/messages.svg';
import Shield from '@aphrodite/assets/icons/shield.svg';
import { ADSButton, ADSCard, ADSHeading, ADSPage, ADSText } from '@aphrodite/common-ui';
import { ADSPageLoading } from '@aphrodite/common-ui';
import { useAuthHelper } from '@aphrodite/common/hooks';
import { IUserProfile } from '@aphrodite/common/types/firestore-types';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';
import { DocumentReference, doc } from '@firebase/firestore';

import Navbar from '../components/Navbar';
import { COLLEGE_NAMES } from '../constants/College';

const StyledContentsContainer = styled.main`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 8%;
  padding-top: 60px;
  height: calc(100vh - 40px);
`;
const StyledDesktopContainer = styled.div`
  align-items: left;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  padding: 28px 12px;
  border-radius: 16px;
  max-height: 200%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin-bottom: 10%;
  @media only screen and (min-width: 680px) {
    padding: 4%;
  }
  @media only screen and (min-width: 1000px) {
    max-width: 80%;
  }
  @media only screen and (min-width: 1300px) {
    max-width: 70%;
  }
  @media only screen and (min-width: 1500px) {
    max-width: 60%;
  }
`;

const StyledIcon = styled.img`
  height: 2em;
  width: 2em;
`;

const StyledInfoADSCard = styled(ADSCard)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 4%;
  width: 100%;
`;

const StyledCardGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const StyledButtonRegion = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin: 20px 0 40px 0;
`;

const StyledAgreement = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export default function PageOnboarding(): React.ReactElement {
  const { user } = useAuthHelper();
  const history = useHistory();
  const userProfileRef = doc(
    firebaseFirestore,
    `UserProfiles/${user!.uid}`,
  ) as DocumentReference<IUserProfile>;
  const { status, data } = useFirestoreDocData<IUserProfile>(userProfileRef);

  const [agreedSafety, setAgreedSafety] = useState(false);
  const [failedContinue, setFailedContinue] = useState(false);

  if (status === 'loading') {
    return <ADSPageLoading />;
  }
  const { college } = data;

  const onClickContinue = () => {
    if (!agreedSafety) {
      setFailedContinue(true);
    } else {
      history.push('/dashboard');
    }
  };

  return (
    <ADSPage>
      <Navbar />
      <StyledContentsContainer>
        <StyledDesktopContainer>
          <ADSHeading level="3" hyphensNone wrapperStyle={{ textAlign: 'center' }}>
            Your safety comes first, always.
          </ADSHeading>
          <StyledInfoContainer>
            <StyledCardGroup>
              <StyledInfoADSCard borderType="solid">
                <StyledIcon src={Messages} />
                <ADSText color="black80" size="s" hyphensNone>
                  If someone makes you uncomfortable - tell someone you trust.
                </ADSText>
              </StyledInfoADSCard>
              <StyledInfoADSCard borderType="solid">
                <StyledIcon src={Shield} />
                <ADSText color="black80" size="s" hyphensNone>
                  Be aware of {COLLEGE_NAMES[college]}'s safety resources,{' '}
                  <a
                    style={{ color: '#b30071', textDecoration: 'underline' }}
                    href="/safety-resources"
                  >
                    we've helped you collate some here.
                  </a>
                </ADSText>
              </StyledInfoADSCard>
              <StyledInfoADSCard borderType="solid">
                <StyledIcon src={Heart} />
                <ADSText color="black80" size="s" hyphensNone>
                  Reach out to us - we are here for you. Any action we take will only be done with
                  your consent.
                </ADSText>
              </StyledInfoADSCard>
            </StyledCardGroup>
            <ADSText hyphensNone wrapperStyle={{ textAlign: 'center' }} size="s">
              For more information on how to keep yourself safe, check out our{' '}
              <a style={{ color: '#b30071', textDecoration: 'underline' }} href="/safety-resources">
                safety resources
              </a>{' '}
              page.
            </ADSText>
            <StyledAgreement>
              <input
                type="checkbox"
                style={{ transform: 'scale(2)', marginLeft: '8px' }}
                onClick={() => {
                  setFailedContinue(false);
                  setAgreedSafety(!agreedSafety);
                }}
              />
              <ADSText hyphensNone weight="semibold" size="s" textAlign="left">
                I agree to use my school resources & community to keep myself safe
              </ADSText>
            </StyledAgreement>
          </StyledInfoContainer>
          <StyledButtonRegion>
            {failedContinue ? (
              <ADSText hyphensNone size="s" wrapperStyle={{ color: 'red', textAlign: 'center' }}>
                You must agree to our safety policy to continue
              </ADSText>
            ) : null}
            <ADSButton
              loading={false}
              wrapperStyle={{ textAlign: 'center' }}
              onClick={onClickContinue}
            >
              Continue
            </ADSButton>
          </StyledButtonRegion>
        </StyledDesktopContainer>
      </StyledContentsContainer>
    </ADSPage>
  );
}
