import { useCallback, useState } from 'react';
import { useFirestoreDocData } from 'reactfire';
import styled from 'styled-components';

import { ReactComponent as EmailLogo } from '@aphrodite/assets/icons/email-colored.svg';
import { ReactComponent as FacebookLogo } from '@aphrodite/assets/logos//facebook-colored.svg';
import { ReactComponent as InstagramLogo } from '@aphrodite/assets/logos//instagram-colored.svg';
import { ReactComponent as TelegramLogo } from '@aphrodite/assets/logos//telegram-colored.svg';
import { useDebounce } from '@aphrodite/common/hooks';
import { useAuthHelper } from '@aphrodite/common/hooks';
import { ICollegeConfiguration, IUserProfile } from '@aphrodite/common/types/firestore-types';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';
import { DocumentReference, doc, updateDoc } from '@firebase/firestore';

import { PROFILE_INSTAGRAM_REF, PROFILE_MAIN_CONTACT_REF } from '../../constants/Questionnaire';
import { IQuestionAnswer } from '../../constants/Questionnaire';
import DashboardLoading from '../dashboard/DashboardLoading';
import { IStatus } from '../questionnaire/Questionnaire';
import ProfileContactField from './ProfileContactField';
import ProfileInfoCard from './ProfileInfoCard';

// Styled Components
const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 12px;
`;
const StyledContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 16px;
  width: 100%;
`;
const StyledLogoContainer = styled(StyledContactContainer)`
  width: fit-content;
  gap: 12px;
  margin-top: 4px;
`;
const StyledVerticleDivider = styled.div`
  width: 2px;
  height: 136px;
  background: -moz-linear-gradient(90deg, #eba487, #e68fa8, #7a9fe3);
  background: -webkit-linear-gradient(90deg, #eba487, #e68fa8, #7a9fe3);
  margin-left: 16px;
  align-self: center;
`;
const StyledForm = styled.form`
  height: 100%;
  background: -moz-linear-gradient(90deg, #eba487, #e68fa8, #7a9fe3);
  background: -webkit-linear-gradient(90deg, #eba487, #e68fa8, #7a9fe3);
  border-radius: 12px;
  padding: 4px;
`;
const StyledProfileInfoCard = styled(ProfileInfoCard)`
  border: 2px solid;
  display: flex;
  flex-direction: column;
`;
interface Props {
  matchProfileData: IUserProfile;
  matchEmail: string;
  isMatchProfile: boolean;
}
export default function ProfileContactCard({
  matchProfileData,
  matchEmail,
  isMatchProfile,
}: Props): React.ReactElement {
  const { user } = useAuthHelper();
  const userProfileRef = doc(
    firebaseFirestore,
    `UserProfiles/${user!.uid}`,
  ) as DocumentReference<IUserProfile>;
  const { status: userProfileStatus, data: userProfileData } =
    useFirestoreDocData<IUserProfile>(userProfileRef);
  // Check for the user's college region to selectively render Telegram or Facebook logo
  const { college } = userProfileData;
  const collegeConfigIndex = 'College' + college.charAt(0).toUpperCase() + college.slice(1);
  const collegeConfigReg = doc(
    firebaseFirestore,
    `Configuration/${collegeConfigIndex}`,
  ) as DocumentReference<ICollegeConfiguration>;
  const { status: collegeConfigStatus, data: collegeFlagData } =
    useFirestoreDocData<ICollegeConfiguration>(collegeConfigReg);
  const region = collegeFlagData ? collegeFlagData['region'] : 'EMPTY';
  const mainContact = isMatchProfile ? matchProfileData.YOUcontact : userProfileData.YOUcontact;
  const instagram = isMatchProfile ? matchProfileData.YOUinstagram : userProfileData.YOUinstagram;
  const [editingEnabled, setEditingEnabled] = useState(false);
  const [mainContactResponse, setmainContactResponse] = useState<IQuestionAnswer>(
    mainContact ?? '',
  );
  const [instagramResponse, setInstagramResponse] = useState<IQuestionAnswer>(instagram ?? '');
  const [status, setStatus] = useState<IStatus>('saved');
  const debouncedMainContactResponse = useDebounce(mainContactResponse, 500);
  const debouncedInstagramResponse = useDebounce(instagramResponse, 500);
  const onStatusChange = useCallback((status: IStatus) => setStatus(status), []);
  const onToggleEditMode = () => {
    setEditingEnabled(!editingEnabled);
  };
  const onClickSave = () => {
    onStatusChange('loading');
    if (
      debouncedMainContactResponse === null ||
      debouncedInstagramResponse === null ||
      debouncedMainContactResponse === undefined ||
      debouncedInstagramResponse === undefined
    ) {
      return;
    }
    updateDoc(userProfileRef, {
      [PROFILE_MAIN_CONTACT_REF]: debouncedMainContactResponse as string,
      [PROFILE_INSTAGRAM_REF]: debouncedInstagramResponse as string,
    })
      .then(() => {
        onStatusChange('saved');
      })
      .catch((error: any) => {
        onStatusChange('error');
      });
    setEditingEnabled(false);
  };
  const handleMainContactChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setmainContactResponse(event.target.value);
  };
  const handleInstagramChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInstagramResponse(event.target.value);
  };
  if (isMatchProfile) {
    return (
      <StyledForm>
        <StyledProfileInfoCard isDisabled={true} title="Contact Me" underline={false}>
          <StyledMainContainer>
            <StyledLogoContainer>
              {region === 'SINGAPORE' ? (
                <TelegramLogo height={20} width={20} />
              ) : (
                <FacebookLogo height={20} width={20} />
              )}
              <InstagramLogo height={20} width={20} />
              <EmailLogo height={20} width={20} />
            </StyledLogoContainer>
            <StyledVerticleDivider />
            <StyledContactContainer>
              <ProfileContactField disabled={true} value={mainContact ?? ''} />
              <ProfileContactField disabled={true} value={instagram ?? ''} />
              <ProfileContactField disabled={true} value={matchEmail} />
            </StyledContactContainer>
          </StyledMainContainer>
        </StyledProfileInfoCard>
      </StyledForm>
    );
  }
  if (userProfileStatus === 'loading' || collegeConfigStatus === 'loading') {
    return <DashboardLoading />;
  }
  return (
    <StyledForm>
      <StyledProfileInfoCard
        isEditMode={editingEnabled}
        title="Contact"
        onSave={onClickSave}
        onToggleEditMode={onToggleEditMode}
        status={status}
        underline={false}
      >
        <StyledMainContainer>
          <StyledLogoContainer>
            {region === 'SINGAPORE' ? (
              <TelegramLogo height={20} width={20} />
            ) : (
              <FacebookLogo height={20} width={20} />
            )}
            <InstagramLogo height={20} width={20} />
            <EmailLogo height={20} width={20} />
          </StyledLogoContainer>
          <StyledVerticleDivider />
          <StyledContactContainer>
            <ProfileContactField
              disabled={!editingEnabled}
              value={mainContactResponse as string}
              onChange={(e) => handleMainContactChange(e)}
            />
            <ProfileContactField
              disabled={!editingEnabled}
              value={instagramResponse as string}
              onChange={(e) => handleInstagramChange(e)}
            />
            {user!.email && <ProfileContactField disabled={true} value={user!.email} />}
          </StyledContactContainer>
        </StyledMainContainer>
      </StyledProfileInfoCard>
    </StyledForm>
  );
}
