import styled, { css } from 'styled-components';

type BackgroundColorType = 'primary' | 'secondary' | 'translucent';
type BorderType = 'shadow' | 'solid';

// Mixins
const backgroundColorMixin = css<Props>`
  ${({ backgroundColor, theme }) => {
    switch (backgroundColor) {
      case 'primary':
        return `
        background: white;
      `;
      case 'secondary':
        return `
          background: ${theme.colors.aphroPinkHighlight}
        `;
      case 'translucent':
        return `
          background: ${theme.gradients.translucent}
        `;
      default:
        return `
          background-color: white;
        `;
    }
  }}
`;

const borderMixin = css<Props>`
  ${({ borderType, theme }) => {
    switch (borderType) {
      case 'solid':
        return `
          border: 1px solid rgba(38, 38, 38, 0.10);
        `;
      default:
        return `
          box-shadow: 0px 1.73018px 1.73018px rgba(0, 0, 0, 0.25);
        `;
    }
  }}
`;

// Styled Components
const StyledContainer = styled.div`
  border-radius: 10px;
  ${borderMixin};
  ${backgroundColorMixin};
`;

interface Props {
  backgroundColor?: BackgroundColorType;
  borderType?: BorderType;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  wrapperStyle?: React.CSSProperties;
}

export default function ADSCard({
  backgroundColor = 'primary',
  borderType = 'shadow',
  children,
  className,
  onClick,
  wrapperStyle = {},
}: Props): React.ReactElement {
  return (
    <StyledContainer
      backgroundColor={backgroundColor}
      borderType={borderType}
      className={className}
      onClick={onClick}
      style={wrapperStyle}
    >
      {children}
    </StyledContainer>
  );
}
