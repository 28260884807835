export interface GraphItem {
  name: any;
  [key: string]: any;
}
export interface GraphData {
  title: string;
  type: string;
  xLabel?: string;
  yLabel?: string;
  dataKeys?: (string | number)[];
  items: GraphItem[];
  graphType: string;
}
export interface GraphProps extends GraphData {
  className?: string;
}
export interface SectionData {
  header?: string;
  data?: string;
  subheader?: string;
  sectionTitle?: string;
  description?: string;
  graphs?: GraphData[];
}
export interface ReportData {
  date: string;
  dataYear?: string;
  university: string;
  numFollowup?: number;
  numStudents: number;
  reportContents: SectionData[];
}

export enum ReportEnum {
  NUS = 'nus',
  NTU = 'ntu',
  SMU = 'smu',
  UWATERLOO2020 = 'uwaterloo-pandemic-edition-3e529da440d9515187efc6febd166be3',
  UOFT2020 = 'uoft-pandemic-edition-06ee23944c76fa7b39e938fb9814ac92',
  UBC_DATA = 'ubc',
  UCLA_DATA = 'ucla',
  UOFT_DATA = 'uoft',
  UWATERLOO_DATA = 'uwaterloo',
  HKU_DATA = 'hku',
  MCMASTER_DATA = 'mcmaster',
  MIT_DATA = 'mit',
  HARVARD_DATA = 'harvard',
  WESTERN_DATA = 'western',
}

export interface AttrCount {
  [key: string]: number;
}
export interface UniData {
  gender: AttrCount;
  major: AttrCount;
  sexualOrientation: AttrCount;
  totalCount: number;
  university: string;
  year: AttrCount;
}
export interface InternalStatisticsDocument {
  date: number;
  uniData: UniData[];
}
