import { FunctionComponent } from 'react';

import { SimpleBarChart, SimplePieChart } from '@aphrodite/common-ui/graphs';
import { GraphProps } from '@aphrodite/common/types/DataReportTypes';

import LineChart from './LineChart';
import MultipleSortBarChart from './MultipleSortBarChart';
import StackedBarChart from './StackedBarChart';
import StackedVerticalBarChart from './StackedVerticalBarChart';

const Graph: FunctionComponent<GraphProps> = (props) => {
  const graphType = props.graphType;
  switch (graphType) {
    case 'bar':
      return <SimpleBarChart {...props} />;
    case 'pie':
      return <SimplePieChart {...props} />;
    case 'stacked_bar':
      return <StackedBarChart {...props} />;
    case 'vertical_stacked_bar':
      return <StackedVerticalBarChart {...props} />;
    case 'line':
      return <LineChart {...props} />;
    case 'multipleCategorySortCountBar':
      return <MultipleSortBarChart {...props} sort={true} />;
    case 'multipleCategoryCountBar':
      return <MultipleSortBarChart {...props} sort={false} />;
    default:
      return null;
  }
};

export default Graph;
