import { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { ADSButton, ADSHeading, ADSPage, ADSText } from '@aphrodite/common-ui';

import Navbar from '../components/Navbar';

// Styled Components
const StyledContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 450px;
  max-width: 100%;
  padding-left: 8%;
  margin: auto;
  margin-top: 32%;
  padding-right: 8%;
  @media only screen and (min-width: 680px) {
    margin-top: 12%;
    padding-left: 0;
  }
`;
const StyledHeader = styled(ADSHeading)`
  font-size: 84px;
  line-height: 110%;
`;
const StyledTextChunk = styled(ADSText)`
  margin-top: 16px;
`;
const StyledLink = styled(NavLink)`
  margin-top: 32px;
  text-decoration: none;
`;

//Main Component
const PageNotFound: FunctionComponent<{}> = () => {
  return (
    <ADSPage>
      <Navbar />
      <StyledContainer>
        <StyledHeader level="1">Oops...</StyledHeader>
        <StyledTextChunk size="m">The page you requested could not be found.</StyledTextChunk>
        <StyledLink to="/">
          <ADSButton>Click here to return home</ADSButton>
        </StyledLink>
      </StyledContainer>
    </ADSPage>
  );
};

export default PageNotFound;
