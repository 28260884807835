import { FunctionComponent, MutableRefObject, ReactNode } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

import Cancel from '@aphrodite/assets/logos/cancel.svg';

import ADSAnchor from './ADSAnchor';
import ADSButton from './ADSButton';
import ADSConfetti from './ADSConfetti';
import ADSText from './ADSText';

const PopupContainer = styled.div`
  background: #00000050;
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
`;
const DottedDivider = styled.div`
  border: none;
  border-top: 1px dashed lightgray;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`;
const StyledButton = styled(ADSButton)`
  margin: 24px auto 12px auto;
`;
const CancelButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`;
const StyledPosterImage = styled.img`
  align-self: center;
  max-height: 100px;
  max-width: 100%;
  @media only screen and (min-width: 680px) {
    max-height: 150px;
  }
  @media only screen and (min-width: 1024px) {
    max-height: 180px;
  }
`;
const StyledTitle = styled(ADSText)`
  font-size: x-large;
  margin: 0 auto 12px auto;
  text-align: center;
  top: 20px;
`;
const StyledText = styled(ADSText)`
  margin: 8px 16px 8px 16px;
`;
const TextContainer = styled.div`
  position: relative;
  width: 50vw;
  height: auto;
  max-height: 70vh;
  min-width: 280px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20vh;
  margin-bottom: 10vh;
  background: #fff;
  border-radius: 16px;
  overflow: auto;
  @media only screen and (min-width: 1024px) {
    max-height: 55vh;
  }
`;
const StyledScrollableContainer = styled.div`
  height: auto;
  max-height: 60vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 32px;
  gap: 12px;
  @media only screen and (min-width: 1024px) {
    max-height: 45vh;
  }
`;
const StyledFixedContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.lightCream};
  justify-content: center;
  vertical-align: center;
  padding: 12px 8px;
  width: 100%;
`;

interface ConfirmationPopupProps {
  title?: string;
  backdropImage?: string;
  cancelToggle?: () => void;
  children?: ReactNode;
  content?: string;
  subContent?: string;
  buttonText?: string;
  buttonAction?: () => void;
  showConfetti?: boolean;
  innerRef?: MutableRefObject<null>;
  fixedContent?: ReactNode;
}
const StyledLogo = styled.img`
  height: 8px;
`;
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
const { height, width } = getWindowDimensions();
const ADSModal: FunctionComponent<ConfirmationPopupProps> = ({
  title,
  backdropImage,
  cancelToggle,
  children,
  content,
  subContent,
  buttonText,
  buttonAction,
  showConfetti,
  innerRef,
  fixedContent = null,
}) => {
  return (
    <PopupContainer onClick={cancelToggle}>
      {showConfetti && <ADSConfetti width={width} height={height} />}
      <TextContainer ref={innerRef} onClick={(e) => e.stopPropagation()}>
        <StyledScrollableContainer>
          {cancelToggle && (
            <CancelButtonContainer>
              <ADSButton onClick={cancelToggle} styleType="pinkGradient">
                <StyledLogo src={Cancel} alt="cancel button" />
              </ADSButton>
            </CancelButtonContainer>
          )}
          {title && !backdropImage && <StyledTitle>{title}</StyledTitle>}
          {backdropImage && (
            <div
              style={{
                width: '100%',
                alignContent: 'center',
                display: 'flex',
                justifyContent: 'center',
                padding: '10px',
              }}
            >
              <StyledPosterImage src={backdropImage} />
            </div>
          )}
          {title && <DottedDivider />}
          {children}
          {content && (
            <StyledText weight="semibold" size="xs" textAlign={'center'}>
              <ReactMarkdown
                children={content}
                components={{
                  a: (props: any) => (
                    <ADSAnchor to={props.href} underline={true}>
                      {props.children}
                    </ADSAnchor>
                  ),
                  p: 'span',
                }}
              />
            </StyledText>
          )}
          {subContent && (
            <StyledText weight="semibold" size="xxs" textAlign={'center'}>
              <ReactMarkdown
                children={subContent}
                components={{
                  a: (props: any) => (
                    <ADSAnchor to={props.href} underline={true}>
                      {props.children}
                    </ADSAnchor>
                  ),
                  p: 'span',
                }}
              />
            </StyledText>
          )}
          {buttonText && buttonAction && (
            <StyledButton onClick={buttonAction}>{buttonText}</StyledButton>
          )}
        </StyledScrollableContainer>
        {fixedContent && <StyledFixedContainer>{fixedContent}</StyledFixedContainer>}
      </TextContainer>
    </PopupContainer>
  );
};

export default ADSModal;
