import React, { createContext, useContext, useState } from 'react';

interface AccordionContextType {
  activeAccordionIndex: number | string;
  setActiveAccordionIndex: React.Dispatch<React.SetStateAction<number | string>>;
}
const AccordionContext = createContext<AccordionContextType | undefined>(undefined);

const useAccordionContext = () => {
  const context = useContext(AccordionContext);
  if (!context) {
    throw new Error('useAccordionContext must be used within an AccordionProvider');
  }
  return context;
};

// Define the AccordionProvider component
interface AccordionProviderProps {
  children: React.ReactNode;
}
const AccordionProvider: React.FC<AccordionProviderProps> = ({ children }) => {
  const [activeAccordionIndex, setActiveAccordionIndex] = useState<number | string>(-1);
  return (
    <AccordionContext.Provider value={{ activeAccordionIndex, setActiveAccordionIndex }}>
      {children}
    </AccordionContext.Provider>
  );
};

export { useAccordionContext, AccordionProvider };
