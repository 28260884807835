import styled from 'styled-components';

import { ADSText } from '@aphrodite/common-ui';

// Styled Components
const StyledFieldItem = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-left: 4px;
  padding-right: 4px;
  width: 100%;
`;
const StyledFieldPrompt = styled.div`
  margin-right: 24px;
  min-width: fit-content;
`;
interface Props {
  children: React.ReactNode;
  promptText?: string;
}

export default function ProfileInfoField({ children, promptText }: Props): React.ReactElement {
  return (
    <StyledFieldItem>
      <StyledFieldPrompt>
        {promptText && (
          <ADSText size="s" weight="semibold" color="standardGrey80">
            {promptText}
          </ADSText>
        )}
      </StyledFieldPrompt>
      {children}
    </StyledFieldItem>
  );
}
