import { useState } from 'react';
import { useFirestoreDocData } from 'reactfire';
import styled from 'styled-components';

import { ADSHeading, ADSText } from '@aphrodite/common-ui';
import {
  QUESTIONNAIRE_CLOSE_DATE_GLOBAL,
  QUESTIONNAIRE_CLOSE_DATE_SINGAPORE,
} from '@aphrodite/common/constants/Dates';
import { useAuthHelper } from '@aphrodite/common/hooks';
import { useIsSingaporeWebsite } from '@aphrodite/common/hooks';
import { IUserProfile } from '@aphrodite/common/types/firestore-types';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';
import { DocumentReference, doc } from '@firebase/firestore';

import ModalWithdraw from '../ModalWithdraw';
import ProfileFunFactCard from '../profile/ProfileFunFactCard';
import ProfileMessageCard from '../profile/ProfileMessageCard';
import ProfileCollegeCard from './../profile/ProfileCollegeCard';
import ProfileContactCard from './../profile/ProfileContactCard';
import ProfileDetailsContainer from './../profile/ProfileDetailsContainer';
import ProfileInterestCard from './../profile/ProfileInterestCard';
import DashboardLoading from './DashboardLoading';

const StyledContentsContainer = styled.main`
  max-width: 100%;
  display: flex;
  padding: 20% 8% 8% 10%;
  flex-direction: column;
  justify-content: center;
  @media only screen and (min-width: 1024px) {
    row-gap: 0;
    margin-top: ${({ theme }) => `${parseFloat(theme.spacing.navbarDesktopLogoHeight) * 0.25}px`};
    margin-left: ${({ theme }) => theme.spacing.navbarDesktopWidth};
    padding: 0 4%;
  }
`;
const StyledIntroContainer = styled.div`
  margin-bottom: 24px;
  @media only screen and (min-width: 1024px) {
    display: flex;
    gap: 12px;
  }
`;
const StyledMiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    gap: 12px;
  }
`;
const StyledCollegeInterestContainer = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.cardSpacing};
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: fit-content;
  @media only screen and (min-width: 1024px) {
    /* margin-top: 0;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    margin-right: 4%;
    margin-bottom: 4%; */
  }
`;
const StyledContactContainer = styled.div`
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing.cardSpacing};
  flex-direction: row;
  @media only screen and (min-width: 1024px) {
  }
`;
const StyledFunFactContainer = styled.div`
  width: 100%;
  margin-top: 24px;
  margin-bottom: ${(props) => props.theme.spacing.cardSpacing};
  @media only screen and (min-width: 1024px) {
    margin-top: 16px;
  }
`;
const StyledFunFactHeading = styled(ADSHeading)`
  display: none;
  @media only screen and (min-width: 1024px) {
    display: inline;
  }
`;
const StyledSmallFunFactHeading = styled(ADSText)`
  text-align: center;
  @media only screen and (min-width: 1024px) {
    display: none;
  }
`;
const StyledMessageContainer = styled.div`
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing.cardSpacing};
  @media only screen and (min-width: 1024px) {
    grid-column: 2 / 4;
    grid-row: 5 / 6;
    margin-right: 4%;
  }
`;
const StyledWithdrawContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 24px;
  margin-left: 4px;
  margin-right: 4px;
  @media only screen and (min-width: 1024px) {
    justify-content: left;
  }
`;
const StyledDivider = styled.hr`
  border: none;
  border-top: 2px solid ${(props) => props.theme.colors.standardGrey70};
  margin-top: 4px;
  margin-bottom: 8px;
`;
const StyledButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;
export default function DashboardRouteProfile(): React.ReactElement {
  const { user } = useAuthHelper();
  const isSingaporeWebsite = useIsSingaporeWebsite();
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const userProfileRef = doc(
    firebaseFirestore,
    `UserProfiles/${user!.uid}`,
  ) as DocumentReference<IUserProfile>;
  const { status, data } = useFirestoreDocData<IUserProfile>(userProfileRef);
  const questionnaireCloseDate = isSingaporeWebsite
    ? QUESTIONNAIRE_CLOSE_DATE_SINGAPORE
    : QUESTIONNAIRE_CLOSE_DATE_GLOBAL;
  const withdrawModalToggle = () => setShowWithdrawModal(!showWithdrawModal);
  if (status === 'loading') {
    return <DashboardLoading />;
  }
  return (
    <>
      <StyledContentsContainer>
        <StyledIntroContainer>
          <ProfileDetailsContainer matchProfileData={data} isMatchProfile={false} />
        </StyledIntroContainer>
        <StyledMiddleContainer>
          <StyledCollegeInterestContainer>
            <ProfileCollegeCard matchProfileData={data} isMatchProfile={false} />
            <ProfileInterestCard />
          </StyledCollegeInterestContainer>
          <StyledContactContainer>
            <ProfileContactCard matchProfileData={data} matchEmail=" " isMatchProfile={false} />
          </StyledContactContainer>
        </StyledMiddleContainer>
        <StyledFunFactContainer>
          <StyledFunFactHeading level="3" weight="semibold">
            Fun fact
          </StyledFunFactHeading>
          <StyledSmallFunFactHeading size="m" weight="semibold" textAlign="center">
            Fun fact
          </StyledSmallFunFactHeading>
          <StyledDivider />
          <ProfileFunFactCard />
        </StyledFunFactContainer>
        <StyledMessageContainer>
          <ProfileMessageCard />
        </StyledMessageContainer>
        <StyledDivider />
        <StyledWithdrawContainer>
          <StyledButton onClick={withdrawModalToggle}>
            <ADSText size="xs" color="standardGrey80" textDecoration="underline">
              Click here to withdraw from the Aphrodite Project before{' '}
              {questionnaireCloseDate.format('Do MMMM')}
            </ADSText>
          </StyledButton>
        </StyledWithdrawContainer>
      </StyledContentsContainer>
      {showWithdrawModal && <ModalWithdraw showWithdrawModal={withdrawModalToggle} />}
    </>
  );
}
