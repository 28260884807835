import { CollegesEnum } from '../constants/College';
import { QUESTIONNAIRE_URLS } from '../constants/Storage';

export const getUniversityRef = (college: CollegesEnum) => {
  switch (college) {
    case CollegesEnum.NUS:
      return QUESTIONNAIRE_URLS.QUESTIONNAIRE_REF_NUS;
    case CollegesEnum.NTU:
      return QUESTIONNAIRE_URLS.QUESTIONNAIRE_REF_NTU;
    case CollegesEnum.SMU:
      return QUESTIONNAIRE_URLS.QUESTIONNAIRE_REF_SMU;
    case CollegesEnum.HARVARD:
      return QUESTIONNAIRE_URLS.QUESTIONNAIRE_REF_HARVARD;
    case CollegesEnum.WESTERN:
      return QUESTIONNAIRE_URLS.QUESTIONNAIRE_REF_WESTERN;
    case CollegesEnum.UWATERLOO:
      return QUESTIONNAIRE_URLS.QUESTIONNAIRE_REF_UW;
    case CollegesEnum.UOFT:
      return QUESTIONNAIRE_URLS.QUESTIONNAIRE_REF_UOFT;
    case CollegesEnum.UBC:
      return QUESTIONNAIRE_URLS.QUESTIONNAIRE_REF_UBC;
    case CollegesEnum.MCMASTER:
      return QUESTIONNAIRE_URLS.QUESTIONNAIRE_REF_MAC;
    case CollegesEnum.UCLA:
      return QUESTIONNAIRE_URLS.QUESTIONNAIRE_REF_UCLA;
    case CollegesEnum.MIT:
      return QUESTIONNAIRE_URLS.QUESTIONNAIRE_REF_MIT;
    case CollegesEnum.CMU:
      return QUESTIONNAIRE_URLS.QUESTIONNAIRE_REF_CMU;
    case CollegesEnum.HKU:
      return QUESTIONNAIRE_URLS.QUESTIONNAIRE_REF_HKU;
    default:
      throw new Error(
        `College: ${college} isn't registered in Aphrodite. Please email the Aphrodite team to let us know that this error occured.`,
      );
  }
};
