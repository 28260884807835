/**
 * Route that should only be shown if the user is not authenticated.
 */
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useAuthHelper } from '@aphrodite/common/hooks';

const DEFAULT_AUTHENTICATED_PATH = '/dashboard';

export type PublicRouteProps = {
  authenticatedPath?: string;
} & RouteProps;

export default function PublicRoute({
  authenticatedPath = DEFAULT_AUTHENTICATED_PATH,
  ...routeProps
}: PublicRouteProps) {
  const { user } = useAuthHelper();
  if (user) {
    return (
      <Redirect
        to={{
          pathname: '/dashboard',
          state: { from: routeProps.location },
        }}
      />
    );
  } else {
    return <Route {...routeProps} />;
  }
}
