import { useLayoutEffect, useState } from 'react';

const TARGET_SIZES = {
  MOBILE: 680,
  TABLET: 1024,
};

type TargetScreenSize = 'MOBILE' | 'TABLET';

export default function useIsMobile(targetScreenSize: TargetScreenSize) {
  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useLayoutEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  return width < TARGET_SIZES[targetScreenSize];
}
