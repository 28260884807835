import { KeyboardEvent } from 'react';
import styled from 'styled-components';

// Styled Components
const StyledInput = styled.input`
  border: none;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.transparentPurple}`};
  background: none;
  color: ${(props) => props.theme.colors.darkPurple};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  height: 40px;
  hyphens: auto;
  line-height: 28px;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: normal;
  width: 100%;
  &:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    border-bottom: ${({ theme }) => `2px solid ${theme.colors.darkPurple}`};
  }
  @media only screen and (min-width: 680px) {
    font-size: 22px;
    height: 44px;
    line-height: 34px;
  }
`;

export interface Props {
  //handleSetResponse: (inputAnswer: string) => void;
  className?: string;
  handleSetResponse: (inputAnswer: string) => void;
  onClickNextWithValidation: () => void;
  value: string;
  validation: {
    required?: boolean;
    max_value?: number;
    min_value?: number;
  };
}
export default function QuestionShortNumber({
  className,
  handleSetResponse,
  onClickNextWithValidation,
  value,
  validation: { max_value = Number.MAX_SAFE_INTEGER, min_value = 0 },
}: Props): React.ReactElement {
  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'e' || e.key === '.' || e.key === '-' || e.key === '+') {
      e.preventDefault();
      return;
    }
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onClickNextWithValidation();
    }
  };
  return (
    <StyledInput
      className={className}
      max={max_value}
      min={min_value}
      maxLength={2}
      onChange={(e) => {
        const regex = /^[0-9\b]+$/;
        if (!e.target.validity.valid) {
          return;
        }
        if (e.target.value === '' || regex.test(e.target.value)) {
          handleSetResponse(e.target.value);
        }
      }}
      onKeyDown={handleKeyPress}
      pattern="\d*"
      placeholder="Type your answer here..."
      type="number"
      value={value ?? ''}
    />
  );
}
