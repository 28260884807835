import React from 'react';

import { ADSPage } from '@aphrodite/common-ui';

import Navbar from '../components/Navbar';
import SafetyPageList from '../components/landing/safetyPage/SafetyPageList';

export default function PageSafetyResources(): React.ReactElement {
  return (
    <ADSPage>
      <Navbar />
      <SafetyPageList />
    </ADSPage>
  );
}
