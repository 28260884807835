import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { ADSButton, ADSCard, ADSHeading, ADSText } from '@aphrodite/common-ui';
import { useIsSingaporeWebsite } from '@aphrodite/common/hooks';

import aphroHeartImg from '../../assets/images/aphrodite_braided_hair_hearts.png';
import { QuestionnaireStatusType } from '../../constants/Questionnaire';

// Styled Components
const StyledADSCard = styled(ADSCard)`
  display: inline-flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 4%;
  height: max-content;
  background-image: url(${aphroHeartImg});
  background-size: 45%;
  background-position: right bottom;
  background-repeat: no-repeat;
  @media only screen and (min-width: 540px) {
    background-size: 30%;
  }
`;
const StyledContainer = styled.div`
  margin-left: 10px;
  @media only screen and (min-width: 768px) {
    margin-left: 40px;
  }
`;
const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`;
const StyledText = styled(ADSText)`
  margin-top: 20px;
  margin-right: 30%;
  white-space: pre-line;
`;
const StyledRedText = styled(ADSText)`
  margin-top: 5px;
  margin-bottom: 20px;
`;

interface StatusData {
  to?: string;
  caption: string;
  buttonCaption?: string;
  title: string;
}

interface Props {
  className?: string;
  status: 'notStarted' | 'incomplete' | 'complete' | 'matchesOut';
}

// Main Component
export default function DashboardCardStatus({ className, status }: Props): React.ReactElement {
  const isSingaporeWebsite = useIsSingaporeWebsite();
  const STATUS_DATA: {
    [key in QuestionnaireStatusType]: StatusData;
  } = {
    notStarted: {
      title: 'Get Started',
      caption:
        'Take our questionnaire and get matched by our algorithm built upon psychology and an economics nobel prize algorithm!',
      buttonCaption: 'Count me in!',
      to: '/dashboard/questionnaire',
    },
    incomplete: {
      title: 'Resume Questionnaire',
      caption:
        "Complete the questionnaire and leave the match-making to us. \nLet's jump back in!\n",
      buttonCaption: 'Continue',
      to: '/dashboard/questionnaire',
    },
    complete: {
      title: "You're all set!",
      caption: `Sit tight and wait while we crunch the data! Matches will be out by the end of ${
        isSingaporeWebsite ? 'September 19' : 'February 13'
      } \n`,
    },
    matchesOut: {
      title: 'Matches are out!',
      caption:
        "It is the moment you've been waiting for! Without further ado, we present to you -- your match! \n",
      buttonCaption: 'Reveal result!',
      to: '/dashboard/results',
    },
  };

  return (
    <StyledADSCard className={className}>
      <StyledContainer>
        <ADSHeading level="3" weight="semibold">
          {STATUS_DATA[status].title}
        </ADSHeading>
        <StyledText size="s" hyphensNone={true}>
          {STATUS_DATA[status].caption} <br />
        </StyledText>
        <StyledRedText color="aphroRedDark" size="s">
          {' '}
          <i>-- Aphrodite</i>{' '}
        </StyledRedText>
        {STATUS_DATA[status].buttonCaption && STATUS_DATA[status].to && (
          <StyledNavLink to={STATUS_DATA[status].to as string}>
            <ADSButton>{STATUS_DATA[status].buttonCaption}</ADSButton>
          </StyledNavLink>
        )}
      </StyledContainer>
    </StyledADSCard>
  );
}
