import Img01 from '../assets/images/landing_stories_01.png';
import Img02 from '../assets/images/landing_stories_02.png';
import Img03 from '../assets/images/landing_stories_03.png';

export const GLOBAL_STORIES_2023 = [
  {
    titleLine: '“I am now ENGAGED!”',
    messageLines:
      '“Neither of us could have imagined just how perfect we are for each other... Thank you for your life changing services.”',
    uni: 'University of Toronto',
    isLove: true,
    imgUrl: Img01,
  },
  {
    titleLine: '“Your algorithm may have contributed to a decades-long friendship”',
    messageLines:
      '““The first person I was matched with went on to become my roommate in 2022 during both of our third years.”',
    uni: 'University of Toronto',
    isLove: false,
    imgUrl: Img02,
  },
  {
    titleLine: '“I reckon your algorithm is quite accurate:)” ',
    messageLines:
      '“My partner and I met in person for the first time three years ago. Thank you for your project! Life would’ve been quite different without it”',
    uni: 'National University of Singapore',
    isLove: true,
    imgUrl: Img03,
  },
];
