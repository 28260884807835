import React from 'react';
import { useFirestoreDocData } from 'reactfire';
import styled from 'styled-components';

import { ReactComponent as EditIcon } from '@aphrodite/assets/icons/edit.svg';
import { ADSAnchor, ADSText } from '@aphrodite/common-ui';
import { useAuthHelper } from '@aphrodite/common/hooks';
import { IUserProfile } from '@aphrodite/common/types/firestore-types';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';
import { DocumentReference, doc } from '@firebase/firestore';

import { COLLEGE_FULL_NAMES } from '../../constants/College';
import { PROFILE_MAJOR_REF, PROFILE_STUDY_YEAR_REF } from '../../constants/Questionnaire';
import DashboardLoading from '../dashboard/DashboardLoading';
import ProfileInfoCard from './ProfileInfoCard';
import ProfileInfoField from './ProfileInfoField';

// Styled Components
const StyledInfoContainer = styled.div`
  padding-top: 12px;
`;
const StyledText = styled(ADSText)`
  padding-top: 2px;
  padding-bottom: 2px;
`;
const StyledSmallContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding-top: 12px;
  width: 100%;
`;
const StyledEditIcon = styled(EditIcon)`
  fill: ${(props) => props.theme.colors.standardGrey40};
  height: 24px;
  width: 24px;
`;
interface Props {
  matchProfileData: IUserProfile;
  isMatchProfile: boolean;
}
export default function ProfileCollegeCard({
  matchProfileData,
  isMatchProfile,
}: Props): React.ReactElement {
  const { user } = useAuthHelper();
  const userProfileRef = doc(
    firebaseFirestore,
    `UserProfiles/${user!.uid}`,
  ) as DocumentReference<IUserProfile>;
  const { status: userProfileStatus, data: userProfileData } =
    useFirestoreDocData<IUserProfile>(userProfileRef);
  if (userProfileStatus === 'loading') {
    return <DashboardLoading />;
  }
  const studyYear = isMatchProfile
    ? matchProfileData.YOUstudyear
    : userProfileData[PROFILE_STUDY_YEAR_REF];
  const major = isMatchProfile ? matchProfileData.YOUmajor : userProfileData[PROFILE_MAJOR_REF];
  const college = matchProfileData.college;
  if (isMatchProfile) {
    return (
      <ProfileInfoCard title={COLLEGE_FULL_NAMES[college]} icon="university" isDisabled={true}>
        <StyledInfoContainer>
          <ProfileInfoField promptText="I study">
            <StyledText size="xs">{major?.label}</StyledText>
          </ProfileInfoField>
        </StyledInfoContainer>
        <StyledInfoContainer>
          <ProfileInfoField promptText="I am in">
            <StyledText size="xs">{studyYear && studyYear[0] ? studyYear[0].label : ''}</StyledText>
          </ProfileInfoField>
        </StyledInfoContainer>
      </ProfileInfoCard>
    );
  }
  return (
    <ProfileInfoCard title={COLLEGE_FULL_NAMES[college]} icon="university" isDisabled={true}>
      <StyledInfoContainer>
        <ProfileInfoField promptText="I study">
          <StyledText size="xs">{major?.label}</StyledText>
        </ProfileInfoField>
      </StyledInfoContainer>
      <StyledInfoContainer>
        <ProfileInfoField promptText="I am in">
          <StyledText size="xs">{studyYear && studyYear[0] ? studyYear[0].label : ''}</StyledText>
        </ProfileInfoField>
      </StyledInfoContainer>
      <ADSAnchor to="/dashboard/profile-questionnaire">
        <StyledSmallContainer>
          <StyledEditIcon />
          <ADSText size="xxs" color="standardGrey70" textDecoration="underline">
            Edit responses in the questionnaire.
          </ADSText>
        </StyledSmallContainer>
      </ADSAnchor>
    </ProfileInfoCard>
  );
}
