export const SINGAPORE_SCHOOL_RESOURCES = [
  {
    title: 'Resources at NTU (Nanyang Technological University)',
    content: (
      <div>
        <a
          href="https://www.ntu.edu.sg/life-at-ntu/student-life/ntu-wellbeing/student-well-being"
          target="_blank"
          rel="noopener noreferrer"
        >
          {'NTU Student Counseling'}
        </a>
        <br />
        <br />
        {'NTU Counseling Hotline: 6790 4462'}
        <br />
        <br />
        <span style={{ fontWeight: 600 }}>
          {
            'All students at NTU are eligible for counseling services at no additional cost to them.'
          }
        </span>
        {' All services are confidential.'}
        <br />
        <br />
        <a
          href="https://www.ntu.edu.sg/mae/about-us/pastoral-care/help-lines#Content_C001_Col01"
          target="_blank"
          rel="noopener noreferrer"
        >
          {'NTU Help lines'}
        </a>
        <br />
        <br />
        {
          'This help line includes contact for the NTU Police, University Counselling Centre, Campus Security, and community hotlines, free of cost to all students.'
        }
      </div>
    ),
  },
  {
    title: 'Resources at NUS (National University of Singapore)',
    content: (
      <div>
        <a
          href="https://www.nus.edu.sg/uhc/resources/articles/details/counselling-psychological-services"
          target="_blank"
          rel="noopener noreferrer"
        >
          {'NUS University Counseling Services (UCS)'}
        </a>
        <br />
        <br />
        <span style={{ fontWeight: 600 }}>
          {
            'All students at NUS are eligible for counseling services at no additional cost to them.'
          }
        </span>
        {' All services are confidential.'}
        <br />
        <br />
        {'UCS Email: ucs@nus.edu.sg'}
        <br />
        <br />
        {'UCS Phone: +6565162376'}
        <br />
        <br />
        {'UCS also provides a 24-hour Crisis Intervention Hotline at the above link.'}
      </div>
    ),
  },
  {
    title: 'Resources at SMU (Singapore Management University)',
    content: (
      <div>
        <a href="https://voices.smu.edu.sg/" target="_blank" rel="noopener noreferrer">
          Voices @ SMU
        </a>
        <br />
        <br />
        {'Voices@SMU is a resource for '}
        <span style={{ fontWeight: 600 }}>{'all students at no cost '}</span>
        {'and is there to support '}
        <span style={{ fontWeight: 600 }}>{'any concern.'}</span>
        <br />
        <br />
        {"SMU's Campus Security: +6568280343"}
        <br />
        <br />
        <a
          href="https://www.smu.edu.sg/campus-life/health-and-safety/student-wellness/student-wellness"
          target="_blank"
          rel="noopener noreferrer"
        >
          {'SMU Counseling Appointments'}
        </a>
        <br />
        <br />
        <span style={{ fontWeight: 600 }}>
          {
            'All students at SMU are eligible for counseling services at no additional cost to them.'
          }
        </span>
        {' All services are confidential.'}
        <br />
        <br />
        <a
          href="https://www.smu.edu.sg/campus-life/health-and-safety/student-wellness/community-resources"
          target="_blank"
          rel="noopener noreferrer"
        >
          {'SMU Community Resources'}
        </a>
        <br />
        <br />
        {
          'This community resources page lists service hotlines you can call in Singapore free of cost.'
        }
      </div>
    ),
  },
];
