export const isEmulatorEnabled = false;
export const emulatorConfig = {
  auth: 9099,
  firestore: 8000,
  functions: 5001,
  hosting: 4001,
  storage: 9199,
  pubsub: 8085,
  database: 9000,
};
