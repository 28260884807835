//import classNames from 'classnames';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

import linkedinLogo from '@aphrodite/assets/logos/linkedin-logo.svg';
import { ADSAnchor, ADSHeading, ADSPage, ADSText } from '@aphrodite/common-ui';

import Navbar from '../components/Navbar';
import { coreMemberInfo } from '../constants/aboutUsConstants';

// Styled Components
const StyledImage = styled.img`
  border-radius: 16px;
  min-width: 80vw;
  width: 100%;
  @media only screen and (min-width: 680px) {
    min-width: 0;
  }
`;
const StyledLogo = styled.img`
  width: 16px;
  height: 16px;
`;
const StyledContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 100%;
  padding-left: 8%;
  padding-right: 8%;
  margin-top: 8%;
  @media only screen and (min-width: 680px) {
    margin-top: 6%;
    padding: 0 10%;
  }
`;
const StyledSection = styled.div`
  margin-top: 24px;
`;
const StyledADSText = styled(ADSText)`
  margin-top: 24px;
`;
const StyledCardSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 12px;
  flex-wrap: wrap;
  @media only screen and (min-width: 680px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    flex-wrap: wrap;
    &:after {
      content: '';
      flex: 0 0 21%;
    }
  }
`;
const StyledInfoCard = styled.div`
  max-width: 100%;
  margin-bottom: 1.5em;
  margin-top: 12px;
  @media only screen and (min-width: 680px) {
    flex: 1 0 21%;
    max-width: 21%;
    margin-top: 20px;
  }
`;
// Main Component
interface InfoCardProps {
  imgSrc: string;
  linkedin?: string;
  name: string;
  role?: string;
  universities: string[];
}

const InfoCard: FunctionComponent<InfoCardProps> = ({
  imgSrc,
  linkedin,
  name,
  role,
  universities,
}) => {
  return (
    <StyledInfoCard>
      <StyledImage src={imgSrc} />
      <ADSText size="m" weight="semibold">
        {name}
      </ADSText>
      {universities.map((uni) => (
        <ADSText
          color="standardGrey80"
          key={uni}
          hyphensNone={true}
          size="xs"
          wrapperStyle={{ display: 'block' }}
        >
          {uni}
        </ADSText>
      ))}
      <br />
      <ADSText color="standardGrey80" size="xs">
        {role}
      </ADSText>
      {linkedin ? (
        <ADSAnchor target="_blank" to={linkedin}>
          <StyledLogo src={linkedinLogo} alt="linkedin" />
        </ADSAnchor>
      ) : null}
    </StyledInfoCard>
  );
};

const PageAboutUs: FunctionComponent<{}> = () => {
  return (
    <ADSPage fadeIn={true}>
      <Navbar />
      <StyledContainer>
        <ADSHeading hyphensNone={true} level="1">
          about us
        </ADSHeading>
        <StyledADSText hyphensNone={true} size="s">
          We're a bunch of passionate people just trying to help build a more loving, inclusive
          world. Meet the team behind the Aphrodite Project!
        </StyledADSText>
        <StyledSection>
          <ADSText size="m" weight="semibold">
            The Core Team
          </ADSText>
          <StyledCardSection>
            {coreMemberInfo.map((info, i) => {
              return (
                <InfoCard
                  imgSrc={info.imgSrc}
                  linkedin={info.linkedin}
                  name={info.name}
                  role={info.role}
                  universities={info.university}
                  key={i}
                />
              );
            })}
            <StyledInfoCard />
          </StyledCardSection>
        </StyledSection>
      </StyledContainer>
    </ADSPage>
  );
};

export default PageAboutUs;
