import saporeImg from '../assets/partners/sapore.png';

export interface IPartner {
  company: string;
  companyLogo: string;
  howToClaim: string[];
  termsAndConditions: string[];
  title: string;
  // Claim methods
  requireMatch?: boolean;
  requireMatchCode?: boolean;
  promoCode?: string;
  url?: string;
}

export const PARTNER_DETAILS: IPartner[] = [
  {
    company: 'Sapore',
    companyLogo: saporeImg,
    howToClaim: ['Flash this card when you dine in with your match at Sapore (NUS UTown)!'],
    termsAndConditions: [
      'Valid from 19 September 2022 to 20 October 2022.',
      'The free item must be priced at an equal or lower amount than the chargeable item. Handmade pastas and items with seafood will incur an additional charge of $2 per dish.',
      'Applicable to all ala-carte items only.',
      'Only valid to dine-in orders. Not valid with any other promotional offers.',
      'Promotion is strictly for Aphrodite Project matches only. Both parties are required to present their Aphrodite Project match code (via the Aphrodite Project portal) for verification purposes. Screenshots are not allowed.',
    ],
    title: '1-for-1 pastas, pizzas, risottos, salads, desserts, and drinks',
    requireMatch: true,
    requireMatchCode: true,
  },
];
