import { NavLink } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';
import styled from 'styled-components';

import QuestionMark from '@aphrodite/assets/icons/question-mark.png';
import { ADSButton } from '@aphrodite/common-ui';
import { ADSHeading } from '@aphrodite/common-ui';
import { ADSText } from '@aphrodite/common-ui';
import { useAuthHelper } from '@aphrodite/common/hooks';
import { useIsMatchesReleased } from '@aphrodite/common/hooks';
import { IMatchResult } from '@aphrodite/common/types/firestore-types';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';
import { doc } from '@firebase/firestore';

import { PARTNER_DETAILS } from '../../constants/Partners';
import PartnershipCard from '../partnership/PartnershipCard';
import DashboardLoading from './DashboardLoading';

const StyledContainer = styled.div`
  max-width: 100%;
  display: flex;
  padding: 20% 8% 8% 8%;
  flex-direction: column;
  justify-content: center;
  @media only screen and (min-width: 1024px) {
    margin-top: ${({ theme }) => `${parseFloat(theme.spacing.navbarDesktopLogoHeight) * 0.25}px`};
    margin-left: ${({ theme }) => theme.spacing.navbarDesktopWidth};
    padding: 0 4%;
  }
`;
const StyledFlexDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 40px;
  b .button {
    margin-top: 20px;
  }
`;
const StyledImg = styled.img`
  height: 48px;
  margin-bottom: 30px;
  margin-top: 30px;
  width: 48px;
  @media only screen and (min-width: 1024px) {
    height: 65px;
    width: 65px;
  }
`;
const StyledNavLink = styled(NavLink)`
  max-width: fit-content;
  text-decoration: none;
  margin-top: 24px;
`;
const StyledPartnershipDiv = styled.div`
  margin-top: 12px;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  @media only screen and (max-width: 1024px) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
const StyledText = styled(ADSText)`
  margin-bottom: 5px;
  @media only screen and (min-width: 1024px) {
    font-size: 30px;
    line-height: 39px;
  }
`;

const SHOW_PARTNERSHIP_DETAILS = true;

export default function DashboardTabPartnership(): React.ReactElement {
  const { user } = useAuthHelper();
  const { status: matchesOutFlagStatus, flag: matchesOutFlag } = useIsMatchesReleased();
  const matchResultRef = doc(firebaseFirestore, `MatchResult/${user!.uid}`);
  const { status: matchResultStatus, data: matchResultData } =
    useFirestoreDocData<IMatchResult>(matchResultRef);

  if (matchResultStatus === 'loading' || matchesOutFlagStatus === 'loading') {
    return <DashboardLoading />;
  }
  const { codeA, codeB } = matchResultData ?? {
    codeA: undefined,
    codeB: undefined,
  };
  const renderPartnershipDetails = () => {
    if (!SHOW_PARTNERSHIP_DETAILS) {
      return (
        <>
          <StyledFlexDiv>
            <StyledImg src={QuestionMark} alt="" className="img" />
            <StyledText
              color="standardGrey70"
              size="m"
              inline={true}
              className="text"
              textAlign="center"
              weight="light"
            >
              Look out for deals for you and your match coming soon!
            </StyledText>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <ADSText textAlign="center" size="xs" hyphensNone={true} color="black80">
              Haven't completed the questionnaire yet? <br /> Let’s do that first!
            </ADSText>
            <StyledNavLink to="/dashboard/questionnaire">
              <ADSButton>To the questionnaire!</ADSButton>
            </StyledNavLink>
          </StyledFlexDiv>
        </>
      );
    }
    return (
      <StyledPartnershipDiv>
        {PARTNER_DETAILS.map((partnerDetail, i) => (
          <PartnershipCard
            codeA={codeA}
            codeB={codeB}
            key={i}
            isMatchResultsReleased={matchesOutFlag as boolean}
            partnerDetails={partnerDetail}
          />
        ))}
      </StyledPartnershipDiv>
    );
  };
  return (
    <StyledContainer>
      <ADSHeading level="2" wrapperStyle={{ marginTop: '4%' }}>
        Deals for Two
      </ADSHeading>
      <ADSText size="xs" color="standardGrey70" hyphensNone={true}>
        Our partners are here to improve the post-matching experience for you and your match! We
        aren't earning from any of these deals.
      </ADSText>
      {renderPartnershipDetails()}
    </StyledContainer>
  );
}
