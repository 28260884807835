import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';
import styled from 'styled-components';

import { ADSButton } from '@aphrodite/common-ui';
import { ADSCard } from '@aphrodite/common-ui';
import { ADSHeading } from '@aphrodite/common-ui';
import { ADSText } from '@aphrodite/common-ui';
import {
  QUESTIONNAIRE_CLOSE_DATE_GLOBAL,
  QUESTIONNAIRE_CLOSE_DATE_SINGAPORE,
} from '@aphrodite/common/constants/Dates';
import { useAuthHelper, useIsQuestionnaireClosed } from '@aphrodite/common/hooks';
import { useIsMatchesReleased, useIsSingaporeWebsite } from '@aphrodite/common/hooks';
import { IUserProfile } from '@aphrodite/common/types/firestore-types';
import { firebaseStorage } from '@aphrodite/firebase/firebase';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';
import { DocumentReference, doc } from '@firebase/firestore';
import { getDownloadURL, ref } from '@firebase/storage';

import { IQuestionnaire } from '../../constants/Questionnaire';
import { QuestionnaireStatusType } from '../../constants/Questionnaire';
import { getQuestionnaireStatus } from '../../helpers/questionnaire';
import { getUniversityRef } from '../../helpers/university';
import DashboardLoading from '../dashboard/DashboardLoading';

const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  padding: 60px 8%;
  height: 100vh;
  @media only screen and (min-width: 1024px) {
    padding: 60px 12%;
  }
`;
const StyledContainerCard = styled(ADSCard)`
  width: 100%;
  padding: 4% 12%;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
  @media only screen and (min-width: 1024px) {
    padding: 8% 20%;
  }
`;
interface QuestionnaireStatusData {
  buttonCaption?: string;
  title: string;
  description: string;
  to?: string;
}
export default function QuestionnaireStatusScreen(): React.ReactElement {
  const { user } = useAuthHelper();
  const isSingaporeWebsite = useIsSingaporeWebsite();
  const isQuestionnaireClosed = useIsQuestionnaireClosed();
  const [questionnaire, setQuestionnaire] = useState<IQuestionnaire | null>(null);
  const userProfileRef = doc(
    firebaseFirestore,
    `UserProfiles/${user!.uid}`,
  ) as DocumentReference<IUserProfile>;
  const userQuestionnaireRef = doc(
    firebaseFirestore,
    `Questionnaire/${user!.uid}`,
  ) as DocumentReference<any>;
  const { status: userProfileStatus, data: userProfileData } =
    useFirestoreDocData<IUserProfile>(userProfileRef);
  const { status: userQuestionnaireStatus, data: userQuestionnaireData } =
    useFirestoreDocData<any>(userQuestionnaireRef);
  const { status: matchesOutFlagStatus, flag: matchesOutFlag } = useIsMatchesReleased();
  useEffect(() => {
    let unmounted = false;
    if (userProfileStatus === 'loading') {
      return;
    }
    const { college } = userProfileData;
    const storageRef = ref(firebaseStorage, getUniversityRef(college));
    getDownloadURL(storageRef).then((url) => {
      axios.get(url).then((response) => {
        if (!unmounted) setQuestionnaire(response.data);
      });
    });
    return () => {
      unmounted = true;
    };
  }, [userProfileData, userProfileStatus]);
  if (
    matchesOutFlagStatus === 'loading' ||
    userProfileStatus === 'loading' ||
    userQuestionnaireStatus === 'loading' ||
    !questionnaire
  ) {
    return <DashboardLoading />;
  }
  const STATUS_DATA: {
    [key in QuestionnaireStatusType]: QuestionnaireStatusData;
  } = {
    notStarted: {
      buttonCaption: 'Get Started',
      title: 'Get Started',
      to: '/dashboard/questionnaire/questions',
      description:
        "You haven't started the questionnaire!\n\nTake our questionnaire and get matched by our algorithm built upon psychology and an economics nobel prize algorithm!",
    },
    incomplete: {
      buttonCaption: 'Resume',
      title: 'Resume Questionnaire',
      to: '/dashboard/questionnaire/questions?resume=true',
      description: `You're not done!\n\nComplete the questionnaire by ${
        isSingaporeWebsite
          ? QUESTIONNAIRE_CLOSE_DATE_SINGAPORE.format('MMMM Do, HH:mma')
          : QUESTIONNAIRE_CLOSE_DATE_GLOBAL.format('MMMM Do, HH:mma')
      } to get a match.`,
    },
    complete: {
      buttonCaption: 'Edit Response',
      title: "You're all set!",
      to: '/dashboard/questionnaire/questions',
      description: `Sit tight and wait while we crunch the data!\n\nMatches will be out by the end of ${
        isSingaporeWebsite ? 'September 19' : 'February 13'
      }!`,
    },
    matchesOut: {
      buttonCaption: 'View Response',
      title: 'View your questionnaire response',
      to: '/dashboard/questionnaire/questions',
      description: 'Matches are out!\n\nYou can still view your questionnaire response here.',
    },
  };
  const questionnaireCompletionStatus = getQuestionnaireStatus(
    userProfileData,
    userQuestionnaireData,
    questionnaire.fields,
  );
  const aphroditeStatus: QuestionnaireStatusType = matchesOutFlag
    ? 'matchesOut'
    : questionnaireCompletionStatus;
  return (
    <StyledContainer>
      <StyledContainerCard backgroundColor="translucent">
        <ADSHeading
          level="2"
          wrapperStyle={{
            fontSize: '30px',
            alignItems: 'center',
            textAlign: 'center',
            backdropFilter: 'blur(6.41163px)',
            marginBottom: '16px',
            marginTop: '16px',
          }}
        >
          Questionnaire
        </ADSHeading>
        <ADSHeading
          level="3"
          wrapperStyle={{
            alignItems: 'center',
            textAlign: 'center',
            backdropFilter: 'blur(6.41163px)',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '40px',
          }}
        >
          {STATUS_DATA[aphroditeStatus].title}
        </ADSHeading>
        <ADSText
          hyphensNone={true}
          color="standardGrey70"
          size="s"
          wrapperStyle={{ whiteSpace: 'pre-line', textAlign: 'center', marginBottom: '24px' }}
        >
          {STATUS_DATA[aphroditeStatus].description}
        </ADSText>
        {STATUS_DATA[aphroditeStatus].to && STATUS_DATA[aphroditeStatus].buttonCaption && (
          <NavLink
            style={{ textDecoration: 'none', justifyContent: 'center' }}
            to={STATUS_DATA[aphroditeStatus].to as string}
          >
            <ADSButton>
              {isQuestionnaireClosed ? 'View response' : STATUS_DATA[aphroditeStatus].buttonCaption}
            </ADSButton>
          </NavLink>
        )}
      </StyledContainerCard>
    </StyledContainer>
  );
}
