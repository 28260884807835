import { ReactNode, SyntheticEvent } from 'react';
import styled, { css } from 'styled-components';

import ADSLoading from './ADSLoading';

// Mixins
const handleStyleType = (
  type:
    | 'defaultGradient'
    | 'pinkGradient'
    | 'minimalFullLength'
    | 'minimal'
    | 'plain'
    | 'defaultFullLength' = 'defaultGradient',
) => {
  switch (type) {
    case 'defaultGradient':
    case 'defaultFullLength':
      return css`
        background: #151515;
        border: 2px solid #151515;
        border-radius: 6.75469px;
        color: #ffffff;
        width: 100%;
      `;
    case 'pinkGradient':
      return css`
        background: #f0d6df;
        border: 2px solid #151515;
        border-radius: 6.75469px;
        color: #151515;
      `;
    case 'minimal':
      return css`
        background: rbga(255, 255, 255, 0);
        border: 2px solid #151515;
        border-radius: 8px;
      `;
    case 'minimalFullLength':
      return css`
        background: rbga(255, 255, 255, 0);
        border: 2px solid #151515;
        border-radius: 8px;
        width: 100%;
      `;
    case 'plain':
      return css`
        background: transparent;
      `;
  }
};
// Styled Components
const StyledButton = styled.button<{
  styleType?:
    | 'defaultGradient'
    | 'pinkGradient'
    | 'minimal'
    | 'minimalFullLength'
    | 'defaultFullLength'
    | 'plain';
}>`
  align-items: center;
  position: relative;
  top: -6px;
  left: -6px;
  z-index: 1;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.5;
  transition: all 0.15s ease-in-out;
  padding: 8px 16px;
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.standardGrey80};
    background-color: white;
  }
  ${({ styleType }) => handleStyleType(styleType)};
  @media only screen and (min-width: 680px) {
    font-size: 16px;
  }
`;
const StyledLoading = styled(ADSLoading)`
  margin: 8px 8px 0;
  vertical-align: middle;
`;
const StyledBackgroundDiv = styled.div`
  width: fit-content;
  height: auto;
  border: 2px solid #000000;
  border-radius: 8px;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
  ${(props) => {
    switch (props.className) {
      case 'minimal':
        return css`
          background: rbga(255, 255, 255, 0);
        `;
      case 'minimalFullLength':
        return css`
          background: rbga(255, 255, 255, 0);
          width: 100%;
        `;
      case 'defaultFullLength':
        return css`
          width: 100%;
          background: linear-gradient(90deg, #eba487 0%, #e68fa8 53.13%, #7a9fe3 98.97%);
        `;
      case 'plain':
        return css`
          border: none;
        `;
      default:
        return css`
          background: linear-gradient(90deg, #eba487 0%, #e68fa8 53.13%, #7a9fe3 98.97%);
        `;
    }
  }}
`;
export interface Props {
  type?: 'button' | 'submit';
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: (e: SyntheticEvent<HTMLButtonElement>) => void;
  onMouseEnter?: (e: SyntheticEvent<HTMLButtonElement>) => void;
  onMouseLeave?: (e: SyntheticEvent<HTMLButtonElement>) => void;
  styleType?:
    | 'defaultGradient'
    | 'pinkGradient'
    | 'minimal'
    | 'minimalFullLength'
    | 'defaultFullLength'
    | 'plain';
  wrapperStyle?: React.CSSProperties;
}

export default function ADSButton({
  type = 'button',
  children,
  className,
  disabled = false,
  loading,
  onClick,
  onMouseEnter,
  onMouseLeave,
  styleType = 'defaultGradient',
  wrapperStyle = {},
}: Props): React.ReactElement {
  const onButtonClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e);
    }
  };
  const onButtonMouseEnter = (e: SyntheticEvent<HTMLButtonElement>) => {
    if (onMouseEnter) {
      onMouseEnter(e);
    }
  };
  const onButtonMouseLeave = (e: SyntheticEvent<HTMLButtonElement>) => {
    if (onMouseLeave) {
      onMouseLeave(e);
    }
  };
  if (disabled) {
    return (
      <StyledButton
        className={className}
        disabled={disabled}
        role={type === 'submit' ? 'submitButton' : undefined}
        type={type}
        styleType={styleType}
        style={wrapperStyle}
      >
        {loading ? <StyledLoading color="white" size="s" /> : null}
        {children}
      </StyledButton>
    );
  }
  return (
    <StyledBackgroundDiv className={styleType}>
      <StyledButton
        className={className}
        disabled={disabled}
        onClick={onButtonClick}
        onMouseEnter={onButtonMouseEnter}
        onMouseLeave={onButtonMouseLeave}
        role={type === 'submit' ? 'submitButton' : undefined}
        type={type}
        styleType={styleType}
        style={wrapperStyle}
      >
        {loading ? (
          <StyledLoading
            color={styleType === 'defaultGradient' ? 'white' : 'blackDefault'}
            size="s"
          />
        ) : null}
        {children}
      </StyledButton>
    </StyledBackgroundDiv>
  );
}
