import CtvLogo from '../assets/images/pressLogo_ctv_news.svg';
import HardvardCrimsonLogo from '../assets/images/pressLogo_harvard_crimson.png';
import MothershipLogo from '../assets/images/pressLogo_mothership.png';
import RthkLogo from '../assets/images/pressLogo_rthk.png';
import SoapboxLogo from '../assets/images/pressLogo_soapbox.webp';
import TheRecordLogo from '../assets/images/pressLogo_the_Record.svg';
import TheBoardaryLogo from '../assets/images/pressLogo_the_boundary.jpg';
import TheMediumLogo from '../assets/images/pressLogo_the_medium.png';
import TheOctantLogo from '../assets/images/pressLogo_the_octant.png';
import TheVarsityLogo from '../assets/images/pressLogo_the_varsity.svg';

export const PressArticleInfo = [
  {
    title: 'Common Room interview - RTHK Radio 3',
    icon: 'https://www.rthk.hk/favicon.ico',
    imgSrc: 'https://www.rthk.hk/oldassets/images/rthk/radio3/common_room/9105_115.jpg',
    description:
      'Every Valentine’s Day, the Aphrodite Project helps thousands of students across the globe find a date. Kyle Tse asked its HKU project managers how its matchmaking works.',
    source: 'RTHK Radio 3',
    url: 'https://www.rthk.hk/radio/radio3/programme/common_room/episode/833325',
    logo: RthkLogo,
  },
  {
    title: 'The Aphrodite Project returns for another year - The Medium',
    icon: 'https://themedium.ca/wp-content/uploads/2021/07/cropped-COLOR_logo-2021-22-32x32.png',
    imgSrc: 'https://themedium.ca/wp-content/uploads/2022/02/2022-02-14-News-Aphrodite-Project.jpg',
    description:
      'Created by the University of Waterloo, the Aphrodite Project made its return this year on January 24—just in time for...',
    source: 'The Medium',
    url: 'https://themedium.ca/the-aphrodite-project-returns-for-another-year/',
    logo: TheMediumLogo,
  },
  {
    title: 'Love at First Match',
    icon: 'https://www.thecrimson.com/favicon.ico',
    imgSrc:
      'https://s3.amazonaws.com/thumbnails.thecrimson.com/photos/2021/02/09/201142_1348211.png.2000x1601_q95_crop-smart_upscale.png',
    description: 'Can an algorithm designed by college students help you find the one?',
    source: 'The Harvard Crimson',
    url: 'https://www.thecrimson.com/article/2021/2/11/project-aphrodite-2021/',
    logo: HardvardCrimsonLogo,
  },
  {
    title: 'Dating app matches UW students through algorithm',
    icon: 'https://static.ctvnews.ca/bellmedia/common/img/favicon.ico',
    imgSrc:
      'https://www.ctvnews.ca/polopoly_fs/1.3720391.1560538476!/httpImage/image.jpg_gen/derivatives/landscape_300/image.jpg',
    description:
      'Exchange students at the University of Waterloo have created a dating app for students.',
    source: 'CTV News',
    url: 'https://kitchener.ctvnews.ca/dating-app-matches-uw-students-through-algorithm-1.5292874',
    logo: CtvLogo,
  },
  {
    title: 'Aphrodite Project Matches Lucky Student with Northrop Frye Statue',
    icon: 'https://static.wixstatic.com/media/3ea86a_02dfd4c2ad3d4f4b93611ee1ee8ba74c%7Emv2.png/v1/fill/w_32%2Ch_32%2Clg_1%2Cusm_0.66_1.00_0.01/3ea86a_02dfd4c2ad3d4f4b93611ee1ee8ba74c%7Emv2.png',
    imgSrc:
      'https://static.wixstatic.com/media/7d0f33_f2c8a65c8319467292782a904c08cd9f~mv2.jpg/v1/fit/w_1000%2Ch_1000%2Cal_c%2Cq_80/file.jpg',
    description: 'Have you seen that body? God, the man is chiseled.',
    source: 'The Boundary',
    url: 'https://www.boundarynews.com/post/aphrodite-project-matches-lucky-student-with-northrop-frye-statue',
    logo: TheBoardaryLogo,
  },
  {
    title: 'Playing Cupid: Aphrodite Project founder on rapid growth, Covid-19 and 2021',
    icon: 'https://i0.wp.com/soapbox.sg/wp-content/uploads/2019/08/cropped-Asset-4x075.png?fit=180%2C180',
    imgSrc:
      'https://i0.wp.com/soapbox.sg/wp-content/uploads/2021/01/aprhodite-1.jpg?fit=1280%2C960&ssl=1',
    description: 'The team is working to add height into the in-depth questionnaire...',
    source: 'Soapbox.sg',
    url: 'http://soapbox.sg/2021/01/10/playing-cupid-aphrodite-project-founder-on-rapid-growth-covid-19-and-2021/',
    logo: SoapboxLogo,
  },
  {
    title: 'How an algorithmic dating service matched up 4,000 University of Waterloo students',
    icon: 'https://www.therecord.com/favicon-therecord-310.png',
    imgSrc:
      'https://images.thestar.com/P3Nv0RlQ9MHJIBh5fzC9Mb_08yw=/1200x800/smart/filters:cb(1597075777387):format(webp)/https://www.therecord.com/content/dam/therecord/news/waterloo-region/2020/08/09/how-an-algorithmic-dating-service-matched-up-4000-university-of-waterloo-students/dsc_0280_1.jpg',
    description: 'Service offers participants only one match, unlike most popular dating apps.',
    source: 'The Record',
    url: 'https://www.therecord.com/news/waterloo-region/2020/08/09/how-an-algorithmic-dating-service-matched-up-4000-university-of-waterloo-students.html?rf',
    logo: TheRecordLogo,
  },
  {
    title: 'How a matchmaking algorithm paired up thousands of hopeless U of T romantics',
    icon: '',
    imgSrc:
      'https://thevarsity.ca/wp-content/uploads/2020/06/Arts_Behind-the-Aphrodite-Project_Kristal-Menguc.jpg',
    description: 'Behind the Aphrodite Project',
    source: 'The Varsity',
    url: 'https://thevarsity.ca/2020/07/06/how-a-matchmaking-algorithm-paired-up-thousands-of-hopeless-u-of-t-romantics/',
    logo: TheVarsityLogo,
  },
  {
    title: 'From Arrow to Algorithm: The Aphrodite Project Could Find You True Love',
    icon: 'https://i0.wp.com/theoctant.org/wp-content/uploads/2015/01/OctantLogo2-54c322c6v1_site_icon.png?fit=192%2C192&ssl=1',
    imgSrc:
      'https://i0.wp.com/theoctant.org/wp-content/uploads/2019/10/header-image-e1595915243564.png?fit=702%2C429&ssl=1',
    description:
      'Come this Sunday, participants of the Aphrodite Project might be matched with their soulmates.',
    source: 'The Octant',
    url: 'https://theoctant.org/edition/issue/allposts/features/from-arrow-to-algorithm-the-aphrodite-project-could-find-you-true-love/',
    logo: TheOctantLogo,
  },
  {
    title:
      'Dating project for NUS & Yale-NUS students uses Nobel Prize-winning algorithm to find 1 ideal match',
    icon: 'https://mothership.sg/assets/images/favicon-32x32.png',
    imgSrc: 'https://static.mothership.sg/1/2019/10/nus-featured.jpg',
    description: 'Modern cupids.',
    source: 'Mothership',
    url: 'https://mothership.sg/2019/10/dating-project-nus-yale-nus-students/',
    logo: MothershipLogo,
  },
];
