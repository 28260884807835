export const VALENTINES_SCHOOL_RESOURCES = [
    {
      title: 'Resources at CMU',
      content: (
        <div>
          <span style={{ fontWeight: 600 }}>
            {
              'All students at CMU are eligible for counseling and support services at no additional cost. All services are confidential.'
            }
          </span>
          <br />
          <br />
          <a href="https://www.cmu.edu/counseling/resources/index.html" target="_blank" rel="noopener noreferrer">
            {"CMU campus support resources"}
          </a>
          <div style={{marginLeft: 10}}>
              {'• Contact number: 412-268-2922'}
          </div>
          <br />
          <a href="https://www.cmu.edu/counseling/" target="_blank" rel="noopener noreferrer">
            {'CMU counseling services'}
          </a>
          <br />
          <br />
          <a href="https://www.cmu.edu/police/" target="_blank" rel="noopener noreferrer">
            {'CMU police department'}
          </a>
          <div style={{marginLeft: 10}}>
              {'• Contact number: 412-268-2323'}
          </div>
        </div>
      ),
    },
    {
      title: 'Resources at Harvard',
      content: (
        <div>
          <span style={{ fontWeight: 600 }}>
            {
              'All students at Harvard are eligible for counseling and support services at no additional cost. All services are confidential.'
            }
          </span>
          <br />
          <br />
          <a href="https://www.harvard.edu/support-resources/" target="_blank" rel="noopener noreferrer">
            {"Harvard's student support resources page"}
          </a>
          <div style={{marginLeft: 10}}>
              {'• Contacts for your mental well-being, whether you are in immediate distress or not'}
          </div>
          <br />
          <a href="https://oge.harvard.edu/share" target="_blank" rel="noopener noreferrer">
            {'Harvard\'s SHARE counselors, with 24/7 confidential hotline'}
          </a>
          <br />
          <br />
          <a href="https://www.hupd.harvard.edu/" target="_blank" rel="noopener noreferrer">
            {'Harvard University Police Department (HUPD)'}
          </a>
          <div style={{marginLeft: 10}}>
              {'• HUPD: 617-495-1212'}
              <br/>
              {'• HUPD Longwood: 617-432-1212'}
          </div>
        </div>
      ),
    },
    {
      title: 'Resources at HKU',
      content: (
        <div>
          <span style={{ fontWeight: 600 }}>
            {
              'All students at HKU are eligible for counseling and support services at no additional cost. All services are confidential.'
            }
          </span>
          <br />
          <br />
          <a href="https://www.cedars.hku.hk/cope" target="_blank" rel="noopener noreferrer">
            {"Counselling and Person Enrichment Section (CoPE)"}
          </a>
          <div style={{marginLeft: 10}}>
              {'• Registration for counseling appointments'}
          </div>
          <br />
          <a href="https://www.cedars.hku.hk/cope/cps/reach-out-for-help" target="_blank" rel="noopener noreferrer">
            {'HKU police and hotline contacts'}
          </a>
        </div>
      ),
    },

    {
        title: 'Resources at McMaster',
        content: (
          <div>
            <span style={{ fontWeight: 600 }}>
              {
                'All students at McMaster are eligible for counseling and support services at no additional cost. All services are confidential.'
              }
            </span>
            <br />
            <br />
            <a href="https://wellness.mcmaster.ca/resources/#tab-content-student-support-services" target="_blank" rel="noopener noreferrer">
              {"McMaster student support resources"}
            </a>
            <br />
            <br />
            <a href="https://msumcmaster.ca/info/health-dental-insurance/sap/" target="_blank" rel="noopener noreferrer">
              {'McMaster student support counseling'}
            </a>
            <br />
            <br />
            <a href="https://wellness.mcmaster.ca/resources/#tab-content-phone-lines" target="_blank" rel="noopener noreferrer">
              {'McMaster student phone lines'}
            </a>
          </div>
        ),
      },


      {
        title: 'Resources at MIT',
        content: (
          <div>
            <span style={{ fontWeight: 600 }}>
              {
                'All students at MIT are eligible for counseling and support services at no additional cost. All services are confidential.'
              }
            </span>
            <br />
            <br />
            <a href="https://studentlife.mit.edu/s3" target="_blank" rel="noopener noreferrer">
              {"MIT student support services"}
            </a>
            <br />
            <br />
            <a href="https://doingwell.mit.edu/support-3/" target="_blank" rel="noopener noreferrer">
              {'MIT DoingWell support program'}
            </a>
            <br />
            <br />
            <a href="https://police.mit.edu/" target="_blank" rel="noopener noreferrer">
              {'MIT campus police'}
            </a>
            <div style={{marginLeft: 10}}>
              {'• Contact number: (617) 253-1212 or 100 from campus phones'}
          </div>
          </div>
        ),
      },


      {
        title: 'Resources at UBC',
        content: (
          <div>
            <span style={{ fontWeight: 600 }}>
              {
                'All students at UBC are eligible for counseling and support services at no additional cost. All services are confidential.'
              }
            </span>
            <br />
            <br />
            <a href="https://students.ubc.ca/health" target="_blank" rel="noopener noreferrer">
              {"UBC health and well-being services"}
            </a>
            <br />
            <br />
            <a href="https://students.ubc.ca/health/counselling-services" target="_blank" rel="noopener noreferrer">
              {'UBC counseling services'}
            </a>
            <br />
            <br />
            <a href="https://security.ubc.ca/" target="_blank" rel="noopener noreferrer">
              {'UBC campus security'}
            </a>
            <div style={{marginLeft: 10}}>
              {'• Contact number: 604-822-2222 (24/7)'}
            </div>
          </div>
        ),
      },

      {
        title: 'Resources at UCLA',
        content: (
          <div>
            <span style={{ fontWeight: 600 }}>
              {
                'All students at UCLA are eligible for counseling and support services at no additional cost. All services are confidential.'
              }
            </span>
            <br />
            <br />
            <a href="https://www.studentaffairs.ucla.edu/guidebook/safety-and-emergency-services" target="_blank" rel="noopener noreferrer">
              {"UCLA safety and support resources"}
            </a>
            <br />
            <br />
            <a href="https://counseling.ucla.edu/" target="_blank" rel="noopener noreferrer">
              {'UCLA counseling services'}
            </a>
            <br />
            <br />
            <a href="https://police.ucla.edu/" target="_blank" rel="noopener noreferrer">
              {'UCLA police department'}
            </a>
          </div>
        ),
      },


      {
        title: 'Resources at U of T',
        content: (
          <div>
            <span style={{ fontWeight: 600 }}>
              {
                'All students at UofT are eligible for counseling and support services at no additional cost. All services are confidential.'
              }
            </span>
            <br />
            <br />
            <a href="https://safety.utoronto.ca/" target="_blank" rel="noopener noreferrer">
              {'UofT mental health and campus security page'}
            </a>
            <div style={{marginLeft: 10}}>
              {'• Contains links to mental health support, counseling, and campus police'}
            </div>
          </div>
        ),
      },


      {
        title: 'Resources at UWaterloo',
        content: (
          <div>
            <span style={{ fontWeight: 600 }}>
              {
                'All students at UWaterloo are eligible for counseling and support services at no additional cost. All services are confidential.'
              }
            </span>
            <br />
            <br />
            <a href="https://uwaterloo.ca/campus-wellness/get-mental-health-support-when-you-need-it" target="_blank" rel="noopener noreferrer">
              {'UWaterloo mental health resources page'}
            </a>
            <br />
            <br />
            <a href="https://uwaterloo.ca/campus-wellness/counselling-services" target="_blank" rel="noopener noreferrer">
              {'UWaterloo counseling services page'}
            </a>
            <br />
            <br />
            <a href="https://uwaterloo.ca/special-constable-service/" target="_blank" rel="noopener noreferrer">
              {'UWaterloo campus police contact'}
            </a>      
          </div>
        ),
      },

      {
        title: 'Resources at Western University',
        content: (
          <div>
            <span style={{ fontWeight: 600 }}>
              {
                'All students at Western University are eligible for counseling and support services at no additional cost. All services are confidential.'
              }
            </span>
            <br />
            <br />
            <a href="https://www.uwo.ca/health//psych/index.html" target="_blank" rel="noopener noreferrer">
              {'Western mental health resources page'}
            </a>
            <br />
            <br />
            <a href="https://www.uwo.ca/health//psych/book_an_appointment.html" target="_blank" rel="noopener noreferrer">
              {'Western counseling services page'}
            </a>
            <br />
            <br />
            <a href="https://uwo.ca/campussafety/about/contact_us1.html" target="_blank" rel="noopener noreferrer">
              {'Western campus police contact'}
            </a>      
          </div>
        ),
      },

];
  