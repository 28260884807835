import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ADSPageLoading } from '@aphrodite/common-ui';
import { useAuthHelper } from '@aphrodite/common/hooks';

export default function PageConfirmEmail() {
  const history = useHistory();
  const location = useLocation();
  const { signInWithEmailLink } = useAuthHelper();

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        await signInWithEmailLink(location.search);
        history.push('/onboarding');
      } catch (error: any) {
        alert(
          `An error occured: ${error.message}. We have to redirect you to reconfirm your email address.`,
        );
        history.push('/start');
      }
    };
    confirmEmail();
  }, [history, location.search, signInWithEmailLink]);
  return <ADSPageLoading />;
}
