import styled, { css, keyframes } from 'styled-components';

// Mixins
function blinkingEffect(theme: any) {
  return keyframes`
    50% {
      border: 2px solid ${theme.colors.transparentPurple};
      background-color: ${theme.colors.transparentPalePurple};
      color: ${theme.colors.transparentPurple};
    }
  `;
}

// Styled Components
const StyledChoiceButton = styled.button<{
  isSelected: boolean;
  isHighlighted?: boolean;
  additionalStyles?: any;
}>`
  animation-name: ${(props) => (props.isSelected ? blinkingEffect(props.theme) : undefined)};
  animation-delay: 0s;
  animation-direction: normal;
  animation-duration: 0.25s;
  animation-fill-mode: none;
  animation-iteration-count: 2;
  animation-play-state: running;
  animation-timing-function: ease;
  background: ${(props) =>
    props.isHighlighted || props.isSelected
      ? props.theme.gradients.selectedButtonGradient
      : props.theme.colors.palePinkHighlight};
  border: ${(props) => (props.isSelected ? '2px' : '1px')} solid;
  border-color: ${(props) => (props.isSelected ? props.theme.gradients.darkGradient : undefined)};
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  margin-bottom: 8px;
  min-width: 76px;
  padding: ${(props) => (props.isSelected ? '6px' : '7px')};
  text-align: left;
  vertical-align: middle;
  -webkit-box-align: center;
  &:hover {
    background-color: ${(props) => props.theme.colors.palePinkHighlightTransparent};
  }
  @media only screen and (max-width: 680px) {
    font-size: 16px;
  }
  ${(props) =>
    props.additionalStyles &&
    css`
      ${props.additionalStyles}
    `}
`;
interface Props {
  children: React.ReactNode;
  isSelected: boolean;
  isHighlighted?: boolean;
  additionalStyles?: any;
  onClick: () => void;
}

export default function QuestionChoiceButton({
  children,
  isSelected,
  isHighlighted,
  additionalStyles,
  onClick,
}: Props): React.ReactElement {
  return (
    <StyledChoiceButton
      isSelected={isSelected}
      isHighlighted={isHighlighted}
      additionalStyles={additionalStyles}
      onClick={onClick}
    >
      {children}
    </StyledChoiceButton>
  );
}
