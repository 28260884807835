import assert from 'assert';
import React from 'react';
import { useFirestoreDocData } from 'reactfire';
import styled from 'styled-components';

import pinkGradientHeart from '@aphrodite/assets/icons/pink-gradient-heart.png';
import { ADSModal, ADSText } from '@aphrodite/common-ui';
import { useAuthHelper } from '@aphrodite/common/hooks';
import { IMatchResult } from '@aphrodite/common/types/firestore-types';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';
import { doc } from '@firebase/firestore';

import DashboardLoading from './dashboard/DashboardLoading';

const StyledHeartDivider = styled.img`
  height: 24px;
  margin: 16px auto 16px auto;
  display: block;
`;
const StyledXLText = styled(ADSText)`
  font-size: 32px;
  line-height: 48px;
  background: -webkit-linear-gradient(0deg, #eba487, #e68fa8, #7a9fe3);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media only screen and (min-width: 1024px) {
    font-size: 45px;
    line-height: 67px;
  }
`;
interface Props {
  showPopupToggle: () => void;
  matchID: string;
}
export default function MatchResultPopup({ showPopupToggle, matchID }: Props): React.ReactElement {
  const { user } = useAuthHelper();
  const matchResultRef = doc(firebaseFirestore, `MatchResult/${user!.uid}`);
  const { status, data } = useFirestoreDocData<IMatchResult>(matchResultRef);
  if (status === 'success') {
    const poolSize = data.poolSize;
    const matchRank = matchID === data.matchA ? data.rankA : data.rankB;
    assert(matchRank);
    assert(poolSize);
    return (
      <ADSModal title={'Match Rank'} cancelToggle={showPopupToggle}>
        <StyledXLText
          weight="semibold"
          size="l"
          textAlign={'center'}
          wrapperStyle={{ marginTop: '12px', marginBottom: '4px' }}
        >
          {`${matchRank}  out of  ${poolSize}`}
        </StyledXLText>
        <ADSText
          size="xs"
          color={'standardGrey70'}
          textAlign={'center'}
          wrapperStyle={{ marginTop: '4px', marginBottom: '16px' }}
        >
          that's the top
          <span style={{ color: '#000000', fontWeight: 600 }}>
            {` ${((matchRank / poolSize) * 100).toFixed(2)}% `}
          </span>
          of all possible matches!
        </ADSText>
        <ADSText size="xs" weight={'semibold'} textAlign={'center'}>
          How do we derive this?
        </ADSText>
        <ADSText
          hyphensNone={true}
          size="xs"
          color={'standardGrey70'}
          textAlign={'center'}
          wrapperStyle={{ marginLeft: '16px', marginRight: '16px' }}
        >
          This is the average of how you and your match ranked each other relative to your entire
          matching pool based on your dealbreakers, values, personality, behaviours, and lifestyle
          preferences you and your match indicated.
        </ADSText>
        <StyledHeartDivider src={pinkGradientHeart} alt="pink gradient heart icon" />
        <ADSText
          hyphensNone={true}
          size="xs"
          color={'standardGrey70'}
          textAlign={'center'}
          wrapperStyle={{
            marginLeft: '16px',
            marginRight: '16px',
            marginBottom: '16px',
          }}
        >
          Really, this is just a number. There’s so much more to your match and we encourage you to
          explore what could turn out to be a great friendship, or something more!
        </ADSText>
        <br />
        <ADSText
          size="xxs"
          color={'standardGrey70'}
          textAlign={'center'}
          wrapperStyle={{
            marginLeft: '16px',
            marginRight: '16px',
            marginBottom: '16px',
          }}
        >
          We do not report the underlying compatibility percentage of matches to protect
          participants' privacy. This stance is meant to avoid the risk of a participant's sensitive
          questionnaire responses being inferred by their match simply from the direct compatibility
          percentage.
        </ADSText>
      </ADSModal>
    );
  }
  return <DashboardLoading />;
}
