import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';
import styled from 'styled-components';

import apLogo from '@aphrodite/assets/logos/ap.png';
import {
  ADSAnchor,
  ADSButton,
  ADSHeading,
  ADSInput,
  ADSPage,
  ADSPageLoading,
  ADSText,
} from '@aphrodite/common-ui';
import { useAuthHelper } from '@aphrodite/common/hooks';
import { useIsMobile } from '@aphrodite/common/hooks';
import { IUserProfile } from '@aphrodite/common/types/firestore-types';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';
import { DocumentReference, doc, updateDoc } from '@firebase/firestore';

import Navbar from '../components/Navbar';
import { COLLEGE_NAMES } from '../constants/College';

const StyledContentsContainer = styled.main`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 8%;
  padding-top: 60px;
  height: calc(100vh - 40px);
`;
const StyledDesktopContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  @media only screen and (min-width: 680px) {
    max-width: 60%;
    background-color: white;
    padding: 2% 4%;
    border-radius: 16px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    height: 90%;
  }
`;
const StyledImage = styled.img`
  height: 40px;
  margin-bottom: 2em;
  @media only screen and (min-width: 680px) {
    height: 52px;
  }
`;
const StyledSubText = styled(ADSText)`
  margin-top: 0.25em;
  margin-bottom: 2.5em;
`;
const StyledInputSection = styled.div`
  margin: 0.5em 0;
  width: 100%;
`;
export default function PageOnboarding(): React.ReactElement {
  const { user } = useAuthHelper();
  const history = useHistory();
  const userProfileRef = doc(
    firebaseFirestore,
    `UserProfiles/${user!.uid}`,
  ) as DocumentReference<IUserProfile>;
  const { status, data } = useFirestoreDocData<IUserProfile>(userProfileRef);
  const isMobile = useIsMobile('MOBILE');
  const [inputFirstName, setInputFirstName] = useState('');
  const [inputLastName, setInputLastName] = useState('');
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const isValidName = (name: string) => {
    return name && name.replace(/\s/g, '').length !== 0;
  };
  const handleSubmit = () => {
    setIsLoading(true);
    if (!isValidName(inputFirstName) || !isValidName(inputLastName)) {
      setIsFirstNameValid(!!inputFirstName);
      setIsLastNameValid(!!inputLastName);
      setIsLoading(false);
      return;
    }
    updateDoc(userProfileRef, {
      firstName: inputFirstName,
      lastName: inputLastName,
    })
      .then(() => {
        history.push('/onboarding/safety');
      })
      .catch(() => {
        alert(
          'Something went wrong while saving your profile, please try again. If this keeps happening, please get in touch with us!',
        );
      });
  };
  const handleLastInputEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };
  if (!user) {
    return <Redirect to="/start" />;
  }
  if (status === 'loading' || !data) {
    return <ADSPageLoading />;
  }
  const { college, firstName, lastName } = data;
  if (firstName && lastName) {
    // Onboarding process is already done, push to safety page.
    return <Redirect to="/onboarding/safety" />;
  }
  return (
    <ADSPage>
      <Navbar />
      <StyledContentsContainer>
        <StyledDesktopContainer>
          <StyledImage src={apLogo} />
          <ADSHeading level="3" textAlign="center">
            Welcome to the {isMobile ? <br /> : undefined}
            {COLLEGE_NAMES[college]}
            <br />
            Aphrodite Project!
          </ADSHeading>
          <StyledSubText color="standardGrey70" size="xs" textAlign="center">
            One last step! Let's set up your profile.
          </StyledSubText>
          <StyledInputSection>
            <ADSText weight="semibold">
              First Name<span style={{ color: '#F3909E' }}>*</span>
            </ADSText>
            <ADSInput
              isValid={isFirstNameValid}
              onChange={(e) => {
                setIsFirstNameValid(true);
                setInputFirstName(e.target.value);
              }}
              placeholder="First name"
              value={inputFirstName}
              wrapperStyle={{ marginTop: '4px' }}
            />
          </StyledInputSection>
          <StyledInputSection>
            <ADSText weight="semibold">
              Last Name<span style={{ color: '#F3909E' }}>*</span>
            </ADSText>
            <ADSInput
              isValid={isLastNameValid}
              onChange={(e) => {
                setIsLastNameValid(true);
                setInputLastName(e.target.value);
              }}
              onKeyPress={handleLastInputEnterPress}
              placeholder="Last name"
              value={inputLastName}
              wrapperStyle={{ marginTop: '4px', marginBottom: '16px' }}
            />
          </StyledInputSection>
          <ADSButton loading={isLoading} onClick={handleSubmit}>
            Continue
          </ADSButton>
          <ADSText
            color="standardGrey40"
            hyphensNone={true}
            size="xxs"
            wrapperStyle={{ marginTop: '16px', marginBottom: '32px' }}
          >
            We understand the sensitive nature of data in the Aphrodite Project and are adhering by
            a strict privacy policy{' '}
            <ADSAnchor target="_blank" to="https://bit.ly/aphropolicy2022" underline={true}>
              here
            </ADSAnchor>
            . Click "Continue" above to accept our{' '}
            <ADSAnchor target="_blank" to="https://bit.ly/aphropolicy2022" underline={true}>
              privacy policy
            </ADSAnchor>
            .
          </ADSText>
        </StyledDesktopContainer>
      </StyledContentsContainer>
    </ADSPage>
  );
}
