import styled from 'styled-components';

import { ADSText } from '@aphrodite/common-ui';

const StyledSpeechDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: inherit;
  height: fit-content;
  gap: 8px;
  overflow: hidden;
  margin-top: 30px;
  margin-bottom: 30px;
  background: #ffffff;
`;
const StyledLeftBubble = styled.div`
  display: flex;
  background: #feeff3;
  border-radius: 10px;
  align-self: flex-start;
  width: 95vw;
  margin-left: -20px;
  min-height: 9vh;
  .text {
    margin: 12px;
    margin-left: 48px;
    margin-right: 20px;
    align-self: center;
  }
  @media only screen and (min-width: 640px) {
    margin-left: 8%;
    margin-right: auto;
    width: 480px;
    min-height: 100px;
    .text {
      margin-left: 20px;
      font-size: 18px;
      line-height: 27px;
    }
  }
  @media only screen and (min-width: 1440px) {
    margin-left: 16%;
  }
`;
const StyledRightBubble = styled(StyledLeftBubble)`
  align-self: flex-end;
  background: #eaf1ff;
  margin-right: -20px;
  .text {
    margin-right: 48px;
    margin-left: 20px;
    align-self: center;
  }
  @media only screen and (min-width: 640px) {
    margin-right: 8%;
    margin-left: auto;
    min-height: 100px;
    .text {
      margin-right: 20px;
      font-size: 18px;
      line-height: 27px;
    }
  }
  @media only screen and (min-width: 1440px) {
    margin-right: 16%;
  }
`;
const StyledLeftBubbleName = styled(ADSText)`
  margin-left: 8.5%;
  margin-right: auto;
  @media only screen and (min-width: 1440px) {
    margin-left: 16%;
  }
`;
const StyledRightBubbleName = styled(ADSText)`
  margin-right: 8.5%;
  margin-left: auto;
  @media only screen and (min-width: 1440px) {
    margin-right: 16%;
  }
`;
interface Props {
  content: string;
  name?: string;
  isLeft: boolean;
}
export default function LandingSpeechBubble({ content, name, isLeft }: Props): React.ReactElement {
  return (
    <StyledSpeechDiv>
      {isLeft ? (
        <StyledLeftBubble>
          <ADSText className="text" size="xs">
            {content}
          </ADSText>
        </StyledLeftBubble>
      ) : (
        <StyledRightBubble>
          <ADSText className="text" textAlign="right" size="xs">
            {content}
          </ADSText>
        </StyledRightBubble>
      )}
      {name && isLeft ? <StyledLeftBubbleName size="xs">{name}</StyledLeftBubbleName> : <></>}
      {name && !isLeft ? <StyledRightBubbleName size="xs">{name}</StyledRightBubbleName> : <></>}
    </StyledSpeechDiv>
  );
}
