import React, { useCallback, useState } from 'react';
import { useRef } from 'react';
import { useFirestoreDocData } from 'reactfire';
import styled, { keyframes } from 'styled-components';

import { ReactComponent as EditIcon } from '@aphrodite/assets/icons/edit.svg';
import { ReactComponent as SaveIcon } from '@aphrodite/assets/icons/save.svg';
import { ADSCard, ADSHeading, ADSInput, ADSLoading, ADSText } from '@aphrodite/common-ui';
import { useDebounce } from '@aphrodite/common/hooks';
import { useAuthHelper } from '@aphrodite/common/hooks';
import { IUserProfile } from '@aphrodite/common/types/firestore-types';
import { firebaseFirestore, firebaseStorage } from '@aphrodite/firebase/firebase';
import { doc, updateDoc } from '@firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from '@firebase/storage';

import {
  PROFILE_FIRST_NAME_REF,
  PROFILE_IMG_URL,
  PROFILE_LAST_NAME_REF,
} from '../../constants/Questionnaire';
import { IQuestionAnswer } from '../../constants/Questionnaire';
import DashboardLoading from '../dashboard/DashboardLoading';
import { IStatus } from '../questionnaire/Questionnaire';

// Mixins
const fadeIn = keyframes`
  from {
    transform: translateY(-50px);
    opacity: 0;
    height: 0px;
    padding: 0px;
  }
  to {
    transform: translateY(0);
    opacity: 1;
    height: 160px;
    padding: 12px;
  }
`;
const fadeOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
    height: 160px;
    padding: 12px;
  }

  to {
    transform: translateY(-50px);
    opacity: 0;
    height: 0px;
    padding: 0;
  }
`;

// Styled Components
const StyledButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  gap: 8px;
`;
const StyledCard = styled(ADSCard)<{ visible: boolean }>`
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  height: ${(props) => (props.visible ? '220px' : 0)};
  padding: ${(props) => (props.visible ? '12px' : 0)};
  width: 100%;
  animation: ${(props) => (props.visible ? fadeIn : fadeOut)} 0.5s ease;
  transition: height 0.5s ease, visibility 0.5s ease, opacity 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  @media only screen and (min-width: 1024px) {
    flex-direction: row;
  }
`;
const StyledSmallContainer = styled(StyledContainer)`
  flex-direction: row;
  gap: 8px;
  @media only screen and (min-width: 1024px) {
    justify-content: left;
  }
`;
const StyledNameContainer = styled(StyledContainer)`
  min-width: 60%;
  gap: 12px;
  @media only screen and (min-width: 1024px) {
    align-items: start;
    flex-direction: column;
  }
`;
const StyledEditIcon = styled(EditIcon)`
  fill: ${(props) => props.theme.colors.standardGrey40};
  height: 20px;
  width: 20px;
`;
const StyledImg = styled.img`
  border-radius: 12px;
  height: 136px;
  object-fit: cover;
  width: 136px;
`;
const StyledImageCard = styled(ADSCard)`
  min-width: 136px;
  min-height: 136px;
  width: 136px;
  height: 136px;
  cursor: pointer;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-items: center;
`;
const StyledNameLabel = styled(ADSText)`
  margin-left: 8px;
  margin-bottom: 4px;
`;
const StyledNameInput = styled(ADSInput)`
  border: none;
  margin-top: 4px;
  outline: none;
  padding: 8px 12px;
  width: 100%;
`;
const StyledProfileLoading = styled(ADSLoading)`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding-left: 16px;
  padding-top: 8px;
  text-align: center;
  width: 100%;
`;
const StyledSaveIcon = styled(SaveIcon)`
  fill: ${(props) => props.theme.colors.standardGrey40};
  height: 20px;
  width: 20px;
`;
const StyledInput = styled.input`
  display: none;
  width: 100%;
  height: 100%;
`;
const StyledImageText = styled(ADSText)`
  padding: 4px;
`;
const StyledLoadingIcon = styled(ADSLoading)`
  height: 20px;
  width: 20px;
`;

interface Props {
  matchProfileData: IUserProfile;
  isMatchProfile: boolean;
}

const ACCEPTED_FILE_TYPES = 'image/png, image/gif, image/jpeg';

export default function ProfileDetailsContainer({
  matchProfileData,
  isMatchProfile,
}: Props): React.ReactElement {
  const { user } = useAuthHelper();
  const userProfileRef = doc(firebaseFirestore, `UserProfiles/${user!.uid}`);
  const { status: userProfileStatus, data: userProfileData } =
    useFirestoreDocData<any>(userProfileRef);
  const firstName = userProfileData[PROFILE_FIRST_NAME_REF];
  const lastName = userProfileData[PROFILE_LAST_NAME_REF];
  const imgURL = userProfileData[PROFILE_IMG_URL];
  const matchImgURL = matchProfileData[PROFILE_IMG_URL];
  const [editingEnabled, setEditingEnabled] = useState(false);
  const [firstNameResponse, setFirstNameResponse] = useState<IQuestionAnswer>(firstName ?? '');
  const [lastNameResponse, setLastNameResponse] = useState<IQuestionAnswer>(lastName ?? '');
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [status, setStatus] = useState<IStatus>('saved');
  const debouncedFirstNameResponse = useDebounce(firstNameResponse, 500);
  const debouncedLastNameResponse = useDebounce(lastNameResponse, 500);
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isProfilePicLoading, setIsProfilePicLoading] = useState(false);
  const isNumber = (value: IQuestionAnswer): boolean => {
    if (value.toString().match('.*\\d.*')) {
      return true;
    }
    return false;
  };
  const isEmptyName = (name: IQuestionAnswer): boolean => {
    return name.toString().replace(/\s/g, '').length === 0;
  };
  const isValidName = (name: IQuestionAnswer): boolean => {
    if (name && typeof name === 'string' && !isEmptyName(name) && !isNumber(name)) {
      return true;
    }
    return false;
  };
  const handleImageAsFile = (e: any) => {
    const image = e.target.files[0];
    if (!image) {
      return;
    }
    const profileStorageRef = ref(firebaseStorage, `/profilePictures/${user!.uid}/${image.name}`);
    const uploadTask = uploadBytesResumable(profileStorageRef, image);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        setIsProfilePicLoading(true);
      },
      (err) => {
        setIsProfilePicLoading(false);
        alert(
          `An error occured: ${err.message}. If this happens again, please contact the Aphrodite team.`,
        );
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((url) => {
            updateDoc(userProfileRef, {
              [PROFILE_IMG_URL]: url,
            });
          })
          .catch((err) => {
            alert(
              `An error occured: ${err.message}. If this happens again, please contact the Aphrodite team.`,
            );
          });
        setIsProfilePicLoading(false);
      },
    );
  };
  const handleImageClick = () => {
    if (!inputFileRef || !inputFileRef.current) {
      return;
    }
    inputFileRef.current!.click();
  };
  const renderImageCardContent = () => {
    if (isProfilePicLoading) {
      return <StyledProfileLoading />;
    }
    return (
      <>
        {imgURL && <StyledImg src={imgURL} alt="profile picture" />}
        {!imgURL && (
          <StyledImageText color="standardGrey40" size="xs" textAlign="center">
            Upload a picture or gif!
          </StyledImageText>
        )}
        <StyledInput
          accept={ACCEPTED_FILE_TYPES}
          type="file"
          ref={inputFileRef}
          onChange={handleImageAsFile}
        />
      </>
    );
  };
  const renderImage = () => {
    return <StyledImageCard onClick={handleImageClick}>{renderImageCardContent()}</StyledImageCard>;
  };
  const onStatusChange = useCallback((status: IStatus) => setStatus(status), []);
  const onToggleEditMode = () => {
    setEditingEnabled(!editingEnabled);
  };
  const onClickSave = () => {
    onStatusChange('loading');
    if (!isValidName(debouncedFirstNameResponse) || debouncedFirstNameResponse === undefined) {
      setIsFirstNameValid(false);
      return;
    } else if (!isValidName(debouncedLastNameResponse) || debouncedLastNameResponse === undefined) {
      setIsLastNameValid(false);
      return;
    }
    updateDoc(userProfileRef, {
      [PROFILE_FIRST_NAME_REF]: debouncedFirstNameResponse,
      [PROFILE_LAST_NAME_REF]: debouncedLastNameResponse,
    })
      .then(() => {
        onStatusChange('saved');
      })
      .catch((error: any) => {
        onStatusChange('error');
      });
    setEditingEnabled(false);
  };
  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsFirstNameValid(true);
    setFirstNameResponse(event.target.value);
  };
  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLastNameValid(true);
    setLastNameResponse(event.target.value);
  };
  const renderIcon = () => {
    if (!editingEnabled) {
      if (status === 'loading') {
        return <StyledLoadingIcon size="s" />;
      } else if (status === 'saved') {
        return (
          <StyledButton type="button" onClick={onToggleEditMode}>
            <StyledEditIcon />
          </StyledButton>
        );
      }
    }
    return (
      <StyledButton type="button" onClick={onClickSave}>
        <StyledSaveIcon />
        <ADSText size="xxs" color="standardGrey70">
          Save
        </ADSText>
      </StyledButton>
    );
  };
  if (userProfileStatus === 'loading') {
    return <DashboardLoading />;
  }
  if (isMatchProfile) {
    return (
      <StyledContainer>
        <StyledImageCard>
          {matchImgURL && <StyledImg src={matchImgURL} alt="profile picture" />}
          {!matchImgURL && (
            <StyledImageText color="standardGrey40" size="s" textAlign="center">
              No Profile Image
            </StyledImageText>
          )}
        </StyledImageCard>
        <StyledNameContainer>
          <ADSHeading level="2" weight="bold">
            {matchProfileData.firstName} {matchProfileData.lastName}
          </ADSHeading>
        </StyledNameContainer>
      </StyledContainer>
    );
  }
  return (
    <>
      <StyledContainer>
        {renderImage()}
        {editingEnabled ? (
          <StyledCard backgroundColor="translucent" visible={editingEnabled}>
            <StyledNameLabel size="xs" color="standardGrey80">
              First Name:
            </StyledNameLabel>
            <StyledNameInput
              type="text"
              value={firstNameResponse as string}
              onChange={(e) => handleFirstNameChange(e)}
              isValid={isFirstNameValid}
              fontSize="s"
              color="standardGrey80"
              wrapperStyle={{ height: '36px', marginBottom: '16px', overflow: 'visible' }}
            />
            <StyledNameLabel size="xs" color="standardGrey80">
              Last Name:
            </StyledNameLabel>
            <StyledNameInput
              type="text"
              value={lastNameResponse as string}
              onChange={(e) => handleLastNameChange(e)}
              isValid={isLastNameValid}
              fontSize="s"
              color="standardGrey80"
              wrapperStyle={{ height: '36px', marginBottom: '16px', overflow: 'visible' }}
            />
            <StyledSmallContainer style={{ marginLeft: '8px', marginBottom: '8px' }}>
              {renderIcon()}
            </StyledSmallContainer>
          </StyledCard>
        ) : (
          <StyledNameContainer>
            <StyledSmallContainer>
              <ADSHeading level="2" weight="bold">
                {matchProfileData.firstName} {matchProfileData.lastName}
              </ADSHeading>
              {renderIcon()}
            </StyledSmallContainer>
            <ADSText size="s" weight="semibold">
              This is your profile which will be shared with your match!
            </ADSText>
          </StyledNameContainer>
        )}
      </StyledContainer>
    </>
  );
}
