import TemplateEmailPage from './templates/TemplateEmailPage';

export default function PageSignupEmail(): React.ReactElement {
  return (
    <TemplateEmailPage
      title={({ projectName }) => `Welcome to the ${projectName} Aphrodite Project!`}
      description={({ schoolName }) =>
        `Enter your ${schoolName} email. We use school emails to ensure the integrity of the matching pool and give everyone safe matches from your college.`
      }
    />
  );
}
