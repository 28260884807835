import { KeyboardEvent, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

const handleWeight = (weight: 'light' | 'semibold' | 'regular' = 'regular') => {
  switch (weight) {
    case 'light':
      return css`
        font-weight: 300;
      `;
    case 'regular':
      return css`
        font-weight: 400;
      `;
    case 'semibold':
      return css`
        font-weight: 500;
      `;
  }
};

// Styled Components
const StyledInput = styled.textarea`
  border: none;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.transparentPurple}`};
  background: none;
  color: ${({ theme }) => `${theme.colors.darkPurple}`};
  display: block;
  font-style: normal;
  font-weight: 400;
  min-height: 44px;
  max-height: 264px;
  overflow: hidden;
  overflow-wrap: break-word;
  resize: none;
  text-align: left;
  white-space: pre-wrap;
  width: 100%;
  &:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    border-bottom: ${({ theme }) => `2px solid ${theme.colors.darkPurple}`};
  }
  @media only screen and (min-width: 680px) {
    height: 44px;
    font-size: 22px;
    line-height: 34px;
  }
`;
const StyledText = styled.span<{ weight: 'light' | 'semibold' | 'regular' }>`
  color: ${({ theme }) => theme.colors.standardGrey90};
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  ${({ weight }) => handleWeight(weight)};
`;

export interface Props {
  className?: string;
  handleSetResponse: (inputAnswer: string) => void;
  onClickNextWithValidation: () => void;
  validations: {
    max_length?: number;
  };
  value: string;
}

export default function QuestionLongText({
  className,
  handleSetResponse,
  onClickNextWithValidation,
  validations: { max_length = 500 },
  value = '',
}: Props): React.ReactElement {
  const textareaRef = useRef<HTMLTextAreaElement>() as React.MutableRefObject<HTMLTextAreaElement>;
  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = '0px';
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight < 264 ? scrollHeight + 'px' : '264px';
    }
  }, [value]);
  const handleKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onClickNextWithValidation();
    }
  };
  return (
    <>
      <StyledInput
        ref={textareaRef}
        className={className}
        maxLength={max_length}
        onChange={(e) => {
          handleSetResponse(e.target.value);
        }}
        onKeyPress={handleKeyPress}
        placeholder="Type your answer here..."
        value={value}
      />
      <StyledText weight="semibold">Shift ⇧ + Enter ↵&nbsp;</StyledText>
      <StyledText weight="regular">to make a line break</StyledText>
    </>
  );
}
