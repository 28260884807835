import CountUp from 'react-countup';
import styled from 'styled-components';

import { ADSHeading } from '@aphrodite/common-ui';
import { ADSText } from '@aphrodite/common-ui';

import StatsBackground from '../../assets/images/landing_stats_background.png';

const StyledCardDiv = styled.div`
  left: 0px;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  padding-left: 8%;
  padding-right: 8%;
  height: 70vh;
  width: 100vw;
  @media only screen and (min-width: 680px) {
    padding-top: 40px;
    padding-bottom: 40px;
    height: 50vh;
    min-height: 600px;
  }
  @media only screen and (min-width: 1440px) {
    padding-left: 16%;
    padding-right: 16%;
  }
`;
const StyledStatsDiv = styled(StyledCardDiv)`
  background-image: url(${StatsBackground});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 568px) {
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;
const StyledStatDiv = styled.div`
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 8%;
  padding-bottom: 8%;
  height: 100vh;
  gap: 10%;
  @media only screen and (min-width: 680px) {
    padding-right: 0px;
    height: 40vh;
  }
`;
const StyledFlexDiv = styled.div`
  width: match-parent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8vw;
  margin-right: auto;
  gap: 12px;
  @media only screen and (min-width: 680px) {
    margin: 4vh 0;
    align-items: center;
  }
  .Stat {
    font-size: 64px;
  }
`;
interface Props {
  signUpCount: number;
}

export default function LandingStats({ signUpCount }: Props): React.ReactElement {
  const renderMatchCount = () => {
    return (
      <StyledFlexDiv>
        <ADSHeading className="Stat" weight="bold">
          <CountUp end={signUpCount + 76448} duration={1} />
        </ADSHeading>
        <ADSText>Matched since 2019</ADSText>
      </StyledFlexDiv>
    );
  };

  return (
    <StyledStatsDiv>
      <StyledStatDiv>
        {renderMatchCount()}
        <StyledFlexDiv>
          <ADSHeading className="Stat" weight="bold">
            2 in 5
          </ADSHeading>
          <ADSText>Connections made</ADSText>
        </StyledFlexDiv>
      </StyledStatDiv>
    </StyledStatsDiv>
  );
}
