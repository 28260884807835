import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { ADSButton, ADSCard, ADSText } from '@aphrodite/common-ui';

import { NAV_ITEMS } from '../../constants/Navigation';

// Styled Components
const StyledCard = styled(ADSCard)`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 20px;
  padding: 16px;
  gap: 20px;
`;

interface Props {
  className?: string;
}

export default function DashboardCardPlayCupid({ className }: Props): React.ReactElement {
  return (
    <StyledCard className={className}>
      <ADSText
        hyphensNone={true}
        textAlign="center"
      >{`Have friends that you want to match up?`}</ADSText>
      <NavLink style={{ textDecoration: 'none' }} to={NAV_ITEMS.PLAY_CUPID.to}>
        <ADSButton>Play cupid here!</ADSButton>
      </NavLink>
    </StyledCard>
  );
}
