import dayjs from 'dayjs';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { ADSButton, ADSCard, ADSText } from '@aphrodite/common-ui';

import COLLEGE_CONSTANTS, { CollegesEnum } from '../../constants/College';
import { NAV_ITEMS } from '../../constants/Navigation';

// Styled Components
const StyledCard = styled(ADSCard)`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 20px;
  padding: 16px;
  gap: 20px;
`;

interface Props {
  className?: string;
  college: string;
}

export default function DashboardCardDataReport({ className, college }: Props): React.ReactElement {
  const lastYear = dayjs().year() - 1;
  return (
    <StyledCard className={className}>
      <ADSText
        hyphensNone={true}
        textAlign="center"
      >{`Want to check out the data report for the ${lastYear} ${
        COLLEGE_CONSTANTS.COLLEGE_NAMES[college as CollegesEnum]
      } Aphrodite Project?`}</ADSText>
      <NavLink style={{ textDecoration: 'none' }} to={NAV_ITEMS.DATA_REPORT.to}>
        <ADSButton>Check it out here</ADSButton>
      </NavLink>
    </StyledCard>
  );
}
