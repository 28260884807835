/**
 * Helper typescript function to assert that context is not undefined for typescript.
 * Use this when your context will not be used outside a provider and your initial value has to be null.
 * Reference: https://juliangaramendy.dev/blog/strict-react-context
 */
import React from 'react';

export default function createStrictContext<T>(
  options: {
    errorMessage?: string;
    name?: string;
  } = {},
) {
  const Context = React.createContext<T | undefined>(undefined);
  Context.displayName = options.name; // for DevTools

  function useContext() {
    const context = React.useContext(Context);
    if (context === undefined) {
      throw new Error(options.errorMessage || `${options.name || ''} Context Provider is missing`);
    }
    return context;
  }

  return [Context.Provider, useContext] as [React.Provider<T>, () => T];
}
