import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';
import styled, { css } from 'styled-components';

import { ReactComponent as CheckIcon } from '@aphrodite/assets/icons/check.svg';
import { ReactComponent as BackIcon } from '@aphrodite/assets/icons/left-arrow.svg';
import { ReactComponent as WarningIcon } from '@aphrodite/assets/icons/warning.svg';
import arrowDown from '@aphrodite/assets/logos/arrow-down.svg';
import arrowUp from '@aphrodite/assets/logos/arrow-up.svg';
import { ADSButton, ADSLoading, ADSText } from '@aphrodite/common-ui';
import { useAuthHelper, useIsQuestionnaireDisabled } from '@aphrodite/common/hooks';
import { IUserProfile } from '@aphrodite/common/types/firestore-types';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';
import { firebaseStorage } from '@aphrodite/firebase/firebase';
import { DocumentReference, doc } from '@firebase/firestore';
import { getDownloadURL, ref } from '@firebase/storage';

import { NAV_ITEMS } from '../../constants/Navigation';
import { IQuestion, IQuestionnaire } from '../../constants/Questionnaire';
import { PROFILE_MAJOR_REF, PROFILE_STUDY_YEAR_REF } from '../../constants/Questionnaire';
import { searchQuestion } from '../../helpers/questionnaire';
import { getUniversityRef } from '../../helpers/university';
import DashboardLoading from '../dashboard/DashboardLoading';
import Question from '../questionnaire/Question';

// Styled Components
const StyledArrowButton = styled(ADSButton)<{ disabled: boolean }>`
  opacity: ${(props) => (props.disabled ? '60%' : undefined)};
  width: auto;
  &:hover {
    opacity: ${(props) => (props.disabled ? '60%' : undefined)};
  }
`;
const StyledArrowButtonGroup = styled.div`
  bottom: 32px;
  display: flex;
  position: fixed;
  right: 32px;
  gap: 8px;
`;
const StyledIcon = styled.img`
  height: 1em;
  width: 1em;
`;
const StyledViewFrame = styled.div`
  height: 100vh;
  overflow-y: hidden;
`;
const StyledScrollContainer = styled.div<{ scrollDistance: number }>`
  transition: transform 600ms ease 0s;
  transform: translateY(${(props) => props.scrollDistance}vh);
`;
const StyledBackContainer = styled.div`
  position: absolute;
  right: 48px;
  top: 48px;
  z-index: 1;
  @media only screen and (max-width: 1024px) {
    top: 92px;
  }
  @media only screen and (max-width: 680px) {
    display: none;
  }
`;
const StyledBackIcon = styled(BackIcon)`
  height: 16px;
  width: 16px;
  margin-right: 8px;
  cursor: pointer;
`;
const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const StyledFullscreenQuestionContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  justify-content: center;
  margin: auto;
  max-width: 100%;
  padding: 0 8%;
  width: ${(props) => props.theme.spacing.questionnaireWidth};
  @media only screen and (min-width: 1024px) {
    padding-left: 10%;
    padding-right: 10%;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}
`;

const SCREEN_SCROLL_DIST_MULTIPLIER = -100;
export default function ProfileQuestionnaire(): React.ReactElement {
  const { user } = useAuthHelper();
  const history = useHistory();
  const [questionIndex, setQuestionIndex] = useState(0);
  const [questionnaire, setQuestionnaire] = useState<IQuestionnaire | null>(null);
  const [scrollDistance, setScrollDistance] = useState(0);
  const [status, setStatus] = useState<IStatus>('saved');
  // Firebase Storage refs
  const userQuestionnaireRef = doc(firebaseFirestore, `Questionnaire/${user!.uid}`);
  const userProfileRef = doc(
    firebaseFirestore,
    `UserProfiles/${user?.uid}`,
  ) as DocumentReference<IUserProfile>;
  const isQuestionnaireDisabled = useIsQuestionnaireDisabled();
  // Retrieve data
  const { status: userQuestionnaireStatus, data: userQuestionnaireData } =
    useFirestoreDocData<any>(userQuestionnaireRef);
  const { status: userProfileStatus, data: userProfileData } =
    useFirestoreDocData<IUserProfile>(userProfileRef);
  useEffect(() => {
    let unmounted = false;
    if (userProfileStatus === 'loading') {
      return;
    }
    const { college } = userProfileData;
    const storageRef = ref(firebaseStorage, getUniversityRef(college));
    getDownloadURL(storageRef).then((url) => {
      axios.get(url).then((response) => {
        if (!unmounted) setQuestionnaire(response.data);
      });
    });
    return () => {
      unmounted = true;
    };
  }, [userProfileData, userProfileStatus]);
  const handlePrevQuestion = () => {
    if (!isFirstQuestion(questionIndex)) {
      let prevIndex = questionIndex - 1;
      setQuestionIndex(prevIndex);
      setScrollDistance(prevIndex * SCREEN_SCROLL_DIST_MULTIPLIER);
    }
  };
  const isFirstQuestion = (index: number): boolean => {
    return index <= 0;
  };
  const handleSetStatus = useCallback((status: IStatus) => setStatus(status), []);
  if (userQuestionnaireStatus === 'loading' || userProfileStatus === 'loading' || !questionnaire) {
    return <DashboardLoading />;
  }
  // This section is modified to the scope of the profile questions required
  const allQuestions = questionnaire.fields;
  const majorQuestion = searchQuestion(PROFILE_MAJOR_REF, allQuestions);
  const studyYearQuestion = searchQuestion(PROFILE_STUDY_YEAR_REF, allQuestions);
  let questions: IQuestion[] = [];
  if (majorQuestion && studyYearQuestion) {
    questions = [majorQuestion, studyYearQuestion];
  }

  const isLastQuestion = (index: number): boolean => {
    return index >= questions.length - 1;
  };
  const handleNextQuestion = () => {
    if (!isLastQuestion(questionIndex)) {
      const nextIndex = questionIndex + 1;
      setQuestionIndex(nextIndex);
      setScrollDistance(nextIndex * SCREEN_SCROLL_DIST_MULTIPLIER);
    }
  };
  return (
    <StyledViewFrame>
      <StyledBackContainer>
        <StyledNavLink to={NAV_ITEMS.PROFILE.to}>
          <StyledBackIcon />
          <ADSText inline={true} size="xs">
            Back to profile
          </ADSText>
        </StyledNavLink>
      </StyledBackContainer>
      {questions.map((question, index) => {
        const { id, properties, title, type, validations } = question;
        const { database } = properties;
        const value =
          database === 'private'
            ? userQuestionnaireData[id]
            : userProfileData[id as keyof IUserProfile];
        return (
          <StyledScrollContainer key={id} scrollDistance={scrollDistance}>
            <StyledFullscreenQuestionContainer disabled={isQuestionnaireDisabled}>
              <Question
                isDisabled={isQuestionnaireDisabled}
                isFinal={isLastQuestion(index)}
                onClickNext={handleNextQuestion}
                id={id}
                properties={properties}
                onStatusChange={handleSetStatus}
                onFinalSubmit={() => history.push(NAV_ITEMS.PROFILE.to)}
                title={title}
                type={type}
                validations={validations}
                value={value}
              />
            </StyledFullscreenQuestionContainer>
          </StyledScrollContainer>
        );
      })}
      <StyledStatusContainer>
        <StatusBox status={status} />
      </StyledStatusContainer>
      <StyledArrowButtonGroup>
        <StyledArrowButton
          disabled={isFirstQuestion(questionIndex)}
          onClick={handlePrevQuestion}
          styleType="pinkGradient"
        >
          <StyledIcon src={arrowUp} alt="up arrow" />
        </StyledArrowButton>
        <StyledArrowButton
          disabled={isLastQuestion(questionIndex)}
          onClick={handleNextQuestion}
          styleType="pinkGradient"
        >
          <StyledIcon src={arrowDown} alt="down arrow" />
        </StyledArrowButton>
      </StyledArrowButtonGroup>
    </StyledViewFrame>
  );
}

const StyledStatusContainer = styled.div`
  bottom: 32px;
  display: flex;
  flex-direction: row;
  height: 24px;
  left: 32px;
  position: fixed;
  @media only screen and (min-width: 1024px) {
    left: ${({ theme }) => `${parseFloat(theme.spacing.navbarDesktopWidth) + 32}px`};
  }
`;
const StyledStatusIconContainer = styled.div`
  height: 20px;
  width: 20px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 680px) {
    width: 24px;
    height: 24px;
    padding-bottom: 8px;
  }
`;

export type IStatus = 'loading' | 'saved' | 'error';
interface StatusBoxProps {
  status: IStatus;
}

function StatusBox({ status }: StatusBoxProps): React.ReactElement {
  switch (status) {
    case 'loading':
      return (
        <StyledStatusContainer>
          <StyledStatusIconContainer>
            <ADSLoading size="s" />
          </StyledStatusIconContainer>
          <ADSText size="s" wrapperStyle={{ marginLeft: '8px' }}>
            Saving
          </ADSText>
        </StyledStatusContainer>
      );
    case 'saved':
      return (
        <StyledStatusContainer>
          <StyledStatusIconContainer>
            <CheckIcon />
          </StyledStatusIconContainer>
          <ADSText size="s" wrapperStyle={{ marginLeft: '8px' }}>
            Saved
          </ADSText>
        </StyledStatusContainer>
      );
    case 'error':
      return (
        <StyledStatusContainer>
          <StyledStatusIconContainer>
            <WarningIcon />
          </StyledStatusIconContainer>
          <ADSText size="s" wrapperStyle={{ marginLeft: '8px' }}>
            Error saving! please answer the last question again!
          </ADSText>
        </StyledStatusContainer>
      );
  }
}
