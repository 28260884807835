import React from 'react';
import { useFirestoreDocData } from 'reactfire';
import styled from 'styled-components';

import { ADSCard, ADSHeading, ADSHighlighter, ADSText } from '@aphrodite/common-ui';
import { Region } from '@aphrodite/common/constants/Region';
import { useAuthHelper, useGetDateStatistics } from '@aphrodite/common/hooks';
import { ICollegeConfiguration, IUserProfile } from '@aphrodite/common/types/firestore-types';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';
import { DocumentReference, doc } from '@firebase/firestore';

import { CollegesEnum } from '../../constants/College';

// Styled Components
const StyledCard = styled(ADSCard)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  padding: 8px;
`;
const StyledDataContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;
const StyledDataText = styled(ADSHeading)`
  margin-left: 8px;
  margin-right: 8px;
`;

interface Props {
  className?: string;
}

export default function DashboardCardStats({ className }: Props): React.ReactElement {
  const { user } = useAuthHelper();
  const userProfileRef = doc(
    firebaseFirestore,
    `UserProfiles/${user!.uid}`,
  ) as DocumentReference<IUserProfile>;
  const { status: userProfileStatus, data: userProfileData } =
    useFirestoreDocData<IUserProfile>(userProfileRef);
  const { college } = userProfileData;
  const collegeConfigIndex = 'College' + college.charAt(0).toUpperCase() + college.slice(1);
  // Check college region
  const collegeFlagRef = doc(
    firebaseFirestore,
    `Configuration/${collegeConfigIndex}`,
  ) as DocumentReference<ICollegeConfiguration>;
  const { status: collegeConfigStatus, data: collegeConfigData } =
    useFirestoreDocData<ICollegeConfiguration>(collegeFlagRef);
  // Retrieve list of active colleges in the user's region
  const region: Region | undefined = collegeConfigData?.region;
  const collegeName = collegeConfigData ? collegeConfigData['shortform'] : college;
  const collegeValues = Object.values(CollegesEnum);
  const { statisticsStatus, statisticsData } = useGetDateStatistics(region);
  // Loading statuses for firestore.
  if (
    userProfileStatus === 'loading' ||
    collegeConfigStatus === 'loading' ||
    statisticsStatus === 'loading'
  ) {
    return <></>;
  }
  let signupNumber = 0;
  statisticsData.forEach((stats) => {
    if (college === 'mit' || college === 'harvard') {
      signupNumber += stats['mitxharvard'];
    } else {
      signupNumber += stats[college];
    }
  });
  let totalSignupNumber = 0;
  statisticsData?.forEach((stats) => {
    collegeValues.forEach((college) => {
      if (stats[college]) {
        totalSignupNumber += stats[college];
      }
    });
  });
  const renderCollegeName = () => {
    if (college === 'mit' || college === 'harvard') {
      return 'MIT x Harvard';
    }
    return collegeName;
  };
  return (
    <StyledCard className={className}>
      <StyledDataContainer>
        <StyledDataText level="2">
          <ADSHighlighter>{76448 + totalSignupNumber}</ADSHighlighter>
        </StyledDataText>
        <ADSText size="s">Participants since 2019</ADSText>
      </StyledDataContainer>
      <StyledDataContainer>
        <ADSText size="s">{renderCollegeName()} sign-ups so far:</ADSText>
        <StyledDataText level="2">
          <ADSHighlighter>{signupNumber}</ADSHighlighter>
        </StyledDataText>
      </StyledDataContainer>
    </StyledCard>
  );
}
