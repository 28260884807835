import { useCallback, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useFirestoreDocData } from 'reactfire';
import styled, { css } from 'styled-components';

import { ADSAnchor, ADSHeading, ADSLoading, ADSText } from '@aphrodite/common-ui';
import { useAuthHelper, useIsQuestionnaireDisabled } from '@aphrodite/common/hooks';
import { IUserProfile } from '@aphrodite/common/types/firestore-types';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';
import { DocumentReference, doc, updateDoc } from '@firebase/firestore';

import COLLEGE_CONSTANTS from '../../constants/College';
import { CollegesEnum } from '../../constants/College';
import { PROFILE_BLOCK_LIST_REF } from '../../constants/Questionnaire';
import { IStatus } from '../profile/ProfileQuestionnaire';
import QuestionMultipleEntry from '../questionnaire/QuestionMultipleEntry';
import DashboardLoading from './DashboardLoading';

// Styled Components
const StyledContainer = styled.div`
  max-width: 100%;
  display: flex;
  padding: 20% 8% 8% 8%;
  flex-direction: column;
  justify-content: center;
  @media only screen and (min-width: 1024px) {
    margin-top: ${({ theme }) => `${parseFloat(theme.spacing.navbarDesktopLogoHeight) * 0.25}px`};
    margin-left: ${({ theme }) => theme.spacing.navbarDesktopWidth};
    padding: 0 4%;
  }
`;
const StyledDescription = styled(ADSText)`
  color: rgba(0, 0, 0, 0.6);
  margin-top: 8px;
  & > span {
    white-space: pre-line;
  }
`;
const StyledQuestionContainer = styled.div<{ isDisabled: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: auto;
  max-width: 100%;
  margin-top: 8%;
  width: ${(props) => props.theme.spacing.questionnaireWidth};
  @media only screen and (min-width: 1024px) {
    padding-left: 10%;
    padding-right: 10%;
  }
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}
`;
const StyledTextContainer = styled.div`
  letter-spacing: 0.4px;
  padding-bottom: 24px;
  @media only screen and (max-width: 1024px) {
    max-height: 50%;
  }
`;
const StyledQuestionComponent = styled.div`
  margin-bottom: 12px;
  max-height: 46%;
  @media only screen and (min-height: 568px) {
    max-height: 50%;
  }
  @media only screen and (min-height: 768px) {
    max-height: 55%;
  }
`;
const StyledLoadingIcon = styled(ADSLoading)`
  right: 16px;
  margin-right: 8px;
  position: absolute;
`;

export default function DashboardTabNaughtyList(): React.ReactElement {
  const { user } = useAuthHelper();
  const userProfileRef = doc(
    firebaseFirestore,
    `UserProfiles/${user!.uid}`,
  ) as DocumentReference<IUserProfile>;
  const { status: userProfileStatus, data: userProfileData } =
    useFirestoreDocData<IUserProfile>(userProfileRef);
  const uniKeyString = userProfileData.college;
  const uniKey = uniKeyString as CollegesEnum;
  const schoolEmail = COLLEGE_CONSTANTS.COLLEGE_EMAILS[uniKey];
  const schoolName =
    uniKey === 'harvard' || uniKey === 'mit'
      ? 'Harvard or MIT'
      : COLLEGE_CONSTANTS.COLLEGE_NAMES[uniKey];
  const getEmailFormats = () => {
    if (uniKey === 'harvard' || uniKey === 'mit') {
      return COLLEGE_CONSTANTS.COLLEGE_ALLOWLIST_DOMAINS.harvard.concat(
        COLLEGE_CONSTANTS.COLLEGE_ALLOWLIST_DOMAINS.mit,
      );
    }
    return COLLEGE_CONSTANTS.COLLEGE_ALLOWLIST_DOMAINS[uniKey];
  };
  const emailFormatMessage = `Only ${schoolName} emails of the following formats are allowed: ${getEmailFormats().join(
    ', ',
  )}.`;
  const getRegexp = () => {
    if (uniKey === 'harvard' || uniKey === 'mit') {
      return COLLEGE_CONSTANTS.COLLEGE_DOMAINS_ALLOWLIST_REGEX.harvard.concat(
        COLLEGE_CONSTANTS.COLLEGE_DOMAINS_ALLOWLIST_REGEX.mit,
      );
    }
    return COLLEGE_CONSTANTS.COLLEGE_DOMAINS_ALLOWLIST_REGEX[uniKey];
  };
  const userNaughtyList = userProfileData[PROFILE_BLOCK_LIST_REF] || [];
  const [blockList, setNaughtyList] = useState(userNaughtyList);
  const [status, setStatus] = useState<IStatus>('saved');
  const onStatusChange = useCallback((status: IStatus) => setStatus(status), []);
  const onResponseChange = useCallback((blockListResponse: any) => {
    setNaughtyList(blockListResponse);
  }, []);
  const handleOnClickNext = (blockListToSave: string[]) => {
    onStatusChange('loading');
    if (blockListToSave === null || blockListToSave === undefined) {
      return;
    }
    updateDoc(userProfileRef, {
      [PROFILE_BLOCK_LIST_REF]: blockListToSave,
    })
      .then(() => {
        onStatusChange('saved');
      })
      .catch((error: any) => {
        onStatusChange('error');
      });
  };
  const isQuestionnaireDisabled = useIsQuestionnaireDisabled();
  const tabName =
    uniKeyString === 'uwaterloo' || uniKeyString === 'uoft' ? 'Naughty List' : 'Block List';
  if (userProfileStatus === 'loading') {
    return <DashboardLoading />;
  }
  return (
    <StyledContainer>
      {status === 'loading' && <StyledLoadingIcon size="s" />}
      <ADSHeading level="2" wrapperStyle={{ marginTop: '4%' }}>
        {tabName}
      </ADSHeading>
      <StyledQuestionContainer isDisabled={isQuestionnaireDisabled}>
        <StyledTextContainer
          onWheel={(e) => e.stopPropagation()}
          onTouchMove={(e) => e.stopPropagation()}
        >
          <ADSText hyphensNone={true} inline={true} size="l">
            <ReactMarkdown
              children="Want to avoid matching with your ex, a relative, or someone else?"
              components={{
                p: 'span',
              }}
            />
          </ADSText>
          <StyledDescription hyphensNone={true} size="m">
            <ReactMarkdown
              components={{
                a: (props: any) => (
                  <ADSAnchor to={props.href} underline={true}>
                    {props.children}
                  </ADSAnchor>
                ),
                p: 'span',
              }}
              children="Add their email here and you won't be matched with them."
            />
          </StyledDescription>
        </StyledTextContainer>
        <StyledQuestionComponent>
          <QuestionMultipleEntry
            onClickNext={handleOnClickNext}
            handleSetResponse={onResponseChange}
            maxAnswerAllowed={50}
            value={blockList}
            placeholder={schoolEmail}
            regexInput={getRegexp()}
            regexErrorMessage={emailFormatMessage}
          />
        </StyledQuestionComponent>
      </StyledQuestionContainer>
    </StyledContainer>
  );
}
