import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useFirebaseApp } from 'reactfire';
import styled, { useTheme } from 'styled-components';

import { ReactComponent as ErrorIcon } from '@aphrodite/assets/icons/error.svg';
import bowArrow from '@aphrodite/assets/logos/bow-and-arrow.svg';
import tick from '@aphrodite/assets/logos/tick.svg';
import { ADSAnchor, ADSButton, ADSText } from '@aphrodite/common-ui';
import { ThemeType } from '@aphrodite/common-ui/AphroditeTheme';
import { useAuthHelper } from '@aphrodite/common/hooks';
import { useDebounce } from '@aphrodite/common/hooks';
import usePrevious from '@aphrodite/common/hooks/usePrevious';
import { IUserProfile } from '@aphrodite/common/types/firestore-types';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';
import { DocumentReference, doc, updateDoc } from '@firebase/firestore';

import {
  ERROR_COIN,
  ERROR_REQUIRED,
  IQuestion,
  IQuestionAnswer,
  IQuestionChoice,
  QuestionType,
} from '../../constants/Questionnaire';
import { IQuestionGroupNumberAnswer } from '../../constants/Questionnaire';
import QuestionDropdown from './QuestionDropdown';
import QuestionGroupCoin from './QuestionGroupCoin';
import QuestionLongText from './QuestionLongText';
import QuestionMultipleChoice from './QuestionMultipleChoice';
import QuestionOpinionScale from './QuestionOpinionScale';
import QuestionShortNumber from './QuestionShortNumber';
import QuestionShortText from './QuestionShortText';
import QuestionStatement from './QuestionStatement';
import { IStatus } from './Questionnaire';

// Styled Components
const StyledDescription = styled(ADSText)`
  color: rgba(0, 0, 0, 0.6);
  margin-top: 8px;
  & > span {
    white-space: pre-line;
  }
`;
const StyledIcon = styled.img`
  height: 1em;
  width: 1em;
`;
const StyledNextButton = styled(ADSButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px 0px;
  color: black;
  font-size: 16px;
  padding: 8px 12px;
  width: fit-content;
  @media only screen and (min-width: 1024px) {
    font-size: 20px;
  }
`;
const StyledQuestionComponent = styled.div`
  margin-bottom: 12px;
  max-height: 46%;
  @media only screen and (min-height: 568px) {
    max-height: 50%;
  }
  @media only screen and (min-height: 768px) {
    max-height: 55%;
  }
`;
const StyledQuestionContainer = styled.div<{ isDisabled: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: center;
  margin: auto;
  width: 100%;
`;
const StyledSpan = styled.span`
  margin-left: 12px;
`;
const StyledTextContainer = styled.div<{ isHidden?: boolean }>`
  letter-spacing: 0.4px;
  padding-bottom: 24px;
  max-height: 45%;
  overflow: auto;
  @media only screen and (max-width: 1024px) {
    max-height: 50%;
  }
  @media only screen and (max-height: 680px) {
    display: ${(props) => (props.isHidden ? 'none' : 'inline-block')};
  }
`;
const StyledTickIcon = styled(StyledIcon)`
  margin-left: 8px;
`;

function isValidGroupNumberResponse(
  fieldIDs: string[],
  groupNumberResponse: IQuestionGroupNumberAnswer,
): boolean {
  if (!groupNumberResponse) {
    return false;
  }
  if (fieldIDs.every((item: string) => !(item in groupNumberResponse))) {
    return false;
  }
  const totalCoins = Object.values(groupNumberResponse)
    .map((x) => parseInt(x))
    .reduce((prev, curr) => prev + curr, 0);
  return totalCoins === 20;
}
// TODO: Clean up this component's error handling states.
export interface QuestionProps extends IQuestion {
  hideButton?: boolean;
  isDisabled?: boolean;
  isFinal?: boolean;
  onStatusChange?: (status: IStatus) => void;
  onClickNext?: () => void;
  onFinalSubmit?: () => void;
  requireUser?: boolean;
  value?: IQuestionAnswer;
  currentQuestion?: number;
  questionIndex?: number;
}
export default function Question({
  currentQuestion,
  hideButton = false,
  id,
  isDisabled = false,
  isFinal = false,
  onClickNext = () => {},
  onFinalSubmit = () => {},
  properties: {
    alphabetical_order = false,
    allow_multiple_selection = false,
    allow_other_choice = false,
    button_text = 'Ok',
    choices = [],
    database = 'private',
    description = '',
    fields = [],
    labels = { left: '', center: '', right: '' },
    randomize = false,
    start_at_one = true,
    steps = 0,
    vertical_alignment = false,
  },
  requireUser = true,
  questionIndex,
  onStatusChange = () => {},
  onValueChange,
  title,
  type,
  validations = { required: false, max_length: 500, max_selection: 0 },
  value,
}: QuestionProps): React.ReactElement {
  const { user } = useAuthHelper();
  const [response, setResponse] = useState<IQuestionAnswer>();
  const [error, setError] = useState('');
  const [isDropdownFullscreen, setIsDropdownFullscreen] = useState(false);
  const firebase = useFirebaseApp();
  const debouncedResponse = useDebounce(response, 500);
  const prevDebouncedResponse = usePrevious(debouncedResponse);
  const { required } = validations;

  const toggleDropdownFullscreen = (isFullscreen: boolean) => {
    setIsDropdownFullscreen(isFullscreen);
  };
  const handleOnClickNextWithValidation = () => {
    if (!required) {
      onClickNext();
      return;
    }
    // Validate the response
    switch (type) {
      case QuestionType.LONG_TEXT:
      case QuestionType.NUMBER:
      case QuestionType.OPINION_SCALE:
      case QuestionType.SHORT_TEXT:
        if (response === null || response === undefined || response === '') {
          setError(ERROR_REQUIRED);
          return;
        }
        break;
      case QuestionType.DROPDOWN:
        if (!response || Object.values(response).length === 0) {
          setError(ERROR_REQUIRED);
          return;
        }
        break;
      case QuestionType.MULTIPLE_CHOICE:
        if (!response || (response as IQuestionAnswer[]).length === 0) {
          setError(ERROR_REQUIRED);
          return;
        }
        break;
      case QuestionType.GROUP_COIN:
        if (
          !isValidGroupNumberResponse(
            fields.map((field) => field.id),
            debouncedResponse as IQuestionGroupNumberAnswer,
          )
        ) {
          setError(ERROR_COIN);
          return;
        }
        break;
    }
    onClickNext();
  };
  const handleSetResponse = (inputAnswer: IQuestionAnswer) => {
    if (inputAnswer === null || inputAnswer === undefined) {
      return;
    }
    if (
      type === QuestionType.GROUP_COIN &&
      !isValidGroupNumberResponse(
        fields.map((field) => field.id),
        inputAnswer as IQuestionGroupNumberAnswer,
      )
    ) {
      setError(ERROR_COIN);
      setResponse(inputAnswer);
      if (onValueChange) {
        onValueChange(inputAnswer);
      }
      return;
    }
    setResponse(inputAnswer);
    setError(''); // Clear any errors
    if (onValueChange) {
      onValueChange(inputAnswer);
    }
  };
  useEffect(() => {
    if (value !== null || value !== undefined) {
      setResponse(value);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isDisabled) {
      return;
    }
    if (prevDebouncedResponse === debouncedResponse) {
      return;
    }
    if (debouncedResponse === null || debouncedResponse === undefined) {
      return;
    }
    if (
      type === QuestionType.GROUP_COIN &&
      !isValidGroupNumberResponse(
        fields.map((field) => field.id),
        debouncedResponse as IQuestionGroupNumberAnswer,
      )
    ) {
      return;
    }
    onStatusChange('loading');
    if (requireUser) {
      const userProfileRef = doc(
        firebaseFirestore,
        `UserProfiles/${user?.uid}`,
      ) as DocumentReference<IUserProfile>;
      const userQuestionnaireRef = doc(firebaseFirestore, `Questionnaire/${user!.uid}`);
      switch (database) {
        case 'private':
          updateDoc(userQuestionnaireRef, {
            [id]: debouncedResponse,
            hasStarted: true,
          })
            .then(() => {
              onStatusChange('saved');
            })
            .catch((error) => {
              onStatusChange('error');
            });
          return;
        case 'profile':
          updateDoc(userProfileRef, {
            [id]: debouncedResponse,
          })
            .then(() => {
              onStatusChange('saved');
            })
            .catch((error) => {
              onStatusChange('error');
            });
          return;
      }
    }
  }, [
    requireUser,
    database,
    debouncedResponse,
    fields,
    firebase,
    isDisabled,
    id,
    onStatusChange,
    prevDebouncedResponse,
    type,
    user,
  ]);
  if (questionIndex && currentQuestion && Math.abs(questionIndex - currentQuestion) > 2) {
    // Only render if it should be displayed.
    return <StyledQuestionContainer isDisabled={isDisabled} />;
  }
  const getQuestionComponent = () => {
    switch (type) {
      case QuestionType.DROPDOWN:
        return (
          <QuestionDropdown
            handleSetResponse={handleSetResponse}
            onClickNext={onClickNext}
            toggleDropdownFullscreen={toggleDropdownFullscreen}
            onSelect={(res) => {
              setResponse(res);
              if (onValueChange) {
                onValueChange(res);
              }
            }}
            properties={{
              alphabetical_order,
              randomize,
              choices,
            }}
            value={response as IQuestionChoice}
          />
        );
      case QuestionType.GROUP_COIN:
        return (
          <QuestionGroupCoin
            handleSetResponse={handleSetResponse}
            id={id}
            properties={{ fields }}
            value={response as IQuestionGroupNumberAnswer}
          />
        );
      case QuestionType.LONG_TEXT:
        return (
          <QuestionLongText
            handleSetResponse={handleSetResponse}
            onClickNextWithValidation={handleOnClickNextWithValidation}
            validations={{ max_length: validations.max_length }}
            value={(response as string) ?? ''}
          />
        );
      case QuestionType.MULTIPLE_CHOICE:
        return (
          <QuestionMultipleChoice
            handleSetResponse={handleSetResponse}
            onClickNext={onClickNext}
            properties={{
              allow_multiple_selection,
              allow_other_choice,
              randomize,
              choices,
            }}
            validations={{ max_selection: validations.max_selection }}
            selected={(response as IQuestionChoice[]) ?? []}
          />
        );
      case QuestionType.NUMBER:
        return (
          <QuestionShortNumber
            handleSetResponse={handleSetResponse}
            onClickNextWithValidation={handleOnClickNextWithValidation}
            value={response as string}
            validation={validations}
          />
        );
      case QuestionType.OPINION_SCALE:
        return (
          <QuestionOpinionScale
            handleSetResponse={handleSetResponse}
            onClickNext={onClickNext}
            properties={{
              start_at_one,
              steps,
              labels,
            }}
            value={response as number}
          />
        );
      case QuestionType.SHORT_TEXT:
        return (
          <QuestionShortText
            handleSetResponse={handleSetResponse}
            onClickNext={onClickNext}
            value={(response as string) ?? ''}
          />
        );
      default:
        return <QuestionStatement handleSetResponse={handleSetResponse} />;
    }
  };
  const renderNextButton = () => {
    if (isFinal) {
      return (
        <StyledNextButton type="submit" onClick={onFinalSubmit} styleType="pinkGradient">
          Submit
          <StyledTickIcon src={bowArrow} alt="bow and arrow" />
        </StyledNextButton>
      );
    }
    return (
      <StyledNextButton onClick={handleOnClickNextWithValidation} styleType="pinkGradient">
        {button_text}
        {button_text === null && <StyledTickIcon src={tick} alt="tick" />}
      </StyledNextButton>
    );
  };
  return (
    <>
      <StyledQuestionContainer isDisabled={isDisabled}>
        <StyledTextContainer
          onWheel={(e) => e.stopPropagation()}
          onTouchMove={(e) => e.stopPropagation()}
          isHidden={type === QuestionType.DROPDOWN && isDropdownFullscreen}
        >
          <ADSText hyphensNone={true} inline={true} size="l">
            <ReactMarkdown
              children={title ?? ''}
              components={{
                p: 'span',
              }}
            />
            {validations.required && <StyledSpan>*</StyledSpan>}
          </ADSText>
          {description && (
            <StyledDescription hyphensNone={true} size="m">
              <ReactMarkdown
                children={description}
                components={{
                  a: (props: any) => (
                    <ADSAnchor target="_blank" to={props.href} underline={true}>
                      {props.children}
                    </ADSAnchor>
                  ),
                  p: 'span',
                }}
              />
            </StyledDescription>
          )}
        </StyledTextContainer>
        <StyledQuestionComponent>{getQuestionComponent()}</StyledQuestionComponent>
        <div style={{ alignItems: 'flexStart' }}>
          {error && <ErrorStatus errorMessage={error} />}
        </div>
        {!error && !hideButton && renderNextButton()}
      </StyledQuestionContainer>
    </>
  );
}

const StyledErrorMessageContainer = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  outline: 0px;
  min-height: 28px;
  height: 28px;
`;
const StyledErrorIcon = styled(ErrorIcon)``;
interface ErrorStatusProps {
  errorMessage: string;
}

function ErrorStatus({ errorMessage }: ErrorStatusProps): React.ReactElement {
  const theme: ThemeType = useTheme() as ThemeType;
  return (
    <StyledErrorMessageContainer>
      <StyledErrorIcon height="24px" fill={theme.colors.aphroRedDark} width="24px" />
      <ADSText
        inline={true}
        size="xs"
        wrapperStyle={{
          color: theme.colors.aphroRedDark,
          marginLeft: '8px',
        }}
      >
        {errorMessage}
      </ADSText>
    </StyledErrorMessageContainer>
  );
}
