export const SAFETY_TIPS = [
  {
    title: 'If you feel uncomfortable, tell someone you trust',
    content: (
      <div>
        {
          'This is worth repeating. If you receive an email, text message, or call that makes you uncomfortable, tell someone you trust about it. And contact the safety resources at your school (scroll to '
        }
        <span style={{ fontWeight: 600 }}>{'School Resources'}</span>
        {')'}
        <br />
        <br />
        {
          'Your concerns are important. You are not in this alone. Informing others makes you safe. Telling someone you trust and notifying your school is the right thing to do.'
        }
        <br />
        <br />
        {
          'And the Aphrodite Team is here for you, for anything. Please reach out to cupids@aphrodite.global about any concern, no matter how seemingly small. We want to help.'
        }
        <br />
        <br />
        {'If you want to disengage with your match or let us know about a situation, you can also '}
        <span style={{ fontWeight: 600 }}>Report</span>
        {' them on your '}
        <span style={{ fontWeight: 600 }}>Match Results</span>
        {' page. '}
      </div>
    ),
  },
  {
    title: 'Meet when you are ready',
    content: (
      <div>
        {
          "Don't feel pressured to meet with someone. The right person will give you the time you need."
        }
        <br />
        <br />
        {
          'Setting up a video chat before meeting in person can be a great way to vet your match. If they are hesitant or unwilling to do a video call, that is a red flag.'
        }
      </div>
    ),
  },
  {
    title: 'Tell a friend before you meet',
    content: (
      <div>
        {
          'Before meeting your match in person, inform a friend about the details: who you are meeting, where you are going, and how long it will take. Keep a charged phone with you.'
        }
      </div>
    ),
  },
  {
    title: 'Meet publicly',
    content: (
      <div>
        {
          'We advise that you meet in a public place, such as a coffee shop, restaurant, shopping mall, etc, for the entire duration of the date.'
        }
      </div>
    ),
  },
  {
    title: 'Provide your own transportation',
    content: (
      <div>
        {
          'We recommend arranging your own transportation for the date, such as taking a taxi, subway, or rideshare. While it might come across as kind if your date offers to pick you up, having the ability to leave on your own terms provides security.'
        }
      </div>
    ),
  },
  {
    title: 'Trust your gut',
    content: (
      <div>
        {
          "Trust your gut feelings. Don't hesitate to end a date if something feels off. Your safety is your main concern; don't worry about politeness. If you need support, let us or someone you trust know."
        }
      </div>
    ),
  },
];
