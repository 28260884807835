import styled from 'styled-components';

import ADSLoading from './ADSLoading';
import ADSPage from './ADSPage';

// Styled Components
const StyledContainer = styled(ADSPage)`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`;

export default function ADSPageLoading(): React.ReactElement {
  return (
    <StyledContainer>
      <ADSLoading size="l" />
    </StyledContainer>
  );
}
