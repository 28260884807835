import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import {
  AccordionProvider,
  useAccordionContext,
} from '@aphrodite/common-ui/hooks/useAccordionContext';

import ADSHeading from './ADSHeading';
import ADSText from './ADSText';

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 5vh;
  @media only screen and (min-width: 680px) {
    align-items: flex-start;
  }
`;

interface AccordionProps {
  title?: React.ReactNode;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  accordionItems?: AccordionItemProps[];
}

export const ADSAccordion = ({ title, style, children }: AccordionProps) => {
  return (
    <AccordionProvider>
      <div style={style}>
        <ADSHeading
          weight="bold"
          textAlign="left"
          level="2"
          wrapperStyle={{ marginBottom: -30, color: '#000000' }}
        >
          {title}
        </ADSHeading>
        <AccordionContainer>{children}</AccordionContainer>
      </div>
    </AccordionProvider>
  );
};

const AccordionItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  .content.show {
    max-height: 300px;
  }
  .content {
    max-height: 0px;
  }
`;
const AccordionItemTitle = styled.div`
  display: flex;
  flex-direction: rows;
  width: 80vw;
  align-self: flex-start;
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 4px;
  @media only screen and (min-width: 1440px) {
    width: 70vw;
  }
`;
const FadeInAnimation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 01;}
`;
const StyledContentContainer = styled.div<{ collapsed: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 0px;
  transition: all 1s;
  gap: 16px;
  padding: 12px 0;
  border-bottom: 1px solid #c4c4c4;
  @media only screen and (min-width: 680px) {
    flex-direction: row;
    align-items: center;
  }
  ${(props) => {
    if (!props.collapsed) {
      return css`
        max-height: 20%;
        transition: all 1s;
        animation: ${FadeInAnimation} 0.8s;
      `;
    }
  }}
`;
const StyledContent = styled.div`
  width: 80%;
`;

interface AccordionItemProps {
  title: React.ReactNode;
  content: string | JSX.Element;
  index: string | number;
}

export const ADSAccordionItem = ({ title, content, index }: AccordionItemProps) => {
  const { activeAccordionIndex, setActiveAccordionIndex } = useAccordionContext();
  const isAccordionSectionOpen = index === activeAccordionIndex;
  const handleOpen = () => setActiveAccordionIndex(!isAccordionSectionOpen ? index : -1);

  return (
    <AccordionItemContainer onClick={handleOpen}>
      <AccordionItemTitle>
        <ADSText wrapperStyle={{ fontWeight: isAccordionSectionOpen ? 600 : 400 }}>{title}</ADSText>
        <ADSText
          wrapperStyle={{ marginLeft: 'auto', fontWeight: isAccordionSectionOpen ? 600 : 400 }}
        >
          {isAccordionSectionOpen ? '-' : '+'}
        </ADSText>
      </AccordionItemTitle>
      {isAccordionSectionOpen && (
        <StyledContentContainer collapsed={!isAccordionSectionOpen}>
          <StyledContent>{content}</StyledContent>
        </StyledContentContainer>
      )}
    </AccordionItemContainer>
  );
};
