import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import QuestionMark from '@aphrodite/assets/icons/question-mark.png';
import { ADSButton } from '@aphrodite/common-ui';
import { ADSHeading } from '@aphrodite/common-ui';
import { ADSText } from '@aphrodite/common-ui';
import { useAuthHelper } from '@aphrodite/common/hooks';
import { useIsMatchesReleased, useIsSingaporeWebsite } from '@aphrodite/common/hooks';

import { DEVELOPER_USER_ACCOUNTS } from '../../constants/DevUserAccounts';
import MatchResultView from '../matchResult/MatchResultView';
// from '../MatchResultPopup';
import DashboardLoading from './DashboardLoading';

const StyledContainer = styled.div`
  max-width: 100%;
  display: flex;
  padding: 20% 8% 8% 10%;
  flex-direction: column;
  justify-content: center;
  @media only screen and (min-width: 1024px) {
    margin-top: ${({ theme }) => `${parseFloat(theme.spacing.navbarDesktopLogoHeight) * 0.25}px`};
    margin-left: ${({ theme }) => theme.spacing.navbarDesktopWidth};
    padding: 0 4%;
  }
`;
const StyledFlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 20px;
  .button {
    margin-top: 20px;
  }
`;
const StyledImg = styled.img`
  margin-top: 30px;
  height: 48px;
  width: 48px;
  margin-bottom: 30px;
  @media only screen and (min-width: 1024px) {
    height: 65px;
    width: 65px;
  }
`;
const StyledText = styled(ADSText)`
  margin-bottom: 5px;
  @media only screen and (min-width: 1024px) {
    font-size: 30px;
    line-height: 39px;
  }
`;
const StyledNavLink = styled(NavLink)`
  margin-top: 20px;
  margin-bottom: 20px;
  text-decoration: none;
`;

const PendingView = () => {
  const isSingaporeWebsite = useIsSingaporeWebsite();
  return (
    <StyledContainer>
      <ADSHeading level="2" wrapperStyle={{ marginTop: '4%' }}>
        My Match
      </ADSHeading>
      <StyledFlexDiv>
        <StyledImg src={QuestionMark} alt="" className="img" />
        <StyledText
          color="standardGrey70"
          size="m"
          inline={true}
          className="text"
          textAlign="center"
          weight="light"
        >
          Your match results will be displayed here on
        </StyledText>
        <StyledText color="standardGrey70" size="m" weight="semibold" inline={true}>
          {isSingaporeWebsite ? '19 Sep, by 11:59pm' : '13 Feb, by 11:59pm'}
        </StyledText>
      </StyledFlexDiv>
      <StyledFlexDiv>
        <ADSText textAlign="center" size="xs" hyphensNone={true} color="black80">
          Haven't completed the questionnaire yet? <br /> Let’s do that first!
        </ADSText>
        <StyledNavLink to="/dashboard/questionnaire">
          <ADSButton>To the questionnaire!</ADSButton>
        </StyledNavLink>
      </StyledFlexDiv>
    </StyledContainer>
  );
};
export default function DashboardTabResults(): React.ReactElement {
  // DO NOT CHANGE THIS IN FIRESTORE UNTIL MATCHES ARE ACTUALLY RELEASED.
  // CHANGES ON FIRESTORE WILL AFFECT PRODUCTION.
  const { status: matchFlagStatus, flag: matchesOutFlag } = useIsMatchesReleased();
  const { user } = useAuthHelper();
  const isDevTester = user?.email && DEVELOPER_USER_ACCOUNTS.includes(user?.email);
  if (matchFlagStatus === 'loading') {
    return <DashboardLoading />;
  }
  if (matchesOutFlag || isDevTester) {
    return <MatchResultView />;
  } else {
    return <PendingView />;
  }
}
