import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ADSHeading, ADSPage, ADSPageLoading, ADSText } from '@aphrodite/common-ui';
import { useAuthHelper } from '@aphrodite/common/hooks';

import Navbar from '../components/Navbar';

// Styled Components
const StyledContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  max-width: 100%;
  padding-left: 8%;
  padding-right: 8%;
  margin: auto;
  margin-top: 8%;
  @media only screen and (min-width: 680px) {
    width: 1000px;
    margin-top: 12%;
    padding: 0 4%;
  }
`;

export default function PageLogout(): React.ReactElement {
  const { logout, user } = useAuthHelper();
  const [isLoggingOut, setIsLoggingOut] = useState(true);
  useEffect(() => {
    if (!user) {
      setIsLoggingOut(false);
    }
    logout().then(() => setIsLoggingOut(false));
  }, [logout, setIsLoggingOut, user]);
  if (isLoggingOut) {
    return <ADSPageLoading />;
  }
  return (
    <ADSPage>
      <Navbar />
      <StyledContainer>
        <ADSHeading hyphensNone={true}>You've successfully logged out!</ADSHeading>
        <br />
        <br />
        <ADSText>You may now close this page.</ADSText>
      </StyledContainer>
    </ADSPage>
  );
}
