import React from 'react';
import styled from 'styled-components';

import { ADSCard } from '@aphrodite/common-ui';

const StyledLogoCard = styled(ADSCard)<{ isButton: boolean; isDisabled: boolean }>`
  align-self: center;
  display: flex;
  justify-content: center;
  border-radius: 25px;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
  height: 100px;
  width: 80%;
  padding: 8px;
  opacity: ${(props) => (props.isDisabled ? 0.6 : 1)};
  background-color: ${(props) => (props.isDisabled ? props.theme.colors.paleGrey : 'white')};
  &:hover {
    box-shadow: ${(props) =>
      props.isButton && !props.isDisabled ? '0 14px 14px rgba(0, 0, 0, 0.3)' : 'auto'};
    cursor: ${(props) =>
      props.isButton ? (props.isDisabled ? 'not-allowed;' : 'pointer') : 'default'};
  }
  .logo {
    align-self: center;
    display: flex;
    justify-content: center;
    object-fit: contain;
    max-height: 90%;
    max-width: 80%;
  }
  @media only screen and (min-width: 680px) {
    height: 112px;
    width: 200px;
  }
`;
interface LogoCardProps {
  imgSrc: string;
  alt: string;
  type?: 'card' | 'button';
  disabled?: boolean;
  onClick?: () => void;
}

export default function LogoCard({
  imgSrc,
  alt,
  type = 'card',
  disabled = false,
  onClick,
}: LogoCardProps): React.ReactElement {
  return (
    <StyledLogoCard isButton={type === 'button'} isDisabled={disabled} onClick={onClick}>
      <img src={imgSrc} alt={alt} className="logo" />
    </StyledLogoCard>
  );
}
