import 'regenerator-runtime/runtime';

import { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import { getAnalytics, logEvent, setCurrentScreen } from '@firebase/analytics';

import PrivateRoute from '../components/PrivateRoute';
import PublicRoute from '../components/PublicRoute';
import PageAboutUs from './PageAboutUs';
import PageConfirmEmail from './PageConfirmEmail';
import PageDashboard from './PageDashboard';
import PageDataPrivacy from './PageDataPrivacy';
import PageInclusionStatement from './PageInclusionStatement';
import PageLanding from './PageLanding';
import PageLoginEmail from './PageLoginEmail';
import PageLoginUniversitySelect from './PageLoginUniversitySelect';
import PageLogout from './PageLogout';
import PageNotFound from './PageNotFound';
import PageOnboarding from './PageOnboarding';
import PageOnboardingSafety from './PageOnboardingSafety';
import PagePress from './PagePress';
import PageReport from './PageReport';
import PageSafetyResources from './PageSafetyResources';
import PageSignupEmail from './PageSignupEmail';
import PageSignupUniversitySelect from './PageSignupUniversitySelect';
import PageWaitlist from './PageWaitlist';
import PageWaitlistSubmitSuccess from './PageWaitlistSubmitSuccess';
import PageWithdraw from './PageWithdraw';

export default function Root(): React.ReactElement {
  const analytics = getAnalytics();
  const history = useHistory();
  useEffect(() => {
    // Listen for react browser router changes and update
    history.listen((location: any, _: any) => {
      window.scrollTo(0, 0);
      setCurrentScreen(analytics, location.pathname);
      logEvent(analytics, 'page-view', { path_name: location.pathname });
    });
  }, [analytics, history]);
  return (
    <>
      <Switch>
        <Route component={PageLanding} exact={true} path="/" />
        <Route
          component={PageReport}
          exact={true}
          path={[
            '/reports/uwaterloo-pandemic-edition-3e529da440d9515187efc6febd166be3',
            '/reports/uoft-pandemic-edition-06ee23944c76fa7b39e938fb9814ac92',
          ]}
        />
        <Route
          component={PageInclusionStatement}
          exact={true}
          path="/inclusion-and-hard-decisions"
        />
        <Route component={PageAboutUs} exact={true} path="/about" />
        <Route component={PageSafetyResources} exact={true} path="/safety-resources" />
        <Route component={PageDataPrivacy} exact={true} path="/data-privacy" />
        <Route component={PagePress} exact={true} path="/press" />
        <PublicRoute component={PageConfirmEmail} exact={true} path="/confirm" />
        <PublicRoute component={PageSignupUniversitySelect} exact={true} path={['/start']} />
        <PublicRoute component={PageSignupEmail} exact={true} path={'/start/:indexName'} />
        <PublicRoute component={PageLoginUniversitySelect} exact={true} path={'/login'} />
        <PublicRoute component={PageLoginEmail} exact={true} path={'/login/:indexName'} />
        <Route component={PageWaitlistSubmitSuccess} path={'/waitlist/success/:collegeId'} />
        <Route component={PageWaitlist} exact={true} path={'/waitlist'} />
        <Route component={PageOnboarding} path="/onboarding" exact={true} />
        <Route component={PageOnboardingSafety} path="/onboarding/safety" exact={true} />
        <PrivateRoute component={PageDashboard} path="/dashboard" />
        <Route component={PageLogout} exact={true} path="/logout" />
        <Route component={PageWithdraw} exact={true} path="/withdraw" />
        <Route component={PageNotFound} />
      </Switch>
    </>
  );
}
