import React, { FunctionComponent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { ADSHeading, ADSText } from '@aphrodite/common-ui';
import { GraphData, ReportData, SectionData } from '@aphrodite/common/types/DataReportTypes';

import Graph from '../components/Graph';
import DashboardLoading from '../components/dashboard/DashboardLoading';
import PandemicReport from '../components/reports/ReportPandemic';
import NTUDATA2020 from '../constants/data-reports/ntu2020.json';
import NUSDATA2020 from '../constants/data-reports/nus2020.json';
import SMUDATA2020 from '../constants/data-reports/smu2020.json';
import UOFTDATA2020 from '../constants/data-reports/uoft2020.json';
import UWDATA2020 from '../constants/data-reports/uwaterloo2020.json';

//styled-component
const StyledADSHeading = styled(ADSHeading)`
  margin-top: 32px;
  margin-bottom: 8px;
`;
const StyledSessionTitle = styled(ADSHeading)`
  margin: 8px 0;
  @media only screen and (min-width: 600px) {
    margin-bottom: 20px;
  }
`;
const StyledGraph = styled(Graph)`
  margin-top: 16px;
  @media only screen and (min-width: 600px) {
    margin-top: 20px;
  }
`;

//Main Component

/**
 * Article ADSPage component for the data reports.
 */

enum REPORT_NAMES {
  NUS2020 = 'nus-2020',
  NTU2020 = 'ntu-2020',
  SMU2020 = 'smu-2020',
  UWATERLOO2020 = 'uwaterloo-pandemic-edition-3e529da440d9515187efc6febd166be3',
  UOFT2020 = 'uoft-pandemic-edition-06ee23944c76fa7b39e938fb9814ac92',
}

export function getReportData(pathname: string): ReportData | null {
  if (pathname.includes(REPORT_NAMES.UWATERLOO2020)) {
    return UWDATA2020;
  } else if (pathname.includes(REPORT_NAMES.NUS2020)) {
    return NUSDATA2020;
  } else if (pathname.includes(REPORT_NAMES.NTU2020)) {
    return NTUDATA2020;
  } else if (pathname.includes(REPORT_NAMES.SMU2020)) {
    return SMUDATA2020;
  } else if (pathname.includes(REPORT_NAMES.UOFT2020)) {
    return UOFTDATA2020;
  }
  return null;
}
export const renderGraphs = (graphs: GraphData[]) => {
  return graphs ? graphs.map((graph) => <StyledGraph key={graph.title} {...graph} />) : null;
};

export const renderSection = (section: SectionData) => {
  const { header, subheader, sectionTitle, description, graphs } = section;
  return (
    <>
      {header ? (
        <StyledADSHeading level="2" weight="bold">
          {header}
        </StyledADSHeading>
      ) : null}
      {subheader ? (
        <ADSText hyphensNone={true} size="m">
          {subheader}
        </ADSText>
      ) : null}
      <br />
      {sectionTitle ? (
        <StyledSessionTitle level="2" weight="semibold">
          {sectionTitle}
        </StyledSessionTitle>
      ) : null}
      {description ? (
        <>
          <ADSText hyphensNone={true} size="l">
            {description}
          </ADSText>
          <br />
        </>
      ) : null}
      {graphs ? renderGraphs(graphs) : null}
    </>
  );
};

const PageReport: FunctionComponent<{ pathname: string }> = () => {
  const pathname = useLocation().pathname;
  const data = getReportData(pathname);
  const date = data?.date;
  const numStudents = data?.numStudents;
  const sections = data?.reportContents;
  const university = data?.university;
  const year = data?.dataYear;
  const [rendered, setRendered] = useState(false);
  // The datareport takes awhile to render, so this lets us show a loading spinner while we wait
  useEffect(() => {
    setRendered(true);
  }, [setRendered]);
  if (!rendered || !sections) {
    return <DashboardLoading />;
  }
  return (
    <PandemicReport
      sections={sections}
      date={date}
      numStudents={numStudents}
      university={university}
      year={year}
    />
  );
};

export default PageReport;
