import { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import {
  ADSAnchor,
  ADSButton,
  ADSHeading,
  ADSPage,
  ADSPageLoading,
  ADSText,
} from '@aphrodite/common-ui';
import { LAUNCH_DATE_GLOBAL, LAUNCH_DATE_SINGAPORE } from '@aphrodite/common/constants/Dates';
import { Region } from '@aphrodite/common/constants/Region';
import { useCollegeConfigs, useIsLaunched, useIsSingaporeWebsite } from '@aphrodite/common/hooks';
import { ICollegeConfiguration } from '@aphrodite/common/types/firestore-types';

import LogoCard from '../../components/LogoCard';
import Navbar from '../../components/Navbar';

// Styled Components
const StyledContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 1000px;
  max-width: 100%;
  padding: 0 6%;
  margin: auto;
  margin-top: 12%;
  @media only screen and (min-width: 680px) {
    margin-top: 12%;
    padding: 0 4%;
  }
`;
const StyledLink = styled(NavLink)`
  text-decoration: none;
  width: 100%;
`;
const StyledRedirectLink = styled(NavLink)`
  margin-top: 32px;
  text-decoration: none;
`;
const StyledAnchor = styled(ADSAnchor)`
  margin-top: 32px;
  text-decoration: none;
`;
const StyledUniSelectHeader = styled(ADSHeading)`
  margin-left: -2px;
`;
const StyledTextChunk = styled(ADSText)`
  margin-top: 24px;
  font-size: 60;
`;
const StyledUniList = styled.div`
  margin: 12% 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  align-items: center;
  justify-items: center;
  column-gap: 1%;
  row-gap: 4%;
  width: 100%;
  @media only screen and (min-width: 680px) {
    margin: 6% 0;
    column-gap: 4%;
    row-gap: 8%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const StyledMailToText = styled(ADSAnchor)`
  margin-top: 140px;
  margin-bottom: 10%;
  @media only screen and (min-width: 680px) {
    margin-top: 120px;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
`;

// Main Component
enum RedirectPaths {
  signup = '/start',
  signin = '/login',
}
interface TemplateUniversitySelectProps {
  title: string;
  description: string;
  type: keyof typeof RedirectPaths;
}
const TemplateUniversitySelectPage: FunctionComponent<TemplateUniversitySelectProps> = ({
  title,
  description,
  type, // determines redirection step after the college is selected
}) => {
  const isSingaporeWebsite = useIsSingaporeWebsite();
  const collegeConfigs = useCollegeConfigs();
  const isLaunched = useIsLaunched();
  const run = isSingaporeWebsite ? 'Singapore' : "Valentine's Day";
  if (collegeConfigs === 'loading') return <ADSPageLoading />;
  const redirectPath = RedirectPaths[type];
  const isCorrectRun = (college: ICollegeConfiguration) => {
    if (isSingaporeWebsite) return college.region === Region.SINGAPORE;
    else return college.region !== Region.SINGAPORE;
  };
  const latestLaunchDate = isSingaporeWebsite ? LAUNCH_DATE_SINGAPORE : LAUNCH_DATE_GLOBAL;
  const launchDate = latestLaunchDate.format('MMMM YYYY');
  return (
    <>
      <div>
        <Navbar />
      </div>
      <ADSPage>
        {isLaunched ? (
          <StyledContainer>
            <StyledUniSelectHeader hyphensNone={true} level="1">
              {title}
            </StyledUniSelectHeader>
            <StyledTextChunk>{description}</StyledTextChunk>
            <StyledUniList>
              {collegeConfigs
                .sort((a, b) => {
                  if (isCorrectRun(a) && !isCorrectRun(b)) return -1;
                  if (isCorrectRun(b) && !isCorrectRun(a)) return 1;
                  return a.indexName.localeCompare(b.indexName);
                })
                .map((college) => {
                  const collegeIsDisabled = !college.isEnabled || !isCorrectRun(college);
                  return (
                    <StyledLink
                      key={college.indexName}
                      to={{
                        pathname: collegeIsDisabled
                          ? ''
                          : `${redirectPath}/${college.shortform.toLowerCase()}`,
                      }}
                    >
                      <LogoCard
                        imgSrc={college.landingLogo}
                        alt={`${college.shortform} logo`}
                        type="button"
                        disabled={collegeIsDisabled}
                      />
                    </StyledLink>
                  );
                })}
            </StyledUniList>
            <StyledMailToText to="/waitlist">
              <ADSText color="darkGrey" textDecoration="underline" size="s">
                Want to bring the Aphrodite Project to your school? Join our waitlist!
              </ADSText>
            </StyledMailToText>
          </StyledContainer>
        ) : (
          <StyledContainer>
            <ADSHeading level="1">Coming soon!</ADSHeading>
            <StyledTextChunk size="m">
              Our {run} run has not started yet.
              <br /> Stay tuned for our next run this coming {launchDate}!
            </StyledTextChunk>
            <StyledRedirectLink to="/">
              <ADSButton>Click here to return home</ADSButton>
            </StyledRedirectLink>
            {isSingaporeWebsite ? (
              <StyledAnchor to="https://aphrodite.global">
                <ADSButton styleType="pinkGradient">Check out our Global website!</ADSButton>
              </StyledAnchor>
            ) : (
              <StyledAnchor to="https://sg.aphrodite.global">
                <ADSButton styleType="pinkGradient">Check out our Singapore website!</ADSButton>
              </StyledAnchor>
            )}
          </StyledContainer>
        )}
      </ADSPage>
    </>
  );
};

export default TemplateUniversitySelectPage;
