import { ReactNode, SyntheticEvent } from 'react';
import styled, { css } from 'styled-components';

// Styled Components
const ButtonMixin = css`
  display: inline-block;
`;
const TextMixin = css`
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
`;
const StyledAnchor = styled.a<{ type?: 'button' | 'text'; underline: boolean }>`
  color: inherit;
  ${({ underline }) =>
    !underline &&
    css`
      text-decoration: none;
    `}
  ${({ type }) => (type === 'button' && ButtonMixin) || (type === 'text' && TextMixin)}
`;

export interface Props {
  children: ReactNode;
  className?: string;
  onClick?: (e: SyntheticEvent<HTMLAnchorElement>) => void;
  target?: '_blank' | '_self';
  to: string;
  type?: 'button' | 'text';
  underline?: boolean;
  wrapperStyle?: React.CSSProperties;
}

export default function ADSAnchor({
  children,
  className,
  onClick,
  target,
  to,
  type,
  underline = false,
  wrapperStyle = {},
}: Props): JSX.Element {
  return (
    <StyledAnchor
      className={className}
      href={to}
      onClick={onClick ? onClick : undefined}
      rel="Noopener"
      target={target}
      type={type}
      underline={underline}
      style={wrapperStyle}
    >
      {children}
    </StyledAnchor>
  );
}
