import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

import pinkGradientHeart from '@aphrodite/assets/icons/pink-gradient-heart.png';
import { ADSInput } from '@aphrodite/common-ui';
import { ADSText } from '@aphrodite/common-ui';

const StyledLogo = styled.img`
  height: 48px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
`;
const StandardContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 32px;
  width: 100%;
  @media only screen and (min-width: 1280px) {
    margin-top: 0px;
    max-width: 320px;
  }
`;
const CardContainer = styled.div`
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-top: -24px;
  padding: 2% 6%;
  z-index: 0;
`;
const InputContainer = styled.div`
  display: block;
  padding-bottom: 4%;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`;
const StyledInputSection = styled.div`
  padding-top: 12px;
  padding-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
`;

interface Props {
  name: string;
  email: string;
  handleNameInput: (e: ChangeEvent<HTMLInputElement>) => void;
  handleEmailInput: (e: ChangeEvent<HTMLInputElement>) => void;
  title: string;
}

export default function PlayCupidInvitationCard({
  name,
  email,
  handleNameInput,
  handleEmailInput,
  title,
}: Props): React.ReactElement {
  return (
    <StandardContainer>
      <StyledLogo src={pinkGradientHeart} alt="pink gradient heart icon" />
      <CardContainer>
        <ADSText weight="semibold" wrapperStyle={{ marginTop: '8px' }}>
          {title}
        </ADSText>
        <InputContainer>
          <StyledInputSection>
            <ADSInput
              isValid={true}
              onChange={handleNameInput}
              placeholder="Name"
              value={name}
              wrapperStyle={{ height: '36px', marginTop: '4px' }}
            />
          </StyledInputSection>
          <StyledInputSection>
            <ADSInput
              isValid={true}
              onChange={handleEmailInput}
              placeholder="Email"
              value={email}
              wrapperStyle={{ height: '36px', marginTop: '4px' }}
            />
          </StyledInputSection>
        </InputContainer>
      </CardContainer>
    </StandardContainer>
  );
}
