import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { keyframes } from 'styled-components';

import { ADSHeading } from '@aphrodite/common-ui';
import { ADSText } from '@aphrodite/common-ui';

import { coreMemberInfo } from '../../constants/aboutUsConstants';

const StyledCardDiv = styled.div`
  left: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  padding-left: 8%;
  height: 50vh;
  width: 100vw;
  background: linear-gradient(transparent 0%, white 10%);
  @media only screen and (min-width: 680px) {
    height: 50vh;
    min-height: 600px;
  }
  @media only screen and (min-width: 1440px) {
    padding-left: 16%;
    padding-right: 16%;
  }
`;
const StyledRotatingBannerCard = styled(StyledCardDiv)`
  height: 80vh;
  min-height: 500px;
  justify-content: center;
  @media only screen and (min-width: 680px) {
    margin-top: 60px;
  }
`;
const StyledFlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  height: 80vh;
  @media only screen and (min-width: 680px) {
    justify-content: center;
    min-height: 600px;
    height: fit-content;
  }
`;
const StyledText = styled(ADSText)`
  @media only screen and (min-width: 680px) {
    min-width: 440px;
    padding-right: 48%;
  }
`;
const StyledBannerDiv = styled.div`
  width: 90vw;
  height: fit-content;
  height: 64px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media only screen and (min-width: 680px) {
    min-width: 440px;
    height: fit-content;
    width: 100%;
  }
`;
const ScrollAnimation = keyframes`
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: calc(-72px * ${coreMemberInfo.length});
  }
`;
const StyledImageBanner = styled.div`
  height: 64px;
  display: flex;
  flex-direction: row;
  overflow: visible;
  gap: 8px;
  animation: ${ScrollAnimation} 50s linear infinite;
`;

interface StudentImageProps {
  imgSrc: string;
}

const StyledImgDiv = styled.div<StudentImageProps>`
  display: flex;
  min-width: 64px;
  height: 64px;
  background-image: url(${(props) => props.imgSrc});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
`;
const StudentImages: FunctionComponent<StudentImageProps> = ({ imgSrc }) => {
  return <StyledImgDiv imgSrc={imgSrc} />;
};

export default function LandingByStudent(): React.ReactElement {
  return (
    <StyledRotatingBannerCard>
      <StyledFlexDiv>
        <ADSHeading weight="bold" textAlign="left" level="1">
          By Students. <br /> For Students.
        </ADSHeading>
        <StyledBannerDiv>
          <StyledImageBanner>
            {coreMemberInfo.map((info, i) => {
              return <StudentImages imgSrc={info.imgSrc} key={i} />;
            })}
            {coreMemberInfo.map((info, i) => {
              return <StudentImages imgSrc={info.imgSrc} key={i} />;
            })}
          </StyledImageBanner>
        </StyledBannerDiv>
        <StyledText size="m">
          A one-off, student-run digital event. <br />
          Designed to help university students like you to meet your ideal date (or friend!) from
          your school!
        </StyledText>
      </StyledFlexDiv>
    </StyledRotatingBannerCard>
  );
}
