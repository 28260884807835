import { FunctionComponent } from 'react';

import TemplateUniversitySelectPage from './templates/TemplateUniversitySelectPage';

// Main Component
const PageLoginUniversitySelect: FunctionComponent<{}> = () => {
  return (
    <TemplateUniversitySelectPage
      title="Welcome back!"
      description="Which college are you from?"
      type="signin"
    />
  );
};

export default PageLoginUniversitySelect;
