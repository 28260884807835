import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ADSAnchor, ADSButton, ADSHeading, ADSInput, ADSPage, ADSText } from '@aphrodite/common-ui';
import { useAuthHelper, useGetDomainsForCollege } from '@aphrodite/common/hooks';

import Navbar from '../../components/Navbar';
import COLLEGE_CONSTANTS, { CollegesEnum } from '../../constants/College';
import MagicLinkInfo from '../MagicLinkInfo';

// Styled components
const StyledContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 1000px;
  max-width: 100%;
  padding-left: 8%;
  padding-right: 8%;
  margin: auto;
  padding-top: 8%;
  @media only screen and (min-width: 680px) {
    padding: 0 4%;
    padding-top: 8%;
  }
`;
const StyledHeader = styled(ADSHeading)`
  hyphens: none;
  line-height: 110%;
  margin: 4% 0%;
  display: table-caption;
  font-weight: 500;
`;
const StyledTextChuck = styled(ADSText)`
  margin: 4% 0%;
  hyphens: none;
  text-decoration: none;
`;
const StyledInputSubmit = styled.div`
  margin-top: 2%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  gap: 4%;
  margin-bottom: 24px;
`;
const StyledButton = styled(ADSButton)``;
const StyledErrorText = styled(ADSText)<{ isEmailValid: boolean }>`
  ${(props) => {
    if (!props.isEmailValid) {
      return `color: red;`;
    }
  }}
`;

const StyledMailToText = styled(ADSAnchor)`
  margin: 18% 0%;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
  @media only screen and (min-width: 680px) {
    margin: 8% 0%;
  }
`;

// Used for interpolation in the title and description
type CopywritingProps = {
  projectName: string;
  schoolName: string;
};
interface TemplateEmailPageProps {
  title: (props: CopywritingProps) => string;
  description: (props: CopywritingProps) => string;
}

export default function PageEmailVerification({
  title,
  description,
}: TemplateEmailPageProps): React.ReactElement {
  const { sendSignInLinkToEmail } = useAuthHelper();
  const history = useHistory();
  const pathParams: { indexName: string } = useParams();
  const uniKeyString = pathParams.indexName;
  const uniKey = uniKeyString as CollegesEnum;
  const indexName = COLLEGE_CONSTANTS.COLLEGE_NAMES[uniKey];
  if (!indexName) {
    history.push(`/start`);
  }
  let projectName = indexName;
  if (projectName === 'Harvard' || projectName === 'MIT') {
    projectName = 'MITxHarvard';
  }
  const [email, setEmail] = useState('');
  const [isEmailValid, setEmailValid] = useState(true);
  const [confirmEmail, setConfirmEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail((e.target.value as string).toLowerCase());
  };
  const onSubmitEmail = async () => {
    if (isLoading) {
      return; // Don't submit twice.
    }
    setEmailValid(true);
    setIsLoading(true);
    // Check if the user actually typed in an email
    if (!COLLEGE_CONSTANTS.EMAIL_REGEX.test(email)) {
      setEmailValid(false);
      setIsLoading(false);
      return;
    }
    try {
      await sendSignInLinkToEmail(uniKey, email);
      setIsLoading(false);
    } catch (error) {
      setEmailValid(false);
      setIsLoading(false);
      return;
    }
    // Valid email entered
    // Sign out the current user, and sign this user in
    setConfirmEmail(true);
  };
  const schoolEmail = COLLEGE_CONSTANTS.COLLEGE_EMAILS[uniKey];
  const schoolName = COLLEGE_CONSTANTS.COLLEGE_NAMES[uniKey];
  const emailFormatMessage = `Only ${schoolName} emails of the following formats are allowed: ${useGetDomainsForCollege(
    uniKey,
  ).join(', ')}.`;
  const renderUBCHelp = () => {
    if (schoolName !== 'UBC') {
      return null;
    }
    return (
      <>
        <br />
        Haven't created your UBC student email? You can find out how to do so{' '}
        <ADSAnchor
          target="_blank"
          to="https://it.ubc.ca/services/email-voice-internet/ubc-student-email-service"
        >
          <b>
            <u>here.</u>
          </b>
        </ADSAnchor>
        <br />
        (It can take up to two hours to activate!)
      </>
    );
  };
  const copywritingProps = { schoolName, projectName };
  return (
    <>
      <Navbar />
      {!confirmEmail ? (
        <ADSPage>
          <StyledContainer>
            <StyledHeader level="1">{title(copywritingProps)}</StyledHeader>
            <StyledTextChuck>
              {description(copywritingProps)}
              <br />
              <br />
              You will have to request for a new link here each time you sign in!
            </StyledTextChuck>
            <StyledInputSubmit>
              <ADSInput
                onChange={onEmailChange}
                onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    onSubmitEmail();
                  }
                }}
                placeholder={schoolEmail}
                isValid={isEmailValid}
                value={email}
                wrapperStyle={{ margin: '4% 0%' }}
              />
              <StyledButton loading={isLoading} onClick={onSubmitEmail}>
                Continue
              </StyledButton>
            </StyledInputSubmit>
            <StyledErrorText color="standardGrey70" size="xs" isEmailValid={isEmailValid}>
              {emailFormatMessage}
              <br />
              {renderUBCHelp()}
            </StyledErrorText>
            <StyledMailToText to="mailto:cupids@aphrodite.global">
              <ADSText color="darkGrey" textDecoration="underline" size="s">
                Having difficultes with your {schoolName ?? 'school'} email? Let us know!
              </ADSText>
            </StyledMailToText>
          </StyledContainer>
        </ADSPage>
      ) : (
        <MagicLinkInfo email={email} projectName={projectName} />
      )}
    </>
  );
}
