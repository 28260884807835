import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ADSAnchor, ADSHeading, ADSPage, ADSText } from '@aphrodite/common-ui';

// Styled Components
const StyledContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 1000px;
  max-width: 100%;
  padding-left: 8%;
  padding-right: 8%;
  margin: auto;
  margin-top: 5%;
  @media only screen and (max-width: 680px) {
    margin-top: 12%;
  }
`;

const StyledHeader = styled(ADSHeading)`
  hyphens: none;
  line-height: 110%;
  margin: 4% 0%;
  display: table-caption;
  font-weight: 500;
`;

const StyledTextChunk = styled(ADSText)`
  margin: 4% 0%;
  hyphens: none;
  text-decoration: none;
`;

// Main Component
interface Props {
  email: string;
  projectName?: string; // optional for use in generic login page
}

export default function MagicLinkInfo({ email, projectName }: Props): React.ReactElement {
  const [seconds, setSeconds] = useState(0);

  const isResendOTPEnabled = useCallback(() => {
    return seconds === 0;
  }, [seconds]);

  useEffect(() => {
    // On Component mount, set seconds to 60.
    setSeconds(60);
  }, []);
  useEffect(() => {
    if (isResendOTPEnabled()) return;

    const intervalId = setInterval(() => {
      setSeconds(seconds - 1);
    }, 1000);
    // Clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [seconds, isResendOTPEnabled]);
  const renderDisclaimer = () => {
    return (
      <ADSText>
        If you still do not receive the email:
        <br />
        1. Go to your mail settings,
        <br />
        2. Add "onboarding@aphroditeonboarding.com" and "cupids@security.aphroditeproject.co" as a
        safe sender.
        <br />
        3. Try requesting the sign in link again.
      </ADSText>
    );
  };
  return (
    <ADSPage>
      <StyledContainer>
        <StyledHeader level="1">
          We've sent you a magic sign-in link! Check your school inbox!
        </StyledHeader>
        <StyledTextChunk>{'Last step, promise!'}</StyledTextChunk>
        <ADSText>
          {'Please check your email '}
          <u>{email.toLowerCase()}</u> for a magic link to sign-in!
        </ADSText>
        <br />
        <ADSText color="standardGrey70" size={'xs'} wrapperStyle={{ margin: '2% 0%' }}>
          {'If you cant find it, check your junk mailbox! If you are still experiencing issues, '}
          <ADSAnchor target="_blank" to="mailto: cupids@aphrodite.global" type="text">
            <ADSText inline={true} size="xs" textDecoration="underline" weight="semibold">
              drop us an email!
            </ADSText>
          </ADSAnchor>
        </ADSText>
        {renderDisclaimer()}
      </StyledContainer>
    </ADSPage>
  );
}
