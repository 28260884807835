import styled from 'styled-components';

import { ADSLoading } from '@aphrodite/common-ui';

// Styled Components
const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  overflow-y: hidden;
`;

export default function DashboardLoading(): React.ReactElement {
  return (
    <StyledContainer>
      <ADSLoading size="l" />
    </StyledContainer>
  );
}
