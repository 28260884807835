import React, { FunctionComponent } from 'react';
import Confetti from 'react-confetti';

interface Props {
  width: number; // width of the confetti frame
  height: number; // height of the confetti frame
}

const ADSConfetti: FunctionComponent<Props> = ({ width, height }) => {
  // This confetti comes from the centre of the screen
  return (
    <view>
      <Confetti
        recycle={false}
        numberOfPieces={20}
        colors={['#e82c3a', '#ffe3eb']}
        confettiSource={{ x: width / 2, y: height, w: 0, h: 0 }}
        initialVelocityX={{ min: 0, max: 10 }}
        gravity={-0.1}
        initialVelocityY={{ min: -5, max: 0 }}
        drawShape={(ctx) => {
          ctx.beginPath();
          for (let i = 0; i < 22; i++) {
            const angle = 0.35 * i;
            const x = 16 * Math.pow(Math.sin(angle), 3) + 25;
            const y =
              13 * Math.cos(angle) -
              5 * Math.cos(2 * angle) -
              2 * Math.cos(3 * angle) -
              Math.cos(4 * angle) +
              20;
            ctx.lineTo(x, y);
          }
          ctx.stroke();
          ctx.closePath();
        }}
      />
      <Confetti
        recycle={false}
        numberOfPieces={20}
        colors={['#e82c3a', '#ffe3eb']}
        confettiSource={{ x: width / 2, y: height, w: 0, h: 0 }}
        initialVelocityX={{ min: -10, max: 0 }}
        gravity={-0.1}
        initialVelocityY={{ min: -5, max: 0 }}
        drawShape={(ctx) => {
          ctx.beginPath();
          for (let i = 0; i < 22; i++) {
            const angle = 0.35 * i;
            const x = 16 * Math.pow(Math.sin(angle), 3) + 25;
            const y =
              13 * Math.cos(angle) -
              5 * Math.cos(2 * angle) -
              2 * Math.cos(3 * angle) -
              Math.cos(4 * angle) +
              20;
            ctx.lineTo(x, y);
          }
          ctx.stroke();
          ctx.closePath();
        }}
      />
    </view>
  );
};

export default ADSConfetti;
