import styled from 'styled-components';

// Styled Components
const StyledDivider = styled.hr`
  border: none;
  border-top: 1px solid ${(props) => props.theme.colors.black80};
`;
export default function ProfileInfoCard(): React.ReactElement {
  return <StyledDivider />;
}
