import { useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';

import { ADSPage } from '@aphrodite/common-ui';
import { SHOW_PARTNERSHIPS } from '@aphrodite/common/constants/Partnerships';
import { useIsMobile } from '@aphrodite/common/hooks';
import { useIsSingaporeWebsite } from '@aphrodite/common/hooks';

import DashboardTabMatchProfile from '..//components/dashboard/DashboardTabMatchProfile';
import Footer from '../components/Footer';
import DashboardMenuBarDesktop from '../components/dashboard/DashboardMenuBarDesktop';
import DashboardMenuBarMobile from '../components/dashboard/DashboardMenuBarMobile';
import DashboardPlayCupid from '../components/dashboard/DashboardPlayCupid';
import DashboardTabMain from '../components/dashboard/DashboardTabMain';
import DashboardTabNaughtyList from '../components/dashboard/DashboardTabNaughtyList';
import DashboardTabPartnership from '../components/dashboard/DashboardTabPartnership';
import DashboardTabProfile from '../components/dashboard/DashboardTabProfile';
import DashboardTabQuestionnaire from '../components/dashboard/DashboardTabQuestionnaire';
import DashboardTabReport from '../components/dashboard/DashboardTabReport';
import DashboardTabResults from '../components/dashboard/DashboardTabResults';
import ProfileTabQuestionnaire from '../components/profile/ProfileTabQuestionnaire';
import { NAV_ITEMS } from '../constants/Navigation';

// Styled Component

export default function PageDashboard(): React.ReactElement {
  const isMobile = useIsMobile('TABLET');
  const pathname = useLocation().pathname;
  const isSingaporeWebsite = useIsSingaporeWebsite();
  useEffect(() => {
    document.body.scrollTop = 0;
  }, [pathname]);
  return (
    <ADSPage>
      {isMobile ? <DashboardMenuBarMobile /> : <DashboardMenuBarDesktop />}
      <Route path={NAV_ITEMS.DASHBOARD.to} component={DashboardTabMain} exact={true} />
      <Route path={NAV_ITEMS.QUESTIONNAIRE.to} component={DashboardTabQuestionnaire} />
      <Route path={NAV_ITEMS.PROFILE.to} component={DashboardTabProfile} exact={true} />
      <Route path={NAV_ITEMS.PLAY_CUPID.to} component={DashboardPlayCupid} exact={true} />
      {isSingaporeWebsite && SHOW_PARTNERSHIPS && (
        <Route path={NAV_ITEMS.PARTNERSHIP.to} component={DashboardTabPartnership} exact={false} />
      )}
      <Route path={NAV_ITEMS.DATA_REPORT.to} component={DashboardTabReport} exact={false} />
      <Route path={NAV_ITEMS.RESULTS.to} component={DashboardTabResults} exact={false} />
      <Route path="/dashboard/match_profile" component={DashboardTabMatchProfile} exact={false} />
      <Route
        path="/dashboard/profile-questionnaire"
        component={ProfileTabQuestionnaire}
        exact={false}
      />
      <Route path={NAV_ITEMS.NAUGHTY_LIST.to} component={DashboardTabNaughtyList} exact={false} />
      <Route path={NAV_ITEMS.BLOCK_LIST.to} component={DashboardTabNaughtyList} exact={false} />
      {isMobile && (
        <Route
          path={[NAV_ITEMS.DASHBOARD.to, NAV_ITEMS.PROFILE.to, NAV_ITEMS.DATA_REPORT.to]}
          component={Footer}
          exact={true}
        />
      )}
    </ADSPage>
  );
}
