import axios from 'axios';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';
import styled, { css } from 'styled-components';

import { ReactComponent as CheckIcon } from '@aphrodite/assets/icons/check.svg';
import { ReactComponent as WarningIcon } from '@aphrodite/assets/icons/warning.svg';
import arrowDown from '@aphrodite/assets/logos/arrow-down.svg';
import arrowUp from '@aphrodite/assets/logos/arrow-up.svg';
import { ADSButton, ADSLoading, ADSText } from '@aphrodite/common-ui';
import { useAuthHelper, useIsQuestionnaireDisabled } from '@aphrodite/common/hooks';
import { IUserProfile } from '@aphrodite/common/types/firestore-types';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';
import { firebaseStorage } from '@aphrodite/firebase/firebase';
import { DocumentReference, doc } from '@firebase/firestore';
import { getDownloadURL, ref } from '@firebase/storage';

import { MATCH_POOL_FIELD } from '../../constants/Questionnaire';
import { IQuestionnaire } from '../../constants/Questionnaire';
import { createScrollSpeedChecker } from '../../helpers/events';
import { getFirstIncompleteQuestionIndex, getQuestionsForPool } from '../../helpers/questionnaire';
import { getUniversityRef } from '../../helpers/university';
import DashboardLoading from '../dashboard/DashboardLoading';
import Question from './Question';

// Styled Components
const StyledArrowButton = styled(ADSButton)<{ disabled: boolean }>`
  opacity: ${(props) => (props.disabled ? '60%' : undefined)};
  width: fit-content;
  &:hover {
    opacity: ${(props) => (props.disabled ? '60%' : undefined)};
  }
`;
const StyledArrowButtonGroup = styled.div`
  bottom: 32px;
  display: flex;
  position: fixed;
  right: 32px;
  gap: 8px;
`;
const StyledIcon = styled.img`
  height: 1em;
  width: 1em;
`;
const StyledProgressBarContainer = styled.div`
  background: ${({ theme }) => theme.colors.transparentPalePurple};
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 8px;
  @media only screen and (min-width: 1024px) {
    width: calc(('100%' - 260px));
  }
`;
const StyledProgressBar = styled.div<{ questionsDone: number }>`
  position: fixed;
  bottom: 0;
  height: 8px;
  width: ${(props) => `${props.questionsDone}%`};
  background: ${({ theme }) => theme.gradients.progressGradient};
  @media only screen and (min-width: 1024px) {
    left: 260px;
  }
`;
const StyledViewFrame = styled.div`
  height: 100vh;
  overflow: hidden;
`;
const StyledScrollContainer = styled.div<{ scrollDistance: number }>`
  transition: transform 600ms ease 0s;
  transform: translateY(${(props) => props.scrollDistance}vh);
`;
const StyledFullscreenQuestionContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  justify-content: center;
  margin: auto;
  max-width: 100%;
  padding: 0 8%;
  width: ${(props) => props.theme.spacing.questionnaireWidth};
  @media only screen and (min-width: 1024px) {
    padding-left: 10%;
    padding-right: 10%;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}
`;

const SCREEN_SCROLL_DIST_MULTIPLIER = -100;
const checkScrollSpeed = createScrollSpeedChecker(150);

export default function Questionnaire(): React.ReactElement {
  const { user } = useAuthHelper();
  const history = useHistory();
  const parsedQuery = queryString.parse(useLocation().search);
  const startFromLast = parsedQuery['resume'] === 'true';
  const [questionIndex, setQuestionIndex] = useState(0);
  const [questionnaire, setQuestionnaire] = useState<IQuestionnaire | null>(null);
  const [scrollDistance, setScrollDistance] = useState(0);
  const [firstScrollYPosition, setFirstScrollYPosition] = useState(-1);
  const [shouldMoveToQuestion, setShouldMoveToQuestion] = useState(startFromLast);
  const [status, setStatus] = useState<IStatus>('saved');
  const isQuestionnaireDisabled = useIsQuestionnaireDisabled();
  // Firebase Storage refs
  const userProfileRef = doc(
    firebaseFirestore,
    `UserProfiles/${user?.uid}`,
  ) as DocumentReference<IUserProfile>;
  const userQuestionnaireRef = doc(firebaseFirestore, `Questionnaire/${user!.uid}`);
  // Retrieve data
  const { status: userQuestionnaireStatus, data: userQuestionnaireDataRaw } =
    useFirestoreDocData<any>(userQuestionnaireRef);
  const { status: userProfileStatus, data: userProfileData } =
    useFirestoreDocData<IUserProfile>(userProfileRef);
  const userQuestionnaireData = userQuestionnaireDataRaw ?? {};
  const poolTypeResponse = userQuestionnaireData ? userQuestionnaireData[MATCH_POOL_FIELD] : null;
  const questions = useMemo(
    () => (questionnaire ? getQuestionsForPool(questionnaire.fields, poolTypeResponse) : []),
    [poolTypeResponse, questionnaire],
  );
  const firstIncompleteQuestionIndex = getFirstIncompleteQuestionIndex(
    userProfileData,
    userQuestionnaireData,
    questions,
  );
  useEffect(() => {
    let unmounted = false;
    if (userProfileStatus === 'loading') {
      return;
    }
    const { college } = userProfileData;
    const storageRef = ref(firebaseStorage, getUniversityRef(college));
    getDownloadURL(storageRef).then((url) => {
      axios.get(url).then((response) => {
        if (!unmounted) setQuestionnaire(response.data);
      });
    });
    return () => {
      unmounted = true;
    };
  }, [userProfileData, userProfileStatus]);
  useEffect(() => {
    if (
      shouldMoveToQuestion &&
      firstIncompleteQuestionIndex !== null &&
      firstIncompleteQuestionIndex !== -1
    ) {
      setQuestionIndex(firstIncompleteQuestionIndex);
      setScrollDistance(firstIncompleteQuestionIndex * SCREEN_SCROLL_DIST_MULTIPLIER);
      setShouldMoveToQuestion(false);
    }
  }, [setShouldMoveToQuestion, firstIncompleteQuestionIndex, startFromLast, shouldMoveToQuestion]);
  const handleFinalSubmit = () => {
    if (firstIncompleteQuestionIndex && isLastQuestion(firstIncompleteQuestionIndex)) {
      // There's no more scroll, so we just show an error to let the user know that this must be completed.
      setStatus('error');
      return;
    } else if (firstIncompleteQuestionIndex && firstIncompleteQuestionIndex !== -1) {
      setQuestionIndex(firstIncompleteQuestionIndex === -1 ? 0 : firstIncompleteQuestionIndex);
      setScrollDistance(
        (firstIncompleteQuestionIndex === -1 ? 0 : firstIncompleteQuestionIndex) *
          SCREEN_SCROLL_DIST_MULTIPLIER,
      );
      return;
    }
    history.push('/dashboard/questionnaire');
  };
  if (userQuestionnaireStatus === 'loading' || userProfileStatus === 'loading' || !questionnaire) {
    return <DashboardLoading />;
  }
  const handleNextQuestion = () => {
    if (!isLastQuestion(questionIndex)) {
      const nextIndex = questionIndex + 1;
      setQuestionIndex(nextIndex);
      setScrollDistance(nextIndex * SCREEN_SCROLL_DIST_MULTIPLIER);
    }
  };
  const handlePrevQuestion = () => {
    if (!isFirstQuestion(questionIndex)) {
      let prevIndex = questionIndex - 1;
      setQuestionIndex(prevIndex);
      setScrollDistance(prevIndex * SCREEN_SCROLL_DIST_MULTIPLIER);
    }
  };
  const handleSetStatus = (status: IStatus) => setStatus(status);
  const isFirstQuestion = (index: number): boolean => {
    return index <= 0;
  };
  const isLastQuestion = (index: number): boolean => {
    return index >= questions.length - 1;
  };
  return (
    <>
      <StyledProgressBarContainer>
        <StyledProgressBar questionsDone={(questionIndex / (questions.length - 1)) * 100} />
      </StyledProgressBarContainer>
      <StyledViewFrame>
        {questions.map((question, index) => {
          const { id, properties, title, type, validations } = question;
          const { database } = properties;
          const value =
            database === 'private'
              ? userQuestionnaireData[id]
              : userProfileData[id as keyof IUserProfile];
          return (
            <StyledScrollContainer
              key={id}
              scrollDistance={scrollDistance}
              onWheel={(e) => {
                if (questionIndex !== index) {
                  return;
                }
                const scrollSpeed = checkScrollSpeed(e.deltaY);
                if (scrollSpeed > 25) {
                  // EMPIRICALLY DETERMINED NUMBER
                  // THIS IS SPEED
                  handleNextQuestion();
                } else if (scrollSpeed < -25) {
                  handlePrevQuestion();
                }
              }}
              onTouchMove={(e) => {
                if (firstScrollYPosition === -1) {
                  setFirstScrollYPosition(e.touches[0].pageY);
                  return;
                }
                if (questionIndex !== index) {
                  return;
                }
                const position = e.touches[0].pageY;
                if (firstScrollYPosition - position > 85) {
                  // THIS IS DISTANCE.
                  handleNextQuestion();
                } else if (position - firstScrollYPosition > 80) {
                  handlePrevQuestion();
                }
              }}
              onTouchEnd={(e) => {
                setFirstScrollYPosition(-1);
              }}
            >
              <StyledFullscreenQuestionContainer disabled={isQuestionnaireDisabled}>
                <Question
                  isDisabled={isQuestionnaireDisabled}
                  currentQuestion={questionIndex}
                  questionIndex={index}
                  isFinal={isLastQuestion(index)}
                  onClickNext={handleNextQuestion}
                  onFinalSubmit={handleFinalSubmit}
                  id={id}
                  properties={properties}
                  onStatusChange={handleSetStatus}
                  title={title}
                  type={type}
                  validations={validations}
                  value={value}
                />
              </StyledFullscreenQuestionContainer>
            </StyledScrollContainer>
          );
        })}
        <StatusBox status={status} />
        <StyledArrowButtonGroup>
          <StyledArrowButton
            disabled={isFirstQuestion(questionIndex)}
            onClick={handlePrevQuestion}
            styleType="pinkGradient"
          >
            <StyledIcon src={arrowUp} alt="up arrow" />
          </StyledArrowButton>
          <StyledArrowButton
            disabled={isLastQuestion(questionIndex)}
            onClick={handleNextQuestion}
            styleType="pinkGradient"
          >
            <StyledIcon src={arrowDown} alt="down arrow" />
          </StyledArrowButton>
        </StyledArrowButtonGroup>
      </StyledViewFrame>
    </>
  );
}

const StyledStatusContainer = styled.div`
  background: ${({ theme }) => theme.gradients.translucent};
  border-radius: 8px;
  bottom: 32px;
  display: flex;
  flex-direction: row;
  height: 28px;
  left: 32px;
  padding: 4px;
  position: fixed;
  @media only screen and (min-width: 1024px) {
    left: ${({ theme }) => `${parseFloat(theme.spacing.navbarDesktopWidth) + 32}px`};
  }
`;
const StyledStatusIconContainer = styled.div`
  height: 20px;
  width: 20px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 680px) {
    width: 24px;
    height: 24px;
    padding-bottom: 8px;
  }
`;

export type IStatus = 'loading' | 'saved' | 'error';
interface StatusBoxProps {
  status: IStatus;
}

function StatusBox({ status }: StatusBoxProps): React.ReactElement {
  switch (status) {
    case 'loading':
      return (
        <StyledStatusContainer>
          <StyledStatusIconContainer>
            <ADSLoading isMarginOffset={false} size="s" />
          </StyledStatusIconContainer>
          <ADSText size="s" wrapperStyle={{ marginLeft: '8px' }}>
            Saving
          </ADSText>
        </StyledStatusContainer>
      );
    case 'saved':
      return (
        <StyledStatusContainer>
          <StyledStatusIconContainer>
            <CheckIcon width="16px" height="16px" />
          </StyledStatusIconContainer>
          <ADSText size="s" wrapperStyle={{ marginLeft: '8px' }}>
            Saved
          </ADSText>
        </StyledStatusContainer>
      );
    case 'error':
      return (
        <StyledStatusContainer>
          <StyledStatusIconContainer>
            <WarningIcon />
          </StyledStatusIconContainer>
          <ADSText size="s" wrapperStyle={{ marginLeft: '8px' }}>
            Error saving! Try the last question again!
          </ADSText>
        </StyledStatusContainer>
      );
  }
}
