import { ChangeEvent, KeyboardEvent, MouseEvent, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { ReactComponent as AddIcon } from '@aphrodite/assets/icons/add.svg';
import { ReactComponent as CloseIcon } from '@aphrodite/assets/icons/close.svg';

// Mixins
const ShakeKeyframe = keyframes`
  0% {
    margin-left: 0rem;
  }
  25% {
    margin-left: 0.5rem;
  }
  75% {
    margin-left: -0.5rem;
  }
  100% {
    margin-left: 0rem;
  }
`;
const InvalidMixin = css`
  border-bottom: ${({ theme }) => `1px solid red`};
  animation: ${ShakeKeyframe} 0.2s ease-in-out 0s 2 !important;
  &:focus {
    border-bottom: 2px solid red;
  }
`;
// Styled Components
const StyledInput = styled.textarea<{ isError: boolean }>`
  border: none;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.transparentPurple}`};
  background: none;
  color: ${({ theme }) => `${theme.colors.darkPurple}`};
  display: block;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  font-weight: 400;
  height: 40px;
  hyphens: auto;
  line-height: 28px;
  overflow: hidden;
  overflow-wrap: break-word;
  resize: none;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  &:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    border-bottom: ${({ theme }) => `2px solid ${theme.colors.darkPurple}`};
  }
  @media only screen and (min-width: 680px) {
    height: 44px;
    font-size: 22px;
    line-height: 34px;
  }
  ${(props) => {
    return props.isError ? InvalidMixin : undefined;
  }}
`;
const StyledTable = styled.table`
  width: 100%;
`;
const StyledIconCell = styled.td`
  width: 12px;
`;
const StyledIconButton = styled.button<{ isvisible: number }>`
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  pointer-events: ${(props) => (props.isvisible === 1 ? 'visible' : 'none')};
`;
const StyledTickIcon = styled(AddIcon)<{ isvisible: number }>`
  fill: ${(props) => props.theme.colors.darkPurple};
  height: 20px;
  text-align: center;
  vertical-align: middle;
  visibility: ${(props) => (props.isvisible === 1 ? 'visible' : 'hidden')};
  width: 20px;
`;
const StyledAnswerListContainer = styled.div`
  max-height: 200px;
  width: 100%;
  margin-top: 20px;
  overflow-y: auto;
  display: inline-block;
`;
const StyledAnswerContainer = styled.div`
  background-color: ${(props) => props.theme.colors.transparentPalePurple};
  display: inline-block;
  padding: 7px;
  border: 1px solid ${(props) => props.theme.colors.darkPurple};
  border-radius: 4px;
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.darkPurple};
  font-size: 20px;
  margin: 0 10px 10px 0;
  width: max-content;
  height: max-content;
  :last-child {
    margin: 0;
  }
  @media only screen and (max-width: 680px) {
    display: grid;
    width: 100%;
    word-break: break-word;
    hyphens: auto;
    grid-template-columns: auto 20px;
    font-size: 16px;
  }
`;
const StyledCloseIcon = styled(CloseIcon)`
  fill: ${(props) => props.theme.colors.darkPurple};
  height: 20px;
  text-align: center;
  vertical-align: middle;
  width: 20px;
  &:hover {
    fill: ${(props) => props.theme.colors.transparentPurple};
  }
`;
const StyledErrorTd = styled.td<{ isError: boolean }>`
  padding-top: 10px;
  ${(props) => {
    if (props.isError) {
      return `color: red;`;
    }
  }}
  font-size: 14px;
`;
export interface Props {
  className?: string;
  handleSetResponse: (inputAnswer: string[]) => void;
  onClickNext: (responses: string[]) => void;
  maxAnswerAllowed: number;
  value: Array<string> | undefined;
  placeholder?: string;
  regexInput?: RegExp[];
  regexErrorMessage?: string;
}

export default function QuestionMultipleEntry({
  className,
  handleSetResponse,
  onClickNext,
  maxAnswerAllowed = 2,
  value,
  placeholder = 'Type your answer here...',
  regexInput = [/^/],
  regexErrorMessage = '',
}: Props): React.ReactElement {
  const [userEntry, setUserEntry] = useState('');
  const [regErrorMsg, setRegErrorMsg] = useState(regexErrorMessage);
  const answerList = value || [];
  const [userError, setUserError] = useState(false);
  const handleKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    setUserError(false);
    setRegErrorMsg(regexErrorMessage);

    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleAddList(e);
    }
  };
  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setUserEntry(e.target.value);
  };
  const handleAddList = (e: MouseEvent | KeyboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    if (maxAnswerAllowed !== 0 && answerList.length === maxAnswerAllowed) {
      setUserError(true);
      setRegErrorMsg('The limit is ' + maxAnswerAllowed + '!');
      return;
    }
    if (userEntry === '' || !regexInput.some((regex) => regex.test(userEntry))) {
      setUserError(true);
      setRegErrorMsg(regexErrorMessage);
      return;
    }
    handleSetResponse([...answerList, userEntry]);
    onClickNext([...answerList, userEntry]);
    setUserEntry('');
  };
  const handleRemoveValue = (e: MouseEvent, currEmail: string) => {
    e.preventDefault();
    const nextAnswer = answerList.filter((email) => email !== currEmail);
    handleSetResponse(nextAnswer);
    onClickNext(nextAnswer);
  };

  return (
    <>
      <StyledTable>
        <tbody>
          <tr>
            <td>
              <StyledInput
                className={className}
                maxLength={200}
                onChange={(e) => handleInputChange(e)}
                onKeyDown={handleKeyPress}
                placeholder={placeholder}
                value={userEntry}
                isError={userError}
              />
            </td>
            <StyledIconCell>
              <StyledIconButton isvisible={userEntry !== '' ? 1 : 0}>
                <StyledTickIcon
                  isvisible={userEntry !== '' ? 1 : 0}
                  onClick={(e) => handleAddList(e)}
                />
              </StyledIconButton>
            </StyledIconCell>
          </tr>
          <tr>
            <StyledErrorTd color="standardGrey70" isError={userError}>
              {regErrorMsg}
            </StyledErrorTd>
          </tr>
        </tbody>
      </StyledTable>
      {answerList && (
        <StyledAnswerListContainer>
          {answerList.map((value, id) => (
            <StyledAnswerContainer key={id}>
              {value} <StyledCloseIcon onClick={(e) => handleRemoveValue(e, value)} />
            </StyledAnswerContainer>
          ))}
        </StyledAnswerListContainer>
      )}
    </>
  );
}
