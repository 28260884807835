import { FunctionComponent, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import BlueArrow from '@aphrodite/assets/logos/blue-arrow-right.svg';
import PinkArrow from '@aphrodite/assets/logos/pink-arrow-right.svg';
import { ADSText } from '@aphrodite/common-ui';

import { GLOBAL_STORIES_2023 } from '../../constants/UserStoies2023';

const FadeInAnimation = keyframes`
  0% { filter: brightness(0);}
  100% { filter: brightness(1) ;}
`;
const StyledCardDiv = styled.div`
  left: 0px;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  padding-left: 8%;
  padding-right: 8%;
  min-height: 600px;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  background: ${(props) => (props.className === 'Love' ? '#FFE3EB' : '#EAF1FF')};
  margin-top: 75px;
  border-bottom: 10px solid ${(props) => (props.className === 'Love' ? '#CE468B' : '#22B5E5')};
  animation: ${FadeInAnimation} ease-out 0.5s;
  @media only screen and (min-width: 680px) {
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
    height: 50vh;
  }
  @media only screen and (min-width: 1440px) {
    padding-left: 16%;
    padding-right: 16%;
  }
`;
const StyledFlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const StyledUserStory = styled(StyledFlexDiv)`
  gap: 16px;
  @media only screen and (min-width: 680px) {
    flex-direction: row;
    align-items: flex-start;
    gap: 30px;
  }
`;
const StyledImgChunk = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-self: flex-start;
  justify-content: center;
  gap: 16px;
`;
const StyledImg = styled.img`
  align-self: auto;
  width: 250px;
  height: auto;
`;
const StyledTextChunk = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  .Love {
    @media only screen and (min-width: 400px) {
      font-size: 26px;
      line-height: 35px;
    }
    @media only screen and (min-width: 1080px) {
      font-size: 36px;
      line-height: 60px;
    }
  }
`;
const StyledText = styled(ADSText)`
  color: ${(props) => (props.className === 'Love' ? '#CE468B' : '#22B5E5')};
  font-weight: 700;
`;
const StyledNextSlide = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  @media only screen and (min-width: 680px) {
    width: 30px;
    height: 100%;
    align-items: flex-end;
  }
`;
const StyledArrow = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 8%;
  margin-left: auto;
  @media only screen and (min-width: 680px) {
    margin-bottom: 8%;
    margin-top: auto;
  }
`;

interface Props {
  titleLine: string;
  messageLine: string;
  uni: string;
  isLove: boolean;
  imgUrl?: string;
}

export default function LandingStoires(): React.ReactElement {
  const [selected, setSelected] = useState(0);
  const slide = () => {
    if (selected === 2) {
      return setSelected(0);
    }
    return setSelected(selected + 1);
  };
  const UserStory: FunctionComponent<Props> = ({ titleLine, messageLine, uni, isLove, imgUrl }) => {
    return (
      <StyledCardDiv className={isLove ? 'Love' : 'friendship'}>
        <StyledUserStory>
          <StyledImgChunk>
            {isLove ? (
              <StyledText className="Love" weight="semibold">
                LOVE
              </StyledText>
            ) : (
              <StyledText weight="semibold">FRIENDSHIP</StyledText>
            )}
            <StyledImg src={imgUrl} />
          </StyledImgChunk>
          <StyledTextChunk>
            <StyledText className={isLove ? 'Love' : 'friendship'}>{titleLine}</StyledText>
            <ADSText weight="light" wrapperStyle={{ color: '#000000' }}>
              {messageLine}
            </ADSText>
            <ADSText
              weight="semibold"
              size="l"
              wrapperStyle={{ color: '#000000', marginTop: '40px' }}
            >
              From {uni}
            </ADSText>
          </StyledTextChunk>
          <StyledNextSlide onClick={() => slide()}>
            <StyledArrow
              src={isLove ? PinkArrow : BlueArrow}
              className={isLove ? 'Love' : 'friendship'}
            />
          </StyledNextSlide>
        </StyledUserStory>
      </StyledCardDiv>
    );
  };
  return (
    <UserStory
      titleLine={GLOBAL_STORIES_2023[selected].titleLine}
      messageLine={GLOBAL_STORIES_2023[selected].messageLines}
      uni={GLOBAL_STORIES_2023[selected].uni}
      isLove={GLOBAL_STORIES_2023[selected].isLove}
      imgUrl={GLOBAL_STORIES_2023[selected].imgUrl}
    />
  );
}
