import { ReactComponent as DataReportIcon } from '@aphrodite/assets/icons/datareport.svg';
import { ReactComponent as EditIcon } from '@aphrodite/assets/icons/edit.svg';
import { ReactComponent as NaughtyListIcon } from '@aphrodite/assets/icons/flag.svg';
import { ReactComponent as GridIcon } from '@aphrodite/assets/icons/grid.svg';
import { ReactComponent as HeartIcon } from '@aphrodite/assets/icons/heart.svg';
import { ReactComponent as PartnershipIcon } from '@aphrodite/assets/icons/partnership.svg';
import { ReactComponent as ProfileIcon } from '@aphrodite/assets/icons/profile.svg';
import { ReactComponent as PlayCupidIcon } from '@aphrodite/assets/logos/bow-and-arrow.svg';

export interface INavItem {
  to: string;
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  text: string;
}

export const NAV_ITEMS: { [key: string]: INavItem } = {
  DASHBOARD: {
    to: '/dashboard',
    Icon: GridIcon,
    text: 'Dashboard',
  },
  QUESTIONNAIRE: {
    to: '/dashboard/questionnaire',
    Icon: EditIcon,
    text: 'Questionnaire',
  },
  PROFILE: {
    to: '/dashboard/profile',
    Icon: ProfileIcon,
    text: 'Profile',
  },
  PLAY_CUPID: {
    to: '/dashboard/play-cupid',
    Icon: PlayCupidIcon,
    text: 'Play Cupid',
  },
  RESULTS: {
    to: '/dashboard/results',
    Icon: HeartIcon,
    text: 'Results',
  },
  PARTNERSHIP: {
    to: '/dashboard/partnerships',
    Icon: PartnershipIcon,
    text: 'Partner Deals',
  },
  DATA_REPORT: {
    to: '/dashboard/reports',
    Icon: DataReportIcon,
    text: 'Past Data',
  },
  NAUGHTY_LIST: {
    to: '/dashboard/naughty-list',
    Icon: NaughtyListIcon,
    text: 'Naughty List',
  },
  BLOCK_LIST: {
    to: '/dashboard/block-list',
    Icon: NaughtyListIcon,
    text: 'Block List',
  },
};
